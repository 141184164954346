.arco {
  --red-1: 255, 236, 232;
  --red-2: 253, 205, 197;
  --red-3: 251, 172, 163;
  --red-4: 249, 137, 129;
  --red-5: 247, 101, 96;
  --red-6: 245, 63, 63;
  --red-7: 203, 39, 45;
  --red-8: 161, 21, 30;
  --red-9: 119, 8, 19;
  --red-10: 77, 0, 10;
  --orangered-1: 255, 243, 232;
  --orangered-2: 253, 221, 195;
  --orangered-3: 252, 197, 159;
  --orangered-4: 250, 172, 123;
  --orangered-5: 249, 144, 87;
  --orangered-6: 247, 114, 52;
  --orangered-7: 204, 81, 32;
  --orangered-8: 162, 53, 17;
  --orangered-9: 119, 31, 6;
  --orangered-10: 77, 14, 0;
  --orange-1: 255, 247, 232;
  --orange-2: 255, 228, 186;
  --orange-3: 255, 207, 139;
  --orange-4: 255, 182, 93;
  --orange-5: 255, 154, 46;
  --orange-6: 255, 125, 0;
  --orange-7: 210, 95, 0;
  --orange-8: 166, 69, 0;
  --orange-9: 121, 46, 0;
  --orange-10: 77, 27, 0;
  --gold-1: 255, 252, 232;
  --gold-2: 253, 244, 191;
  --gold-3: 252, 233, 150;
  --gold-4: 250, 220, 109;
  --gold-5: 249, 204, 69;
  --gold-6: 247, 186, 30;
  --gold-7: 204, 146, 19;
  --gold-8: 162, 109, 10;
  --gold-9: 119, 75, 4;
  --gold-10: 77, 45, 0;
  --yellow-1: 254, 255, 232;
  --yellow-2: 254, 254, 190;
  --yellow-3: 253, 250, 148;
  --yellow-4: 252, 242, 107;
  --yellow-5: 251, 232, 66;
  --yellow-6: 250, 220, 25;
  --yellow-7: 207, 175, 15;
  --yellow-8: 163, 132, 8;
  --yellow-9: 120, 93, 3;
  --yellow-10: 77, 56, 0;
  --lime-1: 252, 255, 232;
  --lime-2: 237, 248, 187;
  --lime-3: 220, 241, 144;
  --lime-4: 201, 233, 104;
  --lime-5: 181, 226, 65;
  --lime-6: 159, 219, 29;
  --lime-7: 126, 183, 18;
  --lime-8: 95, 148, 10;
  --lime-9: 67, 112, 4;
  --lime-10: 42, 77, 0;
  --green-1: 232, 255, 234;
  --green-2: 175, 240, 181;
  --green-3: 123, 225, 136;
  --green-4: 76, 210, 99;
  --green-5: 35, 195, 67;
  --green-6: 0, 180, 42;
  --green-7: 0, 154, 41;
  --green-8: 0, 128, 38;
  --green-9: 0, 102, 34;
  --green-10: 0, 77, 28;
  --cyan-1: 232, 255, 251;
  --cyan-2: 183, 244, 236;
  --cyan-3: 137, 233, 224;
  --cyan-4: 94, 223, 214;
  --cyan-5: 55, 212, 207;
  --cyan-6: 20, 201, 201;
  --cyan-7: 13, 165, 170;
  --cyan-8: 7, 130, 139;
  --cyan-9: 3, 97, 108;
  --cyan-10: 0, 66, 77;
  --blue-1: 232, 247, 255;
  --blue-2: 195, 231, 254;
  --blue-3: 159, 212, 253;
  --blue-4: 123, 192, 252;
  --blue-5: 87, 169, 251;
  --blue-6: 52, 145, 250;
  --blue-7: 32, 108, 207;
  --blue-8: 17, 75, 163;
  --blue-9: 6, 48, 120;
  --blue-10: 0, 26, 77;
  --arcoblue-1: 232, 243, 255;
  --arcoblue-2: 190, 218, 255;
  --arcoblue-3: 148, 191, 255;
  --arcoblue-4: 106, 161, 255;
  --arcoblue-5: 64, 128, 255;
  --arcoblue-6: 22, 93, 255;
  --arcoblue-7: 14, 66, 210;
  --arcoblue-8: 7, 44, 166;
  --arcoblue-9: 3, 26, 121;
  --arcoblue-10: 0, 13, 77;
  --purple-1: 245, 232, 255;
  --purple-2: 221, 190, 246;
  --purple-3: 195, 150, 237;
  --purple-4: 168, 113, 227;
  --purple-5: 141, 78, 218;
  --purple-6: 114, 46, 209;
  --purple-7: 85, 29, 176;
  --purple-8: 60, 16, 143;
  --purple-9: 39, 6, 110;
  --purple-10: 22, 0, 77;
  --pinkpurple-1: 255, 232, 251;
  --pinkpurple-2: 247, 186, 239;
  --pinkpurple-3: 240, 142, 230;
  --pinkpurple-4: 232, 101, 223;
  --pinkpurple-5: 225, 62, 219;
  --pinkpurple-6: 217, 26, 217;
  --pinkpurple-7: 176, 16, 182;
  --pinkpurple-8: 138, 9, 147;
  --pinkpurple-9: 101, 3, 112;
  --pinkpurple-10: 66, 0, 77;
  --magenta-1: 255, 232, 241;
  --magenta-2: 253, 194, 219;
  --magenta-3: 251, 157, 199;
  --magenta-4: 249, 121, 183;
  --magenta-5: 247, 84, 168;
  --magenta-6: 245, 49, 157;
  --magenta-7: 203, 30, 131;
  --magenta-8: 161, 16, 105;
  --magenta-9: 119, 6, 79;
  --magenta-10: 77, 0, 52;
  --gray-1: 247, 248, 250;
  --gray-2: 242, 243, 245;
  --gray-3: 229, 230, 235;
  --gray-4: 201, 205, 212;
  --gray-5: 169, 174, 184;
  --gray-6: 134, 144, 156;
  --gray-7: 107, 119, 133;
  --gray-8: 78, 89, 105;
  --gray-9: 39, 46, 59;
  --gray-10: 29, 33, 41;
  --success-1: var(--green-1);
  --success-2: var(--green-2);
  --success-3: var(--green-3);
  --success-4: var(--green-4);
  --success-5: var(--green-5);
  --success-6: var(--green-6);
  --success-7: var(--green-7);
  --success-8: var(--green-8);
  --success-9: var(--green-9);
  --success-10: var(--green-10);
  --primary-1: 232, 247, 255;
  --primary-2: 195, 231, 254;
  --primary-3: 159, 212, 253;
  --primary-4: 123, 192, 252;
  --primary-5: 87, 169, 251;
  --primary-6: 52, 145, 250;
  --primary-7: 32, 108, 207;
  --primary-8: 17, 75, 163;
  --primary-9: 6, 48, 120;
  --primary-10: 0, 26, 77;
  --danger-1: var(--red-1);
  --danger-2: var(--red-2);
  --danger-3: var(--red-3);
  --danger-4: var(--red-4);
  --danger-5: var(--red-5);
  --danger-6: var(--red-6);
  --danger-7: var(--red-7);
  --danger-8: var(--red-8);
  --danger-9: var(--red-9);
  --danger-10: var(--red-10);
  --warning-1: var(--orange-1);
  --warning-2: var(--orange-2);
  --warning-3: var(--orange-3);
  --warning-4: var(--orange-4);
  --warning-5: var(--orange-5);
  --warning-6: var(--orange-6);
  --warning-7: var(--orange-7);
  --warning-8: var(--orange-8);
  --warning-9: var(--orange-9);
  --warning-10: var(--orange-10);
  --link-1: var(--arcoblue-1);
  --link-2: var(--arcoblue-2);
  --link-3: var(--arcoblue-3);
  --link-4: var(--arcoblue-4);
  --link-5: var(--arcoblue-5);
  --link-6: var(--arcoblue-6);
  --link-7: var(--arcoblue-7);
  --link-8: var(--arcoblue-8);
  --link-9: var(--arcoblue-9);
  --link-10: var(--arcoblue-10);

  --color-white: #ffffff;
  --color-black: #000000;
  --color-border: rgb(var(--gray-3));
  --color-bg-popup: var(--color-bg-5);
  --color-bg-1: #fff;
  --color-bg-2: #fff;
  --color-bg-3: #fff;
  --color-bg-4: #fff;
  --color-bg-5: #fff;
  --color-bg-white: #fff;
  --color-neutral-1: rgb(var(--gray-1));
  --color-neutral-2: rgb(var(--gray-2));
  --color-neutral-3: rgb(var(--gray-3));
  --color-neutral-4: rgb(var(--gray-4));
  --color-neutral-5: rgb(var(--gray-5));
  --color-neutral-6: rgb(var(--gray-6));
  --color-neutral-7: rgb(var(--gray-7));
  --color-neutral-8: rgb(var(--gray-8));
  --color-neutral-9: rgb(var(--gray-9));
  --color-neutral-10: rgb(var(--gray-10));
  --color-text-1: var(--color-neutral-10);
  --color-text-2: var(--color-neutral-8);
  --color-text-3: var(--color-neutral-6);
  --color-text-4: var(--color-neutral-4);
  --color-border-1: var(--color-neutral-2);
  --color-border-2: var(--color-neutral-3);
  --color-border-3: var(--color-neutral-4);
  --color-border-4: var(--color-neutral-6);
  --color-fill-1: var(--color-neutral-1);
  --color-fill-2: #fafafa;
  --color-fill-3: var(--color-neutral-3);
  --color-fill-4: var(--color-neutral-4);
  --color-primary-light-1: rgb(var(--primary-1));
  --color-primary-light-2: rgb(var(--primary-2));
  --color-primary-light-3: rgb(var(--primary-3));
  --color-primary-light-4: rgb(var(--primary-4));
  --color-secondary: var(--color-neutral-2);
  --color-secondary-hover: var(--color-neutral-3);
  --color-secondary-active: var(--color-neutral-4);
  --color-secondary-disabled: var(--color-neutral-1);
  --color-danger-light-1: rgb(var(--danger-1));
  --color-danger-light-2: rgb(var(--danger-2));
  --color-danger-light-3: rgb(var(--danger-3));
  --color-danger-light-4: rgb(var(--danger-4));
  --color-success-light-1: rgb(var(--success-1));
  --color-success-light-2: rgb(var(--success-2));
  --color-success-light-3: rgb(var(--success-3));
  --color-success-light-4: rgb(var(--success-4));
  --color-warning-light-1: rgb(var(--warning-1));
  --color-warning-light-2: rgb(var(--warning-2));
  --color-warning-light-3: rgb(var(--warning-3));
  --color-warning-light-4: rgb(var(--warning-4));
  --color-link-light-1: rgb(var(--link-1));
  --color-link-light-2: rgb(var(--link-2));
  --color-link-light-3: rgb(var(--link-3));
  --color-link-light-4: rgb(var(--link-4));
  --border-radius-none: 0;
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 8px;
  --border-radius-circle: 50%;
  --color-tooltip-bg: rgb(var(--gray-10));
  --color-spin-layer-bg: rgba(255, 255, 255, 0.6);
  --color-menu-dark-bg: #232324;
  --color-menu-light-bg: #ffffff;
  --color-menu-dark-hover: rgba(255, 255, 255, 0.04);
  --color-mask-bg: rgba(29, 33, 41, 0.6);
}
.arco-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  width: 1em;
  height: 1em;
  vertical-align: -2px;
  stroke: currentColor;
}
.arco-icon[fill='currentColor'] {
  fill: currentColor;
  stroke: none;
}
.arco-icon[stroke='currentColor'] {
  stroke: currentColor;
  fill: none;
}
.arco-icon[fill='currentColor'][stroke='currentColor'] {
  fill: currentColor;
  stroke: currentColor;
}
.arco-icon-loading {
  animation: arco-loading-circle 1s infinite cubic-bezier(0, 0, 1, 1);
}
@keyframes arco-loading-circle {
  100% {
    transform: rotate(360deg);
  }
}
.arco-icon-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 0;
}
.arco-icon-hover .arco-icon {
  position: relative;
  vertical-align: -0.09em;
}
.arco-icon-hover::before {
  content: '';
  position: absolute;
  display: block;
  border-radius: var(--border-radius-circle);
  background-color: transparent;
  box-sizing: border-box;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-icon-hover:hover::before {
  background-color: var(--color-fill-2);
}
.arco-icon-hover.arco-icon-hover-disabled::before {
  opacity: 0;
}
.arco-icon-hover::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-mini::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-small::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-large::before {
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-huge::before {
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
}
.arco-popover {
  z-index: 99999 !important;
}
.arco-tree-node:hover {
  background-color: rgb(247, 248, 250);
}
.fadeInStandard-enter,
.fadeInStandard-appear {
  opacity: 0;
}
.fadeInStandard-enter-active,
.fadeInStandard-appear-active {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.fadeInStandard-exit {
  opacity: 1;
}
.fadeInStandard-exit-active {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.fadeIn-enter,
.fadeIn-appear {
  opacity: 0;
}
.fadeIn-enter-active,
.fadeIn-appear-active {
  opacity: 1;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
}
.fadeIn-exit {
  opacity: 1;
}
.fadeIn-exit-active {
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
}
.slideDynamicOrigin-enter,
.slideDynamicOrigin-appear {
  opacity: 0;
  transform-origin: 0 0;
  transform: scaleY(0.9) translateZ(0);
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideDynamicOrigin-enter-active,
.slideDynamicOrigin-appear-active {
  opacity: 1;
  transform-origin: 0 0;
  transform: scaleY(1) translateZ(0);
}
.slideDynamicOrigin-exit {
  opacity: 1;
  transform-origin: 0 0;
  transform: scaleY(1) translateZ(0);
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideDynamicOrigin-exit-active {
  opacity: 0;
  transform-origin: 0 0;
  transform: scaleY(0.9) translateZ(0);
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideLeft-enter,
.slideLeft-appear {
  transform: translateX(-100%);
}
.slideLeft-enter-active,
.slideLeft-appear-active {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideLeft-exit {
  transform: translateX(0);
}
.slideLeft-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideRight-enter,
.slideRight-appear {
  transform: translateX(100%);
}
.slideRight-enter-active,
.slideRight-appear-active {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideRight-exit {
  transform: translateX(0);
}
.slideRight-exit-active {
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideTop-enter,
.slideTop-appear {
  transform: translateY(-100%);
}
.slideTop-enter-active,
.slideTop-appear-active {
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideTop-exit {
  transform: translateY(0);
}
.slideTop-exit-active {
  transform: translateY(-100%);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideBottom-enter,
.slideBottom-appear {
  transform: translateY(100%);
}
.slideBottom-enter-active,
.slideBottom-appear-active {
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideBottom-exit {
  transform: translateY(0);
}
.slideBottom-exit-active {
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.zoomIn-enter,
.zoomIn-appear {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.zoomIn-enter-active,
.zoomIn-appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.3s cubic-bezier(0.34, 0.69, 0.1, 1),
    transform 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.zoomIn-exit {
  opacity: 1;
  transform: scale(1, 1);
}
.zoomIn-exit-active {
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInFadeOut-enter,
.zoomInFadeOut-appear {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.zoomInFadeOut-enter-active,
.zoomInFadeOut-appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInFadeOut-exit {
  opacity: 1;
  transform: scale(1, 1);
}
.zoomInFadeOut-exit-active {
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInBig-enter,
.zoomInBig-appear {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.zoomInBig-enter-active,
.zoomInBig-appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.2s cubic-bezier(0, 0, 1, 1), transform 0.2s cubic-bezier(0, 0, 1, 1);
}
.zoomInBig-exit {
  opacity: 1;
  transform: scale(1, 1);
}
.zoomInBig-exit-active {
  opacity: 0;
  transform: scale(0.2, 0.2);
  transition: opacity 0.2s cubic-bezier(0, 0, 1, 1), transform 0.2s cubic-bezier(0, 0, 1, 1);
}
.zoomInLeft-enter,
.zoomInLeft-appear {
  opacity: 0.1;
  transform-origin: 0 50%;
  transform: scale(0.1, 0.1);
}
.zoomInLeft-enter-active,
.zoomInLeft-appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.3s cubic-bezier(0, 0, 1, 1), transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInLeft-exit {
  opacity: 1;
  transform-origin: 0 50%;
  transform: scale(1, 1);
}
.zoomInLeft-exit-active {
  opacity: 0.1;
  transform: scale(0.1, 0.1);
  transition: opacity 0.3s cubic-bezier(0, 0, 1, 1), transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInTop-enter,
.zoomInTop-appear {
  opacity: 0;
  transform-origin: 0% 0%;
  transform: scaleY(0.8) translateZ(0);
}
.zoomInTop-enter-active,
.zoomInTop-appear-active {
  opacity: 1;
  transform-origin: 0% 0%;
  transform: scaleY(1) translateZ(0);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInTop-exit {
  opacity: 1;
  transform-origin: 0% 0%;
  transform: scaleY(1) translateZ(0);
}
.zoomInTop-exit-active {
  opacity: 0;
  transform-origin: 0% 0%;
  transform: scaleY(0.8) translateZ(0);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInBottom-enter,
.zoomInBottom-appear {
  opacity: 0;
  transform-origin: 100% 100%;
  transform: scaleY(0.8) translateZ(0);
}
.zoomInBottom-enter-active,
.zoomInBottom-appear-active {
  opacity: 1;
  transform-origin: 100% 100%;
  transform: scaleY(1) translateZ(0);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomInBottom-exit {
  opacity: 1;
  transform-origin: 100% 100%;
  transform: scaleY(1) translateZ(0);
}
.zoomInBottom-exit-active {
  opacity: 0;
  transform-origin: 100% 100%;
  transform: scaleY(0.8) translateZ(0);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
@keyframes arco-draggable-item-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.arco-draggable-item {
  box-sizing: border-box;
  list-style: none;
  user-select: none;
}
.arco-draggable-item-dragging {
  opacity: 0.3;
}
.arco-draggable-item-dragover.arco-draggable-item-gap-left {
  box-shadow: -1px 0 rgb(var(--primary-6));
}
.arco-draggable-item-dragover.arco-draggable-item-gap-right {
  box-shadow: 1px 0 rgb(var(--primary-6));
}
.arco-draggable-item-dragover.arco-draggable-item-gap-top {
  box-shadow: 0 -1px rgb(var(--primary-6));
}
.arco-draggable-item-dragover.arco-draggable-item-gap-bottom {
  box-shadow: 0 1px rgb(var(--primary-6));
}
.arco-draggable-item-dragged {
  animation: arco-draggable-item-blink 0.8s;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
.arco-picker {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px 11px 4px 4px;
  line-height: 1.5715;
  border-radius: var(--border-radius-small);
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
  box-sizing: border-box;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-picker-input {
  display: inline-flex;
  flex: 1;
}
.arco-picker input {
  text-align: left;
  padding: 0;
  padding-left: 8px;
  border: none;
  width: 100%;
  color: var(--color-text-1);
  background-color: transparent;
  line-height: 1.5715;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-picker input::placeholder {
  color: var(--color-text-3);
}
.arco-picker-input-placeholder input {
  color: var(--color-text-3);
}
.arco-picker-suffix {
  width: 14px;
  margin-left: 4px;
  text-align: center;
}
.arco-picker-suffix-icon {
  color: var(--color-text-2);
}
.arco-picker .arco-picker-clear-icon {
  display: none;
  font-size: 12px;
  color: var(--color-text-2);
}
.arco-picker:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-picker:not(.arco-picker-disabled):hover .arco-picker-clear-icon {
  display: inline-block;
}
.arco-picker:not(.arco-picker-disabled):hover .arco-picker-suffix .arco-picker-clear-icon + span {
  display: none;
}
.arco-picker-error {
  background-color: var(--color-danger-light-1);
  border-color: transparent;
}
.arco-picker-error:hover {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-picker-focused {
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-picker-focused,
.arco-picker-focused:hover {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
}
.arco-picker-focused.arco-picker-error {
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-picker-focused .arco-picker-input-active input,
.arco-picker-focused:hover .arco-picker-input-active input {
  background: var(--color-primary-light-1);
}
.arco-picker input[disabled] {
  cursor: not-allowed;
  color: var(--color-text-4);
  -webkit-text-fill-color: var(--color-text-4);
}
.arco-picker input[disabled]::placeholder {
  color: var(--color-text-4);
}
.arco-picker-disabled,
.arco-picker-disabled:hover {
  color: var(--color-text-4);
  border-color: transparent;
  background-color: var(--color-fill-2);
  cursor: not-allowed;
}
.arco-picker-disabled input[disabled],
.arco-picker-disabled:hover input[disabled] {
  cursor: not-allowed;
  color: var(--color-text-4);
  -webkit-text-fill-color: var(--color-text-4);
}
.arco-picker-disabled input[disabled]::placeholder,
.arco-picker-disabled:hover input[disabled]::placeholder {
  color: var(--color-text-4);
}
.arco-picker-separator {
  min-width: 10px;
  padding: 0 8px;
  color: var(--color-text-3);
}
.arco-picker-disabled .arco-picker-separator {
  color: var(--color-text-4);
}
.arco-picker-disabled .arco-picker-suffix-icon {
  color: var(--color-text-4);
}
.arco-picker-size-mini {
  height: 24px;
}
.arco-picker-size-mini input {
  font-size: 12px;
}
.arco-picker-size-small {
  height: 28px;
}
.arco-picker-size-small input {
  font-size: 14px;
}
.arco-picker-size-default {
  height: 32px;
}
.arco-picker-size-default input {
  font-size: 14px;
}
.arco-picker-size-large {
  height: 36px;
}
.arco-picker-size-large input {
  font-size: 14px;
}
.arco-affix {
  position: fixed;
  z-index: 999;
}
.arco-alert {
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
  padding: 8px 15px;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  line-height: 1.5715;
}
.arco-alert-with-title {
  padding: 15px 15px;
}
.arco-alert-with-title {
  align-items: flex-start;
}
.arco-alert-info {
  border: 1px solid transparent;
  background-color: var(--color-primary-light-1);
}
.arco-alert-success {
  border: 1px solid transparent;
  background-color: var(--color-success-light-1);
}
.arco-alert-warning {
  border: 1px solid transparent;
  background-color: var(--color-warning-light-1);
}
.arco-alert-error {
  border: 1px solid transparent;
  background-color: var(--color-danger-light-1);
}
.arco-alert-banner {
  border: none;
  border-radius: 0;
}
.arco-alert-content-wrapper {
  position: relative;
  flex: 1;
}
.arco-alert-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 4px;
}
.arco-alert-info .arco-alert-title {
  color: var(--color-text-1);
}
.arco-alert-info .arco-alert-content {
  color: var(--color-text-1);
}
.arco-alert-info.arco-alert-with-title .arco-alert-content {
  color: var(--color-text-2);
}
.arco-alert-success .arco-alert-title {
  color: var(--color-text-1);
}
.arco-alert-success .arco-alert-content {
  color: var(--color-text-1);
}
.arco-alert-success.arco-alert-with-title .arco-alert-content {
  color: var(--color-text-2);
}
.arco-alert-warning .arco-alert-title {
  color: var(--color-text-1);
}
.arco-alert-warning .arco-alert-content {
  color: var(--color-text-1);
}
.arco-alert-warning.arco-alert-with-title .arco-alert-content {
  color: var(--color-text-2);
}
.arco-alert-error .arco-alert-title {
  color: var(--color-text-1);
}
.arco-alert-error .arco-alert-content {
  color: var(--color-text-1);
}
.arco-alert-error.arco-alert-with-title .arco-alert-content {
  color: var(--color-text-2);
}
.arco-alert-icon-wrapper {
  margin-right: 8px;
  height: 22.001px;
  display: flex;
  align-items: center;
}
.arco-alert-icon-wrapper svg {
  font-size: 16px;
}
.arco-alert-with-title .arco-alert-icon-wrapper {
  height: 24px;
}
.arco-alert-with-title .arco-alert-icon-wrapper svg {
  font-size: 18px;
}
.arco-alert-info .arco-alert-icon-wrapper svg {
  color: rgb(var(--primary-6));
}
.arco-alert-success .arco-alert-icon-wrapper svg {
  color: rgb(var(--success-6));
}
.arco-alert-warning .arco-alert-icon-wrapper svg {
  color: rgb(var(--warning-6));
}
.arco-alert-error .arco-alert-icon-wrapper svg {
  color: rgb(var(--danger-6));
}
.arco-alert-close-btn {
  box-sizing: border-box;
  padding: 0;
  border: none;
  outline: none;
  font-size: 12px;
  color: var(--color-text-2);
  background-color: transparent;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1);
  margin-left: 8px;
  top: 4px;
  right: 0;
}
.arco-alert-close-btn:hover {
  color: var(--color-text-1);
}
.arco-alert-action + .arco-alert-close-btn {
  margin-left: 8px;
}
.arco-alert-action {
  margin-left: 8px;
}
.arco-alert-with-title .arco-alert-close-btn {
  margin-top: 0;
  margin-right: 0;
}
.arco-anchor {
  position: relative;
  width: 150px;
  overflow: auto;
}
.arco-anchor-line-slider {
  position: absolute;
  height: 12px;
  width: 2px;
  margin-top: 9.0005px;
  background-color: rgb(var(--primary-6));
  left: 0;
  top: 0;
  transition: top 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
  z-index: 1;
}
.arco-anchor-list {
  margin-left: 6px;
  position: relative;
}
.arco-anchor-list::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: var(--color-fill-3);
  width: 2px;
  left: -6px;
}
.arco-anchor-link {
  margin-bottom: 2px;
}
.arco-anchor-link-title {
  color: var(--color-text-2);
  line-height: 1.5715;
  font-size: 14px;
  margin-bottom: 2px;
  padding: 4px 8px;
  text-decoration: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  border-radius: var(--border-radius-small);
}
.arco-anchor-link-title:hover {
  background-color: var(--color-fill-2);
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-anchor-link-active > .arco-anchor-link-title {
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-anchor-link .arco-anchor-link {
  margin-left: 16px;
}
.arco-anchor-lineless .arco-anchor-list {
  margin-left: 0;
}
.arco-anchor-lineless .arco-anchor-list::before {
  display: none;
}
.arco-anchor-lineless .arco-anchor-link-active > .arco-anchor-link-title {
  background-color: var(--color-fill-2);
  color: rgb(var(--primary-6));
  font-weight: 500;
}
.arco-autocomplete-popup .arco-select-popup {
  border: 1px solid var(--color-fill-3);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.arco-autocomplete-popup .arco-select-popup .arco-select-popup-inner {
  padding: 4px 0;
  max-height: 200px;
}
.arco-autocomplete-popup .arco-select-popup .arco-select-option {
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 36px;
  color: var(--color-text-1);
  background-color: var(--color-bg-popup);
}
.arco-autocomplete-popup .arco-select-popup .arco-select-option-selected {
  color: var(--color-text-1);
  background-color: var(--color-bg-popup);
}
.arco-autocomplete-popup .arco-select-popup .arco-select-option-hover {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}
.arco-autocomplete-popup .arco-select-popup .arco-select-option-disabled {
  color: var(--color-text-4);
  background-color: var(--color-bg-popup);
}
.arco-autocomplete-popup .arco-select-popup .arco-select-option-selected {
  font-weight: 500;
}
.arco-avatar {
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: var(--color-fill-4);
  white-space: nowrap;
  color: var(--color-white);
  box-sizing: border-box;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.arco-avatar-circle {
  border-radius: var(--border-radius-circle);
}
.arco-avatar-circle .arco-avatar-image {
  border-radius: var(--border-radius-circle);
  overflow: hidden;
}
.arco-avatar-square {
  border-radius: var(--border-radius-medium);
}
.arco-avatar-square .arco-avatar-image {
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}
.arco-avatar-text {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  transform: translateX(-50%);
  font-weight: 500;
  line-height: 1;
}
.arco-avatar-image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.arco-avatar-image img,
.arco-avatar-image picture {
  width: 100%;
  height: 100%;
}
.arco-avatar-trigger-icon-button {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  bottom: -4px;
  right: -4px;
  color: var(--color-fill-4);
  font-size: 12px;
  border-radius: var(--border-radius-circle);
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: var(--color-neutral-2);
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
  z-index: 1;
}
.arco-avatar-trigger-icon-mask {
  position: absolute;
  display: flex;
  opacity: 0;
  z-index: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 16px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  border-radius: var(--border-radius-medium);
  background-color: rgba(29, 33, 41, 0.6);
  color: var(--color-white);
}
.arco-avatar-circle .arco-avatar-trigger-icon-mask {
  border-radius: var(--border-radius-circle);
}
.arco-avatar-with-trigger-icon {
  cursor: pointer;
}
.arco-avatar-with-trigger-icon:hover .arco-avatar-trigger-icon-mask {
  z-index: 2;
  opacity: 1;
}
.arco-avatar-with-trigger-icon:hover .arco-avatar-trigger-icon-button {
  background-color: var(--color-neutral-3);
}
.arco-avatar-group {
  display: inline-block;
  line-height: 0;
}
.arco-avatar-group-max-count-avatar {
  cursor: default;
  color: var(--color-white);
  font-size: 20px;
}
.arco-avatar-group .arco-avatar {
  border: 2px solid var(--color-bg-2);
}
.arco-avatar-group .arco-avatar:not(:first-child) {
  margin-left: -10px;
}
.arco-avatar-group-popover .arco-avatar:not(:first-child) {
  margin-left: 4px;
}
.arco-backtop {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
  cursor: pointer;
}
.arco-backtop-button {
  width: 40px;
  height: 40px;
  font-size: 12px;
  text-align: center;
  outline: none;
  background-color: rgb(var(--primary-6));
  border-radius: var(--border-radius-circle);
  color: var(--color-white);
  transition: all 0.2s cubic-bezier(0, 0, 1, 1);
  cursor: pointer;
  border: none;
}
.arco-backtop-button:hover {
  background-color: rgb(var(--primary-5));
}
.arco-backtop-button svg {
  font-size: 14px;
}
.arco-badge {
  display: inline-block;
  position: relative;
  line-height: 1;
}
.arco-badge-number,
.arco-badge-dot,
.arco-badge-text,
.arco-badge-custom-dot {
  position: absolute;
  z-index: 2;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
  top: 2px;
  right: 2px;
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}
.arco-badge-custom-dot {
  background-color: var(--color-bg-2);
}
.arco-badge-number,
.arco-badge-text {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  font-weight: 500;
  padding: 0 6px;
  font-size: 12px;
  color: var(--color-white);
  background-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 2px var(--color-bg-2);
}
.arco-badge-dot {
  width: 6px;
  height: 6px;
  background-color: rgb(var(--danger-6));
  border-radius: var(--border-radius-circle);
  box-shadow: 0 0 0 2px var(--color-bg-2);
}
.arco-badge-no-children .arco-badge-dot,
.arco-badge-no-children .arco-badge-number,
.arco-badge-no-children .arco-badge-text {
  position: relative;
  display: inline-block;
  top: unset;
  right: unset;
  transform: none;
}
.arco-badge-status-wrapper {
  display: inline-flex;
  align-items: center;
}
.arco-badge-status-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: var(--border-radius-circle);
}
.arco-badge-status-default {
  background-color: var(--color-fill-4);
}
.arco-badge-status-processing {
  background-color: rgb(var(--primary-6));
}
.arco-badge-status-success {
  background-color: rgb(var(--success-6));
}
.arco-badge-status-warning {
  background-color: rgb(var(--warning-6));
}
.arco-badge-status-error {
  background-color: rgb(var(--danger-6));
}
.arco-badge-color-red {
  background-color: rgb(var(--danger-6));
}
.arco-badge-color-orangered {
  background-color: #f77234;
}
.arco-badge-color-orange {
  background-color: rgb(var(--orange-6));
}
.arco-badge-color-gold {
  background-color: rgb(var(--gold-6));
}
.arco-badge-color-lime {
  background-color: rgb(var(--lime-6));
}
.arco-badge-color-green {
  background-color: rgb(var(--success-6));
}
.arco-badge-color-cyan {
  background-color: rgb(var(--cyan-6));
}
.arco-badge-color-arcoblue {
  background-color: rgb(var(--primary-6));
}
.arco-badge-color-purple {
  background-color: rgb(var(--purple-6));
}
.arco-badge-color-pinkpurple {
  background-color: rgb(var(--pinkpurple-6));
}
.arco-badge-color-magenta {
  background-color: rgb(var(--magenta-6));
}
.arco-badge-color-gray {
  background-color: rgb(var(--gray-4));
}
.arco-badge .arco-badge-status-text {
  color: var(--color-text-1);
  margin-left: 8px;
  font-size: 12px;
  line-height: 1.5715;
}
.arco-badge-number-text {
  display: inline-block;
  animation: arco-badge-scale 0.5s cubic-bezier(0.3, 1.3, 0.3, 1);
}
@keyframes arco-badge-scale {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
.badge-zoom-enter,
.badge-zoom-appear {
  transform-origin: center;
  transform: translate(50%, -50%) scale(0.2, 0.2);
}
.badge-zoom-enter-active,
.badge-zoom-appear-active {
  opacity: 1;
  transform-origin: center;
  transform: translate(50%, -50%) scale(1, 1);
  transition: opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.badge-zoom-exit {
  opacity: 1;
  transform-origin: center;
  transform: translate(50%, -50%) scale(1, 1);
}
.badge-zoom-exit-active {
  opacity: 0;
  transform-origin: center;
  transform: translate(50%, -50%) scale(0.2, 0.2);
  transition: opacity 0.3s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-breadcrumb {
  display: inline-block;
  font-size: 14px;
  color: var(--color-text-2);
}
.arco-breadcrumb-icon {
  color: var(--color-text-2);
}
.arco-breadcrumb-item {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  vertical-align: middle;
  line-height: 24px;
  color: var(--color-text-2);
}
.arco-breadcrumb-item > .arco-icon {
  color: var(--color-text-3);
}
.arco-breadcrumb-item a {
  display: inline-block;
  border-radius: var(--border-radius-small);
  padding: 0 4px;
  margin: 0 -4px;
  text-decoration: none;
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-breadcrumb-item a:hover {
  background-color: var(--color-fill-2);
  color: rgb(var(--link-6));
}
.arco-breadcrumb-item:last-child {
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-breadcrumb-item-ellipses {
  display: inline-block;
  position: relative;
  top: -3px;
  padding: 0 4px;
  color: var(--color-text-2);
}
.arco-breadcrumb-item-separator {
  display: inline-block;
  margin: 0 4px;
  vertical-align: middle;
  line-height: 24px;
  color: var(--color-text-4);
}
.arco-breadcrumb-item-with-dropdown {
  cursor: pointer;
}
.arco-breadcrumb-item-dropdown-icon {
  font-size: 12px;
  margin-left: 4px;
  color: var(--color-text-2);
}
.arco-breadcrumb-item-dropdown-icon-active svg {
  transform: rotate(180deg);
}
.arco-btn {
  display: inline-block;
  position: relative;
  outline: none;
  font-weight: 400;
  appearance: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  box-sizing: border-box;
  line-height: 1.5715;
}
.arco-btn > a:only-child {
  color: currentColor;
}
.arco-btn:active {
  transition: none;
}
.arco-btn-long {
  display: block;
  width: 100%;
}
.arco-btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.arco-btn-link:not([href]) {
  color: var(--color-text-4);
}
.arco-btn-link:hover {
  text-decoration: none;
}
.arco-btn-loading {
  cursor: default;
  position: relative;
}
.arco-btn-loading::before {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: block;
  background: #fff;
  border-radius: inherit;
  opacity: 0.4;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
  pointer-events: none;
}
.arco-btn-loading-fixed-width {
  transition: none;
}
.arco-btn-two-chinese-chars > *:not(svg) {
  letter-spacing: 0.3em;
  margin-right: -0.3em;
}
a.arco-btn-icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}
.arco-btn-outline:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--primary-6));
  border: 1px solid rgb(var(--primary-6));
}
.arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: rgb(var(--primary-5));
  color: rgb(var(--primary-5));
  background-color: transparent;
}
.arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: rgb(var(--primary-7));
  color: rgb(var(--primary-7));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-disabled {
  background-color: transparent;
  color: var(--color-primary-light-3);
  border: 1px solid var(--color-primary-light-3);
  cursor: not-allowed;
}
.arco-btn-outline.arco-btn-status-warning:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--warning-6));
  border-color: rgb(var(--warning-6));
}
.arco-btn-outline.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: rgb(var(--warning-5));
  color: rgb(var(--warning-5));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: rgb(var(--warning-7));
  color: rgb(var(--warning-7));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-warning.arco-btn-disabled {
  color: var(--color-warning-light-3);
  background-color: transparent;
  border: 1px solid var(--color-warning-light-3);
}
.arco-btn-outline.arco-btn-status-danger:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--danger-6));
  border-color: rgb(var(--danger-6));
}
.arco-btn-outline.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: rgb(var(--danger-5));
  color: rgb(var(--danger-5));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: rgb(var(--danger-7));
  color: rgb(var(--danger-7));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-danger.arco-btn-disabled {
  color: var(--color-danger-light-3);
  background-color: transparent;
  border: 1px solid var(--color-danger-light-3);
}
.arco-btn-outline.arco-btn-status-success:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--success-6));
  border-color: rgb(var(--success-6));
}
.arco-btn-outline.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: rgb(var(--success-5));
  color: rgb(var(--success-5));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: rgb(var(--success-7));
  color: rgb(var(--success-7));
  background-color: transparent;
}
.arco-btn-outline.arco-btn-status-success.arco-btn-disabled {
  color: var(--color-success-light-3);
  background-color: transparent;
  border: 1px solid var(--color-success-light-3);
}
.arco-btn-primary:not(.arco-btn-disabled) {
  background-color: rgb(var(--primary-6));
  color: #fff;
  border: 1px solid transparent;
}
.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--primary-5));
}
.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--primary-7));
}
.arco-btn-primary.arco-btn-disabled {
  background-color: var(--color-primary-light-3);
  color: #fff;
  border: 1px solid transparent;
  cursor: not-allowed;
}
.arco-btn-primary.arco-btn-status-warning:not(.arco-btn-disabled) {
  background-color: rgb(var(--warning-6));
  color: #fff;
  border-color: transparent;
}
.arco-btn-primary.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--warning-5));
}
.arco-btn-primary.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--warning-7));
}
.arco-btn-primary.arco-btn-status-warning.arco-btn-disabled {
  color: #fff;
  background-color: var(--color-warning-light-3);
  border: 1px solid transparent;
}
.arco-btn-primary.arco-btn-status-danger:not(.arco-btn-disabled) {
  background-color: rgb(var(--danger-6));
  color: #fff;
  border-color: transparent;
}
.arco-btn-primary.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--danger-5));
}
.arco-btn-primary.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--danger-7));
}
.arco-btn-primary.arco-btn-status-danger.arco-btn-disabled {
  color: #fff;
  background-color: var(--color-danger-light-3);
  border: 1px solid transparent;
}
.arco-btn-primary.arco-btn-status-success:not(.arco-btn-disabled) {
  background-color: rgb(var(--success-6));
  color: #fff;
  border-color: transparent;
}
.arco-btn-primary.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--success-5));
}
.arco-btn-primary.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: #fff;
  background-color: rgb(var(--success-7));
}
.arco-btn-primary.arco-btn-status-success.arco-btn-disabled {
  color: #fff;
  background-color: var(--color-success-light-3);
  border: 1px solid transparent;
}
.arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: var(--color-secondary);
  color: var(--color-text-2);
  border: 1px solid transparent;
}
.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: var(--color-text-2);
  background-color: var(--color-secondary-hover);
}
.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: var(--color-text-2);
  background-color: var(--color-secondary-active);
}
.arco-btn-secondary.arco-btn-disabled {
  background-color: var(--color-secondary-disabled);
  color: var(--color-text-4);
  border: 1px solid transparent;
  cursor: not-allowed;
}
.arco-btn-secondary.arco-btn-status-warning:not(.arco-btn-disabled) {
  background-color: var(--color-warning-light-1);
  color: rgb(var(--warning-6));
  border-color: transparent;
}
.arco-btn-secondary.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--warning-6));
  background-color: var(--color-warning-light-2);
}
.arco-btn-secondary.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--warning-6));
  background-color: var(--color-warning-light-3);
}
.arco-btn-secondary.arco-btn-status-warning.arco-btn-disabled {
  color: var(--color-warning-light-3);
  background-color: var(--color-warning-light-1);
  border: 1px solid transparent;
}
.arco-btn-secondary.arco-btn-status-danger:not(.arco-btn-disabled) {
  background-color: var(--color-danger-light-1);
  color: rgb(var(--danger-6));
  border-color: transparent;
}
.arco-btn-secondary.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--danger-6));
  background-color: var(--color-danger-light-2);
}
.arco-btn-secondary.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--danger-6));
  background-color: var(--color-danger-light-3);
}
.arco-btn-secondary.arco-btn-status-danger.arco-btn-disabled {
  color: var(--color-danger-light-3);
  background-color: var(--color-danger-light-1);
  border: 1px solid transparent;
}
.arco-btn-secondary.arco-btn-status-success:not(.arco-btn-disabled) {
  background-color: var(--color-success-light-1);
  color: rgb(var(--success-6));
  border-color: transparent;
}
.arco-btn-secondary.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--success-6));
  background-color: var(--color-success-light-2);
}
.arco-btn-secondary.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--success-6));
  background-color: var(--color-success-light-3);
}
.arco-btn-secondary.arco-btn-status-success.arco-btn-disabled {
  color: var(--color-success-light-3);
  background-color: var(--color-success-light-1);
  border: 1px solid transparent;
}
.arco-btn-dashed:not(.arco-btn-disabled) {
  background-color: var(--color-fill-2);
  color: var(--color-text-2);
  border: 1px dashed var(--color-neutral-3);
}
.arco-btn-dashed:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: var(--color-neutral-4);
  color: var(--color-text-2);
  background-color: var(--color-fill-3);
}
.arco-btn-dashed:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: var(--color-neutral-5);
  color: var(--color-text-2);
  background-color: var(--color-fill-4);
}
.arco-btn-dashed.arco-btn-disabled {
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
  border: 1px dashed var(--color-neutral-3);
  cursor: not-allowed;
}
.arco-btn-dashed.arco-btn-status-warning:not(.arco-btn-disabled) {
  background-color: var(--color-warning-light-1);
  color: rgb(var(--warning-6));
  border-color: var(--color-warning-light-2);
}
.arco-btn-dashed.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: var(--color-warning-light-3);
  color: rgb(var(--warning-6));
  background-color: var(--color-warning-light-2);
}
.arco-btn-dashed.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: var(--color-warning-light-4);
  color: rgb(var(--warning-6));
  background-color: var(--color-warning-light-3);
}
.arco-btn-dashed.arco-btn-status-warning.arco-btn-disabled {
  color: var(--color-warning-light-3);
  background-color: var(--color-warning-light-1);
  border: 1px dashed var(--color-warning-light-2);
}
.arco-btn-dashed.arco-btn-status-danger:not(.arco-btn-disabled) {
  background-color: var(--color-danger-light-1);
  color: rgb(var(--danger-6));
  border-color: var(--color-danger-light-2);
}
.arco-btn-dashed.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: var(--color-danger-light-3);
  color: rgb(var(--danger-6));
  background-color: var(--color-danger-light-2);
}
.arco-btn-dashed.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: var(--color-danger-light-4);
  color: rgb(var(--danger-6));
  background-color: var(--color-danger-light-3);
}
.arco-btn-dashed.arco-btn-status-danger.arco-btn-disabled {
  color: var(--color-danger-light-3);
  background-color: var(--color-danger-light-1);
  border: 1px dashed var(--color-danger-light-2);
}
.arco-btn-dashed.arco-btn-status-success:not(.arco-btn-disabled) {
  background-color: var(--color-success-light-1);
  color: rgb(var(--success-6));
  border-color: var(--color-success-light-2);
}
.arco-btn-dashed.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: var(--color-success-light-3);
  color: rgb(var(--success-6));
  background-color: var(--color-success-light-2);
}
.arco-btn-dashed.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: var(--color-success-light-4);
  color: rgb(var(--success-6));
  background-color: var(--color-success-light-3);
}
.arco-btn-dashed.arco-btn-status-success.arco-btn-disabled {
  color: var(--color-success-light-3);
  background-color: var(--color-success-light-1);
  border: 1px dashed var(--color-success-light-2);
}
.arco-btn-text:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--primary-6));
  border: 1px solid transparent;
}
.arco-btn-text:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--primary-6));
  background-color: var(--color-fill-2);
}
.arco-btn-text:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--primary-6));
  background-color: var(--color-fill-3);
}
.arco-btn-text.arco-btn-disabled {
  background-color: transparent;
  color: var(--color-primary-light-3);
  border: 1px solid transparent;
  cursor: not-allowed;
}
.arco-btn-text.arco-btn-status-warning:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--warning-6));
  border-color: transparent;
}
.arco-btn-text.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--warning-6));
  background-color: var(--color-fill-2);
}
.arco-btn-text.arco-btn-status-warning:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--warning-6));
  background-color: var(--color-fill-3);
}
.arco-btn-text.arco-btn-status-warning.arco-btn-disabled {
  color: var(--color-warning-light-3);
  background-color: transparent;
  border: 1px solid transparent;
}
.arco-btn-text.arco-btn-status-danger:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--danger-6));
  border-color: transparent;
}
.arco-btn-text.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--danger-6));
  background-color: var(--color-fill-2);
}
.arco-btn-text.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--danger-6));
  background-color: var(--color-fill-3);
}
.arco-btn-text.arco-btn-status-danger.arco-btn-disabled {
  color: var(--color-danger-light-3);
  background-color: transparent;
  border: 1px solid transparent;
}
.arco-btn-text.arco-btn-status-success:not(.arco-btn-disabled) {
  background-color: transparent;
  color: rgb(var(--success-6));
  border-color: transparent;
}
.arco-btn-text.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  border-color: transparent;
  color: rgb(var(--success-6));
  background-color: var(--color-fill-2);
}
.arco-btn-text.arco-btn-status-success:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  border-color: transparent;
  color: rgb(var(--success-6));
  background-color: var(--color-fill-3);
}
.arco-btn-text.arco-btn-status-success.arco-btn-disabled {
  color: var(--color-success-light-3);
  background-color: transparent;
  border: 1px solid transparent;
}
.arco-btn-size-mini {
  padding: 0 11px;
  font-size: 12px;
  height: 24px;
  border-radius: var(--border-radius-small);
}
.arco-btn-size-mini > svg + span,
.arco-btn-size-mini > span + svg {
  margin-left: 4px;
}
.arco-btn-size-mini svg {
  vertical-align: -1px;
}
.arco-btn-size-mini.arco-btn-loading-fixed-width.arco-btn-loading {
  padding-left: 3px;
  padding-right: 3px;
}
.arco-btn-size-mini.arco-btn-icon-only {
  width: 24px;
  height: 24px;
  padding: 0;
}
.arco-btn-size-mini.arco-btn-shape-circle {
  width: 24px;
  height: 24px;
  padding: 0;
  text-align: center;
  border-radius: var(--border-radius-circle);
}
.arco-btn-size-mini.arco-btn-shape-round {
  border-radius: calc(24px * 0.5);
}
.arco-btn-size-small {
  padding: 0 15px;
  font-size: 14px;
  height: 28px;
  border-radius: var(--border-radius-small);
}
.arco-btn-size-small > svg + span,
.arco-btn-size-small > span + svg {
  margin-left: 6px;
}
.arco-btn-size-small svg {
  vertical-align: -2px;
}
.arco-btn-size-small.arco-btn-loading-fixed-width.arco-btn-loading {
  padding-left: 5px;
  padding-right: 5px;
}
.arco-btn-size-small.arco-btn-icon-only {
  width: 28px;
  height: 28px;
  padding: 0;
}
.arco-btn-size-small.arco-btn-shape-circle {
  width: 28px;
  height: 28px;
  padding: 0;
  text-align: center;
  border-radius: var(--border-radius-circle);
}
.arco-btn-size-small.arco-btn-shape-round {
  border-radius: calc(28px * 0.5);
}
.arco-btn-size-default {
  padding: 0 15px;
  font-size: 14px;
  height: 32px;
  border-radius: var(--border-radius-small);
}
.arco-btn-size-default > svg + span,
.arco-btn-size-default > span + svg {
  margin-left: 8px;
}
.arco-btn-size-default svg {
  vertical-align: -2px;
}
.arco-btn-size-default.arco-btn-loading-fixed-width.arco-btn-loading {
  padding-left: 4px;
  padding-right: 4px;
}
.arco-btn-size-default.arco-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 0;
}
.arco-btn-size-default.arco-btn-shape-circle {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  border-radius: var(--border-radius-circle);
}
.arco-btn-size-default.arco-btn-shape-round {
  border-radius: calc(32px * 0.5);
}
.arco-btn-size-large {
  padding: 0 19px;
  font-size: 14px;
  height: 36px;
  border-radius: var(--border-radius-small);
}
.arco-btn-size-large > svg + span,
.arco-btn-size-large > span + svg {
  margin-left: 8px;
}
.arco-btn-size-large svg {
  vertical-align: -2px;
}
.arco-btn-size-large.arco-btn-loading-fixed-width.arco-btn-loading {
  padding-left: 8px;
  padding-right: 8px;
}
.arco-btn-size-large.arco-btn-icon-only {
  width: 36px;
  height: 36px;
  padding: 0;
}
.arco-btn-size-large.arco-btn-shape-circle {
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  border-radius: var(--border-radius-circle);
}
.arco-btn-size-large.arco-btn-shape-round {
  border-radius: calc(36px * 0.5);
}
.arco-btn-group {
  display: inline-block;
}
.arco-btn-group .arco-btn-outline:not(:first-child),
.arco-btn-group .arco-btn-dashed:not(:first-child) {
  margin-left: -1px;
}
.arco-btn-group .arco-btn-primary:not(:last-child) {
  border-right: 1px solid rgb(var(--primary-5));
}
.arco-btn-group .arco-btn-secondary:not(:last-child) {
  border-right: 1px solid var(--color-secondary-hover);
}
.arco-btn-group .arco-btn-status-warning:not(:last-child) {
  border-right: 1px solid rgb(var(--warning-5));
}
.arco-btn-group .arco-btn-status-danger:not(:last-child) {
  border-right: 1px solid rgb(var(--danger-5));
}
.arco-btn-group .arco-btn-status-success:not(:last-child) {
  border-right: 1px solid rgb(var(--success-5));
}
.arco-btn-group .arco-btn-outline:hover,
.arco-btn-group .arco-btn-dashed:hover,
.arco-btn-group .arco-btn-outline:active,
.arco-btn-group .arco-btn-dashed:active {
  z-index: 2;
}
.arco-btn-group .arco-btn:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-btn-group .arco-btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-btn-group .arco-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
body[arco-theme='dark'] .arco-btn-primary.arco-btn-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.arco-calendar {
  box-sizing: border-box;
  border: 1px solid var(--color-neutral-3);
}
.arco-calendar-header {
  display: flex;
  padding: 24px 24px;
}
.arco-calendar-header-left {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  height: 28px;
  line-height: 28px;
}
.arco-calendar-header-right {
  height: 28px;
  position: relative;
}
.arco-calendar-header-value {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-calendar-header-icon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  user-select: none;
  margin-right: 12px;
  color: var(--color-text-2);
  background-color: var(--color-bg-5);
}
.arco-calendar-header-icon:not(:first-child) {
  margin-left: 12px;
  margin-right: 32px;
}
.arco-calendar-header-icon:not(.arco-calendar-header-icon-hidden) {
  cursor: pointer;
}
.arco-calendar-header-icon:not(.arco-calendar-header-icon-hidden):hover {
  background-color: var(--color-fill-3);
}
.arco-calendar .arco-calendar-header-value-year {
  width: 100px;
  margin-right: 8px;
}
.arco-calendar .arco-calendar-header-value-month {
  width: 76px;
  margin-right: 32px;
}
.arco-calendar-month {
  width: 100%;
}
.arco-calendar-month-row {
  height: 100px;
  display: flex;
}
.arco-calendar-month-row .arco-calendar-cell {
  flex: 1;
  border-bottom: 1px solid var(--color-neutral-3);
  overflow: hidden;
}
.arco-calendar-month-row:last-child .arco-calendar-cell {
  border-bottom: unset;
}
.arco-calendar-month-cell-body {
  box-sizing: border-box;
}
.arco-calendar-mode-month:not(.arco-calendar-panel) .arco-calendar-cell:not(:last-child) {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-calendar-week-list {
  display: flex;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-calendar-week-list-item {
  padding: 20px 16px;
  text-align: left;
  color: #7d7d7f;
  flex: 1;
}
.arco-calendar-cell .arco-calendar-date {
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.arco-calendar-cell .arco-calendar-date-circle {
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
}
.arco-calendar-date-content {
  height: 70px;
  overflow-y: auto;
}
.arco-calendar-cell-today .arco-calendar-date-circle {
  border: 1px solid rgb(var(--primary-6));
}
.arco-calendar-date-value {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-4);
}
.arco-calendar-cell-in-view .arco-calendar-date-value {
  color: var(--color-text-1);
}
.arco-calendar-mode-month .arco-calendar-cell-selected .arco-calendar-date-circle {
  border: 1px solid rgb(var(--primary-6));
  background-color: rgb(var(--primary-6));
  color: #fff;
}
.arco-calendar-mode-year:not(.arco-calendar-panel) {
  min-width: 820px;
}
.arco-calendar-mode-year .arco-calendar-header {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-calendar-mode-year .arco-calendar-body {
  padding: 12px;
}
.arco-calendar-mode-year .arco-calendar-year-row {
  display: flex;
}
.arco-calendar-year-row > .arco-calendar-cell {
  flex: 1;
  padding: 20px 8px;
}
.arco-calendar-year-row > .arco-calendar-cell:not(:last-child) {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-calendar-year-row:not(:last-child) > .arco-calendar-cell {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-calendar-month-with-days .arco-calendar-month-row {
  height: 26px;
}
.arco-calendar-month-with-days .arco-calendar-cell {
  border-bottom: 0;
}
.arco-calendar-month-with-days .arco-calendar-month-cell-body {
  padding: 0;
}
.arco-calendar-month-with-days .arco-calendar-month-title {
  padding: 10px 6px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-calendar-month-cell {
  font-size: 12px;
  width: 100%;
}
.arco-calendar-month-cell .arco-calendar-week-list {
  border-bottom: unset;
  padding: 0;
}
.arco-calendar-month-cell .arco-calendar-week-list-item {
  padding: 6px;
  text-align: center;
  color: #7d7d7f;
}
.arco-calendar-month-cell .arco-calendar-cell {
  text-align: center;
}
.arco-calendar-month-cell .arco-calendar-date {
  padding: 2px;
}
.arco-calendar-month-cell .arco-calendar-date-value {
  font-size: 14px;
}
.arco-calendar-month-cell .arco-calendar-date-circle {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
}
.arco-calendar-panel {
  border: 1px solid var(--color-neutral-3);
  background-color: var(--color-bg-5);
}
.arco-calendar-panel .arco-calendar-header {
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-calendar-panel .arco-calendar-header-value {
  font-size: 14px;
  line-height: 24px;
  flex: 1;
  text-align: center;
}
.arco-calendar-panel .arco-calendar-header-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-left: 2px;
  margin-right: 2px;
}
.arco-calendar-panel .arco-calendar-body {
  padding: 14px 16px;
}
.arco-calendar-panel .arco-calendar-month-cell-body {
  padding: 0;
}
.arco-calendar-panel .arco-calendar-month-row {
  height: unset;
}
.arco-calendar-panel .arco-calendar-week-list {
  padding: 0;
  border-bottom: unset;
}
.arco-calendar-panel .arco-calendar-week-list-item {
  padding: 0;
  text-align: center;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
}
.arco-calendar-panel .arco-calendar-cell,
.arco-calendar-panel .arco-calendar-year-row .arco-calendar-cell {
  text-align: center;
  box-sizing: border-box;
  padding: 2px 0;
  border-bottom: 0;
  border-right: 0;
}
.arco-calendar-panel .arco-calendar-cell .arco-calendar-date {
  padding: 4px 0;
  display: flex;
  justify-content: center;
}
.arco-calendar-panel .arco-calendar-cell .arco-calendar-date-value {
  font-size: 14px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}
.arco-calendar-panel.arco-calendar-mode-year .arco-calendar-cell {
  padding: 4px 0;
}
.arco-calendar-panel.arco-calendar-mode-year .arco-calendar-cell .arco-calendar-date {
  padding: 4px;
}
.arco-calendar-panel.arco-calendar-mode-year .arco-calendar-cell .arco-calendar-date-value {
  border-radius: 12px;
  width: 100%;
}
.arco-calendar-panel .arco-calendar-cell-selected .arco-calendar-date-value {
  color: var(--color-white);
  background-color: rgb(var(--primary-6));
  border-radius: 50%;
}
.arco-calendar-panel
  .arco-calendar-cell:not(.arco-calendar-cell-selected):not(.arco-calendar-cell-range-start):not(
    .arco-calendar-cell-range-end
  ):not(.arco-calendar-cell-hover-range-start):not(.arco-calendar-cell-hover-range-end):not(
    .arco-calendar-cell-disabled
  ):not(.arco-calendar-cell-week)
  .arco-calendar-date-value:hover {
  background-color: var(--color-primary-light-1);
  border-radius: 50%;
  color: rgb(var(--primary-6));
}
.arco-calendar-panel.arco-calendar-mode-year
  .arco-calendar-cell:not(.arco-calendar-cell-selected):not(.arco-calendar-cell-range-start):not(
    .arco-calendar-cell-range-end
  ):not(.arco-calendar-cell-hover-range-start):not(.arco-calendar-cell-hover-range-end):not(
    .arco-calendar-cell-disabled
  )
  .arco-calendar-date-value:hover {
  border-radius: 12px;
}
.arco-calendar-panel .arco-calendar-cell-today {
  position: relative;
}
.arco-calendar-panel .arco-calendar-cell-today::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgb(var(--primary-6));
}
.arco-calendar-cell-in-range .arco-calendar-date {
  background-color: var(--color-primary-light-1);
}
.arco-calendar-cell-range-start .arco-calendar-date {
  border-radius: 16px 0 0 16px;
}
.arco-calendar-cell-range-end .arco-calendar-date {
  border-radius: 0 16px 16px 0;
}
.arco-calendar-cell-in-range-near-hover .arco-calendar-date {
  border-radius: 0;
}
.arco-calendar-cell-range-start .arco-calendar-date-value,
.arco-calendar-cell-range-end .arco-calendar-date-value {
  background-color: rgb(var(--primary-6));
  border-radius: 50%;
  color: var(--color-white);
}
.arco-calendar-cell-hover-in-range .arco-calendar-date {
  background-color: var(--color-primary-light-1);
}
.arco-calendar-cell-hover-range-start .arco-calendar-date {
  border-radius: 16px 0 0 16px;
}
.arco-calendar-cell-hover-range-end .arco-calendar-date {
  border-radius: 0 16px 16px 0;
}
.arco-calendar-cell-hover-range-start .arco-calendar-date-value,
.arco-calendar-cell-hover-range-end .arco-calendar-date-value {
  background-color: var(--color-primary-light-2);
  border-radius: 50%;
  color: var(--color-text-1);
}
.arco-calendar-panel .arco-calendar-cell-disabled > .arco-calendar-date {
  background-color: var(--color-fill-1);
  cursor: not-allowed;
}
.arco-calendar-panel
  .arco-calendar-cell-disabled
  > .arco-calendar-date
  > .arco-calendar-date-value {
  color: var(--color-text-4);
  background-color: var(--color-fill-1);
  cursor: not-allowed;
}
.arco-calendar-panel .arco-calendar-footer-btn-wrapper {
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-top: 1px solid var(--color-neutral-3);
  cursor: pointer;
  color: var(--color-text-1);
}
.arco-card {
  position: relative;
  background: var(--color-bg-2);
  transition: box-shadow 0.2s cubic-bezier(0, 0, 1, 1);
  border-radius: var(--border-radius-none);
}
.arco-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-neutral-3);
  overflow: hidden;
}
.arco-card-header-no-title::before {
  content: ' ';
  display: block;
}
.arco-card-header-title {
  flex: 1;
  font-weight: 500;
  color: var(--color-text-1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-card-header-extra {
  color: rgb(var(--primary-6));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-card-body {
  color: var(--color-text-2);
}
.arco-card-cover {
  overflow: hidden;
}
.arco-card-cover > * {
  display: block;
  width: 100%;
}
.arco-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.arco-card-actions::before {
  content: '';
  visibility: hidden;
}
.arco-card-actions-right {
  display: flex;
  align-items: center;
}
.arco-card-actions-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-2);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-card-actions-item:hover {
  color: rgb(var(--primary-6));
}
.arco-card-actions-item:not(:last-child) {
  margin-right: 12px;
}
.arco-card-meta-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arco-card-meta-footer:last-child {
  margin-top: 20px;
}
.arco-card-meta-footer-only-actions::before {
  content: '';
  visibility: hidden;
}
.arco-card-meta-footer .arco-card-actions {
  margin-top: 0;
}
.arco-card-meta-title {
  font-weight: 500;
  color: var(--color-text-1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-card-meta-description:not(:first-child) {
  margin-top: 4px;
}
.arco-card-grid {
  position: relative;
  box-sizing: border-box;
  width: 33.33%;
  box-shadow: 1px 0 0 0 var(--color-neutral-3), 0 1px 0 0 var(--color-neutral-3),
    1px 1px 0 0 var(--color-neutral-3), 1px 0 0 0 var(--color-neutral-3) inset,
    0 1px 0 0 var(--color-neutral-3) inset;
}
.arco-card-grid::before {
  content: '';
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: box-shadow 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-card-grid-hoverable:hover {
  z-index: 1;
}
.arco-card-grid-hoverable:hover::before {
  box-shadow: 0 4px 10px rgb(var(--gray-2));
}
.arco-card-grid .arco-card {
  background: none;
  box-shadow: none;
}
.arco-card-contain-grid:not(.arco-card-loading) > .arco-card-body {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px;
  padding: 0;
}
.arco-card-hoverable:hover {
  box-shadow: 0 4px 10px rgb(var(--gray-2));
}
.arco-card-bordered {
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-small);
}
.arco-card-bordered .arco-card-cover {
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}
.arco-card-loading .arco-card-body {
  overflow: hidden;
  text-align: center;
}
.arco-card-size-default {
  font-size: 14px;
}
.arco-card-size-default .arco-card-header {
  height: 46px;
  padding-left: 16px;
  padding-right: 16px;
}
.arco-card-size-default .arco-card-header-title,
.arco-card-size-default .arco-card-meta-title {
  font-size: 16px;
}
.arco-card-size-default .arco-card-header-extra {
  font-size: 14px;
}
.arco-card-size-default .arco-card-body {
  padding: 16px 16px;
}
.arco-card-size-small {
  font-size: 14px;
}
.arco-card-size-small .arco-card-header {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
}
.arco-card-size-small .arco-card-header-title,
.arco-card-size-small .arco-card-meta-title {
  font-size: 16px;
}
.arco-card-size-small .arco-card-header-extra {
  font-size: 14px;
}
.arco-card-size-small .arco-card-body {
  padding: 12px 16px;
}
body[arco-theme='dark'] .arco-card-grid-hoverable:hover::before,
body[arco-theme='dark'] .arco-card-hoverable:hover {
  box-shadow: 0 4px 10px rgba(var(--gray-1), 40%);
}
@keyframes arco-carousel-slide-x-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes arco-carousel-slide-x-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes arco-carousel-slide-x-in-reverse {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes arco-carousel-slide-x-out-reverse {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes arco-carousel-slide-y-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes arco-carousel-slide-y-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes arco-carousel-slide-y-in-reverse {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes arco-carousel-slide-y-out-reverse {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes arco-carousel-card-bottom-to-middle {
  from {
    opacity: 0;
    transform: translateX(0%) translateZ(-400px);
  }
  to {
    opacity: 0.4;
    transform: translateX(0%) translateZ(-200px);
  }
}
@keyframes arco-carousel-card-middle-to-bottom {
  from {
    opacity: 0.4;
    transform: translateX(-100%) translateZ(-200px);
  }
  to {
    opacity: 0;
    transform: translateX(-100%) translateZ(-400px);
  }
}
@keyframes arco-carousel-card-top-to-middle {
  from {
    opacity: 1;
    transform: translateX(-50%) translateZ(0);
  }
  to {
    opacity: 0.4;
    transform: translateX(-100%) translateZ(-200px);
  }
}
@keyframes arco-carousel-card-middle-to-top {
  from {
    opacity: 0.4;
    transform: translateX(0) translateZ(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateZ(0);
  }
}
@keyframes arco-carousel-card-bottom-to-middle-reverse {
  from {
    opacity: 0;
    transform: translateX(-100%) translateZ(-400px);
  }
  to {
    opacity: 0.4;
    transform: translateX(-100%) translateZ(-200px);
  }
}
@keyframes arco-carousel-card-middle-to-bottom-reverse {
  from {
    opacity: 0.4;
    transform: translateX(0%) translateZ(-200px);
  }
  to {
    opacity: 0;
    transform: translateX(0%) translateZ(-400px);
  }
}
@keyframes arco-carousel-card-top-to-middle-reverse {
  from {
    opacity: 1;
    transform: translateX(-50%) translateZ(0);
  }
  to {
    opacity: 0.4;
    transform: translateX(0%) translateZ(-200px);
  }
}
@keyframes arco-carousel-card-middle-to-top-reverse {
  from {
    opacity: 0.4;
    transform: translateX(-100%) translateZ(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateZ(0);
  }
}
.arco-carousel {
  position: relative;
}
.arco-carousel-indicator-position-outer {
  margin-bottom: 30px;
}
.arco-carousel-slide,
.arco-carousel-card,
.arco-carousel-fade {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.arco-carousel-slide > *,
.arco-carousel-card > *,
.arco-carousel-fade > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.arco-carousel-item-current {
  z-index: 1;
}
.arco-carousel-slide > *:not(.arco-carousel-item-current) {
  visibility: hidden;
}
.arco-carousel-slide.arco-carousel-horizontal .arco-carousel-item-slide-out {
  display: block;
  animation: arco-carousel-slide-x-out;
}
.arco-carousel-slide.arco-carousel-horizontal .arco-carousel-item-slide-in {
  display: block;
  animation: arco-carousel-slide-x-in;
}
.arco-carousel-slide.arco-carousel-horizontal.arco-carousel-negative .arco-carousel-item-slide-out {
  animation: arco-carousel-slide-x-out-reverse;
}
.arco-carousel-slide.arco-carousel-horizontal.arco-carousel-negative .arco-carousel-item-slide-in {
  animation: arco-carousel-slide-x-in-reverse;
}
.arco-carousel-slide.arco-carousel-vertical .arco-carousel-item-slide-out {
  display: block;
  animation: arco-carousel-slide-y-out;
}
.arco-carousel-slide.arco-carousel-vertical .arco-carousel-item-slide-in {
  display: block;
  animation: arco-carousel-slide-y-in;
}
.arco-carousel-slide.arco-carousel-vertical.arco-carousel-negative .arco-carousel-item-slide-out {
  animation: arco-carousel-slide-y-out-reverse;
}
.arco-carousel-slide.arco-carousel-vertical.arco-carousel-negative .arco-carousel-item-slide-in {
  animation: arco-carousel-slide-y-in-reverse;
}
.arco-carousel-card {
  perspective: 800px;
}
.arco-carousel-card > * {
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) translateZ(-400px);
  animation: arco-carousel-card-middle-to-bottom;
}
.arco-carousel-card .arco-carousel-item-prev {
  opacity: 0.4;
  transform: translateX(-100%) translateZ(-200px);
  animation: arco-carousel-card-top-to-middle;
}
.arco-carousel-card .arco-carousel-item-next {
  opacity: 0.4;
  transform: translateX(0%) translateZ(-200px);
  animation: arco-carousel-card-bottom-to-middle;
}
.arco-carousel-card .arco-carousel-item-current {
  opacity: 1;
  transform: translateX(-50%) translateZ(0);
  animation: arco-carousel-card-middle-to-top;
}
.arco-carousel-card.arco-carousel-negative > * {
  animation: arco-carousel-card-middle-to-bottom-reverse;
}
.arco-carousel-card.arco-carousel-negative .arco-carousel-item-prev {
  animation: arco-carousel-card-bottom-to-middle-reverse;
}
.arco-carousel-card.arco-carousel-negative .arco-carousel-item-next {
  animation: arco-carousel-card-top-to-middle-reverse;
}
.arco-carousel-card.arco-carousel-negative .arco-carousel-item-current {
  animation: arco-carousel-card-middle-to-top-reverse;
}
.arco-carousel-fade > * {
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.arco-carousel-fade .arco-carousel-item-current {
  opacity: 1;
}
.arco-carousel-indicator {
  display: flex;
  position: absolute;
  margin: 0;
  padding: 0;
}
.arco-carousel-indicator-wrapper {
  position: absolute;
  z-index: 2;
}
.arco-carousel-indicator-wrapper-top {
  left: 0;
  right: 0;
  top: 0;
  height: 48px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 87%);
}
.arco-carousel-indicator-wrapper-bottom {
  left: 0;
  right: 0;
  bottom: 0;
  height: 48px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.15) 100%);
}
.arco-carousel-indicator-wrapper-left {
  left: 0;
  top: 0;
  width: 48px;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 87%);
}
.arco-carousel-indicator-wrapper-right {
  right: 0;
  top: 0;
  width: 48px;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.15) 100%);
}
.arco-carousel-indicator-wrapper-outer {
  left: 0;
  right: 0;
  background: none;
}
.arco-carousel-indicator-bottom {
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.arco-carousel-indicator-top {
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.arco-carousel-indicator-left {
  left: 12px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}
.arco-carousel-indicator-right {
  right: 12px;
  top: 50%;
  transform: translate(50%, -50%) rotate(90deg);
}
.arco-carousel-indicator-outer {
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  border-radius: 20px;
  background-color: transparent;
}
.arco-carousel-indicator-outer.arco-carousel-indicator-dot {
  bottom: -22px;
}
.arco-carousel-indicator-outer.arco-carousel-indicator-line {
  bottom: -20px;
}
.arco-carousel-indicator-outer.arco-carousel-indicator-slider {
  padding: 0;
  bottom: -16px;
  background-color: rgba(var(--gray-4), 0.5);
}
.arco-carousel-indicator-outer .arco-carousel-indicator-item {
  background-color: rgba(var(--gray-4), 0.5);
}
.arco-carousel-indicator-outer .arco-carousel-indicator-item:hover,
.arco-carousel-indicator-outer .arco-carousel-indicator-item-active {
  background-color: var(--color-fill-4);
}
.arco-carousel-indicator-item {
  display: inline-block;
  border-radius: var(--border-radius-medium);
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.arco-carousel-indicator-item:hover,
.arco-carousel-indicator-item-active {
  background-color: var(--color-white);
}
.arco-carousel-indicator-dot .arco-carousel-indicator-item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.arco-carousel-indicator-dot .arco-carousel-indicator-item:not(:last-child) {
  margin-right: 8px;
}
.arco-carousel-indicator-line .arco-carousel-indicator-item {
  width: 12px;
  height: 4px;
}
.arco-carousel-indicator-line .arco-carousel-indicator-item:not(:last-child) {
  margin-right: 8px;
}
.arco-carousel-indicator-slider {
  width: 48px;
  height: 4px;
  border-radius: var(--border-radius-medium);
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.arco-carousel-indicator-slider .arco-carousel-indicator-item {
  position: absolute;
  top: 0;
  height: 100%;
  transition: left 0.3s;
}
.arco-carousel-arrow > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--color-white);
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  z-index: 2;
}
.arco-carousel-arrow > div > svg {
  color: var(--color-white);
  font-size: 14px;
}
.arco-carousel-arrow > div:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.arco-carousel-arrow-left {
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.arco-carousel-arrow-right {
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
.arco-carousel-arrow-top {
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
}
.arco-carousel-arrow-bottom {
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
}
.arco-carousel-arrow-hover div {
  opacity: 0;
  transition: all 0.3s;
}
.arco-carousel:hover .arco-carousel-arrow-hover div {
  opacity: 1;
}
body[arco-theme='dark'] .arco-carousel-arrow > div {
  background-color: rgba(var(--gray-1), 0.3);
}
body[arco-theme='dark'] .arco-carousel-arrow > div:hover {
  background-color: rgba(var(--gray-1), 0.5);
}
body[arco-theme='dark'] .arco-carousel-indicator-item,
body[arco-theme='dark'] .arco-carousel-indicator-slider {
  background-color: rgba(var(--gray-1), 0.3);
}
body[arco-theme='dark'] .arco-carousel-indicator-item-active,
body[arco-theme='dark'] .arco-carousel-indicator-item:hover {
  background-color: var(--color-white);
}
body[arco-theme='dark'] .arco-carousel-indicator-outer.arco-carousel-indicator-slider {
  background-color: rgba(var(--gray-4), 0.5);
}
body[arco-theme='dark'] .arco-carousel-indicator-outer .arco-carousel-indicator-item:hover,
body[arco-theme='dark'] .arco-carousel-indicator-outer .arco-carousel-indicator-item-active {
  background-color: var(--color-fill-4);
}
.arco-cascader .arco-cascader-view {
  color: var(--color-text-1);
  background-color: transparent;
  border: 1px solid var(--color-neutral-3);
}
.arco-cascader:hover .arco-cascader-view {
  background-color: transparent;
  border-color: rgb(var(--primary-6));
}
.arco-cascader.arco-cascader-focused .arco-cascader-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-cascader .arco-cascader-suffix-icon,
.arco-cascader .arco-cascader-loading-icon,
.arco-cascader .arco-cascader-search-icon,
.arco-cascader .arco-cascader-clear-icon,
.arco-cascader .arco-cascader-arrow-icon,
.arco-cascader .arco-cascader-expand-icon {
  color: var(--color-text-2);
}
.arco-cascader-no-border .arco-cascader-view {
  border: none !important;
  background: none !important;
}
.arco-cascader-error .arco-cascader-view {
  background-color: var(--color-danger-light-1);
  border: 1px solid transparent;
}
.arco-cascader-error:hover .arco-cascader-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-cascader-error.arco-cascader-focused .arco-cascader-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-cascader-no-border .arco-cascader-view {
  border: none !important;
  background: none !important;
}
.arco-cascader-disabled .arco-cascader-view {
  color: var(--color-text-4);
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
}
.arco-cascader-disabled:hover .arco-cascader-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-cascader-disabled .arco-cascader-suffix-icon,
.arco-cascader-disabled .arco-cascader-loading-icon,
.arco-cascader-disabled .arco-cascader-search-icon,
.arco-cascader-disabled .arco-cascader-clear-icon,
.arco-cascader-disabled .arco-cascader-arrow-icon,
.arco-cascader-disabled .arco-cascader-expand-icon {
  color: var(--color-text-4);
}
.arco-cascader-no-border .arco-cascader-view {
  border: none !important;
  background: none !important;
}
.arco-cascader-size-mini.arco-cascader-multiple .arco-cascader-view {
  height: auto;
  font-size: 12px;
  padding: 0 3px;
  line-height: 0;
}
.arco-cascader-size-mini.arco-cascader-multiple .arco-input-tag-has-placeholder input,
.arco-cascader-size-mini.arco-cascader-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 4px;
}
.arco-cascader-size-mini.arco-cascader-multiple .arco-cascader-suffix {
  padding-right: 4px;
}
.arco-cascader-size-mini.arco-cascader-multiple input {
  font-size: 12px;
}
.arco-cascader-size-mini.arco-cascader-single .arco-cascader-view {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 7px;
}
.arco-cascader-size-mini.arco-cascader-single input {
  font-size: 12px;
}
.arco-cascader-size-mini.arco-cascader-multiple .arco-cascader-view-with-prefix {
  padding-left: 7px;
}
.arco-cascader-size-small.arco-cascader-multiple .arco-cascader-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-cascader-size-small.arco-cascader-multiple .arco-input-tag-has-placeholder input,
.arco-cascader-size-small.arco-cascader-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-cascader-size-small.arco-cascader-multiple .arco-cascader-suffix {
  padding-right: 8px;
}
.arco-cascader-size-small.arco-cascader-multiple input {
  font-size: 14px;
}
.arco-cascader-size-small.arco-cascader-single .arco-cascader-view {
  height: 28px;
  line-height: 26px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-cascader-size-small.arco-cascader-single input {
  font-size: 14px;
}
.arco-cascader-size-small.arco-cascader-multiple .arco-cascader-view-with-prefix {
  padding-left: 11px;
}
.arco-cascader-size-default.arco-cascader-multiple .arco-cascader-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-cascader-size-default.arco-cascader-multiple .arco-input-tag-has-placeholder input,
.arco-cascader-size-default.arco-cascader-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-cascader-size-default.arco-cascader-multiple .arco-cascader-suffix {
  padding-right: 8px;
}
.arco-cascader-size-default.arco-cascader-multiple input {
  font-size: 14px;
}
.arco-cascader-size-default.arco-cascader-single .arco-cascader-view {
  height: 32px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-cascader-size-default.arco-cascader-single input {
  font-size: 14px;
}
.arco-cascader-size-default.arco-cascader-multiple .arco-cascader-view-with-prefix {
  padding-left: 11px;
}
.arco-cascader-size-large.arco-cascader-multiple .arco-cascader-view {
  height: auto;
  font-size: 16px;
  padding: 0 3px;
  line-height: 0;
}
.arco-cascader-size-large.arco-cascader-multiple .arco-input-tag-has-placeholder input,
.arco-cascader-size-large.arco-cascader-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 12px;
}
.arco-cascader-size-large.arco-cascader-multiple .arco-cascader-suffix {
  padding-right: 12px;
}
.arco-cascader-size-large.arco-cascader-multiple input {
  font-size: 16px;
}
.arco-cascader-size-large.arco-cascader-single .arco-cascader-view {
  height: 36px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 15px;
}
.arco-cascader-size-large.arco-cascader-single input {
  font-size: 16px;
}
.arco-cascader-size-large.arco-cascader-multiple .arco-cascader-view-with-prefix {
  padding-left: 15px;
}
.arco-cascader {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
.arco-cascader-view {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius-small);
  outline: none;
  user-select: none;
  text-align: left;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1), padding 0s linear;
}
.arco-cascader-view input {
  color: inherit;
  cursor: inherit;
}
.arco-cascader-view input::placeholder {
  color: var(--color-text-3);
}
.arco-cascader-multiple,
.arco-cascader-show-search {
  cursor: text;
}
.arco-cascader-disabled {
  cursor: not-allowed;
}
.arco-cascader-disabled .arco-cascader-view input::placeholder {
  color: var(--color-text-4);
}
.arco-cascader-single .arco-cascader-view-input {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-cascader-single .arco-cascader-view-value {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-cascader-single .arco-cascader-view-value::after {
  content: '.';
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.arco-cascader-single .arco-cascader-view .arco-cascader-hidden {
  width: 0 !important;
  position: absolute;
}
.arco-cascader-multiple {
  vertical-align: top;
}
.arco-cascader-multiple .arco-cascader-view {
  padding: 0 4px;
  line-height: 0;
}
.arco-cascader-multiple .arco-cascader-view-with-prefix {
  padding-left: 12px;
}
.arco-cascader-multiple .arco-input-tag {
  flex: 1;
  padding: 0;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  overflow: hidden;
}
.arco-cascader-multiple .arco-tag {
  max-width: 100%;
}
.arco-cascader-multiple:not(.arco-cascader-focused)
  .arco-input-tag
  input:not(:first-child)[value=''] {
  width: 0 !important;
  position: absolute;
}
.arco-cascader-prefix {
  display: flex;
  align-items: center;
  margin-right: 12px;
  white-space: nowrap;
  color: var(--color-text-2);
}
.arco-cascader-suffix {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.arco-cascader-suffix-icon,
.arco-cascader-search-icon,
.arco-cascader-loading-icon,
.arco-cascader-expand-icon,
.arco-cascader-clear-icon {
  font-size: 12px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-cascader-arrow-icon {
  font-size: 12px;
}
.arco-cascader-open .arco-cascader-arrow-icon svg {
  transform: rotateZ(180deg);
}
.arco-cascader .arco-cascader-clear-icon {
  display: none;
  cursor: pointer;
}
.arco-cascader .arco-cascader-clear-icon > svg {
  position: relative;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-cascader:hover .arco-cascader-clear-icon {
  display: block;
}
.arco-cascader:hover .arco-cascader-clear-icon ~ * {
  display: none;
}
.arco-cascader-popup {
  top: 4px;
  box-sizing: border-box;
  border: 1px solid var(--color-fill-3);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.arco-cascader-popup-trigger-hover .arco-cascader-list-item {
  transition: font-weight 0s;
}
.arco-cascader-popup .arco-cascader-popup-inner {
  width: 100%;
  white-space: nowrap;
  list-style: none;
  height: 200px;
}
.arco-cascader-highlight {
  font-weight: 500;
}
.arco-cascader-list-column {
  position: relative;
  vertical-align: top;
  display: inline-block;
  background-color: var(--color-bg-popup);
  height: 100%;
}
.arco-cascader-list-column:not(:last-of-type) {
  border-right: 1px solid var(--color-fill-3);
}
.arco-cascader-list-wrapper {
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  padding: 4px 0;
  flex-direction: column;
}
.arco-cascader-list-wrapper-with-footer {
  padding-bottom: 0;
}
.arco-cascader-list-empty {
  height: 100%;
  display: flex;
  align-items: center;
}
.arco-cascader-list {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1;
}
.arco-cascader-list-item,
.arco-cascader-list-search-item {
  position: relative;
  height: 36px;
  line-height: 36px;
  min-width: 100px;
  font-size: 14px;
  color: var(--color-text-1);
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  background-color: transparent;
}
.arco-cascader-list-item-label,
.arco-cascader-list-search-item-label {
  flex-grow: 1;
  padding-left: 12px;
  padding-right: 34px;
}
.arco-cascader-list-item .arco-icon-right,
.arco-cascader-list-search-item .arco-icon-right,
.arco-cascader-list-item .arco-icon-check,
.arco-cascader-list-search-item .arco-icon-check {
  position: absolute;
  color: var(--color-text-2);
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%);
  right: 10px;
}
.arco-cascader-list-item .arco-icon-check,
.arco-cascader-list-search-item .arco-icon-check {
  color: rgb(var(--primary-6));
}
.arco-cascader-list-item .arco-icon-loading,
.arco-cascader-list-search-item .arco-icon-loading {
  position: absolute;
  margin-top: -6px;
  top: 50%;
  font-size: 12px;
  right: 10px;
  color: rgb(var(--primary-6));
}
.arco-cascader-list-item:hover,
.arco-cascader-list-search-item-hover {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}
.arco-cascader-list-item:hover
  .arco-checkbox:not(.arco-checkbox-disabled):not(.arco-checkbox-checked):hover
  .arco-checkbox-icon-hover::before,
.arco-cascader-list-search-item-hover
  .arco-checkbox:not(.arco-checkbox-disabled):not(.arco-checkbox-checked):hover
  .arco-checkbox-icon-hover::before {
  background-color: var(--color-fill-3);
}
.arco-cascader-list-item-disabled,
.arco-cascader-list-search-item-disabled,
.arco-cascader-list-item-disabled:hover,
.arco-cascader-list-search-item-disabled:hover {
  cursor: not-allowed;
  background-color: transparent;
  color: var(--color-text-4);
}
.arco-cascader-list-item-disabled .arco-icon-right,
.arco-cascader-list-search-item-disabled .arco-icon-right,
.arco-cascader-list-item-disabled:hover .arco-icon-right,
.arco-cascader-list-search-item-disabled:hover .arco-icon-right {
  color: inherit;
}
.arco-cascader-list-item-disabled .arco-icon-check,
.arco-cascader-list-search-item-disabled .arco-icon-check,
.arco-cascader-list-item-disabled:hover .arco-icon-check,
.arco-cascader-list-search-item-disabled:hover .arco-icon-check {
  color: var(--color-primary-light-3);
}
.arco-cascader-list-item-active {
  transition: all 0.2s cubic-bezier(0, 0, 1, 1);
  background-color: var(--color-fill-2);
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-cascader-list-item-active:hover {
  background-color: var(--color-fill-2);
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-cascader-list-item-active.arco-cascader-list-item-disabled,
.arco-cascader-list-item-active.arco-cascader-list-item-disabled:hover {
  background-color: var(--color-fill-2);
  font-weight: 500;
  color: var(--color-text-4);
}
.arco-cascader-list-multiple .arco-cascader-list-item-label {
  padding-left: 0;
}
.arco-cascader-list-multiple .arco-cascader-list-item,
.arco-cascader-list-multiple .arco-cascader-list-search-item {
  padding-left: 12px;
}
.arco-cascader-list-multiple .arco-cascader-list-item .arco-checkbox,
.arco-cascader-list-multiple .arco-cascader-list-search-item .arco-checkbox {
  padding-left: 0;
  margin-right: 8px;
}
.arco-cascader-list-search.arco-cascader-list-multiple .arco-cascader-list-item-label {
  padding-right: 12px;
}
.arco-cascader-list-footer {
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  border-top: 1px solid var(--color-fill-3);
  box-sizing: border-box;
}
.cascaderSlide-enter-active,
.cascaderSlide-appear-active {
  transition: margin 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-icon-hover.arco-checkbox-icon-hover::before {
  width: 24px;
  height: 24px;
}
.arco-checkbox {
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 5px;
  line-height: unset;
  position: relative;
}
.arco-checkbox > input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.arco-checkbox > input[type='checkbox']:focus + .arco-checkbox-icon-hover::before {
  background-color: var(--color-fill-2);
}
.arco-checkbox:hover .arco-checkbox-icon-hover::before {
  background-color: var(--color-fill-2);
}
.arco-checkbox-text {
  color: var(--color-text-1);
  margin-left: 8px;
}
.arco-checkbox-mask-wrapper {
  vertical-align: middle;
  top: -0.09em;
  position: relative;
  line-height: 1;
}
.arco-checkbox-mask {
  position: relative;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 2px solid var(--color-fill-3);
  border-radius: var(--border-radius-small);
  background-color: var(--color-bg-2);
  user-select: none;
}
.arco-checkbox-mask::after {
  content: '';
  display: block;
  height: 2px;
  width: 6px;
  background: var(--color-white);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  position: absolute;
  border-radius: 0.5px;
}
.arco-checkbox-mask-icon {
  position: relative;
  height: 100%;
  transform: scale(0);
  color: var(--color-white);
  transform-origin: center 75%;
  margin: 0 auto;
  display: block;
  width: 8px;
}
.arco-checkbox:hover .arco-checkbox-mask {
  border-color: var(--color-fill-4);
  transition: border-color 0.1s cubic-bezier(0, 0, 1, 1),
    transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-checkbox-checked:hover .arco-checkbox-mask,
.arco-checkbox-indeterminate:hover .arco-checkbox-mask {
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-checkbox-checked .arco-checkbox-mask {
  border-color: transparent;
  background-color: rgb(var(--primary-6));
}
.arco-checkbox-checked .arco-checkbox-mask-icon {
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-checkbox-indeterminate .arco-checkbox-mask {
  border-color: transparent;
  background-color: rgb(var(--primary-6));
}
.arco-checkbox-indeterminate .arco-checkbox-mask-icon {
  transform: scale(0);
}
.arco-checkbox-indeterminate .arco-checkbox-mask::after {
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-checkbox.arco-checkbox-disabled {
  cursor: not-allowed;
}
.arco-checkbox.arco-checkbox-disabled .arco-checkbox-icon-hover {
  cursor: not-allowed;
}
.arco-checkbox.arco-checkbox-disabled:hover .arco-checkbox-mask {
  border-color: var(--color-fill-3);
}
.arco-checkbox-checked:hover .arco-checkbox-mask,
.arco-checkbox-indeterminate:hover .arco-checkbox-mask {
  border-color: transparent;
}
.arco-checkbox-disabled .arco-checkbox-mask {
  border-color: var(--color-fill-3);
  background-color: var(--color-fill-2);
}
.arco-checkbox-disabled.arco-checkbox-checked .arco-checkbox-mask,
.arco-checkbox-disabled.arco-checkbox-checked:hover .arco-checkbox-mask,
.arco-checkbox-disabled.arco-checkbox-indeterminate .arco-checkbox-mask,
.arco-checkbox-disabled.arco-checkbox-indeterminate:hover .arco-checkbox-mask {
  border-color: transparent;
  background-color: var(--color-primary-light-3);
}
.arco-checkbox-disabled:hover .arco-checkbox-mask-wrapper::before,
.arco-checkbox-checked:hover .arco-checkbox-mask-wrapper::before,
.arco-checkbox-indeterminate:hover .arco-checkbox-mask-wrapper::before {
  background-color: transparent;
}
.arco-checkbox-disabled .arco-checkbox-text {
  color: var(--color-text-4);
}
.arco-checkbox-disabled .arco-checkbox-mask-icon {
  color: var(--color-fill-3);
}
.arco-checkbox-group {
  display: inline-block;
}
.arco-checkbox-group .arco-checkbox {
  margin-right: 16px;
}
.arco-checkbox-group-direction-vertical .arco-checkbox {
  display: block;
  margin-right: 0;
  line-height: 32px;
}
.arco-icon-hover.arco-collapse-item-icon-hover::before {
  width: 16px;
  height: 16px;
}
.arco-icon-hover.arco-collapse-item-icon-hover:hover::before {
  background-color: var(--color-fill-2);
}
.arco-collapse {
  overflow: hidden;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-neutral-3);
  line-height: 1.5715;
}
.arco-collapse-item {
  border-bottom: 1px solid var(--color-neutral-3);
  box-sizing: border-box;
}
.arco-collapse-item-active > .arco-collapse-item-header {
  background-color: var(--color-neutral-1);
  border-color: var(--color-neutral-3);
  transition: border-color 0s ease 0s;
}
.arco-collapse-item-active > .arco-collapse-item-header .arco-collapse-item-header-title {
  font-weight: 500;
}
.arco-collapse-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--color-neutral-1);
  border-bottom: 1px solid transparent;
  color: var(--color-text-1);
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  line-height: 24px;
  transition: border-color 0s ease 0.19s;
}
.arco-collapse-item-header-left {
  padding-left: 32px;
  padding-right: 13px;
}
.arco-collapse-item-header-right {
  padding-left: 13px;
  padding-right: 32px;
}
.arco-collapse-item-header-disabled {
  cursor: not-allowed;
  color: var(--color-text-4);
  background-color: #fafafa;
}
.arco-collapse-item-header-disabled .arco-collapse-item-header-icon {
  color: var(--color-text-4);
}
.arco-collapse-item-header-title {
  display: inline;
}
.arco-collapse-item-header-extra {
  float: right;
}
.arco-collapse-item .arco-collapse-item-icon-hover {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  text-align: center;
}
.arco-collapse-item .arco-collapse-item-icon-hover-right {
  right: 13px;
  left: unset;
}
.arco-collapse-item .arco-collapse-item-icon-hover-right > .arco-collapse-item-header-icon-down {
  transform: rotate(-90deg);
}
.arco-collapse-item-header-icon {
  color: var(--color-neutral-7);
  font-size: 14px;
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
  vertical-align: middle;
  position: relative;
  display: block;
}
.arco-collapse-item-header-icon-down {
  transform: rotate(90deg);
}
.arco-collapse-item-content {
  display: none;
  overflow: hidden;
  position: relative;
  color: var(--color-text-1);
  background-color: var(--color-bg-5);
  font-size: 14px;
  transition: height 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-collapse-item-content-expanded {
  display: block;
  height: auto;
}
.arco-collapse-item-content-box {
  padding: 8px 13px 8px 34px;
}
.arco-collapse-item.arco-collapse-item-disabled > .arco-collapse-item-content {
  color: var(--color-text-1);
}
.arco-collapse-item-no-icon > .arco-collapse-item-header {
  padding-left: 13px;
  padding-right: 13px;
}
.arco-collapse-item:last-of-type {
  border-bottom: none;
}
.arco-collapse.arco-collapse-borderless {
  border: none;
}
.arco-collapse::after {
  display: table;
  content: '';
  clear: both;
}
.arco-comment {
  display: flex;
  flex-wrap: nowrap;
  line-height: 1.5715;
}
.arco-comment:not(:first-of-type),
.arco-comment-inner-comment {
  margin-top: 20px;
}
.arco-comment-inner {
  flex: 1;
}
.arco-comment-avatar {
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.arco-comment-avatar > img {
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-circle);
}
.arco-comment-author {
  color: var(--color-text-2);
  font-size: 14px;
  margin-right: 8px;
}
.arco-comment-datetime {
  color: var(--color-text-3);
  font-size: 12px;
}
.arco-comment-content {
  font-size: 14px;
  color: var(--color-text-1);
}
.arco-comment-title-align-right {
  display: flex;
  justify-content: space-between;
}
.arco-comment-actions {
  margin-top: 8px;
  color: var(--color-text-2);
  font-size: 14px;
}
.arco-comment-actions > *:not(:last-child) {
  margin-right: 8px;
}
.arco-comment-actions-align-right {
  display: flex;
  justify-content: flex-end;
}
.arco-picker-container,
.arco-picker-range-container {
  border: 1px solid var(--color-neutral-3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-sizing: border-box;
  min-height: 60px;
  overflow: hidden;
}
.arco-picker-container-shortcuts-placement-left,
.arco-picker-range-container-shortcuts-placement-left {
  display: flex;
  align-items: flex-start;
}
.arco-picker-container-shortcuts-placement-left > .arco-picker-shortcuts,
.arco-picker-range-container-shortcuts-placement-left > .arco-picker-shortcuts {
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.arco-picker-container-shortcuts-placement-left > .arco-picker-shortcuts > *,
.arco-picker-range-container-shortcuts-placement-left > .arco-picker-shortcuts > * {
  margin: 5px 0;
}
.arco-picker-container-shortcuts-placement-left .arco-picker-panel-wrapper,
.arco-picker-range-container-shortcuts-placement-left .arco-picker-panel-wrapper,
.arco-picker-container-shortcuts-placement-left .arco-picker-range-panel-wrapper,
.arco-picker-range-container-shortcuts-placement-left .arco-picker-range-panel-wrapper {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-picker-panel-only,
.arco-picker-range-panel-only {
  box-shadow: none;
}
.arco-picker-panel-only .arco-panel-date-inner,
.arco-picker-range-panel-only .arco-panel-date-inner {
  width: 100%;
}
.arco-picker-range-panel-only .arco-panel-date,
.arco-picker-range-panel-only .arco-panel-month,
.arco-picker-range-panel-only .arco-panel-year {
  width: 100%;
}
.arco-picker-header {
  display: flex;
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-picker-header-value {
  font-size: 14px;
  line-height: 24px;
  flex: 1;
  text-align: center;
  color: var(--color-text-1);
  font-weight: 500;
  box-sizing: border-box;
}
.arco-picker-header-icon {
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  user-select: none;
  color: var(--color-text-2);
  background-color: var(--color-bg-popup);
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}
.arco-picker-header-icon:not(.arco-picker-header-icon-hidden) {
  cursor: pointer;
}
.arco-picker-header-icon:not(.arco-picker-header-icon-hidden):hover {
  background-color: var(--color-fill-3);
}
.arco-picker-header-label {
  cursor: pointer;
  padding: 2px;
  border-radius: 2px;
  transition: all 0.1s;
}
.arco-picker-header-label:hover {
  background-color: var(--color-fill-3);
}
.arco-picker-body {
  padding: 14px 16px;
}
.arco-picker-week-list {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 16px 0;
}
.arco-picker-week-list-item {
  color: var(--color-text-2);
  flex: 1;
  padding: 0;
  text-align: center;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
}
.arco-picker-row {
  display: flex;
}
.arco-picker-cell {
  position: relative;
  flex: 1;
  cursor: pointer;
  padding: 2px 0;
}
.arco-picker-cell .arco-picker-date {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding: 4px 0;
  justify-content: center;
}
.arco-picker-date-value {
  color: var(--color-text-4);
  font-size: 14px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  font-weight: 500;
}
.arco-picker-cell-in-view .arco-picker-date-value {
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-picker-cell:hover .arco-picker-date-value {
  background-color: var(--color-fill-3);
  color: var(--color-text-1);
}
.arco-picker-cell-disabled .arco-picker-date {
  background-color: var(--color-fill-1);
  cursor: not-allowed;
}
.arco-picker-cell-disabled .arco-picker-date-value,
.arco-picker-cell-disabled:hover .arco-picker-date-value {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-picker-cell-selected .arco-picker-date-value,
.arco-picker-cell-selected:hover .arco-picker-date-value {
  color: var(--color-white);
  background-color: rgb(var(--primary-6));
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-picker-cell-today {
  position: relative;
}
.arco-picker-cell-today::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgb(var(--primary-6));
}
.arco-picker-cell-in-range .arco-picker-date {
  background-color: var(--color-primary-light-1);
}
.arco-picker-cell-range-start .arco-picker-date,
.arco-picker-cell-hover-range-start .arco-picker-date {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.arco-picker-cell-range-end .arco-picker-date,
.arco-picker-cell-hover-range-end .arco-picker-date {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.arco-picker-cell-range-start .arco-picker-date-value,
.arco-picker-cell-range-end .arco-picker-date-value,
.arco-picker-cell-range-start:hover .arco-picker-date-value,
.arco-picker-cell-range-end:hover .arco-picker-date-value {
  background-color: rgb(var(--primary-6));
  border-radius: 24px;
  color: var(--color-white);
}
.arco-picker-cell-hover-in-range .arco-picker-date,
.arco-picker-cell-hover-range-start:not(.arco-picker-cell-range-start):not(
    .arco-picker-cell-range-end
  )
  .arco-picker-date-value,
.arco-picker-cell-hover-range-end:not(.arco-picker-cell-range-start):not(
    .arco-picker-cell-range-end
  )
  .arco-picker-date-value {
  background-color: var(--color-primary-light-2);
}
.arco-picker-cell-range-edge-in-hover-range .arco-picker-date {
  border-radius: 0;
}
.arco-picker-cell-hover-range-edge-in-range .arco-picker-date {
  border-radius: 0;
}
.arco-picker-cell-hidden .arco-picker-date {
  display: none;
}
.arco-picker-footer {
  width: min-content;
  min-width: 100%;
}
.arco-picker-footer-btn-wrapper {
  border-top: 1px solid var(--color-neutral-3);
  padding: 3px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.arco-picker-footer-extra-wrapper {
  padding: 8px 24px;
  color: var(--color-text-1);
  border-top: 1px solid var(--color-neutral-3);
  box-sizing: border-box;
  font-size: 12px;
}
.arco-picker-footer-now-wrapper {
  border-top: 1px solid var(--color-neutral-3);
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.arco-picker-btn-select-date,
.arco-picker-btn-select-time {
  margin-right: 8px;
}
.arco-picker-btn-confirm {
  margin: 5px 0;
}
.arco-picker-shortcuts {
  flex: 1;
}
.arco-picker-shortcuts > * {
  margin: 5px 10px 5px 0;
}
.arco-panel-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.arco-panel-date-inner {
  width: 265px;
}
.arco-panel-date-inner .arco-picker-body {
  padding-top: 0;
}
.arco-panel-date-timepicker {
  display: flex;
  flex-direction: column;
}
.arco-panel-date-timepicker-title {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid var(--color-neutral-3);
  color: var(--color-text-1);
}
.arco-panel-date-timepicker .arco-timepicker {
  width: 265px;
  height: 276px;
  padding: 0 6px;
  overflow: hidden;
}
.arco-panel-date-timepicker .arco-timepicker-list {
  width: 100%;
  height: 100%;
  padding: 0 4px;
  box-sizing: border-box;
}
.arco-panel-date-timepicker .arco-timepicker-list:not(:last-child) {
  border-right: 0;
}
.arco-panel-date-timepicker .arco-timepicker ul::after {
  height: 236px;
}
.arco-panel-date-timepicker .arco-timepicker-cell {
  width: 100%;
}
.arco-panel-date-holder {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--color-neutral-3);
}
.arco-panel-date-holder-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--color-text-3);
  font-size: 16px;
  transition: color 0.2s;
}
.arco-panel-date-holder-btn:first-child {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-panel-date-holder-btn:hover,
.arco-panel-date-holder-btn:active,
.arco-panel-date-holder-btn-active {
  color: var(--color-text-1);
}
.arco-panel-date-holder-btn-value {
  margin-left: 8px;
}
.arco-panel-date:first-child .arco-panel-date-holder {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-panel-month,
.arco-panel-quarter,
.arco-panel-year {
  box-sizing: border-box;
  width: 265px;
}
.arco-panel-month .arco-picker-date,
.arco-panel-quarter .arco-picker-date,
.arco-panel-year .arco-picker-date {
  padding: 4px;
}
.arco-panel-month .arco-picker-date-value,
.arco-panel-quarter .arco-picker-date-value,
.arco-panel-year .arco-picker-date-value {
  border-radius: 24px;
  width: 100%;
}
.arco-panel-month
  .arco-picker-cell:not(.arco-picker-cell-selected):not(.arco-picker-cell-range-start):not(
    .arco-picker-cell-range-end
  ):not(.arco-picker-cell-disabled):not(.arco-picker-cell-week)
  .arco-picker-date-value:hover,
.arco-panel-quarter
  .arco-picker-cell:not(.arco-picker-cell-selected):not(.arco-picker-cell-range-start):not(
    .arco-picker-cell-range-end
  ):not(.arco-picker-cell-disabled):not(.arco-picker-cell-week)
  .arco-picker-date-value:hover,
.arco-panel-year
  .arco-picker-cell:not(.arco-picker-cell-selected):not(.arco-picker-cell-range-start):not(
    .arco-picker-cell-range-end
  ):not(.arco-picker-cell-disabled):not(.arco-picker-cell-week)
  .arco-picker-date-value:hover {
  border-radius: 24px;
}
.arco-panel-year {
  box-sizing: border-box;
  width: 265px;
}
.arco-panel-week {
  box-sizing: border-box;
}
.arco-panel-week-wrapper {
  display: flex;
}
.arco-panel-week-inner {
  width: 298px;
}
.arco-panel-week-inner .arco-picker-body {
  padding-top: 0;
}
.arco-panel-week .arco-picker-row-week {
  cursor: pointer;
}
.arco-panel-week .arco-picker-row-week .arco-picker-date-value {
  width: 100%;
  border-radius: 0;
}
.arco-panel-week .arco-picker-cell .arco-picker-date {
  border-radius: 0;
}
.arco-panel-week .arco-picker-cell:nth-child(2) .arco-picker-date {
  padding-left: 4px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.arco-panel-week .arco-picker-cell:nth-child(2) .arco-picker-date .arco-picker-date-value {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.arco-panel-week .arco-picker-cell:nth-child(8) .arco-picker-date {
  padding-right: 4px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.arco-panel-week .arco-picker-cell:nth-child(8) .arco-picker-date .arco-picker-date-value {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.arco-panel-week
  .arco-picker-row-week:hover
  .arco-picker-cell:not(.arco-picker-cell-week):not(.arco-picker-cell-selected):not(
    .arco-picker-cell-range-start
  ):not(.arco-picker-cell-range-end):not(.arco-picker-cell-in-range):not(
    .arco-picker-cell-hover-in-range
  )
  .arco-picker-date-value {
  background-color: var(--color-fill-3);
}
.arco-panel-quarter {
  box-sizing: border-box;
  width: 265px;
}
.arco-picker-range-wrapper {
  display: flex;
}
.arco-datepicker-shortcuts-wrapper {
  width: 106px;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}
.arco-datepicker-shortcuts-wrapper > li {
  width: 100%;
  padding: 6px 16px;
  cursor: pointer;
  box-sizing: border-box;
}
.arco-datepicker-shortcuts-wrapper > li:hover {
  color: rgb(var(--primary-6));
}
.arco-descriptions-table {
  width: 100%;
  border-collapse: collapse;
}
.arco-descriptions-table-layout-fixed table {
  table-layout: fixed;
}
.arco-descriptions-title {
  font-size: 16px;
  color: var(--color-text-1);
  font-weight: 500;
  line-height: 1.5715;
  margin-bottom: 16px;
}
.arco-descriptions-item,
.arco-descriptions-item-label,
.arco-descriptions-item-value {
  padding: 0 4px 12px 0;
  text-align: left;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5715;
}
.arco-descriptions-item-label {
  color: var(--color-text-3);
  font-weight: 500;
  width: 1px;
  white-space: nowrap;
}
.arco-descriptions-table-layout-fixed .arco-descriptions-item-label {
  width: auto;
}
.arco-descriptions-item-value {
  color: var(--color-text-1);
  font-weight: 400;
}
.arco-descriptions-item-label-inline,
.arco-descriptions-item-value-inline {
  text-align: left;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5715;
}
.arco-descriptions-item-label-inline {
  color: var(--color-text-3);
  font-weight: 500;
  margin-bottom: 2px;
}
.arco-descriptions-item-value-inline {
  color: var(--color-text-1);
  font-weight: 400;
}
.arco-descriptions-layout-inline-horizontal .arco-descriptions-item-label-inline {
  margin-right: 4px;
}
.arco-descriptions-layout-inline-horizontal .arco-descriptions-item-label-inline,
.arco-descriptions-layout-inline-horizontal .arco-descriptions-item-value-inline {
  display: inline-block;
  margin-bottom: 0;
}
.arco-descriptions-border.arco-descriptions-layout-inline-vertical .arco-descriptions-item {
  padding: 12px 20px;
}
.arco-descriptions-border .arco-descriptions-body {
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}
.arco-descriptions-border .arco-descriptions-row:not(:last-child) {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-descriptions-border .arco-descriptions-item,
.arco-descriptions-border .arco-descriptions-item-label,
.arco-descriptions-border .arco-descriptions-item-value {
  border-right: 1px solid var(--color-neutral-3);
  padding: 7px 20px;
}
.arco-descriptions-border .arco-descriptions-item-label {
  background-color: var(--color-fill-1);
}
.arco-descriptions-border .arco-descriptions-item-value:last-child {
  border-right: none;
}
.arco-descriptions-border .arco-descriptions-item:last-child {
  border-right: none;
}
.arco-descriptions-border.arco-descriptions-layout-vertical
  .arco-descriptions-item-label:last-child {
  border-right: none;
}
.arco-descriptions-layout-vertical:not(.arco-descriptions-border)
  .arco-descriptions-item-value:first-child {
  padding-left: 0;
}
.arco-descriptions-size-mini .arco-descriptions-title {
  margin-bottom: 6px;
}
.arco-descriptions-size-mini .arco-descriptions-item-label,
.arco-descriptions-size-mini .arco-descriptions-item-value {
  padding-bottom: 2px;
  padding-right: 20px;
  font-size: 12px;
}
.arco-descriptions-size-mini.arco-descriptions-border .arco-descriptions-item-label,
.arco-descriptions-size-mini.arco-descriptions-border .arco-descriptions-item-value {
  padding: 3px 20px;
}
.arco-descriptions-size-mini.arco-descriptions-border.arco-descriptions-layout-inline-vertical
  .arco-descriptions-item {
  padding: 8px 20px;
}
.arco-descriptions-size-small .arco-descriptions-title {
  margin-bottom: 8px;
}
.arco-descriptions-size-small .arco-descriptions-item-label,
.arco-descriptions-size-small .arco-descriptions-item-value {
  padding-bottom: 4px;
  padding-right: 20px;
  font-size: 14px;
}
.arco-descriptions-size-small.arco-descriptions-border .arco-descriptions-item-label,
.arco-descriptions-size-small.arco-descriptions-border .arco-descriptions-item-value {
  padding: 3px 20px;
}
.arco-descriptions-size-small.arco-descriptions-border.arco-descriptions-layout-inline-vertical
  .arco-descriptions-item {
  padding: 8px 20px;
}
.arco-descriptions-size-medium .arco-descriptions-title {
  margin-bottom: 12px;
}
.arco-descriptions-size-medium .arco-descriptions-item-label,
.arco-descriptions-size-medium .arco-descriptions-item-value {
  padding-bottom: 8px;
  padding-right: 20px;
  font-size: 14px;
}
.arco-descriptions-size-medium.arco-descriptions-border .arco-descriptions-item-label,
.arco-descriptions-size-medium.arco-descriptions-border .arco-descriptions-item-value {
  padding: 5px 20px;
}
.arco-descriptions-size-medium.arco-descriptions-border.arco-descriptions-layout-inline-vertical
  .arco-descriptions-item {
  padding: 10px 20px;
}
.arco-descriptions-size-large .arco-descriptions-title {
  margin-bottom: 20px;
}
.arco-descriptions-size-large .arco-descriptions-item-label,
.arco-descriptions-size-large .arco-descriptions-item-value {
  padding-bottom: 16px;
  padding-right: 20px;
  font-size: 14px;
}
.arco-descriptions-size-large.arco-descriptions-border .arco-descriptions-item-label,
.arco-descriptions-size-large.arco-descriptions-border .arco-descriptions-item-value {
  padding: 9px 20px;
}
.arco-descriptions-size-large.arco-descriptions-border.arco-descriptions-layout-inline-vertical
  .arco-descriptions-item {
  padding: 14px 20px;
}
.arco-divider-horizontal {
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid var(--color-neutral-3);
  clear: both;
}
.arco-divider-horizontal.arco-divider-with-text {
  margin: 20px 0;
}
.arco-divider-vertical {
  display: inline-block;
  min-width: 1px;
  max-width: 1px;
  height: 1em;
  margin: 0 12px;
  border-left: 1px solid var(--color-neutral-3);
  vertical-align: middle;
}
.arco-divider-text {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  background: var(--color-bg-2);
  color: var(--color-text-1);
  transform: translateY(-50%);
}
.arco-divider-text-center {
  left: 50%;
  transform: translate(-50%, -50%);
}
.arco-divider-text-left {
  left: 24px;
}
.arco-divider-text-right {
  right: 24px;
}
.arco-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-mask-bg);
}
.arco-drawer-no-mask {
  pointer-events: none;
}
.arco-drawer-no-mask .arco-drawer {
  pointer-events: auto;
}
.arco-drawer-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.arco-drawer-wrapper-hide {
  display: none;
}
.arco-drawer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-3);
  line-height: 1.5715;
}
.arco-drawer-wrapper {
  position: relative;
  height: 100%;
}
.arco-drawer-fixed {
  position: fixed;
  top: 0;
  z-index: 1001;
}
.arco-drawer-inner {
  height: 100%;
  overflow: hidden;
}
.arco-drawer-scroll {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.arco-drawer-header {
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  border-bottom: 1px solid var(--color-neutral-3);
  flex-shrink: 0;
}
.arco-drawer-header-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-1);
  text-align: left;
}
.arco-drawer-footer {
  box-sizing: border-box;
  padding: 16px 16px;
  border-top: 1px solid var(--color-neutral-3);
  text-align: right;
  flex-shrink: 0;
}
.arco-drawer-footer > .arco-btn {
  margin-left: 12px;
}
.arco-drawer .arco-drawer-close-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  z-index: 1;
  font-size: 12px;
  color: var(--color-text-1);
}
.arco-drawer-content {
  flex: 1;
  height: 100%;
  padding: 12px 16px;
  color: var(--color-text-1);
  box-sizing: border-box;
  position: relative;
  overflow: auto;
}
.arco-dropdown-menu {
  position: relative;
  box-sizing: border-box;
  max-height: 200px;
  padding: 4px 0;
  border: 1px solid var(--color-fill-3);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.arco-dropdown-menu-hidden {
  display: none;
}
.arco-dropdown-menu-item,
.arco-dropdown-menu-pop-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 36px;
  text-align: left;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-1);
  background-color: transparent;
}
.arco-dropdown-menu-item.arco-dropdown-menu-selected,
.arco-dropdown-menu-pop-header.arco-dropdown-menu-selected {
  color: var(--color-text-1);
  background-color: transparent;
  font-weight: 500;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-dropdown-menu-item:hover,
.arco-dropdown-menu-pop-header:hover {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}
.arco-dropdown-menu-item.arco-dropdown-menu-active,
.arco-dropdown-menu-pop-header.arco-dropdown-menu-active {
  box-shadow: 0 0 0 1px rgb(var(--primary-6)) inset;
}
.arco-dropdown-menu-item.arco-dropdown-menu-disabled,
.arco-dropdown-menu-pop-header.arco-dropdown-menu-disabled {
  color: var(--color-text-4);
  background-color: transparent;
  cursor: not-allowed;
}
.arco-dropdown-menu-pop-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arco-dropdown-menu-pop-header .arco-dropdown-menu-icon-suffix {
  margin-left: 12px;
}
.arco-dropdown-menu-group:first-child .arco-dropdown-menu-group-title {
  margin-top: 4px;
}
.arco-dropdown-menu-group-title {
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  line-height: 20px;
  font-size: 12px;
  color: var(--color-text-3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-dropdown-menu-dark {
  border-color: var(--color-menu-dark-bg);
  background-color: var(--color-menu-dark-bg);
}
.arco-dropdown-menu-dark .arco-dropdown-menu-item,
.arco-dropdown-menu-dark .arco-dropdown-menu-pop-header {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-dropdown-menu-dark .arco-dropdown-menu-item.arco-dropdown-menu-selected,
.arco-dropdown-menu-dark .arco-dropdown-menu-pop-header.arco-dropdown-menu-selected {
  color: var(--color-white);
  background-color: transparent;
}
.arco-dropdown-menu-dark .arco-dropdown-menu-item.arco-dropdown-menu-selected:hover,
.arco-dropdown-menu-dark .arco-dropdown-menu-pop-header.arco-dropdown-menu-selected:hover {
  color: var(--color-white);
}
.arco-dropdown-menu-dark .arco-dropdown-menu-item:hover,
.arco-dropdown-menu-dark .arco-dropdown-menu-pop-header:hover {
  color: var(--color-text-4);
  background-color: var(--color-menu-dark-hover);
}
.arco-dropdown-menu-dark .arco-dropdown-menu-item.arco-dropdown-menu-disabled,
.arco-dropdown-menu-dark .arco-dropdown-menu-pop-header.arco-dropdown-menu-disabled {
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-dropdown-menu-dark .arco-dropdown-menu-group-title {
  color: var(--color-text-3);
}
.arco-dropdown-menu-pop-trigger .arco-trigger-arrow {
  display: none;
}
.arco-dropdown-menu + .arco-trigger-arrow {
  background-color: var(--color-bg-popup);
}
.arco-empty {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
}
.arco-empty .arco-empty-wrapper {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: rgb(var(--gray-5));
}
.arco-empty .arco-empty-wrapper .arco-empty-image {
  font-size: 48px;
  line-height: 1;
  margin-bottom: 4px;
}
.arco-empty .arco-empty-wrapper img {
  height: 80px;
}
.arco-empty .arco-empty-description {
  color: rgb(var(--gray-5));
  font-size: 14px;
}
.arco-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.arco-form-inline {
  flex-flow: row wrap;
}
.arco-form-inline .arco-form-item {
  width: auto;
  margin-bottom: 8px;
}
.arco-form-item {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.arco-form-item > .arco-form-label-item {
  padding-right: 16px;
}
.arco-form-item.arco-form-item-error {
  margin-bottom: 0;
}
.arco-form-item-wrapper-flex.arco-col {
  flex: 1;
}
.arco-form-size-mini .arco-form-label-item {
  line-height: 24px;
}
.arco-form-size-mini .arco-form-label-item > label {
  font-size: 12px;
}
.arco-form-size-mini .arco-form-item-control {
  min-height: 24px;
}
.arco-form-size-small .arco-form-label-item {
  line-height: 28px;
}
.arco-form-size-small .arco-form-label-item > label {
  font-size: 14px;
}
.arco-form-size-small .arco-form-item-control {
  min-height: 28px;
}
.arco-form-size-large .arco-form-label-item {
  line-height: 36px;
}
.arco-form-size-large .arco-form-label-item > label {
  font-size: 14px;
}
.arco-form-size-large .arco-form-item-control {
  min-height: 36px;
}
.arco-form-extra {
  font-size: 12px;
  color: var(--color-text-3);
  margin-top: 4px;
}
.arco-form-message {
  font-size: 12px;
  color: rgb(var(--danger-6));
  min-height: 20px;
  line-height: 20px;
}
.arco-form-message-help {
  color: var(--color-text-3);
}
.arco-form-message + .arco-form-extra {
  margin-bottom: 4px;
  margin-top: 0;
}
.arco-form-layout-vertical {
  display: block;
}
.arco-form-layout-vertical > .arco-form-label-item {
  line-height: 1.5715;
  white-space: normal;
  text-align: left;
  padding: 0;
  margin-bottom: 8px;
}
.arco-form-layout-inline {
  margin-right: 24px;
}
.arco-form-label-item {
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}
.arco-form-label-item-flex.arco-col {
  flex: 0;
}
.arco-form-label-item-flex.arco-col > label {
  white-space: nowrap;
}
.arco-form-label-item > label {
  font-size: 14px;
  white-space: normal;
  color: var(--color-text-2);
}
.arco-form-label-item .arco-form-item-symbol {
  color: rgb(var(--danger-6));
  font-size: 12px;
  line-height: 1;
}
.arco-form-label-item .arco-form-item-symbol svg {
  transform: scale(0.5);
}
.arco-form-label-item-left {
  text-align: left;
}
.arco-form-item-control {
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 100%;
}
.arco-form-item-control-children {
  width: 100%;
  flex: 1;
}
.arco-form-item-control-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.arco-form .arco-slider {
  display: block;
}
.arco-form-item-status-validating .arco-input:not(.arco-input-disabled),
.arco-form-item-status-validating .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
.arco-form-item-status-validating .arco-textarea:not(.arco-textarea-disabled) {
  border-color: transparent;
  background-color: var(--color-fill-2);
}
.arco-form-item-status-validating .arco-input:not(.arco-input-disabled):hover,
.arco-form-item-status-validating
  .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
.arco-form-item-status-validating .arco-textarea:not(.arco-textarea-disabled):hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-validating .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
.arco-form-item-status-validating .arco-input-inner-wrapper.arco-textarea-focus,
.arco-form-item-status-validating .arco-textarea.arco-input-inner-wrapper-focus,
.arco-form-item-status-validating .arco-textarea.arco-textarea-focus,
.arco-form-item-status-validating .arco-input-inner-wrapper.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-validating .arco-input-inner-wrapper.arco-textarea-focus:hover,
.arco-form-item-status-validating .arco-textarea.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-validating .arco-textarea.arco-textarea-focus:hover {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-input:focus,
.arco-form-item-status-validating .arco-input:focus:hover {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-input-inner-wrapper .arco-input {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-validating .arco-input-inner-wrapper .arco-input:hover {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-validating .arco-select:not(.arco-select-disabled) .arco-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-validating .arco-select:not(.arco-select-disabled):hover .arco-select-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-validating
  .arco-select:not(.arco-select-disabled).arco-select-focused
  .arco-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-cascader:not(.arco-cascader-disabled) .arco-cascader-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-validating
  .arco-cascader:not(.arco-cascader-disabled):hover
  .arco-cascader-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-validating
  .arco-cascader:not(.arco-cascader-disabled).arco-cascader-focused
  .arco-cascader-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating
  .arco-tree-select:not(.arco-tree-select-disabled)
  .arco-tree-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-validating
  .arco-tree-select:not(.arco-tree-select-disabled):hover
  .arco-tree-select-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-validating
  .arco-tree-select:not(.arco-tree-select-disabled).arco-tree-select-focused
  .arco-tree-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-picker:not(.arco-picker-disabled) {
  border-color: transparent;
  background-color: var(--color-fill-2);
}
.arco-form-item-status-validating .arco-picker:not(.arco-picker-disabled):hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-validating .arco-picker-focused:not(.arco-picker-disabled),
.arco-form-item-status-validating .arco-picker-focused:not(.arco-picker-disabled):hover {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-input-tag {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-validating .arco-input-tag:hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-validating .arco-input-tag.arco-input-tag-focus {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-form-item-status-validating .arco-form-message-help {
  color: rgb(var(--primary-6));
}
.arco-form-item-status-validating .arco-form-message-help .arco-form-message-help-warning {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-validating .arco-form-item-feedback {
  color: rgb(var(--primary-6));
}
.arco-form-item-status-success .arco-input:not(.arco-input-disabled),
.arco-form-item-status-success .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
.arco-form-item-status-success .arco-textarea:not(.arco-textarea-disabled) {
  border-color: transparent;
  background-color: var(--color-fill-2);
}
.arco-form-item-status-success .arco-input:not(.arco-input-disabled):hover,
.arco-form-item-status-success
  .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
.arco-form-item-status-success .arco-textarea:not(.arco-textarea-disabled):hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-success .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
.arco-form-item-status-success .arco-input-inner-wrapper.arco-textarea-focus,
.arco-form-item-status-success .arco-textarea.arco-input-inner-wrapper-focus,
.arco-form-item-status-success .arco-textarea.arco-textarea-focus,
.arco-form-item-status-success .arco-input-inner-wrapper.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-success .arco-input-inner-wrapper.arco-textarea-focus:hover,
.arco-form-item-status-success .arco-textarea.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-success .arco-textarea.arco-textarea-focus:hover {
  border-color: rgb(var(--success-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-input:focus,
.arco-form-item-status-success .arco-input:focus:hover {
  border-color: rgb(var(--success-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-input-inner-wrapper .arco-input {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-success .arco-input-inner-wrapper .arco-input:hover {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-success .arco-select:not(.arco-select-disabled) .arco-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-success .arco-select:not(.arco-select-disabled):hover .arco-select-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-success
  .arco-select:not(.arco-select-disabled).arco-select-focused
  .arco-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--success-6));
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-cascader:not(.arco-cascader-disabled) .arco-cascader-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-success
  .arco-cascader:not(.arco-cascader-disabled):hover
  .arco-cascader-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-success
  .arco-cascader:not(.arco-cascader-disabled).arco-cascader-focused
  .arco-cascader-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--success-6));
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success
  .arco-tree-select:not(.arco-tree-select-disabled)
  .arco-tree-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-success
  .arco-tree-select:not(.arco-tree-select-disabled):hover
  .arco-tree-select-view {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-form-item-status-success
  .arco-tree-select:not(.arco-tree-select-disabled).arco-tree-select-focused
  .arco-tree-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--success-6));
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-picker:not(.arco-picker-disabled) {
  border-color: transparent;
  background-color: var(--color-fill-2);
}
.arco-form-item-status-success .arco-picker:not(.arco-picker-disabled):hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-success .arco-picker-focused:not(.arco-picker-disabled),
.arco-form-item-status-success .arco-picker-focused:not(.arco-picker-disabled):hover {
  border-color: rgb(var(--success-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-input-tag {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-form-item-status-success .arco-input-tag:hover {
  border-color: transparent;
  background-color: var(--color-fill-3);
}
.arco-form-item-status-success .arco-input-tag.arco-input-tag-focus {
  border-color: rgb(var(--success-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-success-light-2);
}
.arco-form-item-status-success .arco-form-message-help {
  color: rgb(var(--success-6));
}
.arco-form-item-status-success .arco-form-message-help .arco-form-message-help-warning {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-success .arco-form-item-feedback {
  color: rgb(var(--success-6));
}
.arco-form-item-status-warning .arco-input:not(.arco-input-disabled),
.arco-form-item-status-warning .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
.arco-form-item-status-warning .arco-textarea:not(.arco-textarea-disabled) {
  border-color: transparent;
  background-color: var(--color-warning-light-1);
}
.arco-form-item-status-warning .arco-input:not(.arco-input-disabled):hover,
.arco-form-item-status-warning
  .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
.arco-form-item-status-warning .arco-textarea:not(.arco-textarea-disabled):hover {
  border-color: transparent;
  background-color: var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
.arco-form-item-status-warning .arco-input-inner-wrapper.arco-textarea-focus,
.arco-form-item-status-warning .arco-textarea.arco-input-inner-wrapper-focus,
.arco-form-item-status-warning .arco-textarea.arco-textarea-focus,
.arco-form-item-status-warning .arco-input-inner-wrapper.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-warning .arco-input-inner-wrapper.arco-textarea-focus:hover,
.arco-form-item-status-warning .arco-textarea.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-warning .arco-textarea.arco-textarea-focus:hover {
  border-color: rgb(var(--warning-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-input:focus,
.arco-form-item-status-warning .arco-input:focus:hover {
  border-color: rgb(var(--warning-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-input-inner-wrapper .arco-input {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-warning .arco-input-inner-wrapper .arco-input:hover {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-warning .arco-select:not(.arco-select-disabled) .arco-select-view {
  background-color: var(--color-warning-light-1);
  border-color: transparent;
}
.arco-form-item-status-warning .arco-select:not(.arco-select-disabled):hover .arco-select-view {
  background-color: var(--color-warning-light-2);
  border-color: transparent;
}
.arco-form-item-status-warning
  .arco-select:not(.arco-select-disabled).arco-select-focused
  .arco-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--warning-6));
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-cascader:not(.arco-cascader-disabled) .arco-cascader-view {
  background-color: var(--color-warning-light-1);
  border-color: transparent;
}
.arco-form-item-status-warning
  .arco-cascader:not(.arco-cascader-disabled):hover
  .arco-cascader-view {
  background-color: var(--color-warning-light-2);
  border-color: transparent;
}
.arco-form-item-status-warning
  .arco-cascader:not(.arco-cascader-disabled).arco-cascader-focused
  .arco-cascader-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--warning-6));
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning
  .arco-tree-select:not(.arco-tree-select-disabled)
  .arco-tree-select-view {
  background-color: var(--color-warning-light-1);
  border-color: transparent;
}
.arco-form-item-status-warning
  .arco-tree-select:not(.arco-tree-select-disabled):hover
  .arco-tree-select-view {
  background-color: var(--color-warning-light-2);
  border-color: transparent;
}
.arco-form-item-status-warning
  .arco-tree-select:not(.arco-tree-select-disabled).arco-tree-select-focused
  .arco-tree-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--warning-6));
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-picker:not(.arco-picker-disabled) {
  border-color: transparent;
  background-color: var(--color-warning-light-1);
}
.arco-form-item-status-warning .arco-picker:not(.arco-picker-disabled):hover {
  border-color: transparent;
  background-color: var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-picker-focused:not(.arco-picker-disabled),
.arco-form-item-status-warning .arco-picker-focused:not(.arco-picker-disabled):hover {
  border-color: rgb(var(--warning-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-input-tag {
  background-color: var(--color-warning-light-1);
  border-color: transparent;
}
.arco-form-item-status-warning .arco-input-tag:hover {
  border-color: transparent;
  background-color: var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-input-tag.arco-input-tag-focus {
  border-color: rgb(var(--warning-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-warning-light-2);
}
.arco-form-item-status-warning .arco-form-message-help {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-warning .arco-form-message-help .arco-form-message-help-warning {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-warning .arco-form-item-feedback {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-error .arco-input:not(.arco-input-disabled),
.arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
.arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled) {
  border-color: transparent;
  background-color: var(--color-danger-light-1);
}
.arco-form-item-status-error .arco-input:not(.arco-input-disabled):hover,
.arco-form-item-status-error
  .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
.arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled):hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
.arco-form-item-status-error .arco-input-inner-wrapper.arco-textarea-focus,
.arco-form-item-status-error .arco-textarea.arco-input-inner-wrapper-focus,
.arco-form-item-status-error .arco-textarea.arco-textarea-focus,
.arco-form-item-status-error .arco-input-inner-wrapper.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-error .arco-input-inner-wrapper.arco-textarea-focus:hover,
.arco-form-item-status-error .arco-textarea.arco-input-inner-wrapper-focus:hover,
.arco-form-item-status-error .arco-textarea.arco-textarea-focus:hover {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-input:focus,
.arco-form-item-status-error .arco-input:focus:hover {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-input-inner-wrapper .arco-input {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-error .arco-input-inner-wrapper .arco-input:hover {
  background: none;
  box-shadow: none;
}
.arco-form-item-status-error .arco-select:not(.arco-select-disabled) .arco-select-view {
  background-color: var(--color-danger-light-1);
  border-color: transparent;
}
.arco-form-item-status-error .arco-select:not(.arco-select-disabled):hover .arco-select-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-form-item-status-error
  .arco-select:not(.arco-select-disabled).arco-select-focused
  .arco-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-cascader:not(.arco-cascader-disabled) .arco-cascader-view {
  background-color: var(--color-danger-light-1);
  border-color: transparent;
}
.arco-form-item-status-error .arco-cascader:not(.arco-cascader-disabled):hover .arco-cascader-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-form-item-status-error
  .arco-cascader:not(.arco-cascader-disabled).arco-cascader-focused
  .arco-cascader-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error
  .arco-tree-select:not(.arco-tree-select-disabled)
  .arco-tree-select-view {
  background-color: var(--color-danger-light-1);
  border-color: transparent;
}
.arco-form-item-status-error
  .arco-tree-select:not(.arco-tree-select-disabled):hover
  .arco-tree-select-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-form-item-status-error
  .arco-tree-select:not(.arco-tree-select-disabled).arco-tree-select-focused
  .arco-tree-select-view {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-picker:not(.arco-picker-disabled) {
  border-color: transparent;
  background-color: var(--color-danger-light-1);
}
.arco-form-item-status-error .arco-picker:not(.arco-picker-disabled):hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-picker-focused:not(.arco-picker-disabled),
.arco-form-item-status-error .arco-picker-focused:not(.arco-picker-disabled):hover {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-input-tag {
  background-color: var(--color-danger-light-1);
  border-color: transparent;
}
.arco-form-item-status-error .arco-input-tag:hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-input-tag.arco-input-tag-focus {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-form-item-status-error .arco-form-message-help {
  color: rgb(var(--danger-6));
}
.arco-form-item-status-error .arco-form-message-help .arco-form-message-help-warning {
  color: rgb(var(--warning-6));
}
.arco-form-item-status-error .arco-form-item-feedback {
  color: rgb(var(--danger-6));
}
.arco-form-item-control-children {
  position: relative;
}
.arco-form-item-feedback {
  position: absolute;
  right: 9px;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
}
.arco-form-item-feedback .arco-icon-loading {
  font-size: 12px;
}
.arco-form-item-has-feedback .arco-input,
.arco-form-item-has-feedback .arco-input-inner-wrapper,
.arco-form-item-has-feedback .arco-textarea {
  padding-right: 28px;
}
.arco-form-item-has-feedback .arco-input-number-mode-embed .arco-input-number-step-layer {
  right: 24px;
}
.arco-form-item-has-feedback .arco-select.arco-select-multiple .arco-select-view,
.arco-form-item-has-feedback .arco-select.arco-select-single .arco-select-view {
  padding-right: 28px;
}
.arco-form-item-has-feedback .arco-select.arco-select-multiple .arco-select-suffix {
  padding-right: 0;
}
.arco-form-item-has-feedback .arco-cascader.arco-cascader-multiple .arco-cascader-view,
.arco-form-item-has-feedback .arco-cascader.arco-cascader-single .arco-cascader-view {
  padding-right: 28px;
}
.arco-form-item-has-feedback .arco-cascader.arco-cascader-multiple .arco-cascader-suffix {
  padding-right: 0;
}
.arco-form-item-has-feedback .arco-tree-select.arco-tree-select-multiple .arco-tree-select-view,
.arco-form-item-has-feedback .arco-tree-select.arco-tree-select-single .arco-tree-select-view {
  padding-right: 28px;
}
.arco-form-item-has-feedback .arco-tree-select.arco-tree-select-multiple .arco-tree-select-suffix {
  padding-right: 0;
}
.arco-form-item-has-feedback .arco-picker {
  padding-right: 28px;
}
.arco-form-item-has-feedback .arco-picker-suffix .arco-picker-suffix-icon,
.arco-form-item-has-feedback .arco-picker-suffix .arco-picker-clear-icon {
  margin-right: 0;
  margin-left: 0;
}
.arco-form-item-has-feedback .arco-input-tag {
  padding-right: 23px;
}
.arco-form-item-has-feedback .arco-input-tag-suffix {
  padding-right: 0;
}
.formblink-enter,
.formblink-appear {
  opacity: 0;
}
.formblink-enter-active,
.formblink-appear-active {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0, 0, 1, 1);
}
.formblink-enter-done {
  animation: arco-form-blink 0.5s cubic-bezier(0, 0, 1, 1);
}
@keyframes arco-form-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.arco-row {
  display: flex;
  flex-flow: row wrap;
}
.arco-row-align-start {
  align-items: flex-start;
}
.arco-row-align-center {
  align-items: center;
}
.arco-row-align-end {
  align-items: flex-end;
}
.arco-row-justify-start {
  justify-content: flex-start;
}
.arco-row-justify-center {
  justify-content: center;
}
.arco-row-justify-end {
  justify-content: flex-end;
}
.arco-row-justify-space-around {
  justify-content: space-around;
}
.arco-row-justify-space-between {
  justify-content: space-between;
}
.arco-col {
  position: relative;
  box-sizing: border-box;
}
.arco-col-1 {
  width: 4.16666667%;
  flex: 0 0 4.16666667%;
}
.arco-col-2 {
  width: 8.33333333%;
  flex: 0 0 8.33333333%;
}
.arco-col-3 {
  width: 12.5%;
  flex: 0 0 12.5%;
}
.arco-col-4 {
  width: 16.66666667%;
  flex: 0 0 16.66666667%;
}
.arco-col-5 {
  width: 20.83333333%;
  flex: 0 0 20.83333333%;
}
.arco-col-6 {
  width: 25%;
  flex: 0 0 25%;
}
.arco-col-7 {
  width: 29.16666667%;
  flex: 0 0 29.16666667%;
}
.arco-col-8 {
  width: 33.33333333%;
  flex: 0 0 33.33333333%;
}
.arco-col-9 {
  width: 37.5%;
  flex: 0 0 37.5%;
}
.arco-col-10 {
  width: 41.66666667%;
  flex: 0 0 41.66666667%;
}
.arco-col-11 {
  width: 45.83333333%;
  flex: 0 0 45.83333333%;
}
.arco-col-12 {
  width: 50%;
  flex: 0 0 50%;
}
.arco-col-13 {
  width: 54.16666667%;
  flex: 0 0 54.16666667%;
}
.arco-col-14 {
  width: 58.33333333%;
  flex: 0 0 58.33333333%;
}
.arco-col-15 {
  width: 62.5%;
  flex: 0 0 62.5%;
}
.arco-col-16 {
  width: 66.66666667%;
  flex: 0 0 66.66666667%;
}
.arco-col-17 {
  width: 70.83333333%;
  flex: 0 0 70.83333333%;
}
.arco-col-18 {
  width: 75%;
  flex: 0 0 75%;
}
.arco-col-19 {
  width: 79.16666667%;
  flex: 0 0 79.16666667%;
}
.arco-col-20 {
  width: 83.33333333%;
  flex: 0 0 83.33333333%;
}
.arco-col-21 {
  width: 87.5%;
  flex: 0 0 87.5%;
}
.arco-col-22 {
  width: 91.66666667%;
  flex: 0 0 91.66666667%;
}
.arco-col-23 {
  width: 95.83333333%;
  flex: 0 0 95.83333333%;
}
.arco-col-24 {
  width: 100%;
  flex: 0 0 100%;
}
.arco-col-offset-1 {
  margin-left: 4.16666667%;
}
.arco-col-offset-2 {
  margin-left: 8.33333333%;
}
.arco-col-offset-3 {
  margin-left: 12.5%;
}
.arco-col-offset-4 {
  margin-left: 16.66666667%;
}
.arco-col-offset-5 {
  margin-left: 20.83333333%;
}
.arco-col-offset-6 {
  margin-left: 25%;
}
.arco-col-offset-7 {
  margin-left: 29.16666667%;
}
.arco-col-offset-8 {
  margin-left: 33.33333333%;
}
.arco-col-offset-9 {
  margin-left: 37.5%;
}
.arco-col-offset-10 {
  margin-left: 41.66666667%;
}
.arco-col-offset-11 {
  margin-left: 45.83333333%;
}
.arco-col-offset-12 {
  margin-left: 50%;
}
.arco-col-offset-13 {
  margin-left: 54.16666667%;
}
.arco-col-offset-14 {
  margin-left: 58.33333333%;
}
.arco-col-offset-15 {
  margin-left: 62.5%;
}
.arco-col-offset-16 {
  margin-left: 66.66666667%;
}
.arco-col-offset-17 {
  margin-left: 70.83333333%;
}
.arco-col-offset-18 {
  margin-left: 75%;
}
.arco-col-offset-19 {
  margin-left: 79.16666667%;
}
.arco-col-offset-20 {
  margin-left: 83.33333333%;
}
.arco-col-offset-21 {
  margin-left: 87.5%;
}
.arco-col-offset-22 {
  margin-left: 91.66666667%;
}
.arco-col-offset-23 {
  margin-left: 95.83333333%;
}
.arco-col-order-1 {
  order: 1;
}
.arco-col-order-2 {
  order: 2;
}
.arco-col-order-3 {
  order: 3;
}
.arco-col-order-4 {
  order: 4;
}
.arco-col-order-5 {
  order: 5;
}
.arco-col-order-6 {
  order: 6;
}
.arco-col-order-7 {
  order: 7;
}
.arco-col-order-8 {
  order: 8;
}
.arco-col-order-9 {
  order: 9;
}
.arco-col-order-10 {
  order: 10;
}
.arco-col-order-11 {
  order: 11;
}
.arco-col-order-12 {
  order: 12;
}
.arco-col-order-13 {
  order: 13;
}
.arco-col-order-14 {
  order: 14;
}
.arco-col-order-15 {
  order: 15;
}
.arco-col-order-16 {
  order: 16;
}
.arco-col-order-17 {
  order: 17;
}
.arco-col-order-18 {
  order: 18;
}
.arco-col-order-19 {
  order: 19;
}
.arco-col-order-20 {
  order: 20;
}
.arco-col-order-21 {
  order: 21;
}
.arco-col-order-22 {
  order: 22;
}
.arco-col-order-23 {
  order: 23;
}
.arco-col-order-24 {
  order: 24;
}
.arco-col-pull-1 {
  right: 4.16666667%;
}
.arco-col-pull-2 {
  right: 8.33333333%;
}
.arco-col-pull-3 {
  right: 12.5%;
}
.arco-col-pull-4 {
  right: 16.66666667%;
}
.arco-col-pull-5 {
  right: 20.83333333%;
}
.arco-col-pull-6 {
  right: 25%;
}
.arco-col-pull-7 {
  right: 29.16666667%;
}
.arco-col-pull-8 {
  right: 33.33333333%;
}
.arco-col-pull-9 {
  right: 37.5%;
}
.arco-col-pull-10 {
  right: 41.66666667%;
}
.arco-col-pull-11 {
  right: 45.83333333%;
}
.arco-col-pull-12 {
  right: 50%;
}
.arco-col-pull-13 {
  right: 54.16666667%;
}
.arco-col-pull-14 {
  right: 58.33333333%;
}
.arco-col-pull-15 {
  right: 62.5%;
}
.arco-col-pull-16 {
  right: 66.66666667%;
}
.arco-col-pull-17 {
  right: 70.83333333%;
}
.arco-col-pull-18 {
  right: 75%;
}
.arco-col-pull-19 {
  right: 79.16666667%;
}
.arco-col-pull-20 {
  right: 83.33333333%;
}
.arco-col-pull-21 {
  right: 87.5%;
}
.arco-col-pull-22 {
  right: 91.66666667%;
}
.arco-col-pull-23 {
  right: 95.83333333%;
}
.arco-col-pull-24 {
  right: 100%;
}
.arco-col-push-1 {
  left: 4.16666667%;
}
.arco-col-push-2 {
  left: 8.33333333%;
}
.arco-col-push-3 {
  left: 12.5%;
}
.arco-col-push-4 {
  left: 16.66666667%;
}
.arco-col-push-5 {
  left: 20.83333333%;
}
.arco-col-push-6 {
  left: 25%;
}
.arco-col-push-7 {
  left: 29.16666667%;
}
.arco-col-push-8 {
  left: 33.33333333%;
}
.arco-col-push-9 {
  left: 37.5%;
}
.arco-col-push-10 {
  left: 41.66666667%;
}
.arco-col-push-11 {
  left: 45.83333333%;
}
.arco-col-push-12 {
  left: 50%;
}
.arco-col-push-13 {
  left: 54.16666667%;
}
.arco-col-push-14 {
  left: 58.33333333%;
}
.arco-col-push-15 {
  left: 62.5%;
}
.arco-col-push-16 {
  left: 66.66666667%;
}
.arco-col-push-17 {
  left: 70.83333333%;
}
.arco-col-push-18 {
  left: 75%;
}
.arco-col-push-19 {
  left: 79.16666667%;
}
.arco-col-push-20 {
  left: 83.33333333%;
}
.arco-col-push-21 {
  left: 87.5%;
}
.arco-col-push-22 {
  left: 91.66666667%;
}
.arco-col-push-23 {
  left: 95.83333333%;
}
.arco-col-push-24 {
  left: 100%;
}
.arco-col-xs-1 {
  width: 4.16666667%;
  flex: 0 0 4.16666667%;
}
.arco-col-xs-2 {
  width: 8.33333333%;
  flex: 0 0 8.33333333%;
}
.arco-col-xs-3 {
  width: 12.5%;
  flex: 0 0 12.5%;
}
.arco-col-xs-4 {
  width: 16.66666667%;
  flex: 0 0 16.66666667%;
}
.arco-col-xs-5 {
  width: 20.83333333%;
  flex: 0 0 20.83333333%;
}
.arco-col-xs-6 {
  width: 25%;
  flex: 0 0 25%;
}
.arco-col-xs-7 {
  width: 29.16666667%;
  flex: 0 0 29.16666667%;
}
.arco-col-xs-8 {
  width: 33.33333333%;
  flex: 0 0 33.33333333%;
}
.arco-col-xs-9 {
  width: 37.5%;
  flex: 0 0 37.5%;
}
.arco-col-xs-10 {
  width: 41.66666667%;
  flex: 0 0 41.66666667%;
}
.arco-col-xs-11 {
  width: 45.83333333%;
  flex: 0 0 45.83333333%;
}
.arco-col-xs-12 {
  width: 50%;
  flex: 0 0 50%;
}
.arco-col-xs-13 {
  width: 54.16666667%;
  flex: 0 0 54.16666667%;
}
.arco-col-xs-14 {
  width: 58.33333333%;
  flex: 0 0 58.33333333%;
}
.arco-col-xs-15 {
  width: 62.5%;
  flex: 0 0 62.5%;
}
.arco-col-xs-16 {
  width: 66.66666667%;
  flex: 0 0 66.66666667%;
}
.arco-col-xs-17 {
  width: 70.83333333%;
  flex: 0 0 70.83333333%;
}
.arco-col-xs-18 {
  width: 75%;
  flex: 0 0 75%;
}
.arco-col-xs-19 {
  width: 79.16666667%;
  flex: 0 0 79.16666667%;
}
.arco-col-xs-20 {
  width: 83.33333333%;
  flex: 0 0 83.33333333%;
}
.arco-col-xs-21 {
  width: 87.5%;
  flex: 0 0 87.5%;
}
.arco-col-xs-22 {
  width: 91.66666667%;
  flex: 0 0 91.66666667%;
}
.arco-col-xs-23 {
  width: 95.83333333%;
  flex: 0 0 95.83333333%;
}
.arco-col-xs-24 {
  width: 100%;
  flex: 0 0 100%;
}
.arco-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.arco-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.arco-col-xs-offset-3 {
  margin-left: 12.5%;
}
.arco-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.arco-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.arco-col-xs-offset-6 {
  margin-left: 25%;
}
.arco-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.arco-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.arco-col-xs-offset-9 {
  margin-left: 37.5%;
}
.arco-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.arco-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.arco-col-xs-offset-12 {
  margin-left: 50%;
}
.arco-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.arco-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.arco-col-xs-offset-15 {
  margin-left: 62.5%;
}
.arco-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.arco-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.arco-col-xs-offset-18 {
  margin-left: 75%;
}
.arco-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.arco-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.arco-col-xs-offset-21 {
  margin-left: 87.5%;
}
.arco-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.arco-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.arco-col-xs-order-1 {
  order: 1;
}
.arco-col-xs-order-2 {
  order: 2;
}
.arco-col-xs-order-3 {
  order: 3;
}
.arco-col-xs-order-4 {
  order: 4;
}
.arco-col-xs-order-5 {
  order: 5;
}
.arco-col-xs-order-6 {
  order: 6;
}
.arco-col-xs-order-7 {
  order: 7;
}
.arco-col-xs-order-8 {
  order: 8;
}
.arco-col-xs-order-9 {
  order: 9;
}
.arco-col-xs-order-10 {
  order: 10;
}
.arco-col-xs-order-11 {
  order: 11;
}
.arco-col-xs-order-12 {
  order: 12;
}
.arco-col-xs-order-13 {
  order: 13;
}
.arco-col-xs-order-14 {
  order: 14;
}
.arco-col-xs-order-15 {
  order: 15;
}
.arco-col-xs-order-16 {
  order: 16;
}
.arco-col-xs-order-17 {
  order: 17;
}
.arco-col-xs-order-18 {
  order: 18;
}
.arco-col-xs-order-19 {
  order: 19;
}
.arco-col-xs-order-20 {
  order: 20;
}
.arco-col-xs-order-21 {
  order: 21;
}
.arco-col-xs-order-22 {
  order: 22;
}
.arco-col-xs-order-23 {
  order: 23;
}
.arco-col-xs-order-24 {
  order: 24;
}
.arco-col-xs-pull-1 {
  right: 4.16666667%;
}
.arco-col-xs-pull-2 {
  right: 8.33333333%;
}
.arco-col-xs-pull-3 {
  right: 12.5%;
}
.arco-col-xs-pull-4 {
  right: 16.66666667%;
}
.arco-col-xs-pull-5 {
  right: 20.83333333%;
}
.arco-col-xs-pull-6 {
  right: 25%;
}
.arco-col-xs-pull-7 {
  right: 29.16666667%;
}
.arco-col-xs-pull-8 {
  right: 33.33333333%;
}
.arco-col-xs-pull-9 {
  right: 37.5%;
}
.arco-col-xs-pull-10 {
  right: 41.66666667%;
}
.arco-col-xs-pull-11 {
  right: 45.83333333%;
}
.arco-col-xs-pull-12 {
  right: 50%;
}
.arco-col-xs-pull-13 {
  right: 54.16666667%;
}
.arco-col-xs-pull-14 {
  right: 58.33333333%;
}
.arco-col-xs-pull-15 {
  right: 62.5%;
}
.arco-col-xs-pull-16 {
  right: 66.66666667%;
}
.arco-col-xs-pull-17 {
  right: 70.83333333%;
}
.arco-col-xs-pull-18 {
  right: 75%;
}
.arco-col-xs-pull-19 {
  right: 79.16666667%;
}
.arco-col-xs-pull-20 {
  right: 83.33333333%;
}
.arco-col-xs-pull-21 {
  right: 87.5%;
}
.arco-col-xs-pull-22 {
  right: 91.66666667%;
}
.arco-col-xs-pull-23 {
  right: 95.83333333%;
}
.arco-col-xs-pull-24 {
  right: 100%;
}
.arco-col-xs-push-1 {
  left: 4.16666667%;
}
.arco-col-xs-push-2 {
  left: 8.33333333%;
}
.arco-col-xs-push-3 {
  left: 12.5%;
}
.arco-col-xs-push-4 {
  left: 16.66666667%;
}
.arco-col-xs-push-5 {
  left: 20.83333333%;
}
.arco-col-xs-push-6 {
  left: 25%;
}
.arco-col-xs-push-7 {
  left: 29.16666667%;
}
.arco-col-xs-push-8 {
  left: 33.33333333%;
}
.arco-col-xs-push-9 {
  left: 37.5%;
}
.arco-col-xs-push-10 {
  left: 41.66666667%;
}
.arco-col-xs-push-11 {
  left: 45.83333333%;
}
.arco-col-xs-push-12 {
  left: 50%;
}
.arco-col-xs-push-13 {
  left: 54.16666667%;
}
.arco-col-xs-push-14 {
  left: 58.33333333%;
}
.arco-col-xs-push-15 {
  left: 62.5%;
}
.arco-col-xs-push-16 {
  left: 66.66666667%;
}
.arco-col-xs-push-17 {
  left: 70.83333333%;
}
.arco-col-xs-push-18 {
  left: 75%;
}
.arco-col-xs-push-19 {
  left: 79.16666667%;
}
.arco-col-xs-push-20 {
  left: 83.33333333%;
}
.arco-col-xs-push-21 {
  left: 87.5%;
}
.arco-col-xs-push-22 {
  left: 91.66666667%;
}
.arco-col-xs-push-23 {
  left: 95.83333333%;
}
.arco-col-xs-push-24 {
  left: 100%;
}
@media (min-width: 576px) {
  .arco-col-sm-1 {
    width: 4.16666667%;
    flex: 0 0 4.16666667%;
  }
  .arco-col-sm-2 {
    width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }
  .arco-col-sm-3 {
    width: 12.5%;
    flex: 0 0 12.5%;
  }
  .arco-col-sm-4 {
    width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }
  .arco-col-sm-5 {
    width: 20.83333333%;
    flex: 0 0 20.83333333%;
  }
  .arco-col-sm-6 {
    width: 25%;
    flex: 0 0 25%;
  }
  .arco-col-sm-7 {
    width: 29.16666667%;
    flex: 0 0 29.16666667%;
  }
  .arco-col-sm-8 {
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }
  .arco-col-sm-9 {
    width: 37.5%;
    flex: 0 0 37.5%;
  }
  .arco-col-sm-10 {
    width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }
  .arco-col-sm-11 {
    width: 45.83333333%;
    flex: 0 0 45.83333333%;
  }
  .arco-col-sm-12 {
    width: 50%;
    flex: 0 0 50%;
  }
  .arco-col-sm-13 {
    width: 54.16666667%;
    flex: 0 0 54.16666667%;
  }
  .arco-col-sm-14 {
    width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }
  .arco-col-sm-15 {
    width: 62.5%;
    flex: 0 0 62.5%;
  }
  .arco-col-sm-16 {
    width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }
  .arco-col-sm-17 {
    width: 70.83333333%;
    flex: 0 0 70.83333333%;
  }
  .arco-col-sm-18 {
    width: 75%;
    flex: 0 0 75%;
  }
  .arco-col-sm-19 {
    width: 79.16666667%;
    flex: 0 0 79.16666667%;
  }
  .arco-col-sm-20 {
    width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }
  .arco-col-sm-21 {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
  .arco-col-sm-22 {
    width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }
  .arco-col-sm-23 {
    width: 95.83333333%;
    flex: 0 0 95.83333333%;
  }
  .arco-col-sm-24 {
    width: 100%;
    flex: 0 0 100%;
  }
  .arco-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .arco-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .arco-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .arco-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .arco-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .arco-col-sm-offset-6 {
    margin-left: 25%;
  }
  .arco-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .arco-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .arco-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .arco-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .arco-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .arco-col-sm-offset-12 {
    margin-left: 50%;
  }
  .arco-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .arco-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .arco-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .arco-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .arco-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .arco-col-sm-offset-18 {
    margin-left: 75%;
  }
  .arco-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .arco-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .arco-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .arco-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .arco-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .arco-col-sm-order-1 {
    order: 1;
  }
  .arco-col-sm-order-2 {
    order: 2;
  }
  .arco-col-sm-order-3 {
    order: 3;
  }
  .arco-col-sm-order-4 {
    order: 4;
  }
  .arco-col-sm-order-5 {
    order: 5;
  }
  .arco-col-sm-order-6 {
    order: 6;
  }
  .arco-col-sm-order-7 {
    order: 7;
  }
  .arco-col-sm-order-8 {
    order: 8;
  }
  .arco-col-sm-order-9 {
    order: 9;
  }
  .arco-col-sm-order-10 {
    order: 10;
  }
  .arco-col-sm-order-11 {
    order: 11;
  }
  .arco-col-sm-order-12 {
    order: 12;
  }
  .arco-col-sm-order-13 {
    order: 13;
  }
  .arco-col-sm-order-14 {
    order: 14;
  }
  .arco-col-sm-order-15 {
    order: 15;
  }
  .arco-col-sm-order-16 {
    order: 16;
  }
  .arco-col-sm-order-17 {
    order: 17;
  }
  .arco-col-sm-order-18 {
    order: 18;
  }
  .arco-col-sm-order-19 {
    order: 19;
  }
  .arco-col-sm-order-20 {
    order: 20;
  }
  .arco-col-sm-order-21 {
    order: 21;
  }
  .arco-col-sm-order-22 {
    order: 22;
  }
  .arco-col-sm-order-23 {
    order: 23;
  }
  .arco-col-sm-order-24 {
    order: 24;
  }
  .arco-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .arco-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .arco-col-sm-pull-3 {
    right: 12.5%;
  }
  .arco-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .arco-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .arco-col-sm-pull-6 {
    right: 25%;
  }
  .arco-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .arco-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .arco-col-sm-pull-9 {
    right: 37.5%;
  }
  .arco-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .arco-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .arco-col-sm-pull-12 {
    right: 50%;
  }
  .arco-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .arco-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .arco-col-sm-pull-15 {
    right: 62.5%;
  }
  .arco-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .arco-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .arco-col-sm-pull-18 {
    right: 75%;
  }
  .arco-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .arco-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .arco-col-sm-pull-21 {
    right: 87.5%;
  }
  .arco-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .arco-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .arco-col-sm-pull-24 {
    right: 100%;
  }
  .arco-col-sm-push-1 {
    left: 4.16666667%;
  }
  .arco-col-sm-push-2 {
    left: 8.33333333%;
  }
  .arco-col-sm-push-3 {
    left: 12.5%;
  }
  .arco-col-sm-push-4 {
    left: 16.66666667%;
  }
  .arco-col-sm-push-5 {
    left: 20.83333333%;
  }
  .arco-col-sm-push-6 {
    left: 25%;
  }
  .arco-col-sm-push-7 {
    left: 29.16666667%;
  }
  .arco-col-sm-push-8 {
    left: 33.33333333%;
  }
  .arco-col-sm-push-9 {
    left: 37.5%;
  }
  .arco-col-sm-push-10 {
    left: 41.66666667%;
  }
  .arco-col-sm-push-11 {
    left: 45.83333333%;
  }
  .arco-col-sm-push-12 {
    left: 50%;
  }
  .arco-col-sm-push-13 {
    left: 54.16666667%;
  }
  .arco-col-sm-push-14 {
    left: 58.33333333%;
  }
  .arco-col-sm-push-15 {
    left: 62.5%;
  }
  .arco-col-sm-push-16 {
    left: 66.66666667%;
  }
  .arco-col-sm-push-17 {
    left: 70.83333333%;
  }
  .arco-col-sm-push-18 {
    left: 75%;
  }
  .arco-col-sm-push-19 {
    left: 79.16666667%;
  }
  .arco-col-sm-push-20 {
    left: 83.33333333%;
  }
  .arco-col-sm-push-21 {
    left: 87.5%;
  }
  .arco-col-sm-push-22 {
    left: 91.66666667%;
  }
  .arco-col-sm-push-23 {
    left: 95.83333333%;
  }
  .arco-col-sm-push-24 {
    left: 100%;
  }
}
@media (min-width: 768px) {
  .arco-col-md-1 {
    width: 4.16666667%;
    flex: 0 0 4.16666667%;
  }
  .arco-col-md-2 {
    width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }
  .arco-col-md-3 {
    width: 12.5%;
    flex: 0 0 12.5%;
  }
  .arco-col-md-4 {
    width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }
  .arco-col-md-5 {
    width: 20.83333333%;
    flex: 0 0 20.83333333%;
  }
  .arco-col-md-6 {
    width: 25%;
    flex: 0 0 25%;
  }
  .arco-col-md-7 {
    width: 29.16666667%;
    flex: 0 0 29.16666667%;
  }
  .arco-col-md-8 {
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }
  .arco-col-md-9 {
    width: 37.5%;
    flex: 0 0 37.5%;
  }
  .arco-col-md-10 {
    width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }
  .arco-col-md-11 {
    width: 45.83333333%;
    flex: 0 0 45.83333333%;
  }
  .arco-col-md-12 {
    width: 50%;
    flex: 0 0 50%;
  }
  .arco-col-md-13 {
    width: 54.16666667%;
    flex: 0 0 54.16666667%;
  }
  .arco-col-md-14 {
    width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }
  .arco-col-md-15 {
    width: 62.5%;
    flex: 0 0 62.5%;
  }
  .arco-col-md-16 {
    width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }
  .arco-col-md-17 {
    width: 70.83333333%;
    flex: 0 0 70.83333333%;
  }
  .arco-col-md-18 {
    width: 75%;
    flex: 0 0 75%;
  }
  .arco-col-md-19 {
    width: 79.16666667%;
    flex: 0 0 79.16666667%;
  }
  .arco-col-md-20 {
    width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }
  .arco-col-md-21 {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
  .arco-col-md-22 {
    width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }
  .arco-col-md-23 {
    width: 95.83333333%;
    flex: 0 0 95.83333333%;
  }
  .arco-col-md-24 {
    width: 100%;
    flex: 0 0 100%;
  }
  .arco-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .arco-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .arco-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .arco-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .arco-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .arco-col-md-offset-6 {
    margin-left: 25%;
  }
  .arco-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .arco-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .arco-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .arco-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .arco-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .arco-col-md-offset-12 {
    margin-left: 50%;
  }
  .arco-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .arco-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .arco-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .arco-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .arco-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .arco-col-md-offset-18 {
    margin-left: 75%;
  }
  .arco-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .arco-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .arco-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .arco-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .arco-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .arco-col-md-order-1 {
    order: 1;
  }
  .arco-col-md-order-2 {
    order: 2;
  }
  .arco-col-md-order-3 {
    order: 3;
  }
  .arco-col-md-order-4 {
    order: 4;
  }
  .arco-col-md-order-5 {
    order: 5;
  }
  .arco-col-md-order-6 {
    order: 6;
  }
  .arco-col-md-order-7 {
    order: 7;
  }
  .arco-col-md-order-8 {
    order: 8;
  }
  .arco-col-md-order-9 {
    order: 9;
  }
  .arco-col-md-order-10 {
    order: 10;
  }
  .arco-col-md-order-11 {
    order: 11;
  }
  .arco-col-md-order-12 {
    order: 12;
  }
  .arco-col-md-order-13 {
    order: 13;
  }
  .arco-col-md-order-14 {
    order: 14;
  }
  .arco-col-md-order-15 {
    order: 15;
  }
  .arco-col-md-order-16 {
    order: 16;
  }
  .arco-col-md-order-17 {
    order: 17;
  }
  .arco-col-md-order-18 {
    order: 18;
  }
  .arco-col-md-order-19 {
    order: 19;
  }
  .arco-col-md-order-20 {
    order: 20;
  }
  .arco-col-md-order-21 {
    order: 21;
  }
  .arco-col-md-order-22 {
    order: 22;
  }
  .arco-col-md-order-23 {
    order: 23;
  }
  .arco-col-md-order-24 {
    order: 24;
  }
  .arco-col-md-pull-1 {
    right: 4.16666667%;
  }
  .arco-col-md-pull-2 {
    right: 8.33333333%;
  }
  .arco-col-md-pull-3 {
    right: 12.5%;
  }
  .arco-col-md-pull-4 {
    right: 16.66666667%;
  }
  .arco-col-md-pull-5 {
    right: 20.83333333%;
  }
  .arco-col-md-pull-6 {
    right: 25%;
  }
  .arco-col-md-pull-7 {
    right: 29.16666667%;
  }
  .arco-col-md-pull-8 {
    right: 33.33333333%;
  }
  .arco-col-md-pull-9 {
    right: 37.5%;
  }
  .arco-col-md-pull-10 {
    right: 41.66666667%;
  }
  .arco-col-md-pull-11 {
    right: 45.83333333%;
  }
  .arco-col-md-pull-12 {
    right: 50%;
  }
  .arco-col-md-pull-13 {
    right: 54.16666667%;
  }
  .arco-col-md-pull-14 {
    right: 58.33333333%;
  }
  .arco-col-md-pull-15 {
    right: 62.5%;
  }
  .arco-col-md-pull-16 {
    right: 66.66666667%;
  }
  .arco-col-md-pull-17 {
    right: 70.83333333%;
  }
  .arco-col-md-pull-18 {
    right: 75%;
  }
  .arco-col-md-pull-19 {
    right: 79.16666667%;
  }
  .arco-col-md-pull-20 {
    right: 83.33333333%;
  }
  .arco-col-md-pull-21 {
    right: 87.5%;
  }
  .arco-col-md-pull-22 {
    right: 91.66666667%;
  }
  .arco-col-md-pull-23 {
    right: 95.83333333%;
  }
  .arco-col-md-pull-24 {
    right: 100%;
  }
  .arco-col-md-push-1 {
    left: 4.16666667%;
  }
  .arco-col-md-push-2 {
    left: 8.33333333%;
  }
  .arco-col-md-push-3 {
    left: 12.5%;
  }
  .arco-col-md-push-4 {
    left: 16.66666667%;
  }
  .arco-col-md-push-5 {
    left: 20.83333333%;
  }
  .arco-col-md-push-6 {
    left: 25%;
  }
  .arco-col-md-push-7 {
    left: 29.16666667%;
  }
  .arco-col-md-push-8 {
    left: 33.33333333%;
  }
  .arco-col-md-push-9 {
    left: 37.5%;
  }
  .arco-col-md-push-10 {
    left: 41.66666667%;
  }
  .arco-col-md-push-11 {
    left: 45.83333333%;
  }
  .arco-col-md-push-12 {
    left: 50%;
  }
  .arco-col-md-push-13 {
    left: 54.16666667%;
  }
  .arco-col-md-push-14 {
    left: 58.33333333%;
  }
  .arco-col-md-push-15 {
    left: 62.5%;
  }
  .arco-col-md-push-16 {
    left: 66.66666667%;
  }
  .arco-col-md-push-17 {
    left: 70.83333333%;
  }
  .arco-col-md-push-18 {
    left: 75%;
  }
  .arco-col-md-push-19 {
    left: 79.16666667%;
  }
  .arco-col-md-push-20 {
    left: 83.33333333%;
  }
  .arco-col-md-push-21 {
    left: 87.5%;
  }
  .arco-col-md-push-22 {
    left: 91.66666667%;
  }
  .arco-col-md-push-23 {
    left: 95.83333333%;
  }
  .arco-col-md-push-24 {
    left: 100%;
  }
}
@media (min-width: 992px) {
  .arco-col-lg-1 {
    width: 4.16666667%;
    flex: 0 0 4.16666667%;
  }
  .arco-col-lg-2 {
    width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }
  .arco-col-lg-3 {
    width: 12.5%;
    flex: 0 0 12.5%;
  }
  .arco-col-lg-4 {
    width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }
  .arco-col-lg-5 {
    width: 20.83333333%;
    flex: 0 0 20.83333333%;
  }
  .arco-col-lg-6 {
    width: 25%;
    flex: 0 0 25%;
  }
  .arco-col-lg-7 {
    width: 29.16666667%;
    flex: 0 0 29.16666667%;
  }
  .arco-col-lg-8 {
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }
  .arco-col-lg-9 {
    width: 37.5%;
    flex: 0 0 37.5%;
  }
  .arco-col-lg-10 {
    width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }
  .arco-col-lg-11 {
    width: 45.83333333%;
    flex: 0 0 45.83333333%;
  }
  .arco-col-lg-12 {
    width: 50%;
    flex: 0 0 50%;
  }
  .arco-col-lg-13 {
    width: 54.16666667%;
    flex: 0 0 54.16666667%;
  }
  .arco-col-lg-14 {
    width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }
  .arco-col-lg-15 {
    width: 62.5%;
    flex: 0 0 62.5%;
  }
  .arco-col-lg-16 {
    width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }
  .arco-col-lg-17 {
    width: 70.83333333%;
    flex: 0 0 70.83333333%;
  }
  .arco-col-lg-18 {
    width: 75%;
    flex: 0 0 75%;
  }
  .arco-col-lg-19 {
    width: 79.16666667%;
    flex: 0 0 79.16666667%;
  }
  .arco-col-lg-20 {
    width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }
  .arco-col-lg-21 {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
  .arco-col-lg-22 {
    width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }
  .arco-col-lg-23 {
    width: 95.83333333%;
    flex: 0 0 95.83333333%;
  }
  .arco-col-lg-24 {
    width: 100%;
    flex: 0 0 100%;
  }
  .arco-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .arco-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .arco-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .arco-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .arco-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .arco-col-lg-offset-6 {
    margin-left: 25%;
  }
  .arco-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .arco-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .arco-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .arco-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .arco-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .arco-col-lg-offset-12 {
    margin-left: 50%;
  }
  .arco-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .arco-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .arco-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .arco-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .arco-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .arco-col-lg-offset-18 {
    margin-left: 75%;
  }
  .arco-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .arco-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .arco-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .arco-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .arco-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .arco-col-lg-order-1 {
    order: 1;
  }
  .arco-col-lg-order-2 {
    order: 2;
  }
  .arco-col-lg-order-3 {
    order: 3;
  }
  .arco-col-lg-order-4 {
    order: 4;
  }
  .arco-col-lg-order-5 {
    order: 5;
  }
  .arco-col-lg-order-6 {
    order: 6;
  }
  .arco-col-lg-order-7 {
    order: 7;
  }
  .arco-col-lg-order-8 {
    order: 8;
  }
  .arco-col-lg-order-9 {
    order: 9;
  }
  .arco-col-lg-order-10 {
    order: 10;
  }
  .arco-col-lg-order-11 {
    order: 11;
  }
  .arco-col-lg-order-12 {
    order: 12;
  }
  .arco-col-lg-order-13 {
    order: 13;
  }
  .arco-col-lg-order-14 {
    order: 14;
  }
  .arco-col-lg-order-15 {
    order: 15;
  }
  .arco-col-lg-order-16 {
    order: 16;
  }
  .arco-col-lg-order-17 {
    order: 17;
  }
  .arco-col-lg-order-18 {
    order: 18;
  }
  .arco-col-lg-order-19 {
    order: 19;
  }
  .arco-col-lg-order-20 {
    order: 20;
  }
  .arco-col-lg-order-21 {
    order: 21;
  }
  .arco-col-lg-order-22 {
    order: 22;
  }
  .arco-col-lg-order-23 {
    order: 23;
  }
  .arco-col-lg-order-24 {
    order: 24;
  }
  .arco-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .arco-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .arco-col-lg-pull-3 {
    right: 12.5%;
  }
  .arco-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .arco-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .arco-col-lg-pull-6 {
    right: 25%;
  }
  .arco-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .arco-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .arco-col-lg-pull-9 {
    right: 37.5%;
  }
  .arco-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .arco-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .arco-col-lg-pull-12 {
    right: 50%;
  }
  .arco-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .arco-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .arco-col-lg-pull-15 {
    right: 62.5%;
  }
  .arco-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .arco-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .arco-col-lg-pull-18 {
    right: 75%;
  }
  .arco-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .arco-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .arco-col-lg-pull-21 {
    right: 87.5%;
  }
  .arco-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .arco-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .arco-col-lg-pull-24 {
    right: 100%;
  }
  .arco-col-lg-push-1 {
    left: 4.16666667%;
  }
  .arco-col-lg-push-2 {
    left: 8.33333333%;
  }
  .arco-col-lg-push-3 {
    left: 12.5%;
  }
  .arco-col-lg-push-4 {
    left: 16.66666667%;
  }
  .arco-col-lg-push-5 {
    left: 20.83333333%;
  }
  .arco-col-lg-push-6 {
    left: 25%;
  }
  .arco-col-lg-push-7 {
    left: 29.16666667%;
  }
  .arco-col-lg-push-8 {
    left: 33.33333333%;
  }
  .arco-col-lg-push-9 {
    left: 37.5%;
  }
  .arco-col-lg-push-10 {
    left: 41.66666667%;
  }
  .arco-col-lg-push-11 {
    left: 45.83333333%;
  }
  .arco-col-lg-push-12 {
    left: 50%;
  }
  .arco-col-lg-push-13 {
    left: 54.16666667%;
  }
  .arco-col-lg-push-14 {
    left: 58.33333333%;
  }
  .arco-col-lg-push-15 {
    left: 62.5%;
  }
  .arco-col-lg-push-16 {
    left: 66.66666667%;
  }
  .arco-col-lg-push-17 {
    left: 70.83333333%;
  }
  .arco-col-lg-push-18 {
    left: 75%;
  }
  .arco-col-lg-push-19 {
    left: 79.16666667%;
  }
  .arco-col-lg-push-20 {
    left: 83.33333333%;
  }
  .arco-col-lg-push-21 {
    left: 87.5%;
  }
  .arco-col-lg-push-22 {
    left: 91.66666667%;
  }
  .arco-col-lg-push-23 {
    left: 95.83333333%;
  }
  .arco-col-lg-push-24 {
    left: 100%;
  }
}
@media (min-width: 1200px) {
  .arco-col-xl-1 {
    width: 4.16666667%;
    flex: 0 0 4.16666667%;
  }
  .arco-col-xl-2 {
    width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }
  .arco-col-xl-3 {
    width: 12.5%;
    flex: 0 0 12.5%;
  }
  .arco-col-xl-4 {
    width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }
  .arco-col-xl-5 {
    width: 20.83333333%;
    flex: 0 0 20.83333333%;
  }
  .arco-col-xl-6 {
    width: 25%;
    flex: 0 0 25%;
  }
  .arco-col-xl-7 {
    width: 29.16666667%;
    flex: 0 0 29.16666667%;
  }
  .arco-col-xl-8 {
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }
  .arco-col-xl-9 {
    width: 37.5%;
    flex: 0 0 37.5%;
  }
  .arco-col-xl-10 {
    width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }
  .arco-col-xl-11 {
    width: 45.83333333%;
    flex: 0 0 45.83333333%;
  }
  .arco-col-xl-12 {
    width: 50%;
    flex: 0 0 50%;
  }
  .arco-col-xl-13 {
    width: 54.16666667%;
    flex: 0 0 54.16666667%;
  }
  .arco-col-xl-14 {
    width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }
  .arco-col-xl-15 {
    width: 62.5%;
    flex: 0 0 62.5%;
  }
  .arco-col-xl-16 {
    width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }
  .arco-col-xl-17 {
    width: 70.83333333%;
    flex: 0 0 70.83333333%;
  }
  .arco-col-xl-18 {
    width: 75%;
    flex: 0 0 75%;
  }
  .arco-col-xl-19 {
    width: 79.16666667%;
    flex: 0 0 79.16666667%;
  }
  .arco-col-xl-20 {
    width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }
  .arco-col-xl-21 {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
  .arco-col-xl-22 {
    width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }
  .arco-col-xl-23 {
    width: 95.83333333%;
    flex: 0 0 95.83333333%;
  }
  .arco-col-xl-24 {
    width: 100%;
    flex: 0 0 100%;
  }
  .arco-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .arco-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .arco-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .arco-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .arco-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .arco-col-xl-offset-6 {
    margin-left: 25%;
  }
  .arco-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .arco-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .arco-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .arco-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .arco-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .arco-col-xl-offset-12 {
    margin-left: 50%;
  }
  .arco-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .arco-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .arco-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .arco-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .arco-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .arco-col-xl-offset-18 {
    margin-left: 75%;
  }
  .arco-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .arco-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .arco-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .arco-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .arco-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .arco-col-xl-order-1 {
    order: 1;
  }
  .arco-col-xl-order-2 {
    order: 2;
  }
  .arco-col-xl-order-3 {
    order: 3;
  }
  .arco-col-xl-order-4 {
    order: 4;
  }
  .arco-col-xl-order-5 {
    order: 5;
  }
  .arco-col-xl-order-6 {
    order: 6;
  }
  .arco-col-xl-order-7 {
    order: 7;
  }
  .arco-col-xl-order-8 {
    order: 8;
  }
  .arco-col-xl-order-9 {
    order: 9;
  }
  .arco-col-xl-order-10 {
    order: 10;
  }
  .arco-col-xl-order-11 {
    order: 11;
  }
  .arco-col-xl-order-12 {
    order: 12;
  }
  .arco-col-xl-order-13 {
    order: 13;
  }
  .arco-col-xl-order-14 {
    order: 14;
  }
  .arco-col-xl-order-15 {
    order: 15;
  }
  .arco-col-xl-order-16 {
    order: 16;
  }
  .arco-col-xl-order-17 {
    order: 17;
  }
  .arco-col-xl-order-18 {
    order: 18;
  }
  .arco-col-xl-order-19 {
    order: 19;
  }
  .arco-col-xl-order-20 {
    order: 20;
  }
  .arco-col-xl-order-21 {
    order: 21;
  }
  .arco-col-xl-order-22 {
    order: 22;
  }
  .arco-col-xl-order-23 {
    order: 23;
  }
  .arco-col-xl-order-24 {
    order: 24;
  }
  .arco-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .arco-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .arco-col-xl-pull-3 {
    right: 12.5%;
  }
  .arco-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .arco-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .arco-col-xl-pull-6 {
    right: 25%;
  }
  .arco-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .arco-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .arco-col-xl-pull-9 {
    right: 37.5%;
  }
  .arco-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .arco-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .arco-col-xl-pull-12 {
    right: 50%;
  }
  .arco-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .arco-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .arco-col-xl-pull-15 {
    right: 62.5%;
  }
  .arco-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .arco-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .arco-col-xl-pull-18 {
    right: 75%;
  }
  .arco-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .arco-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .arco-col-xl-pull-21 {
    right: 87.5%;
  }
  .arco-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .arco-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .arco-col-xl-pull-24 {
    right: 100%;
  }
  .arco-col-xl-push-1 {
    left: 4.16666667%;
  }
  .arco-col-xl-push-2 {
    left: 8.33333333%;
  }
  .arco-col-xl-push-3 {
    left: 12.5%;
  }
  .arco-col-xl-push-4 {
    left: 16.66666667%;
  }
  .arco-col-xl-push-5 {
    left: 20.83333333%;
  }
  .arco-col-xl-push-6 {
    left: 25%;
  }
  .arco-col-xl-push-7 {
    left: 29.16666667%;
  }
  .arco-col-xl-push-8 {
    left: 33.33333333%;
  }
  .arco-col-xl-push-9 {
    left: 37.5%;
  }
  .arco-col-xl-push-10 {
    left: 41.66666667%;
  }
  .arco-col-xl-push-11 {
    left: 45.83333333%;
  }
  .arco-col-xl-push-12 {
    left: 50%;
  }
  .arco-col-xl-push-13 {
    left: 54.16666667%;
  }
  .arco-col-xl-push-14 {
    left: 58.33333333%;
  }
  .arco-col-xl-push-15 {
    left: 62.5%;
  }
  .arco-col-xl-push-16 {
    left: 66.66666667%;
  }
  .arco-col-xl-push-17 {
    left: 70.83333333%;
  }
  .arco-col-xl-push-18 {
    left: 75%;
  }
  .arco-col-xl-push-19 {
    left: 79.16666667%;
  }
  .arco-col-xl-push-20 {
    left: 83.33333333%;
  }
  .arco-col-xl-push-21 {
    left: 87.5%;
  }
  .arco-col-xl-push-22 {
    left: 91.66666667%;
  }
  .arco-col-xl-push-23 {
    left: 95.83333333%;
  }
  .arco-col-xl-push-24 {
    left: 100%;
  }
}
@media (min-width: 1600px) {
  .arco-col-xxl-1 {
    width: 4.16666667%;
    flex: 0 0 4.16666667%;
  }
  .arco-col-xxl-2 {
    width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }
  .arco-col-xxl-3 {
    width: 12.5%;
    flex: 0 0 12.5%;
  }
  .arco-col-xxl-4 {
    width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }
  .arco-col-xxl-5 {
    width: 20.83333333%;
    flex: 0 0 20.83333333%;
  }
  .arco-col-xxl-6 {
    width: 25%;
    flex: 0 0 25%;
  }
  .arco-col-xxl-7 {
    width: 29.16666667%;
    flex: 0 0 29.16666667%;
  }
  .arco-col-xxl-8 {
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }
  .arco-col-xxl-9 {
    width: 37.5%;
    flex: 0 0 37.5%;
  }
  .arco-col-xxl-10 {
    width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }
  .arco-col-xxl-11 {
    width: 45.83333333%;
    flex: 0 0 45.83333333%;
  }
  .arco-col-xxl-12 {
    width: 50%;
    flex: 0 0 50%;
  }
  .arco-col-xxl-13 {
    width: 54.16666667%;
    flex: 0 0 54.16666667%;
  }
  .arco-col-xxl-14 {
    width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }
  .arco-col-xxl-15 {
    width: 62.5%;
    flex: 0 0 62.5%;
  }
  .arco-col-xxl-16 {
    width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }
  .arco-col-xxl-17 {
    width: 70.83333333%;
    flex: 0 0 70.83333333%;
  }
  .arco-col-xxl-18 {
    width: 75%;
    flex: 0 0 75%;
  }
  .arco-col-xxl-19 {
    width: 79.16666667%;
    flex: 0 0 79.16666667%;
  }
  .arco-col-xxl-20 {
    width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }
  .arco-col-xxl-21 {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
  .arco-col-xxl-22 {
    width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }
  .arco-col-xxl-23 {
    width: 95.83333333%;
    flex: 0 0 95.83333333%;
  }
  .arco-col-xxl-24 {
    width: 100%;
    flex: 0 0 100%;
  }
  .arco-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .arco-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .arco-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .arco-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .arco-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .arco-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .arco-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .arco-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .arco-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .arco-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .arco-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .arco-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .arco-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .arco-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .arco-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .arco-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .arco-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .arco-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .arco-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .arco-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .arco-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .arco-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .arco-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .arco-col-xxl-order-1 {
    order: 1;
  }
  .arco-col-xxl-order-2 {
    order: 2;
  }
  .arco-col-xxl-order-3 {
    order: 3;
  }
  .arco-col-xxl-order-4 {
    order: 4;
  }
  .arco-col-xxl-order-5 {
    order: 5;
  }
  .arco-col-xxl-order-6 {
    order: 6;
  }
  .arco-col-xxl-order-7 {
    order: 7;
  }
  .arco-col-xxl-order-8 {
    order: 8;
  }
  .arco-col-xxl-order-9 {
    order: 9;
  }
  .arco-col-xxl-order-10 {
    order: 10;
  }
  .arco-col-xxl-order-11 {
    order: 11;
  }
  .arco-col-xxl-order-12 {
    order: 12;
  }
  .arco-col-xxl-order-13 {
    order: 13;
  }
  .arco-col-xxl-order-14 {
    order: 14;
  }
  .arco-col-xxl-order-15 {
    order: 15;
  }
  .arco-col-xxl-order-16 {
    order: 16;
  }
  .arco-col-xxl-order-17 {
    order: 17;
  }
  .arco-col-xxl-order-18 {
    order: 18;
  }
  .arco-col-xxl-order-19 {
    order: 19;
  }
  .arco-col-xxl-order-20 {
    order: 20;
  }
  .arco-col-xxl-order-21 {
    order: 21;
  }
  .arco-col-xxl-order-22 {
    order: 22;
  }
  .arco-col-xxl-order-23 {
    order: 23;
  }
  .arco-col-xxl-order-24 {
    order: 24;
  }
  .arco-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .arco-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .arco-col-xxl-pull-3 {
    right: 12.5%;
  }
  .arco-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .arco-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .arco-col-xxl-pull-6 {
    right: 25%;
  }
  .arco-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .arco-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .arco-col-xxl-pull-9 {
    right: 37.5%;
  }
  .arco-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .arco-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .arco-col-xxl-pull-12 {
    right: 50%;
  }
  .arco-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .arco-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .arco-col-xxl-pull-15 {
    right: 62.5%;
  }
  .arco-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .arco-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .arco-col-xxl-pull-18 {
    right: 75%;
  }
  .arco-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .arco-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .arco-col-xxl-pull-21 {
    right: 87.5%;
  }
  .arco-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .arco-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .arco-col-xxl-pull-24 {
    right: 100%;
  }
  .arco-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .arco-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .arco-col-xxl-push-3 {
    left: 12.5%;
  }
  .arco-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .arco-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .arco-col-xxl-push-6 {
    left: 25%;
  }
  .arco-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .arco-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .arco-col-xxl-push-9 {
    left: 37.5%;
  }
  .arco-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .arco-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .arco-col-xxl-push-12 {
    left: 50%;
  }
  .arco-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .arco-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .arco-col-xxl-push-15 {
    left: 62.5%;
  }
  .arco-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .arco-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .arco-col-xxl-push-18 {
    left: 75%;
  }
  .arco-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .arco-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .arco-col-xxl-push-21 {
    left: 87.5%;
  }
  .arco-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .arco-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .arco-col-xxl-push-24 {
    left: 100%;
  }
}
.arco-image-trigger {
  padding: 6px 4px;
  background: var(--color-bg-5);
  border: 1px solid var(--color-neutral-3);
  border-radius: 4px;
}
.arco-image-trigger .arco-trigger-arrow {
  border: 1px solid var(--color-neutral-3);
  background-color: var(--color-bg-5);
}
.arco-image {
  position: relative;
  display: inline-block;
  border-radius: var(--border-radius-small);
  vertical-align: middle;
}
.arco-image-img {
  vertical-align: middle;
  border-radius: inherit;
}
.arco-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.arco-image-footer {
  width: 100%;
  max-width: 100%;
  display: flex;
}
.arco-image-footer-block {
  flex: auto;
}
.arco-image-caption-title {
  font-size: 16px;
  font-weight: 500;
}
.arco-image-caption-description {
  font-size: 14px;
}
.arco-image-actions {
  padding-left: 12px;
}
.arco-image-actions-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.arco-image-actions-item {
  font-size: 14px;
  line-height: 1;
  margin-left: 12px;
  border-radius: var(--border-radius-small);
  padding: 0 0;
  cursor: pointer;
}
.arco-image-actions-item:first-child {
  margin-left: 0;
}
.arco-image-actions-item-trigger {
  padding: 5px 4px;
  display: inline-block;
}
.arco-image-with-footer-inner .arco-image-footer {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  color: var(--color-white);
  box-sizing: border-box;
  padding: 9px 16px;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
}
.arco-image-with-footer-inner .arco-image-caption-title {
  color: var(--color-white);
}
.arco-image-with-footer-inner .arco-image-caption-description {
  color: var(--color-white);
}
.arco-image-with-footer-inner .arco-image-actions-item:hover {
  background: rgba(0, 0, 0, 0.5);
}
.arco-image-with-footer-outer .arco-image-footer {
  color: var(--color-neutral-8);
  margin-top: 4px;
}
.arco-image-with-footer-outer .arco-image-caption-title {
  color: var(--color-text-1);
}
.arco-image-with-footer-outer .arco-image-caption-description {
  color: var(--color-neutral-6);
}
.arco-image-with-footer-outer .arco-image-actions-item:hover {
  background: var(--color-neutral-2);
}
.arco-image-error {
  width: 100%;
  height: 100%;
  background-color: var(--color-neutral-1);
  color: var(--color-neutral-4);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.arco-image-error-icon {
  width: 60px;
  height: 60px;
  max-width: 100%;
  max-height: 100%;
}
.arco-image-error-icon > svg {
  width: 100%;
  height: 100%;
}
.arco-image-error-alt {
  font-size: 12px;
  line-height: 1.6667;
  text-align: center;
  padding: 8px 16px;
}
.arco-image-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-neutral-1);
}
.arco-image-loader-spin {
  font-size: 32px;
  transform: translate(-50%, -50%);
  position: absolute;
  color: rgb(var(--primary-6));
  left: 50%;
  top: 50%;
  text-align: center;
}
.arco-image-loader-spin-text {
  color: var(--color-neutral-6);
  font-size: 16px;
}
.arco-image-simple.arco-image-with-footer-inner .arco-image-footer {
  padding: 12px 16px;
}
.arco-image-loading .arco-image-img,
.arco-image-loading-error .arco-image-img {
  visibility: hidden;
}
.arco-image-trigger .arco-image-actions-list {
  flex-direction: column;
}
.arco-image-trigger .arco-image-actions-item {
  color: var(--color-neutral-8);
  margin-left: 0;
}
.arco-image-trigger .arco-image-actions-item:hover {
  background: var(--color-neutral-2);
}
.arco-image-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.arco-image-preview-hide {
  display: none;
}
.arco-image-preview-mask,
.arco-image-preview-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.arco-image-preview-mask {
  background-color: var(--color-mask-bg);
}
.arco-image-preview-img-container {
  width: 100%;
  height: 100%;
  text-align: center;
}
.arco-image-preview-img-container::before {
  content: '';
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}
.arco-image-preview-img-container .arco-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  cursor: grab;
}
.arco-image-preview-img-container .arco-image-preview-img.arco-image-preview-img-moving {
  cursor: grabbing;
}
.arco-image-preview-scale-value {
  padding: 7px 10px;
  box-sizing: border-box;
  font-size: 12px;
  color: var(--color-white);
  background-color: rgba(255, 255, 255, 0.08);
  line-height: initial;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.arco-image-preview-toolbar {
  background-color: var(--color-bg-2);
  border-radius: var(--border-radius-medium);
  display: flex;
  align-items: flex-start;
  padding: 4px 16px;
  position: absolute;
  bottom: 46px;
  left: 50%;
  transform: translateX(-50%);
}
.arco-image-preview-toolbar-action {
  font-size: 14px;
  color: var(--color-neutral-8);
  border-radius: var(--border-radius-small);
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.arco-image-preview-toolbar-action:not(:last-of-type) {
  margin-right: 0;
}
.arco-image-preview-toolbar-action:hover {
  background-color: var(--color-neutral-2);
  color: rgb(var(--primary-6));
}
.arco-image-preview-toolbar-action-disabled,
.arco-image-preview-toolbar-action-disabled:hover {
  color: var(--color-text-4);
  background-color: transparent;
  cursor: not-allowed;
}
.arco-image-preview-toolbar-action-name {
  font-size: 12px;
  padding-right: 12px;
}
.arco-image-preview-toolbar-action-content {
  padding: 13px;
  line-height: 1;
}
.arco-image-preview-toolbar-simple {
  padding: 4px 4px;
}
.arco-image-preview-toolbar-simple .arco-image-preview-toolbar-action {
  margin-right: 0;
}
.arco-image-preview-trigger.arco-image-trigger {
  padding: 12px 16px;
}
.arco-image-preview-trigger.arco-image-trigger .arco-image-preview-toolbar-action {
  text-align: left;
  margin-right: 0;
}
.arco-image-preview-trigger.arco-image-trigger
  .arco-image-preview-toolbar-action:not(:last-of-type) {
  margin-bottom: 0;
}
.arco-image-preview-loading {
  color: rgb(var(--primary-6));
  background-color: #232324;
  font-size: 18px;
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-medium);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.arco-image-preview-close-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: 36px;
  top: 36px;
  cursor: pointer;
  font-size: 14px;
}
.arco-image-preview-arrow-left,
.arco-image-preview-arrow-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var(--color-white);
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  z-index: 2;
}
.arco-image-preview-arrow-left > svg,
.arco-image-preview-arrow-right > svg {
  color: var(--color-white);
  font-size: 16px;
}
.arco-image-preview-arrow-left:hover,
.arco-image-preview-arrow-right:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.arco-image-preview-arrow-left {
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.arco-image-preview-arrow-right {
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.arco-image-preview-arrow-disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3);
}
.arco-image-preview-arrow-disabled > svg {
  color: rgba(255, 255, 255, 0.3);
}
.arco-image-preview-arrow-disabled:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.fadeImage-enter,
.fadeImage-appear {
  opacity: 0;
}
.fadeImage-enter-active,
.fadeImage-appear-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.fadeImage-exit {
  opacity: 1;
}
.fadeImage-exit-active {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-input {
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 1.5715;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  box-sizing: border-box;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1), border-color 0.1s cubic-bezier(0, 0, 1, 1),
    background-color 0.1s cubic-bezier(0, 0, 1, 1);
  border: 1px solid var(--color-neutral-3);
}
.arco-input::placeholder {
  color: var(--color-text-3);
}
.arco-input:hover {
  background-color: transparent;
  border-color: rgb(var(--primary-5));
}
.arco-input:focus,
.arco-input.arco-input-focus {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 1px rgb(var(--link-3));
}
.arco-input-error {
  border-color: transparent;
  background-color: var(--color-danger-light-1);
}
.arco-input-error:hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-input-error:focus,
.arco-input-error.arco-input-focus {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-input-disabled {
  background-color: var(--color-fill-2);
  cursor: not-allowed;
  color: var(--color-text-4);
  -webkit-text-fill-color: var(--color-text-4);
  border-color: transparent;
}
.arco-input-disabled:hover {
  border-color: transparent;
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
}
.arco-input-disabled::placeholder {
  color: var(--color-text-4);
}
.arco-input input:disabled {
  color: var(--color-text-4);
  opacity: 1;
  -webkit-text-fill-color: var(--color-text-4);
}
.arco-input-word-limit {
  font-size: 12px;
  color: var(--color-text-3);
  padding-left: 8px;
}
.arco-input-word-limit-error {
  color: rgb(var(--danger-6));
}
.arco-input-size-mini {
  line-height: 1.667;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.arco-input-size-small {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.arco-input-size-large {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
}
.arco-input-group-wrapper-mini .arco-input-group-addbefore,
.arco-input-group-wrapper-mini .arco-input-group-addafter {
  padding-left: 8px;
  padding-right: 8px;
}
.arco-input-inner-wrapper.arco-input-inner-wrapper-mini {
  padding-left: 8px;
  padding-right: 8px;
}
.arco-input-size-mini {
  padding-left: 8px;
  padding-right: 8px;
}
.arco-input-group-wrapper-small .arco-input-group-addbefore,
.arco-input-group-wrapper-small .arco-input-group-addafter {
  padding-left: 12px;
  padding-right: 12px;
}
.arco-input-inner-wrapper.arco-input-inner-wrapper-small {
  padding-left: 12px;
  padding-right: 12px;
}
.arco-input-size-small {
  padding-left: 12px;
  padding-right: 12px;
}
.arco-input-group-wrapper-large .arco-input-group-addbefore,
.arco-input-group-wrapper-large .arco-input-group-addafter {
  padding-left: 16px;
  padding-right: 16px;
}
.arco-input-inner-wrapper.arco-input-inner-wrapper-large {
  padding-left: 16px;
  padding-right: 16px;
}
.arco-input-size-large {
  padding-left: 16px;
  padding-right: 16px;
}
.arco-input-clear-wrapper .arco-input {
  padding-right: 24px;
}
.arco-input-clear-icon {
  display: none;
  font-size: 12px;
  cursor: pointer;
  color: var(--color-text-2);
}
.arco-input-clear-icon > svg {
  transition: color 0.1s cubic-bezier(0, 0, 1, 1);
  position: relative;
}
.arco-input-inner-wrapper {
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  box-sizing: border-box;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1), border-color 0.1s cubic-bezier(0, 0, 1, 1),
    background-color 0.1s cubic-bezier(0, 0, 1, 1);
  border: 1px solid var(--color-neutral-3);
  background-color: transparent;
  display: inline-flex;
  width: 100%;
  position: relative;
  align-items: center;
}
.arco-input-inner-wrapper::placeholder {
  color: var(--color-text-3);
}
.arco-input-inner-wrapper:hover {
  background-color: transparent;
  border-color: rgb(var(--primary-5));
}
.arco-input-inner-wrapper:focus,
.arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 1px rgb(var(--link-3));
}
.arco-input-inner-wrapper-error {
  border-color: transparent;
  background-color: var(--color-danger-light-1);
}
.arco-input-inner-wrapper-error:hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-input-inner-wrapper-error:focus,
.arco-input-inner-wrapper-error.arco-input-inner-wrapper-focus {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-input-inner-wrapper .arco-input {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: none;
  background: none;
}
.arco-input-inner-wrapper .arco-input:hover,
.arco-input-inner-wrapper .arco-input:focus {
  background: none;
  box-shadow: none;
}
.arco-input-inner-wrapper-has-prefix > .arco-input-clear-wrapper .arco-input,
.arco-input-inner-wrapper-has-prefix > .arco-input {
  padding-left: 12px;
}
.arco-input-inner-wrapper > .arco-input-clear-wrapper {
  height: 100%;
}
.arco-input-inner-wrapper > .arco-input-clear-wrapper .arco-input {
  padding-right: 12px;
}
.arco-input-inner-wrapper > .arco-input-clear-wrapper .arco-input-clear-icon {
  right: 8px;
}
.arco-input-inner-wrapper .arco-input-group-prefix,
.arco-input-inner-wrapper .arco-input-group-suffix {
  user-select: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  height: 100%;
}
.arco-input-inner-wrapper .arco-input-group-prefix > svg,
.arco-input-inner-wrapper .arco-input-group-suffix > svg {
  font-size: 14px;
}
.arco-input-inner-wrapper .arco-input-group-prefix {
  color: var(--color-text-2);
}
.arco-input-inner-wrapper .arco-input-group-suffix {
  color: var(--color-text-2);
}
.arco-input-inner-wrapper-disabled {
  background-color: var(--color-fill-2);
  cursor: not-allowed;
  color: var(--color-text-4);
  -webkit-text-fill-color: var(--color-text-4);
  border-color: transparent;
}
.arco-input-inner-wrapper-disabled:hover {
  border-color: transparent;
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
}
.arco-input-inner-wrapper-disabled::placeholder {
  color: var(--color-text-4);
}
.arco-input-inner-wrapper-disabled .arco-input-group-prefix,
.arco-input-inner-wrapper-disabled .arco-input-group-suffix {
  color: inherit;
}
.arco-input-inner-wrapper:hover .arco-input-clear-icon {
  display: inline-block;
}
.arco-input-inner-wrapper:hover .arco-input-clear-icon ~ .arco-input-group-suffix {
  margin-left: 4px;
}
.arco-input-inner-wrapper:not(.arco-input-inner-wrapper-focus)
  .arco-input-clear-icon:hover::before {
  background-color: var(--color-fill-4);
}
.arco-input-group {
  display: table;
  width: 100%;
  height: 100%;
}
.arco-input-group > .arco-input-inner-wrapper,
.arco-input-group > .arco-input {
  border-radius: 0;
}
.arco-input-group > .arco-input-inner-wrapper-focus,
.arco-input-group > .arco-input-focus {
  border-radius: var(--border-radius-small);
}
.arco-input-group > :first-child {
  border-top-left-radius: var(--border-radius-small);
  border-bottom-left-radius: var(--border-radius-small);
}
.arco-input-group > :last-child {
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
}
.arco-input-group-addbefore,
.arco-input-group-addafter {
  width: 1px;
  display: table-cell;
  white-space: nowrap;
  height: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 0 12px;
  color: var(--color-text-1);
  background-color: transparent;
  border: 1px solid transparent;
}
.arco-input-group-addbefore > svg,
.arco-input-group-addafter > svg {
  font-size: 14px;
}
.arco-input-group-addafter {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-addafter .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-addafter .arco-select.arco-select-single .arco-select-view {
  height: 100%;
}
.arco-input-group-addbefore {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-addbefore .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-addbefore .arco-select.arco-select-single .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group {
  font-size: 12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-inner-wrapper
  .arco-input-group-prefix,
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-inner-wrapper
  .arco-input-group-suffix {
  font-size: 12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-inner-wrapper
  .arco-input-group-prefix
  > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-inner-wrapper
  .arco-input-group-suffix
  > svg {
  font-size: 12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addbefore,
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addafter {
  font-size: 12px;
  height: 22px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addbefore > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addafter > svg {
  font-size: 12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-group-addafter
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-group-addbefore
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-mini
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-inner-wrapper
  .arco-input-group-prefix,
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-inner-wrapper
  .arco-input-group-suffix {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-inner-wrapper
  .arco-input-group-prefix
  > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-inner-wrapper
  .arco-input-group-suffix
  > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addbefore,
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addafter {
  font-size: 14px;
  height: 26px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addbefore > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addafter > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-group-addafter
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-group-addbefore
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-small
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-inner-wrapper
  .arco-input-group-prefix,
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-inner-wrapper
  .arco-input-group-suffix {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-inner-wrapper
  .arco-input-group-prefix
  > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-inner-wrapper
  .arco-input-group-suffix
  > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addbefore,
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addafter {
  font-size: 14px;
  height: 34px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addbefore > svg,
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addafter > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-group-addafter
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-group-addbefore
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-group-wrapper-large
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-group-prefix,
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-group-suffix {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-group-prefix
  > svg,
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-group-suffix
  > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addbefore,
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addafter {
  font-size: 14px;
  height: 22px;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addbefore > svg,
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addafter > svg {
  font-size: 14px;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-group-addafter
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-group-addbefore
  .arco-select
  .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper .arco-input {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-clear-wrapper {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-clear-wrapper
  .arco-input {
  height: 100%;
}
.arco-input-group-wrapper .arco-input-inner-wrapper {
  height: 100%;
}
.arco-input-group-wrapper.arco-input-disabled {
  cursor: not-allowed;
}
.arco-input-mirror {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.arco-textarea {
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1), border-color 0.1s cubic-bezier(0, 0, 1, 1),
    background-color 0.1s cubic-bezier(0, 0, 1, 1);
  border: 1px solid var(--color-neutral-3);
  font-size: 14px;
  vertical-align: top;
  position: relative;
  padding: 4px 12px;
  max-width: 100%;
  min-height: 32px;
  height: auto;
  line-height: 1.5715;
  resize: vertical;
  overflow: auto;
}
.arco-textarea::placeholder {
  color: var(--color-text-3);
}
.arco-textarea:hover {
  background-color: transparent;
  border-color: rgb(var(--primary-5));
}
.arco-textarea:focus,
.arco-textarea.arco-textarea-focus {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 1px rgb(var(--link-3));
}
.arco-textarea-error {
  border-color: transparent;
  background-color: var(--color-danger-light-1);
}
.arco-textarea-error:hover {
  border-color: transparent;
  background-color: var(--color-danger-light-2);
}
.arco-textarea-error:focus,
.arco-textarea-error.arco-textarea-focus {
  border-color: rgb(var(--danger-6));
  background-color: var(--color-bg-2);
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-textarea-disabled {
  background-color: var(--color-fill-2);
  cursor: not-allowed;
  color: var(--color-text-4);
  -webkit-text-fill-color: var(--color-text-4);
  border-color: transparent;
}
.arco-textarea-disabled:hover {
  border-color: transparent;
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
}
.arco-textarea-disabled::placeholder {
  color: var(--color-text-4);
}
.arco-input-group.arco-input-group-compact > .arco-select {
  vertical-align: unset;
}
.arco-input-group.arco-input-group-compact > .arco-select .arco-select-view {
  border-radius: 0;
}
.arco-input-group.arco-input-group-compact > * {
  border-radius: 0;
}
.arco-input-group.arco-input-group-compact > * .arco-input-group > :last-child,
.arco-input-group.arco-input-group-compact > * .arco-input-group > :first-child {
  border-radius: 0;
}
.arco-input-group.arco-input-group-compact > *:not(:last-child) {
  position: relative;
  border-right: 1px solid var(--color-neutral-3);
  box-sizing: border-box;
}
.arco-input-group.arco-input-group-compact > *:first-child,
.arco-input-group.arco-input-group-compact > *:first-child .arco-input-group > *:first-child {
  border-top-left-radius: var(--border-radius-small);
  border-bottom-left-radius: var(--border-radius-small);
}
.arco-input-group.arco-input-group-compact > *:first-child .arco-select .arco-select-view,
.arco-input-group.arco-input-group-compact
  > *:first-child
  .arco-input-group
  > *:first-child
  .arco-select
  .arco-select-view {
  border-top-left-radius: var(--border-radius-small);
  border-bottom-left-radius: var(--border-radius-small);
}
.arco-input-group.arco-input-group-compact > *:last-child,
.arco-input-group.arco-input-group-compact > *:last-child .arco-input-group > *:last-child {
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
}
.arco-input-group.arco-input-group-compact > *:last-child .arco-select .arco-select-view,
.arco-input-group.arco-input-group-compact
  > *:last-child
  .arco-input-group
  > *:last-child
  .arco-select
  .arco-select-view {
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
}
.arco-input-group.arco-input-group-compact > .arco-input:not(:last-child) {
  border-right-color: var(--color-neutral-3);
}
.arco-input-group.arco-input-group-compact > .arco-input:not(:last-child):focus {
  border-right-color: rgb(var(--primary-6));
}
.size-height-size-mini {
  line-height: 1.667;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.size-height-size-small {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.size-height-size-large {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
}
.size-height-group-wrapper-mini .arco-input-group-addbefore,
.size-height-group-wrapper-mini .arco-input-group-addafter {
  padding-left: 8px;
  padding-right: 8px;
}
.size-height-inner-wrapper.size-height-inner-wrapper-mini {
  padding-left: 8px;
  padding-right: 8px;
}
.size-height-size-mini {
  padding-left: 8px;
  padding-right: 8px;
}
.size-height-group-wrapper-small .arco-input-group-addbefore,
.size-height-group-wrapper-small .arco-input-group-addafter {
  padding-left: 12px;
  padding-right: 12px;
}
.size-height-inner-wrapper.size-height-inner-wrapper-small {
  padding-left: 12px;
  padding-right: 12px;
}
.size-height-size-small {
  padding-left: 12px;
  padding-right: 12px;
}
.size-height-group-wrapper-large .arco-input-group-addbefore,
.size-height-group-wrapper-large .arco-input-group-addafter {
  padding-left: 16px;
  padding-right: 16px;
}
.size-height-inner-wrapper.size-height-inner-wrapper-large {
  padding-left: 16px;
  padding-right: 16px;
}
.size-height-size-large {
  padding-left: 16px;
  padding-right: 16px;
}
.group-size.group-size-mini .arco-input-group {
  font-size: 12px;
}
.group-size.group-size-mini .arco-input-inner-wrapper .arco-input-group-prefix,
.group-size.group-size-mini .arco-input-inner-wrapper .arco-input-group-suffix {
  font-size: 12px;
}
.group-size.group-size-mini .arco-input-inner-wrapper .arco-input-group-prefix > svg,
.group-size.group-size-mini .arco-input-inner-wrapper .arco-input-group-suffix > svg {
  font-size: 12px;
}
.group-size.group-size-mini .arco-input-group-addbefore,
.group-size.group-size-mini .arco-input-group-addafter {
  font-size: 12px;
  height: 22px;
}
.group-size.group-size-mini .arco-input-group-addbefore > svg,
.group-size.group-size-mini .arco-input-group-addafter > svg {
  font-size: 12px;
}
.group-size.group-size-mini .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-mini .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.group-size.group-size-mini .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-mini .arco-input-group-addafter .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-mini
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.group-size-mini .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-mini .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-size.group-size-mini .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-mini .arco-input-group-addbefore .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-mini
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.group-size-small .arco-input-group {
  font-size: 14px;
}
.group-size.group-size-small .arco-input-inner-wrapper .arco-input-group-prefix,
.group-size.group-size-small .arco-input-inner-wrapper .arco-input-group-suffix {
  font-size: 14px;
}
.group-size.group-size-small .arco-input-inner-wrapper .arco-input-group-prefix > svg,
.group-size.group-size-small .arco-input-inner-wrapper .arco-input-group-suffix > svg {
  font-size: 14px;
}
.group-size.group-size-small .arco-input-group-addbefore,
.group-size.group-size-small .arco-input-group-addafter {
  font-size: 14px;
  height: 26px;
}
.group-size.group-size-small .arco-input-group-addbefore > svg,
.group-size.group-size-small .arco-input-group-addafter > svg {
  font-size: 14px;
}
.group-size.group-size-small .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-small .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.group-size.group-size-small .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-small .arco-input-group-addafter .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-small
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.group-size-small .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-small .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-size.group-size-small .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-small .arco-input-group-addbefore .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-small
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.group-size-large .arco-input-group {
  font-size: 14px;
}
.group-size.group-size-large .arco-input-inner-wrapper .arco-input-group-prefix,
.group-size.group-size-large .arco-input-inner-wrapper .arco-input-group-suffix {
  font-size: 14px;
}
.group-size.group-size-large .arco-input-inner-wrapper .arco-input-group-prefix > svg,
.group-size.group-size-large .arco-input-inner-wrapper .arco-input-group-suffix > svg {
  font-size: 14px;
}
.group-size.group-size-large .arco-input-group-addbefore,
.group-size.group-size-large .arco-input-group-addafter {
  font-size: 14px;
  height: 34px;
}
.group-size.group-size-large .arco-input-group-addbefore > svg,
.group-size.group-size-large .arco-input-group-addafter > svg {
  font-size: 14px;
}
.group-size.group-size-large .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-large .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.group-size.group-size-large .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.group-size-large .arco-input-group-addafter .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-large
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.group-size-large .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-large .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-size.group-size-large .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.group-size-large .arco-input-group-addbefore .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.group-size-large
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.arco-input-custom-height .arco-input-group {
  font-size: 14px;
}
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-prefix,
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-suffix {
  font-size: 14px;
}
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-prefix > svg,
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-suffix > svg {
  font-size: 14px;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore,
.group-size.arco-input-custom-height .arco-input-group-addafter {
  font-size: 14px;
  height: 22px;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore > svg,
.group-size.arco-input-custom-height .arco-input-group-addafter > svg {
  font-size: 14px;
}
.group-size.arco-input-custom-height .arco-input-group-addafter .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.arco-input-custom-height .arco-input-group-addafter .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.group-size.arco-input-custom-height .arco-input-group-addafter .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -12px;
  margin-right: -13px;
}
.group-size.arco-input-custom-height .arco-input-group-addafter .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.arco-input-custom-height
  .arco-input-group-addafter
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore .item-style {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore .arco-input {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore .arco-select {
  margin-top: -1px;
  margin-bottom: -1px;
  width: auto;
  height: 100%;
  margin-left: -13px;
  margin-right: -12px;
}
.group-size.arco-input-custom-height .arco-input-group-addbefore .arco-select .arco-select-view {
  border-radius: 0;
  background-color: inherit;
  border-color: transparent;
}
.group-size.arco-input-custom-height
  .arco-input-group-addbefore
  .arco-select.arco-select-single
  .arco-select-view {
  height: 100%;
}
.group-size.arco-input-custom-height .arco-input-inner-wrapper {
  height: 100%;
}
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input {
  height: 100%;
}
.group-size.arco-input-custom-height .arco-input-inner-wrapper .arco-input-clear-wrapper {
  height: 100%;
}
.group-size.arco-input-custom-height
  .arco-input-inner-wrapper
  .arco-input-clear-wrapper
  .arco-input {
  height: 100%;
}
.arco-textarea-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}
.arco-textarea-clear-wrapper:hover .arco-textarea-clear-icon {
  display: inline-block;
}
.arco-textarea-clear-wrapper .arco-textarea {
  padding-right: 20px;
}
.arco-textarea-word-limit {
  position: absolute;
  font-size: 12px;
  bottom: 6px;
  right: 10px;
  color: var(--color-text-3);
  user-select: none;
}
.arco-textarea-word-limit-error {
  color: rgb(var(--danger-6));
}
.arco-textarea-clear-icon {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  color: var(--color-text-2);
}
.arco-textarea-clear-icon > svg {
  transition: color 0.1s cubic-bezier(0, 0, 1, 1);
  position: relative;
}
.arco-input-search.arco-input-group-wrapper .arco-input-group-addbefore {
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-input-search.arco-input-group-wrapper .arco-input-group-addafter {
  padding: 0;
  border: none;
}
.arco-input-search.arco-input-group-wrapper .arco-input-group-suffix {
  color: var(--color-text-2);
  font-size: 14px;
}
.arco-input-search.arco-input-group-wrapper:not(.arco-input-disabled) .arco-input-group-addbefore {
  cursor: pointer;
  color: var(--color-text-2);
  font-size: 14px;
}
.arco-input-search.arco-input-group-wrapper .arco-input-search-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: auto;
  height: 100%;
  font-size: 14px;
  color: var(--color-white);
}
.arco-input-search.arco-input-group-wrapper .arco-input-search-btn.arco-btn-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.arco-input-search-button .arco-input-inner-wrapper {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.arco-input-password.arco-input-group-wrapper:not(.arco-input-disabled) .arco-input-group-suffix {
  cursor: pointer;
  color: var(--color-text-2);
  font-size: 12px;
}
.arco-input-number {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
}
.arco-input-number-step-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-color: var(--color-neutral-3);
  background-color: var(--color-fill-2);
  color: var(--color-text-2);
  cursor: pointer;
  user-select: none;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-input-number-step-button:hover {
  border-color: var(--color-fill-3);
  background-color: var(--color-fill-3);
}
.arco-input-number-step-button:active {
  border-color: var(--color-fill-4);
  background-color: var(--color-fill-4);
}
.arco-input-number-step-button-disabled {
  cursor: not-allowed;
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
}
.arco-input-number-step-button-disabled:hover,
.arco-input-number-step-button-disabled:active {
  border-color: var(--color-neutral-3);
  background-color: var(--color-fill-2);
}
.arco-input-number-prefix,
.arco-input-number-suffix {
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-input-number-mode-embed .arco-input-number-step-layer {
  opacity: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  width: 18px;
  border-radius: 1px;
  overflow: hidden;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-input-number-mode-embed .arco-input-number-step-layer .arco-input-number-step-button {
  width: 100%;
  height: 50%;
  font-size: 10px;
}
.arco-input-number-mode-embed:not(.arco-input-group-wrapper-disabled):hover
  .arco-input-number-step-layer {
  opacity: 1;
}
.arco-input-number-mode-embed:not(.arco-input-group-wrapper-disabled):hover
  .arco-input-number-step-layer
  ~ .arco-input-number-suffix {
  opacity: 0;
  pointer-events: none;
}
.arco-input-number-mode-embed
  .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-is-focus)
  .arco-input-number-step-button:not(.arco-input-number-step-button-disabled):hover {
  background-color: var(--color-fill-4);
}
.arco-input-number-mode-button .arco-input {
  text-align: center;
}
.arco-input-number-mode-button .arco-input-group {
  position: relative;
}
.arco-input-number-mode-button .arco-input-group-addbefore,
.arco-input-number-mode-button .arco-input-group-addafter {
  padding: 0;
}
.arco-input-number-mode-button .arco-input-group .arco-input-number-step-button {
  position: absolute;
  top: 0;
  height: 100%;
  border: inherit;
}
.arco-input-number-mode-button .arco-input-group .arco-input-number-step-button:active {
  border-color: var(--color-fill-4);
}
.arco-input-number-mode-button .arco-input-group-addbefore .arco-input-number-step-button {
  left: 0;
  border-right-color: var(--color-neutral-3);
}
.arco-input-number-mode-button .arco-input-group-addafter .arco-input-number-step-button {
  right: 0;
  border-left-color: var(--color-neutral-3);
}
.arco-input-number-mode-button.arco-input-number-size-mini .arco-input-group-addbefore,
.arco-input-number-mode-button.arco-input-number-size-mini .arco-input-group-addafter {
  width: 24px;
}
.arco-input-number-mode-button.arco-input-number-size-mini
  .arco-input-group-addbefore
  .arco-input-number-step-button,
.arco-input-number-mode-button.arco-input-number-size-mini
  .arco-input-group-addafter
  .arco-input-number-step-button {
  width: 24px;
}
.arco-input-number-mode-button.arco-input-number-size-small .arco-input-group-addbefore,
.arco-input-number-mode-button.arco-input-number-size-small .arco-input-group-addafter {
  width: 28px;
}
.arco-input-number-mode-button.arco-input-number-size-small
  .arco-input-group-addbefore
  .arco-input-number-step-button,
.arco-input-number-mode-button.arco-input-number-size-small
  .arco-input-group-addafter
  .arco-input-number-step-button {
  width: 28px;
}
.arco-input-number-mode-button.arco-input-number-size-default .arco-input-group-addbefore,
.arco-input-number-mode-button.arco-input-number-size-default .arco-input-group-addafter {
  width: 32px;
}
.arco-input-number-mode-button.arco-input-number-size-default
  .arco-input-group-addbefore
  .arco-input-number-step-button,
.arco-input-number-mode-button.arco-input-number-size-default
  .arco-input-group-addafter
  .arco-input-number-step-button {
  width: 32px;
}
.arco-input-number-mode-button.arco-input-number-size-large .arco-input-group-addbefore,
.arco-input-number-mode-button.arco-input-number-size-large .arco-input-group-addafter {
  width: 36px;
}
.arco-input-number-mode-button.arco-input-number-size-large
  .arco-input-group-addbefore
  .arco-input-number-step-button,
.arco-input-number-mode-button.arco-input-number-size-large
  .arco-input-group-addafter
  .arco-input-number-step-button {
  width: 36px;
}
.arco-input-number-readonly .arco-input-number-step-button {
  pointer-events: none;
  color: var(--color-text-4);
}
.arco-input-number-illegal-value input {
  color: rgb(var(--danger-6));
}
.arco-input-tag {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: var(--border-radius-small);
  vertical-align: top;
  cursor: text;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  background-color: var(--color-fill-2);
  color: var(--color-text-1);
  border: 1px solid transparent;
}
.arco-input-tag-view {
  display: flex;
  width: 100%;
}
.arco-input-tag-inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.arco-input-tag-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 22px;
  padding-right: 8px;
}
.arco-input-tag .arco-input-tag-clear-icon {
  display: none;
  font-size: 12px;
  color: var(--color-text-2);
  cursor: pointer;
}
.arco-input-tag .arco-input-tag-clear-icon > svg {
  transition: color 0.1s cubic-bezier(0, 0, 1, 1);
  position: relative;
}
.arco-input-tag:hover .arco-input-tag-clear-icon {
  display: block;
}
.arco-input-tag:hover .arco-input-tag-clear-icon ~ * {
  display: none;
}
.arco-input-tag:not(.arco-input-tag-focus)-clear-icon:hover::before {
  background-color: var(--color-fill-4);
}
.arco-input-tag:not(.arco-input-tag-focus) .arco-draggable-item {
  cursor: move;
}
.arco-input-tag-input {
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  font-size: inherit;
  cursor: inherit;
  color: inherit;
}
.arco-input-tag-input-mirror {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}
.arco-input-tag-input::placeholder {
  color: var(--color-text-3);
}
.arco-input-tag-tag {
  margin-right: 4px;
  font-size: 12px;
}
.arco-input-tag .arco-icon-hover {
  cursor: pointer;
}
.arco-input-tag .arco-icon-hover.arco-icon-hover-disabled {
  cursor: not-allowed;
}
.arco-input-tag:hover {
  background-color: var(--color-fill-3);
  border: 1px solid transparent;
}
.arco-input-tag.arco-input-tag-focus {
  background-color: var(--color-bg-2);
  border: 1px solid rgb(var(--primary-6));
  box-shadow: 0 0 0 0 rgb(var(--primary-2));
}
.arco-input-tag .arco-input-tag-tag {
  color: var(--color-text-1);
  border-color: var(--color-fill-3);
  background-color: var(--color-bg-2);
  display: inline-flex;
  align-items: center;
}
.arco-input-tag .arco-input-tag-tag-content {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arco-input-tag .arco-icon-hover:hover::before {
  background-color: var(--color-fill-2);
}
.arco-input-tag.arco-input-tag-focus .arco-input-tag-tag {
  border-color: var(--color-fill-2);
  background-color: var(--color-fill-2);
}
.arco-input-tag.arco-input-tag-focus .arco-icon-hover:hover::before {
  background-color: var(--color-fill-3);
}
.arco-input-tag.arco-input-tag-disabled .arco-input-tag-tag {
  color: var(--color-text-4);
  border-color: var(--color-fill-3);
  background-color: var(--color-fill-2);
}
.arco-input-tag-disabled {
  background-color: var(--color-fill-2);
  color: var(--color-text-4);
  border: 1px solid transparent;
  cursor: not-allowed;
}
.arco-input-tag-disabled:hover {
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
}
.arco-input-tag-disabled .arco-input-tag-input::placeholder {
  color: var(--color-text-4);
}
.arco-input-tag-error {
  background-color: rgb(var(--danger-1));
  color: var(--color-text-1);
  border: 1px solid transparent;
}
.arco-input-tag-error:hover {
  background-color: rgb(var(--danger-2));
  border: 1px solid transparent;
}
.arco-input-tag-error.arco-input-tag-focus {
  background-color: var(--color-bg-2);
  border: 1px solid rgb(var(--danger-6));
  box-shadow: 0 0 0 0 rgb(var(--danger-2));
}
.arco-input-tag-readonly {
  cursor: default;
}
.arco-input-tag-size-mini {
  font-size: 12px;
}
.arco-input-tag-size-mini .arco-input-tag-view {
  min-height: 22px;
}
.arco-input-tag-size-mini .arco-input-tag-inner {
  padding-top: -1px;
  padding-bottom: -1px;
}
.arco-input-tag-size-mini .arco-input-tag-tag,
.arco-input-tag-size-mini .arco-input-tag-tag + .arco-input-tag-input {
  margin-top: 1px;
  margin-bottom: 1px;
  height: 20px;
  line-height: 18px;
}
.arco-input-tag-size-mini.arco-input-tag-has-placeholder input,
.arco-input-tag-size-mini.arco-input-tag-has-placeholder .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 4px;
}
.arco-input-tag-size-default {
  font-size: 14px;
}
.arco-input-tag-size-default .arco-input-tag-view {
  min-height: 30px;
}
.arco-input-tag-size-default .arco-input-tag-inner {
  padding-top: 1px;
  padding-bottom: 1px;
}
.arco-input-tag-size-default .arco-input-tag-tag,
.arco-input-tag-size-default .arco-input-tag-tag + .arco-input-tag-input {
  margin-top: 1px;
  margin-bottom: 1px;
  height: 24px;
  line-height: 22px;
}
.arco-input-tag-size-default.arco-input-tag-has-placeholder input,
.arco-input-tag-size-default.arco-input-tag-has-placeholder .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-input-tag-size-small {
  font-size: 14px;
}
.arco-input-tag-size-small .arco-input-tag-view {
  min-height: 26px;
}
.arco-input-tag-size-small .arco-input-tag-inner {
  padding-top: 1px;
  padding-bottom: 1px;
}
.arco-input-tag-size-small .arco-input-tag-tag,
.arco-input-tag-size-small .arco-input-tag-tag + .arco-input-tag-input {
  margin-top: 1px;
  margin-bottom: 1px;
  height: 20px;
  line-height: 18px;
}
.arco-input-tag-size-small.arco-input-tag-has-placeholder input,
.arco-input-tag-size-small.arco-input-tag-has-placeholder .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-input-tag-size-large {
  font-size: 16px;
}
.arco-input-tag-size-large .arco-input-tag-view {
  min-height: 34px;
}
.arco-input-tag-size-large .arco-input-tag-inner {
  padding-top: 1px;
  padding-bottom: 1px;
}
.arco-input-tag-size-large .arco-input-tag-tag,
.arco-input-tag-size-large .arco-input-tag-tag + .arco-input-tag-input {
  margin-top: 1px;
  margin-bottom: 1px;
  height: 28px;
  line-height: 26px;
}
.arco-input-tag-size-large.arco-input-tag-has-placeholder input,
.arco-input-tag-size-large.arco-input-tag-has-placeholder .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 12px;
}
.arco-layout {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
}
.arco-layout-sider {
  position: relative;
  width: auto;
  margin: 0;
  padding: 0;
  flex: none;
  background: var(--color-menu-dark-bg);
  transition: width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-layout-sider-children {
  height: 100%;
  overflow: auto;
}
.arco-layout-sider-collapsed .arco-layout-sider-children::-webkit-scrollbar {
  width: 0;
}
.arco-layout-sider-has-trigger {
  padding-bottom: 48px;
  box-sizing: border-box;
}
.arco-layout-sider-trigger {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  color: var(--color-white);
  cursor: pointer;
  transition: width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
  z-index: 1;
}
.arco-layout-sider-trigger-light {
  background: var(--color-menu-light-bg);
  border-top: 1px solid var(--color-bg-5);
  color: var(--color-text-1);
}
.arco-layout-sider-light {
  background: var(--color-menu-light-bg);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
}
.arco-layout-header {
  margin: 0;
  flex: 0 0 auto;
  box-sizing: border-box;
}
.arco-layout-content {
  flex: 1;
}
.arco-layout-footer {
  flex: 0 0 auto;
  margin: 0;
}
.arco-layout-has-sider {
  flex-direction: row;
}
.arco-layout-has-sider > .arco-layout,
.arco-layout-has-sider > .arco-layout-content {
  overflow-x: hidden;
}
.arco-link {
  color: rgb(var(--link-6));
  font-size: 14px;
  line-height: 1.5715;
  display: inline-block;
  padding: 1px 4px;
  text-decoration: none;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  background-color: transparent;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-link:hover {
  color: rgb(var(--link-6));
  background-color: var(--color-fill-2);
}
.arco-link:active {
  transition: none;
  color: rgb(var(--link-6));
  background-color: var(--color-fill-3);
}
.arco-link.arco-link-hoverless {
  background-color: unset;
  padding: 0;
  display: inline;
}
.arco-link.arco-link-hoverless:active,
.arco-link.arco-link-hoverless:hover {
  background-color: unset;
}
.arco-link.arco-link-disabled {
  color: var(--color-link-light-3);
  background: none;
  cursor: not-allowed;
}
.arco-link-is-success {
  color: rgb(var(--success-6));
}
.arco-link-is-success:hover {
  color: rgb(var(--success-6));
}
.arco-link-is-success:active {
  color: rgb(var(--success-6));
}
.arco-link-is-success.arco-link-disabled {
  color: var(--color-success-light-3);
}
.arco-link-is-error {
  color: rgb(var(--danger-6));
}
.arco-link-is-error:hover {
  color: rgb(var(--danger-6));
}
.arco-link-is-error:active {
  color: rgb(var(--danger-6));
}
.arco-link-is-error.arco-link-disabled {
  color: var(--color-danger-light-3);
}
.arco-link-is-warning {
  color: rgb(var(--warning-6));
}
.arco-link-is-warning:hover {
  color: rgb(var(--warning-6));
}
.arco-link-is-warning:active {
  color: rgb(var(--warning-6));
}
.arco-link-is-warning.arco-link-disabled {
  color: var(--color-warning-light-2);
}
.arco-link-icon {
  margin-right: 6px;
  font-size: 12px;
}
.arco-list {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius-medium);
  font-size: 14px;
  line-height: 1.5715;
  color: var(--color-text-1);
  overflow-y: auto;
  border: 1px solid var(--color-neutral-3);
}
.arco-list-wrapper {
  overflow: hidden;
}
.arco-list-small > .arco-list-header {
  padding: 8px 20px;
}
.arco-list-small > .arco-list-footer,
.arco-list-small > .arco-list-content > .arco-list-item,
.arco-list-small > .arco-list-content .arco-list-row-col > .arco-list-item,
.arco-list-small > .arco-list-content.arco-list-virtual .arco-list-item {
  padding: 9px 20px;
}
.arco-list-default > .arco-list-header {
  padding: 12px 20px;
}
.arco-list-default > .arco-list-footer,
.arco-list-default > .arco-list-content > .arco-list-item,
.arco-list-default > .arco-list-content .arco-list-row-col > .arco-list-item,
.arco-list-default > .arco-list-content.arco-list-virtual .arco-list-item {
  padding: 13px 20px;
}
.arco-list-large > .arco-list-header {
  padding: 16px 20px;
}
.arco-list-large > .arco-list-footer,
.arco-list-large > .arco-list-content > .arco-list-item,
.arco-list-large > .arco-list-content .arco-list-row-col > .arco-list-item,
.arco-list-large > .arco-list-content.arco-list-virtual .arco-list-item {
  padding: 17px 20px;
}
.arco-list-header,
.arco-list-item:not(:last-child) {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-list-footer {
  border-top: 1px solid var(--color-neutral-3);
}
.arco-list-no-border {
  border: none;
}
.arco-list-no-split .arco-list-header,
.arco-list-no-split .arco-list-footer,
.arco-list-no-split .arco-list-item {
  border: none;
}
.arco-list-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-text-1);
}
.arco-list-item {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}
.arco-list-item-main {
  flex: 1;
  overflow: hidden;
}
.arco-list-item-main .arco-list-item-action:not(:first-child) {
  margin-top: 4px;
}
.arco-list-item-meta {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.arco-list-item-meta-avatar {
  display: flex;
}
.arco-list-item-meta-avatar:not(:last-child) {
  margin-right: 16px;
}
.arco-list-item-meta-title {
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-list-item-meta-title:not(:last-child) {
  margin-bottom: 2px;
}
.arco-list-item-meta-description {
  color: var(--color-text-2);
}
.arco-list-item-action {
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
  list-style: none;
}
.arco-list-item-action > li {
  display: inline-block;
  cursor: pointer;
}
.arco-list-item-action > li:not(:last-child) {
  margin-right: 20px;
}
.arco-list-hoverable .arco-list-item:hover {
  background-color: var(--color-fill-1);
}
.arco-list-pagination {
  float: right;
  margin-top: 24px;
}
.arco-list-pagination::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
  visibility: hidden;
  overflow: hidden;
}
.arco-list-scroll-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.arco-list-content {
  flex: 1;
}
.arco-list-content .arco-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.arco-mentions {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
  white-space: pre-wrap;
}
.arco-mentions-textarea,
.arco-mentions-measure {
  padding: 4px 12px;
  font-size: 14px;
  line-height: 1.5715;
  white-space: inherit;
}
.arco-mentions-textarea {
  resize: none;
}
.arco-mentions-measure,
.arco-mentions-align-textarea .arco-mentions-measure-trigger {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  visibility: hidden;
  pointer-events: none;
}
@keyframes arco-menu-selected-item-label-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.arco-menu {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  line-height: 1.5715;
  transition: width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-menu:focus-visible {
  outline: 3px solid var(--color-primary-light-2);
}
.arco-menu-indent {
  display: inline-block;
  width: 20px;
}
.arco-menu .arco-menu-item,
.arco-menu .arco-menu-group-title,
.arco-menu .arco-menu-pop-header,
.arco-menu .arco-menu-inline-header {
  position: relative;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  cursor: pointer;
}
.arco-menu .arco-menu-item.arco-menu-disabled,
.arco-menu .arco-menu-group-title.arco-menu-disabled,
.arco-menu .arco-menu-pop-header.arco-menu-disabled,
.arco-menu .arco-menu-inline-header.arco-menu-disabled {
  cursor: not-allowed;
}
.arco-menu .arco-menu-item.arco-menu-selected,
.arco-menu .arco-menu-group-title.arco-menu-selected,
.arco-menu .arco-menu-pop-header.arco-menu-selected,
.arco-menu .arco-menu-inline-header.arco-menu-selected {
  font-weight: 500;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-menu .arco-menu-item.arco-menu-selected svg,
.arco-menu .arco-menu-group-title.arco-menu-selected svg,
.arco-menu .arco-menu-pop-header.arco-menu-selected svg,
.arco-menu .arco-menu-inline-header.arco-menu-selected svg {
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-menu .arco-menu-item .arco-icon,
.arco-menu .arco-menu-group-title .arco-icon,
.arco-menu .arco-menu-pop-header .arco-icon,
.arco-menu .arco-menu-inline-header .arco-icon {
  margin-right: 16px;
}
.arco-menu-light {
  background-color: var(--color-menu-light-bg);
}
.arco-menu-light .arco-menu-item,
.arco-menu-light .arco-menu-group-title,
.arco-menu-light .arco-menu-pop-header,
.arco-menu-light .arco-menu-inline-header {
  background-color: var(--color-menu-light-bg);
  color: var(--color-text-2);
}
.arco-menu-light .arco-menu-item .arco-icon,
.arco-menu-light .arco-menu-group-title .arco-icon,
.arco-menu-light .arco-menu-pop-header .arco-icon,
.arco-menu-light .arco-menu-inline-header .arco-icon {
  color: var(--color-text-3);
}
.arco-menu-light .arco-menu-item:hover,
.arco-menu-light .arco-menu-group-title:hover,
.arco-menu-light .arco-menu-pop-header:hover,
.arco-menu-light .arco-menu-inline-header:hover {
  background-color: var(--color-fill-2);
  color: var(--color-text-2);
}
.arco-menu-light .arco-menu-item:hover .arco-icon,
.arco-menu-light .arco-menu-group-title:hover .arco-icon,
.arco-menu-light .arco-menu-pop-header:hover .arco-icon,
.arco-menu-light .arco-menu-inline-header:hover .arco-icon {
  color: var(--color-text-3);
}
.arco-menu-light .arco-menu-item.arco-menu-active,
.arco-menu-light .arco-menu-group-title.arco-menu-active,
.arco-menu-light .arco-menu-pop-header.arco-menu-active,
.arco-menu-light .arco-menu-inline-header.arco-menu-active {
  box-shadow: 0 0 0 1px rgb(var(--primary-6)) inset;
}
.arco-menu-light .arco-menu-item.arco-menu-selected,
.arco-menu-light .arco-menu-group-title.arco-menu-selected,
.arco-menu-light .arco-menu-pop-header.arco-menu-selected,
.arco-menu-light .arco-menu-inline-header.arco-menu-selected {
  color: rgb(var(--primary-6));
}
.arco-menu-light .arco-menu-item.arco-menu-selected .arco-icon,
.arco-menu-light .arco-menu-group-title.arco-menu-selected .arco-icon,
.arco-menu-light .arco-menu-pop-header.arco-menu-selected .arco-icon,
.arco-menu-light .arco-menu-inline-header.arco-menu-selected .arco-icon {
  color: rgb(var(--primary-6));
}
.arco-menu-light .arco-menu-item.arco-menu-disabled,
.arco-menu-light .arco-menu-group-title.arco-menu-disabled,
.arco-menu-light .arco-menu-pop-header.arco-menu-disabled,
.arco-menu-light .arco-menu-inline-header.arco-menu-disabled {
  background-color: var(--color-menu-light-bg);
  color: var(--color-text-4);
}
.arco-menu-light .arco-menu-item.arco-menu-disabled .arco-icon,
.arco-menu-light .arco-menu-group-title.arco-menu-disabled .arco-icon,
.arco-menu-light .arco-menu-pop-header.arco-menu-disabled .arco-icon,
.arco-menu-light .arco-menu-inline-header.arco-menu-disabled .arco-icon {
  color: var(--color-text-4);
}
.arco-menu-light .arco-menu-item.arco-menu-selected {
  background-color: var(--color-fill-2);
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected {
  color: rgb(var(--primary-6));
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected .arco-icon {
  color: rgb(var(--primary-6));
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected:hover {
  background-color: var(--color-fill-2);
}
.arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected,
.arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected,
.arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected,
.arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected {
  background: none;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected:hover {
  background-color: var(--color-fill-2);
}
.arco-menu-light .arco-menu-group-title {
  color: var(--color-text-3);
  pointer-events: none;
}
.arco-menu-light .arco-menu-collapse-button {
  background-color: var(--color-fill-1);
  color: var(--color-text-3);
}
.arco-menu-light .arco-menu-collapse-button:hover {
  background-color: var(--color-fill-3);
}
.arco-menu-dark {
  background-color: var(--color-menu-dark-bg);
}
.arco-menu-dark .arco-menu-item,
.arco-menu-dark .arco-menu-group-title,
.arco-menu-dark .arco-menu-pop-header,
.arco-menu-dark .arco-menu-inline-header {
  background-color: var(--color-menu-dark-bg);
  color: var(--color-text-4);
}
.arco-menu-dark .arco-menu-item .arco-icon,
.arco-menu-dark .arco-menu-group-title .arco-icon,
.arco-menu-dark .arco-menu-pop-header .arco-icon,
.arco-menu-dark .arco-menu-inline-header .arco-icon {
  color: var(--color-text-3);
}
.arco-menu-dark .arco-menu-item:hover,
.arco-menu-dark .arco-menu-group-title:hover,
.arco-menu-dark .arco-menu-pop-header:hover,
.arco-menu-dark .arco-menu-inline-header:hover {
  background-color: var(--color-menu-dark-hover);
  color: var(--color-text-4);
}
.arco-menu-dark .arco-menu-item:hover .arco-icon,
.arco-menu-dark .arco-menu-group-title:hover .arco-icon,
.arco-menu-dark .arco-menu-pop-header:hover .arco-icon,
.arco-menu-dark .arco-menu-inline-header:hover .arco-icon {
  color: var(--color-text-3);
}
.arco-menu-dark .arco-menu-item.arco-menu-active,
.arco-menu-dark .arco-menu-group-title.arco-menu-active,
.arco-menu-dark .arco-menu-pop-header.arco-menu-active,
.arco-menu-dark .arco-menu-inline-header.arco-menu-active {
  box-shadow: 0 0 0 1px rgb(var(--primary-6)) inset;
}
.arco-menu-dark .arco-menu-item.arco-menu-selected,
.arco-menu-dark .arco-menu-group-title.arco-menu-selected,
.arco-menu-dark .arco-menu-pop-header.arco-menu-selected,
.arco-menu-dark .arco-menu-inline-header.arco-menu-selected {
  color: var(--color-white);
}
.arco-menu-dark .arco-menu-item.arco-menu-selected .arco-icon,
.arco-menu-dark .arco-menu-group-title.arco-menu-selected .arco-icon,
.arco-menu-dark .arco-menu-pop-header.arco-menu-selected .arco-icon,
.arco-menu-dark .arco-menu-inline-header.arco-menu-selected .arco-icon {
  color: var(--color-white);
}
.arco-menu-dark .arco-menu-item.arco-menu-disabled,
.arco-menu-dark .arco-menu-group-title.arco-menu-disabled,
.arco-menu-dark .arco-menu-pop-header.arco-menu-disabled,
.arco-menu-dark .arco-menu-inline-header.arco-menu-disabled {
  background-color: var(--color-menu-dark-bg);
  color: var(--color-text-2);
}
.arco-menu-dark .arco-menu-item.arco-menu-disabled .arco-icon,
.arco-menu-dark .arco-menu-group-title.arco-menu-disabled .arco-icon,
.arco-menu-dark .arco-menu-pop-header.arco-menu-disabled .arco-icon,
.arco-menu-dark .arco-menu-inline-header.arco-menu-disabled .arco-icon {
  color: var(--color-text-2);
}
.arco-menu-dark .arco-menu-item.arco-menu-selected {
  background-color: var(--color-menu-dark-hover);
}
.arco-menu-dark .arco-menu-inline-header.arco-menu-selected {
  color: rgb(var(--primary-6));
}
.arco-menu-dark .arco-menu-inline-header.arco-menu-selected .arco-icon {
  color: rgb(var(--primary-6));
}
.arco-menu-dark .arco-menu-inline-header.arco-menu-selected:hover {
  background-color: var(--color-menu-dark-hover);
}
.arco-menu-dark.arco-menu-horizontal .arco-menu-item.arco-menu-selected,
.arco-menu-dark.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected,
.arco-menu-dark.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected,
.arco-menu-dark.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected {
  background: none;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-menu-dark.arco-menu-horizontal .arco-menu-item.arco-menu-selected:hover,
.arco-menu-dark.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected:hover,
.arco-menu-dark.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected:hover,
.arco-menu-dark.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected:hover {
  background-color: var(--color-menu-dark-hover);
}
.arco-menu-dark .arco-menu-group-title {
  color: var(--color-text-3);
  pointer-events: none;
}
.arco-menu-dark .arco-menu-collapse-button {
  background-color: rgb(var(--primary-6));
  color: var(--color-white);
}
.arco-menu-dark .arco-menu-collapse-button:hover {
  background-color: rgb(var(--primary-7));
}
.arco-menu a,
.arco-menu a:hover,
.arco-menu a:focus,
.arco-menu a:active {
  color: inherit;
  cursor: inherit;
  text-decoration: none;
}
.arco-menu-inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.arco-menu-icon-suffix.is-open {
  transform: rotate(180deg);
}
.arco-menu-vertical .arco-menu-item,
.arco-menu-vertical .arco-menu-group-title,
.arco-menu-vertical .arco-menu-pop-header,
.arco-menu-vertical .arco-menu-inline-header {
  padding: 0 12px;
  line-height: 40px;
}
.arco-menu-vertical .arco-menu-item .arco-menu-icon-suffix .arco-icon,
.arco-menu-vertical .arco-menu-group-title .arco-menu-icon-suffix .arco-icon,
.arco-menu-vertical .arco-menu-pop-header .arco-menu-icon-suffix .arco-icon,
.arco-menu-vertical .arco-menu-inline-header .arco-menu-icon-suffix .arco-icon {
  margin-right: 0;
}
.arco-menu-vertical .arco-menu-item,
.arco-menu-vertical .arco-menu-group-title,
.arco-menu-vertical .arco-menu-pop-header,
.arco-menu-vertical .arco-menu-inline-header {
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-menu-vertical .arco-menu-item .arco-menu-item-inner,
.arco-menu-vertical .arco-menu-group-title .arco-menu-item-inner,
.arco-menu-vertical .arco-menu-pop-header .arco-menu-item-inner,
.arco-menu-vertical .arco-menu-inline-header .arco-menu-item-inner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.arco-menu-vertical .arco-menu-item .arco-menu-icon-suffix,
.arco-menu-vertical .arco-menu-group-title .arco-menu-icon-suffix,
.arco-menu-vertical .arco-menu-pop-header .arco-menu-icon-suffix,
.arco-menu-vertical .arco-menu-inline-header .arco-menu-icon-suffix {
  position: absolute;
  right: 12px;
}
.arco-menu-vertical .arco-menu-inner {
  padding: 4px 8px;
}
.arco-menu-vertical .arco-menu-item.arco-menu-item-indented {
  display: flex;
}
.arco-menu-vertical .arco-menu-pop-header,
.arco-menu-vertical .arco-menu-inline-header {
  padding-right: 28px;
}
.arco-menu-horizontal {
  width: auto;
  height: auto;
}
.arco-menu-horizontal .arco-menu-item,
.arco-menu-horizontal .arco-menu-group-title,
.arco-menu-horizontal .arco-menu-pop-header,
.arco-menu-horizontal .arco-menu-inline-header {
  padding: 0 12px;
  line-height: 30px;
}
.arco-menu-horizontal .arco-menu-item .arco-menu-icon-suffix .arco-icon,
.arco-menu-horizontal .arco-menu-group-title .arco-menu-icon-suffix .arco-icon,
.arco-menu-horizontal .arco-menu-pop-header .arco-menu-icon-suffix .arco-icon,
.arco-menu-horizontal .arco-menu-inline-header .arco-menu-icon-suffix .arco-icon {
  margin-right: 0;
}
.arco-menu-horizontal .arco-menu-item .arco-icon,
.arco-menu-horizontal .arco-menu-group-title .arco-icon,
.arco-menu-horizontal .arco-menu-pop-header .arco-icon,
.arco-menu-horizontal .arco-menu-inline-header .arco-icon {
  margin-right: 16px;
}
.arco-menu-horizontal .arco-menu-item .arco-menu-icon-suffix,
.arco-menu-horizontal .arco-menu-group-title .arco-menu-icon-suffix,
.arco-menu-horizontal .arco-menu-pop-header .arco-menu-icon-suffix,
.arco-menu-horizontal .arco-menu-inline-header .arco-menu-icon-suffix {
  margin-left: 6px;
}
.arco-menu-horizontal .arco-menu-inner {
  display: flex;
  align-items: center;
  padding: 14px 20px;
}
.arco-menu-horizontal .arco-menu-item,
.arco-menu-horizontal .arco-menu-pop {
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
}
.arco-menu-horizontal .arco-menu-item:not(:first-child),
.arco-menu-horizontal .arco-menu-pop:not(:first-child) {
  margin-left: 12px;
}
.arco-menu-horizontal .arco-menu-pop::after {
  content: ' ';
  width: 100%;
  height: 14px;
  position: absolute;
  left: 0;
  bottom: -14px;
}
.arco-menu-overflow-wrap {
  width: 100%;
}
.arco-menu-overflow-sub-menu-mirror,
.arco-menu-overflow-hidden-menu-item {
  position: absolute !important;
  white-space: nowrap;
  visibility: hidden;
  pointer-events: none;
}
.arco-menu-selected-label {
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: -14px;
  height: 3px;
  background-color: rgb(var(--primary-6));
  animation: arco-menu-selected-item-label-enter 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-menu-pop-button {
  width: auto;
  background: none;
  box-shadow: none;
}
.arco-menu-pop-button.arco-menu-collapse {
  width: auto;
}
.arco-menu-pop-button .arco-menu-item,
.arco-menu-pop-button .arco-menu-group-title,
.arco-menu-pop-button .arco-menu-pop-header,
.arco-menu-pop-button .arco-menu-inline-header {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid transparent;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.arco-menu-collapse {
  width: 48px;
}
.arco-menu-collapse .arco-menu-inner {
  padding: 4px 4px;
}
.arco-menu-collapse .arco-menu-icon-suffix {
  display: none;
}
.arco-menu-collapse .arco-menu-item .arco-icon,
.arco-menu-collapse .arco-menu-group-title .arco-icon,
.arco-menu-collapse .arco-menu-pop-header .arco-icon,
.arco-menu-collapse .arco-menu-inline-header .arco-icon {
  margin-right: 100%;
}
.arco-menu-collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-small);
  cursor: pointer;
}
.arco-menu-inline-content {
  overflow: hidden;
  height: auto;
  transition: height 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-menu-inline-content-hide {
  height: 0;
}
.arco-menu-item-tooltip a {
  color: inherit;
  cursor: text;
}
.arco-menu-item-tooltip a:hover,
.arco-menu-item-tooltip a:focus,
.arco-menu-item-tooltip a:active {
  color: inherit;
}
.arco-menu-pop-trigger.arco-trigger-position-bl {
  transform: translateY(14px);
}
.arco-menu-pop-trigger.arco-trigger-position-bl .arco-trigger-arrow {
  z-index: 0;
  border-left: 1px solid var(--color-neutral-3);
  border-top: 1px solid var(--color-neutral-3);
}
.arco-menu-pop-trigger.arco-trigger-position-rt {
  transform: translateX(8px);
}
.arco-menu-pop-trigger.arco-trigger-position-rt .arco-trigger-arrow {
  z-index: 0;
  border-left: 1px solid var(--color-neutral-3);
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-menu-pop-trigger
  .arco-dropdown-menu-dark
  ~ .arco-trigger-arrow-container
  .arco-trigger-arrow {
  background-color: var(--color-menu-dark-bg);
  border-color: var(--color-menu-dark-bg);
}
.arco-message-wrapper {
  width: 100%;
  position: fixed;
  z-index: 1003;
  padding: 0 10px;
  text-align: center;
  pointer-events: none;
  box-sizing: border-box;
}
.arco-message-wrapper-top {
  top: 40px;
}
.arco-message-wrapper-bottom {
  bottom: 40px;
}
.arco-message {
  position: relative;
  display: inline-block;
  padding: 10px 16px 10px 16px;
  line-height: 1;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--color-neutral-3);
  margin-bottom: 16px;
  background-color: var(--color-bg-popup);
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  pointer-events: auto;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-message-closable {
  padding-right: 38px;
}
.arco-message-icon {
  font-size: 20px;
  color: var(--color-text-1);
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  animation: arco-msg-fade 0.1s cubic-bezier(0, 0, 1, 1),
    arco-msg-fade 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-message-content {
  vertical-align: middle;
  color: var(--color-text-1);
  font-size: 14px;
}
.arco-message-info {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-message-info .arco-message-icon {
  color: rgb(var(--primary-6));
}
.arco-message-info .arco-message-content {
  color: var(--color-text-1);
}
.arco-message-success {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-message-success .arco-message-icon {
  color: rgb(var(--success-6));
}
.arco-message-success .arco-message-content {
  color: var(--color-text-1);
}
.arco-message-warning {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-message-warning .arco-message-icon {
  color: rgb(var(--warning-6));
}
.arco-message-warning .arco-message-content {
  color: var(--color-text-1);
}
.arco-message-error {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-message-error .arco-message-icon {
  color: rgb(var(--danger-6));
}
.arco-message-error .arco-message-content {
  color: var(--color-text-1);
}
.arco-message-loading {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-message-loading .arco-message-icon {
  color: rgb(var(--primary-6));
}
.arco-message-loading .arco-message-content {
  color: var(--color-text-1);
}
.arco-message-close-btn {
  position: absolute;
  top: 14px;
  right: 12px;
  color: var(--color-text-1);
  font-size: 12px;
}
.arco-message-close-btn > svg {
  position: relative;
}
.arco-message .arco-icon-hover.arco-message-icon-hover::before {
  width: 20px;
  height: 20px;
}
.fadeMessage-enter,
.fadeMessage-appear {
  opacity: 0;
}
.fadeMessage-enter-active,
.fadeMessage-appear-active {
  opacity: 1;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
}
.fadeMessage-exit {
  opacity: 0;
  overflow: hidden;
}
.fadeMessage-exit-active {
  opacity: 0;
  height: 0;
  transition: all 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
@keyframes arco-msg-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes arco-msg-scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.arco-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: none;
  background-color: var(--color-mask-bg);
}
.arco-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  overflow: auto;
}
.arco-modal-wrapper-no-mask {
  pointer-events: none;
}
.arco-modal-wrapper-no-mask .arco-modal {
  pointer-events: auto;
}
.arco-modal-wrapper.arco-modal-wrapper-align-center {
  text-align: center;
  white-space: nowrap;
}
.arco-modal-wrapper.arco-modal-wrapper-align-center::after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  width: 0;
}
.arco-modal-wrapper.arco-modal-wrapper-align-center .arco-modal {
  top: 0;
  vertical-align: middle;
  display: inline-block;
}
.arco-modal {
  position: relative;
  margin: 0 auto;
  top: 100px;
  width: 520px;
  border-radius: var(--border-radius-medium);
  border: 0 solid var(--color-neutral-3);
  background-color: var(--color-bg-3);
  line-height: 1.5715;
  text-align: left;
  white-space: initial;
  box-shadow: none;
  box-sizing: border-box;
}
.arco-modal-header {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid var(--color-neutral-3);
  height: 48px;
  display: flex;
  align-items: center;
}
.arco-modal-header .arco-modal-title {
  flex: 1;
  text-align: center;
}
.arco-modal-content {
  position: relative;
  padding: 24px 20px;
  color: var(--color-text-1);
  font-size: 14px;
}
.arco-modal-footer {
  border-top: 1px solid var(--color-neutral-3);
  width: 100%;
  box-sizing: border-box;
  text-align: right;
  padding: 16px 20px;
}
.arco-modal-footer > .arco-btn {
  margin-left: 12px;
}
.arco-modal-footer > .arco-btn:only-child {
  margin-left: 0;
}
.arco-modal .arco-modal-close-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  font-size: 12px;
  cursor: pointer;
  color: var(--color-text-1);
}
.arco-modal-title {
  color: var(--color-text-1);
  font-size: 16px;
  font-weight: 500;
}
.arco-modal-title .arco-icon {
  font-size: 18px;
  margin-right: 10px;
  vertical-align: -3px;
}
.arco-modal-title .arco-icon-info-circle-fill {
  color: rgb(var(--primary-6));
}
.arco-modal-title .arco-icon-check-circle-fill {
  color: rgb(var(--success-6));
}
.arco-modal-title .arco-icon-exclamation-circle-fill {
  color: rgb(var(--warning-6));
}
.arco-modal-title .arco-icon-close-circle-fill {
  color: rgb(var(--danger-6));
}
.arco-modal-simple {
  padding: 24px 32px 32px;
  width: 464px;
}
.arco-modal-simple .arco-modal-header,
.arco-modal-simple .arco-modal-footer {
  border: none;
  padding: 0;
  height: unset;
}
.arco-modal-simple .arco-modal-header {
  margin-bottom: 24px;
}
.arco-modal-simple .arco-modal-title {
  text-align: center;
}
.arco-modal-simple .arco-modal-footer {
  text-align: center;
  margin-top: 32px;
}
.arco-modal-simple .arco-modal-content {
  padding: 0;
}
.zoomModal-enter,
.zoomModal-appear {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.zoomModal-enter-active,
.zoomModal-appear-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.zoomModal-exit {
  opacity: 1;
  transform: scale(1, 1);
}
.zoomModal-exit-active {
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1),
    transform 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.fadeModal-enter,
.fadeModal-appear {
  opacity: 0;
}
.fadeModal-enter-active,
.fadeModal-appear-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.fadeModal-exit {
  opacity: 1;
}
.fadeModal-exit-active {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-notification-wrapper {
  position: fixed;
  z-index: 1003;
}
.arco-notification-wrapper-topLeft {
  left: 20px;
  top: 20px;
}
.arco-notification-wrapper-topRight {
  right: 20px;
  top: 20px;
}
.arco-notification-wrapper-topRight .arco-notification {
  margin-left: auto;
}
.arco-notification-wrapper-bottomLeft {
  bottom: 20px;
  left: 20px;
}
.arco-notification-wrapper-bottomRight {
  bottom: 20px;
  right: 20px;
}
.arco-notification-wrapper-bottomRight .arco-notification {
  margin-left: auto;
}
.arco-notification {
  display: flex;
  position: relative;
  width: 340px;
  padding: 20px 20px 20px 20px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-neutral-3);
  margin-bottom: 20px;
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-notification-icon {
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  color: var(--color-text-1);
}
.arco-notification-info {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-notification-info .arco-notification-icon {
  color: rgb(var(--primary-6));
}
.arco-notification-success {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-notification-success .arco-notification-icon {
  color: rgb(var(--success-6));
}
.arco-notification-warning {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-notification-warning .arco-notification-icon {
  color: rgb(var(--warning-6));
}
.arco-notification-error {
  background-color: var(--color-bg-popup);
  border-color: var(--color-neutral-3);
}
.arco-notification-error .arco-notification-icon {
  color: rgb(var(--danger-6));
}
.arco-notification-left {
  padding-right: 16px;
}
.arco-notification-right {
  flex: 1;
  word-break: break-word;
}
.arco-notification-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-1);
  margin-bottom: 4px;
}
.arco-notification-content {
  font-size: 14px;
  color: var(--color-text-1);
}
.arco-notification-info .arco-notification-title {
  color: var(--color-text-1);
}
.arco-notification-info .arco-notification-content {
  color: var(--color-text-1);
}
.arco-notification-success .arco-notification-title {
  color: var(--color-text-1);
}
.arco-notification-success .arco-notification-content {
  color: var(--color-text-1);
}
.arco-notification-warning .arco-notification-title {
  color: var(--color-text-1);
}
.arco-notification-warning .arco-notification-content {
  color: var(--color-text-1);
}
.arco-notification-error .arco-notification-title {
  color: var(--color-text-1);
}
.arco-notification-error .arco-notification-content {
  color: var(--color-text-1);
}
.arco-notification-btn-wrapper {
  margin-top: 16px;
  text-align: right;
}
.arco-notification .arco-notification-close-btn {
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 12px;
  color: var(--color-text-1);
  font-size: 12px;
}
.arco-notification .arco-notification-close-btn > svg {
  position: relative;
}
.arco-notification .arco-icon-hover.arco-notification-icon-hover::before {
  width: 20px;
  height: 20px;
}
.slideNoticeLeft-enter,
.slideNoticeLeft-appear {
  transform: translateX(-100%);
}
.slideNoticeLeft-enter-active,
.slideNoticeLeft-appear-active {
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.slideNoticeLeft-exit {
  opacity: 0;
}
.slideNoticeLeft-exit-active {
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  transition: all 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.slideNoticeRight-enter,
.slideNoticeRight-appear {
  transform: translateX(100%);
}
.slideNoticeRight-enter-active,
.slideNoticeRight-appear-active {
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.slideNoticeRight-exit {
  opacity: 0;
}
.slideNoticeRight-exit-active {
  opacity: 0;
  height: 0;
  transition: all 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-page-header {
  padding: 16px 0;
}
.arco-breadcrumb + .arco-page-header-head {
  margin-top: 4px;
}
.arco-page-header-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 28px;
}
.arco-page-header-head-left {
  display: flex;
  align-items: center;
}
.arco-page-header-head-wrapper {
  padding-left: 24px;
  padding-right: 20px;
}
.arco-page-header-head-main {
  display: flex;
  align-items: center;
  min-height: 30px;
  overflow: hidden;
}
.arco-page-header-head-main-with-back {
  margin-left: -8px;
  padding-left: 8px;
}
.arco-page-header-head-extra {
  white-space: nowrap;
  overflow: hidden;
}
.arco-page-header .arco-icon-hover.arco-page-header-icon-hover::before {
  width: 30px;
  height: 30px;
}
.arco-page-header .arco-icon-hover.arco-page-header-icon-hover:hover::before {
  background-color: var(--color-fill-2);
}
.arco-page-header-back {
  color: var(--color-text-2);
  font-size: 14px;
  margin-right: 12px;
}
.arco-page-header-back-icon {
  position: relative;
}
.arco-page-header-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-1);
  font-size: 20px;
  font-weight: 600;
}
.arco-page-header-divider {
  width: 1px;
  margin-left: 12px;
  margin-right: 12px;
  height: 16px;
  background-color: var(--color-fill-3);
}
.arco-page-header-sub-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-3);
  font-size: 14px;
}
.arco-page-header-content {
  padding: 20px 32px;
  border-top: 1px solid var(--color-neutral-3);
}
.arco-page-header-footer {
  padding: 16px 20px 0 24px;
}
.arco-page-header-with-breadcrumb {
  padding: 12px 0;
}
.arco-page-header-with-breadcrumb .arco-page-header-footer {
  padding-top: 12px;
}
.arco-page-header-with-content .arco-page-header-head-wrapper {
  padding-bottom: 12px;
}
.arco-page-header-with-footer {
  padding-bottom: 0;
}
.arco-page-header-wrap .arco-page-header-head {
  flex-wrap: wrap;
}
.arco-page-header-wrap .arco-page-header-head .arco-page-header-head-extra {
  margin-top: 4px;
}
.arco-pagination {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.arco-pagination-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.arco-pagination-item {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-radius: var(--border-radius-small);
  color: var(--color-text-2);
  border: 0 solid transparent;
  background-color: transparent;
  font-size: 14px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
}
.arco-pagination-item-prev,
.arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-item-disabled {
  cursor: not-allowed;
  background-color: transparent;
  color: var(--color-text-4);
  border-color: transparent;
}
.arco-pagination-item:not(.arco-pagination-item-disabled):not(.arco-pagination-item-active):hover {
  background-color: var(--color-fill-1);
  border-color: transparent;
  color: var(--color-text-2);
}
.arco-pagination-item-active {
  transition: color 0.2s cubic-bezier(0, 0, 1, 1), background-color 0.2s cubic-bezier(0, 0, 1, 1);
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  border-color: transparent;
}
.arco-pagination-item:not(:last-child) {
  margin-right: 8px;
}
.arco-pagination-item-prev,
.arco-pagination-item-next {
  font-size: 12px;
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-pagination-item-prev:not(.arco-pagination-item-disabled):hover,
.arco-pagination-item-next:not(.arco-pagination-item-disabled):hover {
  background-color: var(--color-fill-1);
  color: rgb(var(--primary-6));
}
.arco-pagination-item-prev::after,
.arco-pagination-item-next::after {
  display: inline-block;
  content: '.';
  font-size: 0;
  vertical-align: middle;
}
.arco-pagination .arco-pagination-item-prev.arco-pagination-item-disabled,
.arco-pagination .arco-pagination-item-next.arco-pagination-item-disabled {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-pagination-item-jumper {
  font-size: 16px;
}
.arco-pagination-jumper {
  margin-left: 8px;
}
.arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-pagination-jumper-text-goto {
  color: var(--color-text-3);
}
.arco-pagination .arco-pagination-jumper-input {
  width: 40px;
  text-align: center;
  margin-left: 8px;
  padding-left: 2px;
  padding-right: 2px;
}
.arco-pagination-option {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-width: 0;
  position: relative;
  margin-left: 8px;
  font-size: 14px;
  height: 32px;
  line-height: 0;
}
.arco-pagination-option .arco-select {
  width: auto;
}
.arco-pagination-option .arco-select-view-value {
  overflow: inherit;
  padding-right: 6px;
}
.arco-pagination-total-text {
  display: inline-block;
  height: 100%;
  color: var(--color-text-1);
  margin-right: 8px;
  font-size: 14px;
  line-height: 32px;
}
.arco-pagination-item-simple-pager {
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px 0 4px;
}
.arco-pagination-item-simple-pager .arco-pagination-jumper-separator {
  padding: 0 12px 0 12px;
}
.arco-pagination-simple .arco-pagination-item {
  margin-right: 0;
}
.arco-pagination-simple .arco-pagination-jumper {
  color: var(--color-text-1);
}
.arco-pagination-simple .arco-pagination-jumper {
  margin-left: 0;
}
.arco-pagination-simple .arco-pagination-jumper .arco-pagination-jumper-input {
  width: 40px;
  margin-left: 0;
}
.arco-pagination-simple .arco-pagination-item-prev,
.arco-pagination-simple .arco-pagination-item-next {
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-pagination-simple .arco-pagination-item-prev:not(.arco-pagination-item-disabled):hover,
.arco-pagination-simple .arco-pagination-item-next:not(.arco-pagination-item-disabled):hover {
  background-color: var(--color-fill-1);
  color: rgb(var(--primary-6));
}
.arco-pagination-simple .arco-pagination-item-prev.arco-pagination-item-disabled,
.arco-pagination-simple .arco-pagination-item-next.arco-pagination-item-disabled {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-pagination-disabled {
  cursor: not-allowed;
}
.arco-pagination-disabled .arco-pagination-item,
.arco-pagination-disabled
  .arco-pagination-item:not(.arco-pagination-item-disabled):not(
    .arco-pagination-item-active
  ):hover {
  cursor: not-allowed;
  background-color: transparent;
  border-color: transparent;
  color: var(--color-text-4);
}
.arco-pagination.arco-pagination-disabled .arco-pagination-item-active {
  background-color: var(--color-fill-1);
  border-color: transparent;
  color: var(--color-primary-light-3);
}
.arco-pagination-size-mini .arco-pagination-item {
  font-size: 12px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.arco-pagination-size-mini .arco-pagination-item-prev,
.arco-pagination-size-mini .arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-size-mini .arco-pagination-total-text {
  font-size: 12px;
  line-height: 24px;
}
.arco-pagination-size-mini .arco-pagination-option {
  font-size: 12px;
  height: 24px;
  line-height: 0;
}
.arco-pagination-size-mini .arco-pagination-jumper > span {
  font-size: 12px;
}
.arco-pagination-size-small .arco-pagination-item {
  font-size: 14px;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
}
.arco-pagination-size-small .arco-pagination-item-prev,
.arco-pagination-size-small .arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-size-small .arco-pagination-total-text {
  font-size: 14px;
  line-height: 28px;
}
.arco-pagination-size-small .arco-pagination-option {
  font-size: 14px;
  height: 28px;
  line-height: 0;
}
.arco-pagination-size-small .arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-pagination-size-large .arco-pagination-item {
  font-size: 14px;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
}
.arco-pagination-size-large .arco-pagination-item-prev,
.arco-pagination-size-large .arco-pagination-item-next {
  font-size: 14px;
}
.arco-pagination-size-large .arco-pagination-total-text {
  font-size: 14px;
  line-height: 36px;
}
.arco-pagination-size-large .arco-pagination-option {
  font-size: 14px;
  height: 36px;
  line-height: 0;
}
.arco-pagination-size-large .arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-popconfirm-content {
  padding: 16px 16px;
  box-sizing: border-box;
}
.arco-popconfirm-title {
  position: relative;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--color-text-1);
  display: flex;
  align-items: flex-start;
}
.arco-popconfirm-title-icon {
  font-size: 18px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  height: 22.001px;
}
.arco-popconfirm-title-icon .arco-icon-exclamation-circle-fill {
  color: rgb(var(--warning-6));
}
.arco-popconfirm .arco-popconfirm-btn {
  text-align: right;
}
.arco-popconfirm .arco-popconfirm-btn > button {
  margin-left: 8px;
}
.arco-popover-content {
  padding: 12px 16px;
}
.arco-popover-title {
  font-size: 16px;
  color: var(--color-text-1);
  font-weight: 500;
}
.arco-popover-title + .arco-popover-inner-content {
  margin-top: 4px;
}
.arco-popover-content,
.arco-popconfirm-content {
  color: var(--color-text-2);
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: none;
  width: 100%;
  font-size: 14px;
  border-radius: var(--border-radius-medium);
  line-height: 1.5715;
  box-sizing: border-box;
  border: 1px solid var(--color-neutral-3);
}
.arco-popover-content-inner,
.arco-popconfirm-content-inner {
  word-wrap: break-word;
  text-align: left;
}
.arco-popover-arrow.arco-trigger-arrow,
.arco-popconfirm-arrow.arco-trigger-arrow {
  background-color: var(--color-bg-popup);
  border: 1px solid var(--color-neutral-3);
  z-index: 1;
}
.arco-progress {
  position: relative;
  line-height: 1;
  font-size: 12px;
}
.arco-progress-line,
.arco-progress-steps {
  display: inline-block;
  max-width: 100%;
  width: 100%;
}
.arco-progress-line-wrapper,
.arco-progress-steps-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.arco-progress-line-text,
.arco-progress-steps-text {
  font-size: 12px;
  margin-left: 16px;
  color: var(--color-text-2);
  white-space: nowrap;
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 32px;
}
.arco-progress-line-text .arco-icon,
.arco-progress-steps-text .arco-icon {
  font-size: 12px;
  margin-left: 4px;
}
.arco-progress-line-outer {
  background-color: var(--color-fill-3);
  border-radius: 100px;
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.arco-progress-line-inner {
  height: 100%;
  border-radius: 100px;
  background-color: rgb(var(--primary-6));
  position: relative;
  transition: width 0.6s cubic-bezier(0.34, 0.69, 0.1, 1),
    background 0.3s cubic-bezier(0.34, 0.69, 0.1, 1);
  max-width: 100%;
}
.arco-progress-line-inner-buffer {
  position: absolute;
  background-color: var(--color-primary-light-3);
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 100px 100px 0;
  max-width: 100%;
  transition: all 0.6s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-progress-line-inner-animate::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 75%
  );
  background-size: 400% 100%;
  animation: arco-progress-loading 1.5s cubic-bezier(0.34, 0.69, 0.1, 1) infinite;
}
.arco-progress-line-text .arco-icon {
  color: var(--color-text-2);
}
.arco-progress-steps-outer {
  display: flex;
  width: 100%;
}
.arco-progress-steps-text {
  margin-left: 8px;
  min-width: unset;
}
.arco-progress-steps-text .arco-icon {
  color: var(--color-text-2);
}
.arco-progress-steps-item {
  height: 100%;
  flex: 1;
  background-color: var(--color-fill-3);
  position: relative;
  display: inline-block;
}
.arco-progress-steps-item:not(:last-of-type) {
  margin-right: 3px;
}
.arco-progress-steps-item:last-of-type {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.arco-progress-steps-item:first-of-type {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.arco-progress-steps-item-active {
  background-color: rgb(var(--primary-6));
}
.arco-progress-steps.arco-progress-small {
  width: auto;
}
.arco-progress-steps.arco-progress-small .arco-progress-steps-item {
  width: 2px;
  flex: unset;
  border-radius: 2px;
}
.arco-progress-steps.arco-progress-small .arco-progress-steps-item:not(:last-of-type) {
  margin-right: 3px;
}
.arco-progress-is-warning .arco-progress-line-inner {
  background-color: rgb(var(--warning-6));
}
.arco-progress-is-warning .arco-progress-steps-item-active {
  background-color: rgb(var(--warning-6));
}
.arco-progress-is-warning .arco-progress-line-text .arco-icon,
.arco-progress-is-warning .arco-progress-steps-text .arco-icon {
  color: rgb(var(--warning-6));
}
.arco-progress-is-success .arco-progress-line-inne {
  background-color: rgb(var(--success-6));
}
.arco-progress-is-success .arco-progress-steps-item-active {
  background-color: rgb(var(--success-6));
}
.arco-progress-is-success .arco-progress-line-text .arco-icon,
.arco-progress-is-success .arco-progress-steps-text .arco-icon {
  color: rgb(var(--success-6));
}
.arco-progress-is-error .arco-progress-line-inner {
  background-color: rgb(var(--danger-6));
}
.arco-progress-is-error .arco-progress-steps-item-active {
  background-color: rgb(var(--danger-6));
}
.arco-progress-is-error .arco-progress-line-text .arco-icon,
.arco-progress-is-error .arco-progress-steps-text .arco-icon {
  color: rgb(var(--danger-6));
}
.arco-progress-small .arco-progress-line-text {
  font-size: 12px;
  margin-left: 16px;
}
.arco-progress-small .arco-progress-line-text .arco-icon {
  font-size: 12px;
}
.arco-progress-large .arco-progress-line-text {
  font-size: 16px;
  margin-left: 16px;
}
.arco-progress-large .arco-progress-line-text .arco-icon {
  font-size: 14px;
}
.arco-progress-circle {
  display: inline-block;
}
.arco-progress-circle-wrapper {
  position: relative;
  text-align: center;
  line-height: 1;
  display: inline-block;
  vertical-align: text-bottom;
}
.arco-progress-circle-svg {
  transform: rotate(-90deg);
}
.arco-progress-circle-text {
  font-size: 14px;
}
.arco-progress-circle-text .arco-icon {
  font-size: 16px;
  color: var(--color-text-2);
}
.arco-progress-circle .arco-progress-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-text-3);
  transform: translate(-50%, -50%);
}
.arco-progress-circle-mask {
  stroke: var(--color-fill-3);
}
.arco-progress-circle-path {
  stroke: rgb(var(--primary-6));
  transition: stroke-dashoffset 0.6s cubic-bezier(0, 0, 1, 1) 0s,
    stroke 0.6s cubic-bezier(0, 0, 1, 1);
}
.arco-progress-mini .arco-progress-circle-mask {
  stroke: var(--color-primary-light-3);
}
.arco-progress-mini .arco-progress-circle-path {
  stroke: rgb(var(--primary-6));
}
.arco-progress-mini.arco-progress-is-warning .arco-progress-circle-mask {
  stroke: var(--color-warning-light-3);
}
.arco-progress-mini.arco-progress-is-error .arco-progress-circle-mask {
  stroke: var(--color-danger-light-3);
}
.arco-progress-mini.arco-progress-is-success .arco-progress-circle-mask {
  stroke: var(--color-success-light-3);
}
.arco-progress-mini.arco-progress-is-success .arco-progress-circle-wrapper .arco-icon-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.arco-progress-mini .arco-progress-circle-text {
  position: static;
  top: unset;
  left: unset;
  transform: unset;
}
.arco-progress-small .arco-progress-circle-text {
  font-size: 13px;
}
.arco-progress-small .arco-progress-circle-text .arco-icon {
  font-size: 14px;
}
.arco-progress-large .arco-progress-circle-text {
  font-size: 16px;
}
.arco-progress-large .arco-progress-circle-text .arco-icon {
  font-size: 16px;
}
.arco-progress-is-warning .arco-progress-circle-path {
  stroke: rgb(var(--warning-6));
}
.arco-progress-is-warning .arco-icon {
  color: rgb(var(--warning-6));
}
.arco-progress-is-success .arco-progress-circle-path {
  stroke: rgb(var(--success-6));
}
.arco-progress-is-success .arco-icon {
  color: rgb(var(--success-6));
}
.arco-progress-is-error .arco-progress-circle-path {
  stroke: rgb(var(--danger-6));
}
.arco-progress-is-error .arco-icon {
  color: rgb(var(--danger-6));
}
@keyframes arco-progress-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.arco-radio > input[type='radio'],
.arco-radio-button > input[type='radio'] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.arco-radio > input[type='radio']:focus + .arco-radio-icon-hover::before,
.arco-radio-button > input[type='radio']:focus + .arco-radio-icon-hover::before {
  background-color: var(--color-fill-2);
}
.arco-icon-hover.arco-radio-icon-hover::before {
  width: 24px;
  height: 24px;
}
.arco-radio {
  position: relative;
  cursor: pointer;
  padding-left: 5px;
  display: inline-block;
  font-size: 14px;
  line-height: unset;
  pointer-events: all !important;
}
.arco-radio-text {
  color: var(--color-text-1);
  margin-left: 8px;
}
.arco-radio-mask-wrapper {
  vertical-align: middle;
  top: -0.09em;
  position: relative;
  line-height: 1;
}
.arco-radio-mask {
  display: block;
  border: 2px solid var(--color-neutral-3);
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border-radius: var(--border-radius-circle);
  position: relative;
  line-height: 14px;
}
.arco-radio-mask::after {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  content: '';
  border-radius: var(--border-radius-circle);
  background-color: var(--color-bg-2);
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
}
.arco-radio:hover .arco-radio-mask {
  border-color: var(--color-neutral-3);
}
.arco-radio-checked .arco-radio-mask {
  background-color: rgb(var(--primary-6));
  border-color: rgb(var(--primary-6));
}
.arco-radio-checked .arco-radio-mask::after {
  transform: scale(0.4);
  background-color: var(--color-white);
}
.arco-radio-checked:hover .arco-radio-mask {
  border-color: rgb(var(--primary-6));
}
.arco-radio-disabled {
  cursor: not-allowed;
}
.arco-radio-disabled .arco-radio-icon-hover {
  cursor: not-allowed;
}
.arco-radio-disabled .arco-radio-text {
  color: var(--color-text-4);
}
.arco-radio-disabled .arco-radio-mask {
  border-color: var(--color-neutral-3);
}
.arco-radio-disabled .arco-radio-mask::after {
  background-color: var(--color-fill-2);
}
.arco-radio-disabled:hover .arco-radio-mask {
  border-color: var(--color-neutral-3);
}
.arco-radio-checked.arco-radio-disabled .arco-radio-mask,
.arco-radio-checked.arco-radio-disabled:hover .arco-radio-mask {
  border-color: transparent;
  background-color: var(--color-primary-light-3);
}
.arco-radio-checked.arco-radio-disabled .arco-radio-mask::after {
  background-color: var(--color-fill-2);
}
.arco-radio-checked.arco-radio-disabled .arco-radio-text {
  color: var(--color-text-4);
}
.arco-radio:hover .arco-radio-icon-hover::before {
  background-color: var(--color-fill-2);
}
.arco-radio-group {
  display: inline-block;
  box-sizing: border-box;
}
.arco-radio-group .arco-radio {
  margin-right: 20px;
  position: relative;
}
.arco-radio-group-type-button {
  position: relative;
  z-index: 0;
  padding: 1.5px;
  line-height: 26px;
}
.arco-radio-button {
  display: inline-block;
  position: relative;
  margin: 1.5px;
  border-radius: var(--border-radius-small);
  font-size: 14px;
  line-height: 26px;
  color: var(--color-text-2);
  background-color: transparent;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-radio-button-inner {
  display: block;
  position: relative;
  padding: 0 12px;
}
.arco-radio-button:not(:first-of-type)::before {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  display: block;
  height: 14px;
  width: 1px;
  background-color: var(--color-neutral-3);
  content: '';
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-radio-button:hover::before,
.arco-radio-button:hover + .arco-radio-button::before,
.arco-radio-button.arco-radio-checked::before,
.arco-radio-button.arco-radio-checked + .arco-radio-button::before {
  opacity: 0;
}
.arco-radio-button::after {
  content: ' ';
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  background-color: var(--color-fill-2);
  pointer-events: none;
  z-index: -1;
}
.arco-radio-button:first-of-type::after {
  border-top-left-radius: var(--border-radius-small);
  border-bottom-left-radius: var(--border-radius-small);
}
.arco-radio-button:last-of-type::after {
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
}
.arco-radio-button:hover {
  background-color: var(--color-bg-5);
  color: var(--color-text-1);
}
.arco-radio-button.arco-radio-checked {
  background-color: var(--color-bg-5);
  color: rgb(var(--primary-6));
  font-weight: 500;
}
.arco-radio-button.arco-radio-disabled {
  cursor: not-allowed;
  background-color: transparent;
  color: var(--color-text-4);
}
.arco-radio-button.arco-radio-disabled.arco-radio-checked {
  background-color: var(--color-bg-5);
  color: var(--color-primary-light-3);
}
.arco-radio-size-small {
  line-height: 28px;
}
.arco-radio-size-small.arco-radio-group-type-button,
.arco-radio-size-small .arco-radio-button {
  font-size: 14px;
  line-height: 22px;
}
.arco-radio-size-large {
  line-height: 36px;
}
.arco-radio-size-large.arco-radio-group-type-button,
.arco-radio-size-large .arco-radio-button {
  font-size: 14px;
  line-height: 30px;
}
.arco-radio-size-mini {
  line-height: 24px;
}
.arco-radio-size-mini.arco-radio-group-type-button,
.arco-radio-size-mini .arco-radio-button {
  font-size: 12px;
  line-height: 18px;
}
.arco-radio-group-direction-vertical .arco-radio {
  display: block;
  line-height: 32px;
  margin-right: 0;
}
body[arco-theme='dark'] .arco-radio-button.arco-radio-checked,
body[arco-theme='dark'] .arco-radio-button:not(.arco-radio-disabled):hover {
  background-color: var(--color-fill-3);
}
body[arco-theme='dark'] .arco-radio-button::after {
  background-color: var(--color-bg-3);
}
.arco-rate {
  display: inline-block;
  user-select: none;
}
.arco-rate-disabled {
  cursor: not-allowed;
}
.arco-rate-inner {
  display: flex;
  align-items: center;
  min-height: 32px;
  font-size: 24px;
  line-height: 1;
}
.arco-rate-character {
  position: relative;
  color: var(--color-fill-3);
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-rate-character:not(:last-child) {
  margin-right: 8px;
}
.arco-rate-character-left,
.arco-rate-character-right {
  transition: inherit;
}
.arco-rate-character-left > *,
.arco-rate-character-right > * {
  float: left;
}
.arco-rate-character-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
}
.arco-rate-character-scale {
  animation: arco-rate-scale 0.4s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-rate-character-full .arco-rate-character-right {
  color: rgb(var(--gold-6));
}
.arco-rate-character-half .arco-rate-character-left {
  color: rgb(var(--gold-6));
  opacity: 1;
}
.arco-rate-character-disabled {
  cursor: not-allowed;
}
.arco-rate:not(.arco-rate-readonly):not(.arco-rate-disabled) .arco-rate-character {
  cursor: pointer;
}
.arco-rate:not(.arco-rate-readonly):not(.arco-rate-disabled) .arco-rate-character:hover,
.arco-rate:not(.arco-rate-readonly):not(.arco-rate-disabled) .arco-rate-character:focus {
  transform: scale(1.2);
}
@keyframes arco-rate-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.arco-resizebox {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.arco-resizebox-direction-left,
.arco-resizebox-direction-right,
.arco-resizebox-direction-top,
.arco-resizebox-direction-bottom {
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  box-sizing: border-box;
}
.arco-resizebox-direction-right {
  left: unset;
  right: 0;
}
.arco-resizebox-direction-bottom {
  top: unset;
  bottom: 0;
}
.arco-resizebox-split,
.arco-resizebox-split-group {
  display: flex;
  user-select: auto;
}
.arco-resizebox-split .arco-resizebox-slit-trigger,
.arco-resizebox-split-group .arco-resizebox-slit-trigger {
  flex: 0;
}
.arco-resizebox-split-pane,
.arco-resizebox-split-group-pane {
  overflow: auto;
}
.arco-resizebox-split .second-pane,
.arco-resizebox-split-group .second-pane {
  flex: 1;
}
.arco-resizebox-split-horizontal,
.arco-resizebox-split-group-horizontal {
  flex-direction: row;
}
.arco-resizebox-split-vertical,
.arco-resizebox-split-group-vertical {
  flex-direction: column;
}
.arco-resizebox-split-moving,
.arco-resizebox-split-group-moving {
  user-select: none;
}
.arco-resizebox-trigger-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--color-neutral-3);
}
.arco-resizebox-trigger-icon {
  display: inline-block;
  color: var(--color-text-1);
  font-size: 12px;
}
.arco-resizebox-trigger-prev > svg,
.arco-resizebox-trigger-next > svg {
  cursor: pointer;
}
.arco-resizebox-trigger-vertical {
  height: 100%;
  cursor: col-resize;
}
.arco-resizebox-trigger-vertical.arco-resizebox-trigger-not-resizable {
  cursor: default;
}
.arco-resizebox-trigger-vertical .arco-resizebox-trigger-prev,
.arco-resizebox-trigger-vertical .arco-resizebox-trigger-next {
  height: 18px;
  line-height: 18px;
}
.arco-resizebox-trigger-vertical .arco-resizebox-trigger-icon-wrapper {
  width: 6px;
  flex-direction: column;
}
.arco-resizebox-trigger-vertical .arco-resizebox-trigger-icon-empty {
  height: 18px;
  width: 100%;
}
.arco-resizebox-trigger-horizontal {
  width: 100%;
  cursor: row-resize;
}
.arco-resizebox-trigger-horizontal.arco-resizebox-trigger-not-resizable {
  cursor: default;
}
.arco-resizebox-trigger-horizontal .arco-resizebox-trigger-prev,
.arco-resizebox-trigger-horizontal .arco-resizebox-trigger-next {
  width: 18px;
  text-align: center;
}
.arco-resizebox-trigger-horizontal .arco-resizebox-trigger-icon-wrapper {
  height: 6px;
}
.arco-resizebox-trigger-horizontal .arco-resizebox-trigger-icon-wrapper .arco-icon {
  vertical-align: -1px;
}
.arco-resizebox-trigger-horizontal .arco-resizebox-trigger-icon-empty {
  width: 18px;
  height: 100%;
}
.arco-result {
  width: 100%;
  box-sizing: border-box;
  padding: 32px 32px 24px;
}
.arco-result-is-404,
.arco-result-is-403,
.arco-result-is-500 {
  padding-top: 24px;
}
.arco-result-is-404 .arco-result-icon-tip,
.arco-result-is-403 .arco-result-icon-tip,
.arco-result-is-500 .arco-result-icon-tip {
  height: 92px;
  width: 92px;
  line-height: 92px;
}
.arco-result-icon {
  margin-bottom: 16px;
  text-align: center;
  font-size: 20px;
}
.arco-result-icon-tip {
  display: inline-block;
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  line-height: 45px;
}
.arco-result-icon-custom {
  width: unset;
  height: unset;
  line-height: inherit;
}
.arco-result-icon-custom > .arco-icon {
  font-size: 45px;
  color: inherit;
}
.arco-result-icon-success {
  color: rgb(var(--success-6));
  background-color: var(--color-success-light-1);
}
.arco-result-icon-error {
  color: rgb(var(--danger-6));
  background-color: var(--color-danger-light-1);
}
.arco-result-icon-info {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
}
.arco-result-icon-warning {
  color: rgb(var(--warning-6));
  background-color: var(--color-warning-light-1);
}
.arco-result-title {
  text-align: center;
  line-height: 1.5715;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-result-subtitle {
  text-align: center;
  line-height: 1.5715;
  font-size: 14px;
  color: var(--color-text-2);
}
.arco-result-extra {
  margin-top: 20px;
  text-align: center;
}
.arco-result-content {
  margin-top: 20px;
}
.arco-select .arco-select-view {
  color: var(--color-text-1);
  background-color: transparent;
  border: 1px solid var(--color-neutral-3);
}
.arco-select:hover .arco-select-view {
  background-color: transparent;
  border-color: rgb(var(--primary-6));
}
.arco-select.arco-select-focused .arco-select-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-select .arco-select-suffix-icon,
.arco-select .arco-select-loading-icon,
.arco-select .arco-select-search-icon,
.arco-select .arco-select-clear-icon,
.arco-select .arco-select-arrow-icon,
.arco-select .arco-select-expand-icon {
  color: var(--color-text-2);
}
.arco-select-no-border .arco-select-view {
  border: none !important;
  background: none !important;
}
.arco-select-error .arco-select-view {
  background-color: var(--color-danger-light-1);
  border: 1px solid transparent;
}
.arco-select-error:hover .arco-select-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-select-error.arco-select-focused .arco-select-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-select-no-border .arco-select-view {
  border: none !important;
  background: none !important;
}
.arco-select-disabled .arco-select-view {
  color: var(--color-text-4);
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
}
.arco-select-disabled:hover .arco-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-select-disabled .arco-select-suffix-icon,
.arco-select-disabled .arco-select-loading-icon,
.arco-select-disabled .arco-select-search-icon,
.arco-select-disabled .arco-select-clear-icon,
.arco-select-disabled .arco-select-arrow-icon,
.arco-select-disabled .arco-select-expand-icon {
  color: var(--color-text-4);
}
.arco-select-no-border .arco-select-view {
  border: none !important;
  background: none !important;
}
.arco-select-size-mini.arco-select-multiple .arco-select-view {
  height: auto;
  font-size: 12px;
  padding: 0 3px;
  line-height: 0;
}
.arco-select-size-mini.arco-select-multiple .arco-input-tag-has-placeholder input,
.arco-select-size-mini.arco-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 4px;
}
.arco-select-size-mini.arco-select-multiple .arco-select-suffix {
  padding-right: 4px;
}
.arco-select-size-mini.arco-select-multiple input {
  font-size: 12px;
}
.arco-select-size-mini.arco-select-single .arco-select-view {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 7px;
}
.arco-select-size-mini.arco-select-single input {
  font-size: 12px;
}
.arco-select-size-mini.arco-select-multiple .arco-select-view-with-prefix {
  padding-left: 7px;
}
.arco-select-size-small.arco-select-multiple .arco-select-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-select-size-small.arco-select-multiple .arco-input-tag-has-placeholder input,
.arco-select-size-small.arco-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-select-size-small.arco-select-multiple .arco-select-suffix {
  padding-right: 8px;
}
.arco-select-size-small.arco-select-multiple input {
  font-size: 14px;
}
.arco-select-size-small.arco-select-single .arco-select-view {
  height: 28px;
  line-height: 26px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-select-size-small.arco-select-single input {
  font-size: 14px;
}
.arco-select-size-small.arco-select-multiple .arco-select-view-with-prefix {
  padding-left: 11px;
}
.arco-select-size-default.arco-select-multiple .arco-select-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-select-size-default.arco-select-multiple .arco-input-tag-has-placeholder input,
.arco-select-size-default.arco-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-select-size-default.arco-select-multiple .arco-select-suffix {
  padding-right: 8px;
}
.arco-select-size-default.arco-select-multiple input {
  font-size: 14px;
}
.arco-select-size-default.arco-select-single .arco-select-view {
  height: 32px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-select-size-default.arco-select-single input {
  font-size: 14px;
}
.arco-select-size-default.arco-select-multiple .arco-select-view-with-prefix {
  padding-left: 11px;
}
.arco-select-size-large.arco-select-multiple .arco-select-view {
  height: auto;
  font-size: 16px;
  padding: 0 3px;
  line-height: 0;
}
.arco-select-size-large.arco-select-multiple .arco-input-tag-has-placeholder input,
.arco-select-size-large.arco-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 12px;
}
.arco-select-size-large.arco-select-multiple .arco-select-suffix {
  padding-right: 12px;
}
.arco-select-size-large.arco-select-multiple input {
  font-size: 16px;
}
.arco-select-size-large.arco-select-single .arco-select-view {
  height: 36px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 15px;
}
.arco-select-size-large.arco-select-single input {
  font-size: 16px;
}
.arco-select-size-large.arco-select-multiple .arco-select-view-with-prefix {
  padding-left: 15px;
}
.arco-select {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
.arco-select-view {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius-small);
  outline: none;
  user-select: none;
  text-align: left;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1), padding 0s linear;
}
.arco-select-view input {
  color: inherit;
  cursor: inherit;
}
.arco-select-view input::placeholder {
  color: var(--color-text-3);
}
.arco-select-multiple,
.arco-select-show-search {
  cursor: text;
}
.arco-select-disabled {
  cursor: not-allowed;
}
.arco-select-disabled .arco-select-view input::placeholder {
  color: var(--color-text-4);
}
.arco-select-single .arco-select-view-input {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-select-single .arco-select-view-value {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-select-single .arco-select-view-value::after {
  content: '.';
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.arco-select-single .arco-select-view .arco-select-hidden {
  width: 0 !important;
  position: absolute;
}
.arco-select-multiple {
  vertical-align: top;
}
.arco-select-multiple .arco-select-view {
  padding: 0 4px;
  line-height: 0;
}
.arco-select-multiple .arco-select-view-with-prefix {
  padding-left: 12px;
}
.arco-select-multiple .arco-input-tag {
  flex: 1;
  padding: 0;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  overflow: hidden;
}
.arco-select-multiple .arco-tag {
  max-width: 100%;
}
.arco-select-multiple:not(.arco-select-focused) .arco-input-tag input:not(:first-child)[value=''] {
  width: 0 !important;
  position: absolute;
}
.arco-select-prefix {
  display: flex;
  align-items: center;
  margin-right: 12px;
  white-space: nowrap;
  color: var(--color-text-2);
}
.arco-select-suffix {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.arco-select-suffix-icon,
.arco-select-search-icon,
.arco-select-loading-icon,
.arco-select-expand-icon,
.arco-select-clear-icon {
  font-size: 12px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-select-arrow-icon {
  font-size: 12px;
}
.arco-select-open .arco-select-arrow-icon svg {
  transform: rotateZ(180deg);
}
.arco-select .arco-select-clear-icon {
  display: none;
  cursor: pointer;
}
.arco-select .arco-select-clear-icon > svg {
  position: relative;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-select:hover .arco-select-clear-icon {
  display: block;
}
.arco-select:hover .arco-select-clear-icon ~ * {
  display: none;
}
.arco-select-popup {
  top: 4px;
  box-sizing: border-box;
  padding: 4px 0;
  border: 1px solid var(--color-fill-3);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.arco-select-popup-hidden {
  display: none;
}
.arco-select-popup .arco-select-popup-inner {
  width: 100%;
  max-height: 200px;
  list-style: none;
}
.arco-select-popup .arco-select-option {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-1);
  background-color: var(--color-bg-popup);
}
.arco-select-popup .arco-select-option-selected {
  color: var(--color-text-1);
  background-color: var(--color-bg-popup);
}
.arco-select-popup .arco-select-option-hover {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}
.arco-select-popup .arco-select-option-disabled {
  color: var(--color-text-4);
  background-color: var(--color-bg-popup);
}
.arco-select-popup .arco-select-option-disabled {
  cursor: not-allowed;
}
.arco-select-popup .arco-select-option-selected {
  font-weight: 500;
}
.arco-select-popup .arco-select-option-empty {
  height: 36px;
}
.arco-select-option-wrapper {
  display: flex;
  align-items: center;
  padding: 0 7px;
}
.arco-select-option-wrapper .arco-select-checkbox {
  padding: 0 5px;
}
.arco-select-option-wrapper .arco-select-checkbox input {
  display: none;
}
.arco-select-option-wrapper .arco-select-option {
  flex: 1;
  margin-left: 1px;
  padding: 0 4px;
  border-radius: var(--border-radius-small);
}
.arco-select-group-title {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 12px 0 12px;
  line-height: 20px;
  font-size: 12px;
  color: var(--color-text-3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-select-group-title:first-child {
  padding-top: 4px;
}
.arco-select-highlight {
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-skeleton {
  display: flex;
}
.arco-skeleton-header {
  display: flex;
}
.arco-skeleton-header .arco-skeleton-image {
  background-color: var(--color-fill-2);
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-small);
}
.arco-skeleton-header .arco-skeleton-image-circle {
  border-radius: 50%;
}
.arco-skeleton-header .arco-skeleton-image-small {
  width: 36px;
  height: 36px;
}
.arco-skeleton-header .arco-skeleton-image-large {
  width: 60px;
  height: 60px;
}
.arco-skeleton-header .arco-skeleton-image-left {
  margin-right: 16px;
}
.arco-skeleton-header .arco-skeleton-image-right {
  margin-left: 16px;
}
.arco-skeleton-content {
  flex-grow: 1;
  overflow: hidden;
}
.arco-skeleton-content .arco-skeleton-text {
  list-style: none;
  padding: 0;
  margin: 0;
}
.arco-skeleton-content .arco-skeleton-text-row {
  background-color: var(--color-fill-2);
  height: 16px;
}
.arco-skeleton-content .arco-skeleton-text-row:not(:last-child) {
  margin-bottom: 16px;
}
.arco-skeleton-animate .arco-skeleton-image,
.arco-skeleton-animate .arco-skeleton-text > li {
  background: linear-gradient(
    90deg,
    var(--color-fill-2) 25%,
    var(--color-fill-3) 37%,
    var(--color-fill-2) 63%
  );
  background-size: 400% 100%;
  animation: arco-skeleton-circle 1.5s cubic-bezier(0, 0, 1, 1) infinite;
}
@keyframes arco-skeleton-circle {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.arco-slider {
  width: 100%;
  display: inline-block;
}
.arco-slider-wrapper {
  display: flex;
  align-items: center;
}
.arco-slider-vertical {
  display: inline-block;
  height: auto;
  width: auto;
  min-width: 22px;
}
.arco-slider-vertical .arco-slider-wrapper {
  flex-direction: column;
}
.arco-slider-with-marks {
  margin-bottom: 24px;
  padding: 0 20px;
}
.arco-slider-vertical.arco-slider-with-marks {
  margin-bottom: 0;
  padding: 0;
}
.arco-slider-road {
  width: 100%;
  height: 12px;
  cursor: pointer;
  flex: 1;
  position: relative;
}
.arco-slider-road::before {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--color-fill-3);
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.arco-slider-road.arco-slider-road-vertical {
  width: 12px;
  max-width: 12px;
  height: 100%;
  min-height: 200px;
  margin-bottom: 12px / 2;
  margin-top: 12px / 2;
  margin-right: 0;
  transform: translateY(0);
}
.arco-slider-road.arco-slider-road-vertical::before {
  width: 2px;
  height: 100%;
  top: unset;
  left: 50%;
  transform: translateX(-50%);
}
.arco-slider-road.arco-slider-road-disabled::before {
  background-color: var(--color-fill-2);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-bar {
  background-color: var(--color-fill-3);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-button {
  cursor: not-allowed;
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-button::after {
  border-color: var(--color-fill-3);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-dots .arco-slider-dot {
  border-color: var(--color-fill-2);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-dots .arco-slider-dot-active {
  border-color: var(--color-fill-3);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-ticks .arco-slider-tick {
  background: var(--color-fill-2);
}
.arco-slider-road.arco-slider-road-disabled .arco-slider-ticks .arco-slider-tick-active {
  background: var(--color-fill-3);
}
.arco-slider-bar {
  position: absolute;
  height: 2px;
  background-color: rgb(var(--primary-6));
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.arco-slider-road-vertical .arco-slider-bar {
  width: 2px;
  height: unset;
  top: unset;
  left: 50%;
  transform: translateX(-50%);
}
.arco-slider-button {
  position: absolute;
  height: 12px;
  width: 12px;
  top: 0;
  left: 0;
  transform: translateX(-50%);
}
.arco-slider-button::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: var(--color-bg-2);
  border: 2px solid rgb(var(--primary-6));
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  transition: all 0.3s cubic-bezier(0.3, 1.3, 0.3, 1);
  top: 0;
}
.arco-slider-button.arco-slider-button-active::after,
.arco-slider-button:hover::after {
  transform: scale(1.16666667);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.arco-slider-road-vertical .arco-slider-button {
  top: unset;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
}
.arco-slider-reverse .arco-slider-button {
  transform: translateX(50%);
  left: unset;
  right: 0;
}
.arco-slider-reverse .arco-slider-road-vertical .arco-slider-button {
  transform: translateY(-50%);
}
.arco-slider-marks {
  position: absolute;
  top: 12px;
  width: 100%;
}
.arco-slider-marks-text {
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  color: var(--color-text-3);
}
.arco-slider-road-vertical .arco-slider-marks {
  height: 100%;
  left: 15px;
  top: 0;
}
.arco-slider-road-vertical .arco-slider-marks-text {
  transform: translateY(50%);
}
.arco-slider-reverse .arco-slider-marks-text {
  transform: translateX(50%);
}
.arco-slider-reverse .arco-slider-road-vertical .arco-slider-marks-text {
  transform: translateY(-50%);
}
.arco-slider-dots {
  height: 100%;
}
.arco-slider-dots .arco-slider-dot-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.arco-slider-road-vertical .arco-slider-dots .arco-slider-dot-wrapper {
  top: unset;
  left: 50%;
  transform: translate(-50%, 50%);
}
.arco-slider-reverse .arco-slider-dots .arco-slider-dot-wrapper {
  transform: translate(50%, -50%);
}
.arco-slider-reverse .arco-slider-road-vertical .arco-slider-dots .arco-slider-dot-wrapper {
  transform: translate(-50%, -50%);
}
.arco-slider-dots .arco-slider-dot-wrapper .arco-slider-dot {
  background-color: var(--color-bg-2);
  box-sizing: border-box;
  border: 2px solid var(--color-fill-3);
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.arco-slider-dots .arco-slider-dot-wrapper .arco-slider-dot-active {
  border-color: rgb(var(--primary-6));
}
.arco-slider-ticks .arco-slider-tick {
  position: absolute;
  width: 1px;
  height: 3px;
  background: var(--color-fill-3);
  top: 50%;
  transform: translate(-50%, -100%);
  margin-top: -1px;
}
.arco-slider-ticks .arco-slider-tick-active {
  background: rgb(var(--primary-6));
}
.arco-slider-vertical .arco-slider-ticks .arco-slider-tick {
  width: 3px;
  height: 1px;
  top: unset;
  margin-top: unset;
  left: 50%;
  transform: translate(1px, 50%);
}
.arco-slider-reverse .arco-slider-ticks .arco-slider-tick {
  transform: translate(50%, -100%);
}
.arco-slider-vertical.arco-slider-reverse .arco-slider-ticks .arco-slider-tick {
  transform: translate(1px, -50%);
}
.arco-slider-input {
  display: flex;
  margin-left: 20px;
}
.arco-slider-vertical .arco-slider-input {
  margin-left: 0;
}
.arco-slider-input > .arco-input-number {
  width: 60px;
  height: 32px;
  line-height: normal;
  overflow: visible;
}
.arco-slider-input > .arco-input-number input {
  text-align: center;
}
.arco-slider-input-range {
  width: 20px;
  line-height: 32px;
  height: 32px;
  text-align: center;
}
.arco-slider-input-range-content {
  display: inline-block;
  width: 8px;
  height: 2px;
  background: rgb(var(--gray-6));
  transform: translate(0, -100%);
}
.arco-space {
  display: inline-flex;
}
.arco-space-vertical {
  flex-direction: column;
}
.arco-space-align-baseline {
  align-items: baseline;
}
.arco-space-align-start {
  align-items: flex-start;
}
.arco-space-align-end {
  align-items: flex-end;
}
.arco-space-align-center {
  align-items: center;
}
.arco-space-wrap {
  flex-wrap: wrap;
}
.arco-spin {
  display: inline-block;
}
.arco-spin-with-tip {
  text-align: center;
}
.arco-spin-icon {
  color: rgb(var(--primary-6));
  font-size: 20px;
}
.arco-spin-tip {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(var(--primary-6));
}
.arco-spin-loading-layer {
  text-align: center;
  user-select: none;
}
.arco-spin-children {
  position: relative;
}
.arco-spin-children::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-spin-layer-bg);
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
  pointer-events: none;
  z-index: 1;
}
.arco-spin-loading {
  position: relative;
  user-select: none;
}
.arco-spin-loading .arco-spin-loading-layer-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.arco-spin-loading .arco-spin-children::after {
  opacity: 1;
  pointer-events: auto;
}
.arco-spin-dot {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 8px;
  height: 8px;
  background-color: rgb(var(--primary-6));
  border-radius: var(--border-radius-circle);
  animation: arco-dot-loading 2s cubic-bezier(0, 0, 1, 1) infinite forwards;
}
.arco-spin-dot:nth-child(2) {
  background-color: rgb(var(--primary-5));
  animation-delay: 400ms;
}
.arco-spin-dot:nth-child(3) {
  background-color: rgb(var(--primary-4));
  animation-delay: 800ms;
}
.arco-spin-dot:nth-child(4) {
  background-color: rgb(var(--primary-4));
  animation-delay: 1200ms;
}
.arco-spin-dot:nth-child(5) {
  background-color: rgb(var(--primary-2));
  animation-delay: 1600ms;
}
.arco-spin-dot-list {
  display: inline-block;
  transform-style: preserve-3d;
  perspective: 200px;
  width: 56px;
  position: relative;
  height: 8px;
}
@keyframes arco-dot-loading {
  0% {
    transform: translate3D(-48.621%, 0, -0.985px) scale(0.511);
  }
  2.778% {
    transform: translate3D(-95.766%, 0, -0.94px) scale(0.545);
  }
  5.556% {
    transform: translate3D(-140%, 0, -0.866px) scale(0.6);
  }
  8.333% {
    transform: translate3D(-179.981%, 0, -0.766px) scale(0.675);
  }
  11.111% {
    transform: translate3D(-214.492%, 0, -0.643px) scale(0.768);
  }
  13.889% {
    transform: translate3D(-242.487%, 0, -0.5px) scale(0.875);
  }
  16.667% {
    transform: translate3D(-263.114%, 0, -0.342px) scale(0.993);
  }
  19.444% {
    transform: translate3D(-275.746%, 0, -0.174px) scale(1.12);
  }
  22.222% {
    transform: translate3D(-280%, 0, 0px) scale(1.25);
  }
  25% {
    transform: translate3D(-275.746%, 0, 0.174px) scale(1.38);
  }
  27.778% {
    transform: translate3D(-263.114%, 0, 0.342px) scale(1.507);
  }
  30.556% {
    transform: translate3D(-242.487%, 0, 0.5px) scale(1.625);
  }
  33.333% {
    transform: translate3D(-214.492%, 0, 0.643px) scale(1.732);
  }
  36.111% {
    transform: translate3D(-179.981%, 0, 0.766px) scale(1.825);
  }
  38.889% {
    transform: translate3D(-140%, 0, 0.866px) scale(1.9);
  }
  41.667% {
    transform: translate3D(-95.766%, 0, 0.94px) scale(1.955);
  }
  44.444% {
    transform: translate3D(-48.621%, 0, 0.985px) scale(1.989);
  }
  47.222% {
    transform: translate3D(0%, 0, 1px) scale(2);
  }
  50% {
    transform: translate3D(48.621%, 0, 0.985px) scale(1.989);
  }
  52.778% {
    transform: translate3D(95.766%, 0, 0.94px) scale(1.955);
  }
  55.556% {
    transform: translate3D(140%, 0, 0.866px) scale(1.9);
  }
  58.333% {
    transform: translate3D(179.981%, 0, 0.766px) scale(1.825);
  }
  61.111% {
    transform: translate3D(214.492%, 0, 0.643px) scale(1.732);
  }
  63.889% {
    transform: translate3D(242.487%, 0, 0.5px) scale(1.625);
  }
  66.667% {
    transform: translate3D(263.114%, 0, 0.342px) scale(1.507);
  }
  69.444% {
    transform: translate3D(275.746%, 0, 0.174px) scale(1.38);
  }
  72.222% {
    transform: translate3D(280%, 0, 0px) scale(1.25);
  }
  75% {
    transform: translate3D(275.746%, 0, -0.174px) scale(1.12);
  }
  77.778% {
    transform: translate3D(263.114%, 0, -0.342px) scale(0.993);
  }
  80.556% {
    transform: translate3D(242.487%, 0, -0.5px) scale(0.875);
  }
  83.333% {
    transform: translate3D(214.492%, 0, -0.643px) scale(0.768);
  }
  86.111% {
    transform: translate3D(179.981%, 0, -0.766px) scale(0.675);
  }
  88.889% {
    transform: translate3D(140%, 0, -0.866px) scale(0.6);
  }
  91.667% {
    transform: translate3D(95.766%, 0, -0.94px) scale(0.545);
  }
  94.444% {
    transform: translate3D(48.621%, 0, -0.985px) scale(0.511);
  }
  97.222% {
    transform: translate3D(0%, 0, -1px) scale(0.5);
  }
}
.arco-statistic {
  display: inline-block;
  line-height: 1.5715;
  color: var(--color-text-2);
}
.arco-statistic-title {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--color-text-2);
}
.arco-statistic-content .arco-statistic-value {
  white-space: nowrap;
  font-size: 26px;
  font-weight: 500;
  color: var(--color-text-1);
}
.arco-statistic-content .arco-statistic-value-int {
  white-space: nowrap;
}
.arco-statistic-content .arco-statistic-value-decimal {
  font-size: 26px;
  display: inline-block;
}
.arco-statistic-content .arco-statistic-value-suffix {
  font-size: 14px;
}
.arco-statistic-content .arco-statistic-value-prefix {
  font-size: 14px;
}
.arco-statistic-extra {
  margin-top: 8px;
  color: var(--color-text-2);
}
.arco-steps-item {
  flex: 1;
  white-space: nowrap;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  text-align: left;
}
.arco-steps-item:last-child {
  flex: none;
  margin-right: 0;
}
.arco-steps-item-active .arco-steps-item-title {
  font-weight: 500;
}
.arco-steps-item-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  font-size: 16px;
  font-weight: 500;
}
.arco-steps-icon {
  width: 28px;
  line-height: 26px;
  height: 28px;
  border-radius: var(--border-radius-circle);
  text-align: center;
  box-sizing: border-box;
}
.arco-steps-item-wait .arco-steps-icon {
  border: 1px solid transparent;
  color: var(--color-text-2);
  background-color: var(--color-fill-2);
}
.arco-steps-item-process .arco-steps-icon {
  border: 1px solid transparent;
  color: var(--color-white);
  background-color: rgb(var(--primary-6));
}
.arco-steps-item-finish .arco-steps-icon {
  border: 1px solid transparent;
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
}
.arco-steps-item-error .arco-steps-icon {
  border: 1px solid transparent;
  color: var(--color-white);
  background-color: rgb(var(--danger-6));
}
.arco-steps-item-title {
  white-space: nowrap;
  color: var(--color-text-2);
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 28px;
  padding-right: 12px;
}
.arco-steps-item-wait .arco-steps-item-title {
  color: var(--color-text-2);
}
.arco-steps-item-process .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-item-finish .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-item-error .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-item-content {
  display: inline-block;
}
.arco-steps-item-description {
  white-space: normal;
  color: var(--color-text-3);
  font-size: 12px;
  margin-top: 2px;
  max-width: 140px;
}
.arco-steps-item-wait .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-item-process .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-item-finish .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-item-error .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-label-horizontal .arco-steps-item:not(:last-child) .arco-steps-item-title::after {
  content: '';
  display: block;
  position: absolute;
  width: 5000px;
  height: 1px;
  left: 100%;
  top: 13.5px;
  background-color: var(--color-neutral-3);
  box-sizing: border-box;
}
.arco-steps-label-horizontal
  .arco-steps-item.arco-steps-item-process
  .arco-steps-item-title::after {
  background-color: var(--color-neutral-3);
}
.arco-steps-label-horizontal .arco-steps-item.arco-steps-item-finish .arco-steps-item-title::after {
  background-color: rgb(var(--primary-6));
}
.arco-steps-label-horizontal
  .arco-steps-item.arco-steps-item-next-error
  .arco-steps-item-title::after {
  background-color: rgb(var(--danger-6));
}
.arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail {
  position: absolute;
  width: 1px;
  top: 34px;
  bottom: 6px;
  left: 13.5px;
  box-sizing: border-box;
  padding: 0;
  height: unset;
}
.arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-neutral-3);
}
.arco-steps-item:not(:last-child) .arco-steps-item-tail {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 13.5px;
  box-sizing: border-box;
}
.arco-steps-item:not(:last-child) .arco-steps-item-tail::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-neutral-3);
}
.arco-steps-item:not(:last-child).arco-steps-item-finish .arco-steps-item-tail::after {
  background-color: rgb(var(--primary-6));
}
.arco-steps-item:not(:last-child).arco-steps-item-next-error .arco-steps-item-tail::after {
  background-color: rgb(var(--danger-6));
}
.arco-steps-size-small.arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail {
  left: 11.5px;
  top: 30px;
}
.arco-steps-size-small:not(.arco-steps-vertical):not(.arco-steps-mode-dot)
  .arco-steps-item:not(:last-child)
  .arco-steps-item-tail {
  top: 11.5px;
}
.arco-steps-size-small .arco-steps-item-icon {
  font-size: 14px;
}
.arco-steps-size-small .arco-steps-item-title {
  font-size: 14px;
  line-height: 24px;
}
.arco-steps-size-small .arco-steps-item-description {
  font-size: 12px;
}
.arco-steps-size-small .arco-steps-icon {
  width: 24px;
  height: 24px;
  line-height: 22px;
  font-size: 14px;
}
.arco-steps-size-small.arco-steps-label-horizontal
  .arco-steps-item:not(:last-child)
  .arco-steps-item-title::after {
  top: 11.5px;
}
.arco-steps-label-vertical .arco-steps-item {
  overflow: visible;
}
.arco-steps-label-vertical .arco-steps-item-title {
  padding-right: 0;
  margin-top: 2px;
}
.arco-steps-label-vertical .arco-steps-item-icon {
  margin-left: 56px;
}
.arco-steps-label-vertical .arco-steps-item-tail {
  left: 96px;
  padding-right: 40px;
}
.arco-steps-label-vertical.arco-steps-size-small .arco-steps-item-icon {
  margin-left: 58px;
}
.arco-steps-label-vertical.arco-steps-size-small .arco-steps-item-tail {
  left: 94px;
  padding-right: 36px;
}
.arco-steps-mode-dot .arco-steps-item {
  flex: 1;
  white-space: nowrap;
  margin-right: 16px;
  position: relative;
  text-align: left;
  overflow: visible;
}
.arco-steps-mode-dot .arco-steps-item:last-child {
  flex: none;
  margin-right: 0;
}
.arco-steps-mode-dot .arco-steps-item-active .arco-steps-item-title {
  font-weight: 500;
}
.arco-steps-mode-dot .arco-steps-item-icon {
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: var(--border-radius-circle);
  vertical-align: top;
}
.arco-steps-mode-dot .arco-steps-item-active .arco-steps-item-icon {
  width: 10px;
  height: 10px;
}
.arco-steps-mode-dot .arco-steps-item-wait .arco-steps-item-icon {
  border-color: var(--color-fill-4);
  background-color: var(--color-fill-4);
}
.arco-steps-mode-dot .arco-steps-item-process .arco-steps-item-icon {
  border-color: rgb(var(--primary-6));
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-dot .arco-steps-item-finish .arco-steps-item-icon {
  border-color: rgb(var(--primary-6));
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-dot .arco-steps-item-error .arco-steps-item-icon {
  border-color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-6));
}
.arco-steps-mode-dot.arco-steps-horizontal .arco-steps-item-icon {
  margin-left: 66px;
}
.arco-steps-mode-dot.arco-steps-horizontal .arco-steps-item-active .arco-steps-item-icon {
  margin-left: 65px;
  margin-top: -1px;
}
.arco-steps-mode-dot .arco-steps-item-content {
  display: inline-block;
}
.arco-steps-mode-dot .arco-steps-item-title {
  position: relative;
  display: inline-block;
  font-size: 16px;
  margin-top: 4px;
}
.arco-steps-mode-dot .arco-steps-item-wait .arco-steps-item-title {
  color: var(--color-text-2);
}
.arco-steps-mode-dot .arco-steps-item-process .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-mode-dot .arco-steps-item-finish .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-mode-dot .arco-steps-item-error .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-mode-dot .arco-steps-item-description {
  white-space: normal;
  font-size: 12px;
  margin-top: 4px;
}
.arco-steps-mode-dot .arco-steps-item-wait .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-dot .arco-steps-item-process .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-dot .arco-steps-item-finish .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-dot .arco-steps-item-error .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-dot .arco-steps-item:not(:last-child) .arco-steps-item-tail {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 3.5px;
  left: 78px;
  box-sizing: border-box;
  background-color: var(--color-neutral-3);
}
.arco-steps-mode-dot .arco-steps-item:not(:last-child) .arco-steps-item-tail::after {
  display: none;
}
.arco-steps-mode-dot
  .arco-steps-item:not(:last-child).arco-steps-item-process
  .arco-steps-item-tail {
  background-color: var(--color-neutral-3);
}
.arco-steps-mode-dot
  .arco-steps-item:not(:last-child).arco-steps-item-finish
  .arco-steps-item-tail {
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-dot
  .arco-steps-item:not(:last-child).arco-steps-item-next-error
  .arco-steps-item-tail {
  background-color: rgb(var(--danger-6));
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-icon {
  margin-right: 16px;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-content {
  overflow: hidden;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-title {
  margin-top: -2px;
}
.arco-steps-mode-dot.arco-steps-vertical.arco-steps-size-small .arco-steps-item-title {
  margin-top: 0;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-active .arco-steps-item-title {
  margin-top: -3px;
}
.arco-steps-mode-dot.arco-steps-vertical.arco-steps-size-small
  .arco-steps-item-active
  .arco-steps-item-title {
  margin-top: -1px;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-description {
  margin-top: 4px;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail {
  position: absolute;
  width: 1px;
  transform: translateX(-50%);
  top: 20px;
  bottom: -4px;
  left: 4px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0;
  height: unset;
}
.arco-steps-mode-dot.arco-steps-vertical
  .arco-steps-item:not(:last-child)
  .arco-steps-item-tail::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-neutral-3);
}
.arco-steps-mode-dot.arco-steps-vertical
  .arco-steps-item:not(:last-child).arco-steps-item-process
  .arco-steps-item-tail::after {
  background-color: var(--color-neutral-3);
}
.arco-steps-mode-dot.arco-steps-vertical
  .arco-steps-item:not(:last-child).arco-steps-item-finish
  .arco-steps-item-tail::after {
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-dot.arco-steps-vertical
  .arco-steps-item:not(:last-child).arco-steps-item-next-error
  .arco-steps-item-tail::after {
  background-color: rgb(var(--danger-6));
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item .arco-steps-item-icon {
  margin-top: 8px;
}
.arco-steps-mode-dot.arco-steps-vertical .arco-steps-item-active .arco-steps-item-icon {
  margin-top: 6px;
  margin-left: -1px;
}
.arco-steps-mode-arrow .arco-steps-item {
  position: relative;
  display: flex;
  flex: 1;
  white-space: nowrap;
  height: 72px;
  align-items: center;
  overflow: visible;
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child) {
  margin-right: 4px;
}
.arco-steps-mode-arrow .arco-steps-item-wait {
  background-color: var(--color-fill-1);
}
.arco-steps-mode-arrow .arco-steps-item-process {
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-arrow .arco-steps-item-finish {
  background-color: var(--color-primary-light-1);
}
.arco-steps-mode-arrow .arco-steps-item-error {
  background-color: rgb(var(--danger-6));
}
.arco-steps-mode-arrow .arco-steps-item-content {
  display: inline-block;
  box-sizing: border-box;
}
.arco-steps-mode-arrow .arco-steps-item:first-child .arco-steps-item-content {
  padding-left: 16px;
}
.arco-steps-mode-arrow .arco-steps-item:not(:first-child) .arco-steps-item-content {
  padding-left: 52px;
}
.arco-steps-mode-arrow .arco-steps-item-title {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-size: 16px;
}
.arco-steps-mode-arrow .arco-steps-item-title::after {
  display: none !important;
}
.arco-steps-mode-arrow .arco-steps-item-wait .arco-steps-item-title {
  color: var(--color-text-2);
}
.arco-steps-mode-arrow .arco-steps-item-process .arco-steps-item-title {
  color: var(--color-white);
}
.arco-steps-mode-arrow .arco-steps-item-finish .arco-steps-item-title {
  color: var(--color-text-1);
}
.arco-steps-mode-arrow .arco-steps-item-error .arco-steps-item-title {
  color: var(--color-white);
}
.arco-steps-mode-arrow .arco-steps-item-active .arco-steps-item-title {
  font-weight: 500;
}
.arco-steps-mode-arrow .arco-steps-item-description {
  white-space: nowrap;
  font-size: 12px;
  margin-top: 0;
  max-width: none;
}
.arco-steps-mode-arrow .arco-steps-item-wait .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-arrow .arco-steps-item-process .arco-steps-item-description {
  color: var(--color-white);
}
.arco-steps-mode-arrow .arco-steps-item-finish .arco-steps-item-description {
  color: var(--color-text-3);
}
.arco-steps-mode-arrow .arco-steps-item-error .arco-steps-item-description {
  color: var(--color-white);
}
.arco-steps-mode-arrow .arco-steps-item:not(:first-child)::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 36px solid transparent;
  border-bottom: 36px solid transparent;
  border-left: 36px solid var(--color-bg-2);
  left: 0;
  top: 0;
  z-index: 1;
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 36px solid transparent;
  border-bottom: 36px solid transparent;
  right: -36px;
  top: 0;
  z-index: 2;
  clear: both;
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child).arco-steps-item-wait::after {
  border-left: 36px solid var(--color-fill-1);
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child).arco-steps-item-process::after {
  border-left: 36px solid rgb(var(--primary-6));
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child).arco-steps-item-error::after {
  border-left: 36px solid rgb(var(--danger-6));
}
.arco-steps-mode-arrow .arco-steps-item:not(:last-child).arco-steps-item-finish::after {
  border-left: 36px solid var(--color-primary-light-1);
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item {
  height: 40px;
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item-title {
  font-size: 14px;
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item-description {
  display: none;
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item:not(:first-child)::before {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--color-bg-2);
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item:not(:last-child)::after {
  right: -20px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--color-fill-1);
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item:first-child .arco-steps-item-content {
  padding-left: 20px;
}
.arco-steps-mode-arrow.arco-steps-size-small
  .arco-steps-item:not(:first-child)
  .arco-steps-item-content {
  padding-left: 40px;
}
.arco-steps-mode-arrow.arco-steps-size-small .arco-steps-item-error:not(:last-child)::after {
  border-left: 20px solid rgb(var(--danger-6));
}
.arco-steps-mode-arrow.arco-steps-size-small
  .arco-steps-item:not(:last-child).arco-steps-item-wait::after {
  border-left: 20px solid var(--color-fill-1);
}
.arco-steps-mode-arrow.arco-steps-size-small
  .arco-steps-item:not(:last-child).arco-steps-item-process::after {
  border-left: 20px solid rgb(var(--primary-6));
}
.arco-steps-mode-arrow.arco-steps-size-small
  .arco-steps-item:not(:last-child).arco-steps-item-finish::after {
  border-left: 20px solid var(--color-primary-light-1);
}
.arco-steps-mode-navigation.arco-steps-label-horizontal
  .arco-steps-item:not(:last-child)
  .arco-steps-item-title::after {
  display: none;
}
.arco-steps-mode-navigation .arco-steps-item {
  padding-left: 20px;
  padding-right: 10px;
  margin-right: 32px;
}
.arco-steps-mode-navigation .arco-steps-item:last-child {
  flex: 1;
}
.arco-steps-mode-navigation .arco-steps-item-content {
  margin-bottom: 20px;
}
.arco-steps-mode-navigation .arco-steps-item-description {
  padding-right: 20px;
}
.arco-steps-mode-navigation .arco-steps-item-active::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  left: 0;
  right: 30px;
  bottom: 0;
  background-color: rgb(var(--primary-6));
}
.arco-steps-mode-navigation .arco-steps-item-active:last-child::after {
  width: 100%;
}
.arco-steps-mode-navigation .arco-steps-item:not(:last-child) .arco-steps-item-content::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 2px solid var(--color-text-4);
  background-color: var(--color-bg-2);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
}
.arco-steps {
  display: flex;
}
.arco-steps-change-onclick .arco-steps-item-title,
.arco-steps-change-onclick .arco-steps-item-description {
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-steps-change-onclick
  .arco-steps-item:not(.arco-steps-item-active):not(.arco-steps-item-disabled) {
  cursor: pointer;
}
.arco-steps-change-onclick
  .arco-steps-item:not(.arco-steps-item-active):not(.arco-steps-item-disabled):hover
  .arco-steps-item-content
  .arco-steps-item-title,
.arco-steps-change-onclick
  .arco-steps-item:not(.arco-steps-item-active):not(.arco-steps-item-disabled):hover
  .arco-steps-item-content
  .arco-steps-item-description {
  color: rgb(var(--primary-6));
}
.arco-steps-lineless .arco-steps-item-title::after {
  display: none !important;
}
.arco-steps-vertical {
  flex-direction: column;
}
.arco-steps-vertical .arco-steps-item:not(:last-child) {
  min-height: 90px;
}
.arco-steps-vertical .arco-steps-item-title::after {
  display: none !important;
}
.arco-steps-vertical .arco-steps-item-description {
  max-width: none;
}
.arco-steps-label-vertical .arco-steps-item-content {
  display: block;
  text-align: center;
  width: 140px;
}
.arco-steps-label-vertical .arco-steps-item-description {
  max-width: none;
}
.switchSlideText-enter,
.switchSlideText-appear {
  left: -100% !important;
}
.switchSlideText-enter-active,
.switchSlideText-appear-active {
  left: 8px !important;
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.switchSlideText-exit {
  left: 100% !important;
}
.switchSlideText-exit-active {
  left: 26px !important;
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-switch {
  position: relative;
  outline: none;
  height: 24px;
  line-height: 24px;
  min-width: 40px;
  background-color: var(--color-fill-4);
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
  padding: 0;
  box-sizing: border-box;
  vertical-align: middle;
  overflow: hidden;
}
.arco-switch-dot {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-bg-white);
  color: var(--color-neutral-3);
  font-size: 12px;
  transition: all 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-switch-checked {
  background-color: rgb(var(--primary-6));
}
.arco-switch-checked .arco-switch-dot {
  color: rgb(var(--primary-6));
  left: 100%;
  transform: translateX(-100%);
  margin-left: -4px;
}
.arco-switch[disabled] .arco-switch-dot {
  color: var(--color-fill-2);
}
.arco-switch[disabled].arco-switch-checked .arco-switch-dot {
  color: var(--color-primary-light-3);
}
.arco-switch[disabled] .arco-switch-dot {
  color: var(--color-fill-2);
}
.arco-switch[disabled].arco-switch-checked .arco-switch-dot {
  color: var(--color-primary-light-3);
}
.arco-switch-text-holder {
  opacity: 0;
  font-size: 12px;
  margin: 0 8px 0 26px;
}
.arco-switch-text {
  position: absolute;
  color: var(--color-white);
  font-size: 12px;
  top: 0;
  left: 26px;
}
.arco-switch-checked .arco-switch-text-holder {
  margin: 0 26px 0 8px;
}
.arco-switch-checked .arco-switch-text {
  left: 8px;
  color: var(--color-white);
}
.arco-switch[disabled] {
  cursor: not-allowed;
  background-color: var(--color-fill-2);
}
.arco-switch[disabled] .arco-switch-text {
  color: var(--color-white);
}
.arco-switch[disabled].arco-switch-checked {
  background-color: var(--color-primary-light-3);
}
.arco-switch[disabled].arco-switch-checked .arco-switch-text {
  color: var(--color-white);
}
.arco-switch-loading {
  background-color: var(--color-fill-2);
}
.arco-switch-loading .arco-switch-dot {
  color: var(--color-neutral-3);
}
.arco-switch-loading .arco-switch-text {
  color: var(--color-white);
}
.arco-switch-loading.arco-switch-checked {
  background-color: var(--color-primary-light-3);
}
.arco-switch-loading.arco-switch-checked .arco-switch-dot {
  color: var(--color-primary-light-3);
}
.arco-switch-loading.arco-switch-checked .arco-switch-text {
  color: var(--color-primary-light-1);
}
.arco-switch-small {
  height: 16px;
  line-height: 16px;
  min-width: 28px;
}
.arco-switch-small.arco-switch-checked {
  padding-left: -2px;
}
.arco-switch-small .arco-switch-dot {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
}
.arco-switch-small .arco-switch-dot-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.66667);
}
.arco-switch-small.arco-switch-checked .arco-switch-dot {
  left: 100%;
  margin-left: -2px;
}
.arco-switch-type-round {
  border-radius: var(--border-radius-small);
  min-width: 40px;
}
.arco-switch-type-round .arco-switch-dot {
  border-radius: 2px;
}
.arco-switch-type-round.arco-switch-small {
  border-radius: 2px;
  height: 16px;
  line-height: 16px;
  min-width: 28px;
}
.arco-switch-type-round.arco-switch-small .arco-switch-dot {
  border-radius: 1px;
}
.arco-switch-type-line {
  min-width: 36px;
  background-color: transparent;
  overflow: unset;
}
.arco-switch-type-line::after {
  content: '';
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 6px;
  background-color: var(--color-fill-4);
  transition: background-color 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-switch-type-line .arco-switch-dot {
  top: 2px;
  left: 0;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--color-bg-white);
  box-shadow: 0 1px 3px var(--color-neutral-6);
}
.arco-switch-type-line.arco-switch-checked {
  background-color: transparent;
}
.arco-switch-type-line.arco-switch-checked::after {
  background-color: rgb(var(--primary-6));
}
.arco-switch-type-line.arco-switch-checked .arco-switch-dot {
  left: 100%;
  margin-left: 0;
}
.arco-switch-type-line[disabled] {
  cursor: not-allowed;
  background-color: transparent;
}
.arco-switch-type-line[disabled]::after {
  background-color: var(--color-fill-2);
}
.arco-switch-type-line[disabled].arco-switch-checked {
  background-color: transparent;
}
.arco-switch-type-line[disabled].arco-switch-checked::after {
  background-color: var(--color-primary-light-3);
}
.arco-switch-type-line.arco-switch-loading {
  background-color: transparent;
}
.arco-switch-type-line.arco-switch-loading::after {
  background-color: var(--color-fill-2);
}
.arco-switch-type-line.arco-switch-loading.arco-switch-checked {
  background-color: transparent;
}
.arco-switch-type-line.arco-switch-loading.arco-switch-checked::after {
  background-color: var(--color-primary-light-3);
}
.arco-switch-type-line.arco-switch-small {
  height: 16px;
  line-height: 16px;
  min-width: 28px;
}
.arco-switch-type-line.arco-switch-small.arco-switch-checked {
  padding-left: 0;
}
.arco-switch-type-line.arco-switch-small .arco-switch-dot {
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}
.arco-switch-type-line.arco-switch-small .arco-switch-dot-icon {
  transform: translate(-50%, -50%) scale(1);
}
.arco-table-filters-popup {
  min-width: 100px;
  background: var(--color-bg-5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-medium);
  box-sizing: border-box;
}
.arco-table-filters-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 4px 0;
}
.arco-table-filters-item {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  overflow: hidden;
}
.arco-table-filters-item .arco-checkbox,
.arco-table-filters-item .arco-radio {
  width: 100%;
  padding: 0 12px;
  white-space: nowrap;
}
.arco-table-filters-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-neutral-3);
  padding: 0 12px;
  height: 38px;
  line-height: 38px;
  box-sizing: border-box;
  overflow: hidden;
}
.arco-table-th-item {
  padding: 9px 16px;
}
.arco-table-th-item.arco-table-col-has-filter {
  padding-right: 28px;
}
.arco-table-col-has-sorter.arco-table-col-has-filter {
  padding-right: 0;
}
.arco-table-col-has-sorter.arco-table-col-has-filter .arco-table-cell-with-sorter {
  padding-right: 28px;
}
.arco-table-col-has-sorter {
  padding: 0;
}
.arco-table-col-has-sorter .arco-table-cell-with-sorter {
  padding: 9px 16px;
  cursor: pointer;
}
.arco-table-td {
  padding: 9px 16px;
  font-size: 14px;
}
.arco-table-th {
  font-size: 14px;
}
.arco-table-footer {
  padding: 9px 16px;
}
.arco-table-expand-fixed-row {
  margin: -9px -16px;
  padding: 9px 16px;
}
.arco-table-expand-content .arco-table-td .arco-table {
  margin: -9px -16px calc(-9px - 1px) -16px;
}
.arco-table-editable-row .arco-table-cell-wrap-value {
  padding: 9px 16px;
}
.arco-table-size-middle .arco-table-th-item {
  padding: 7px 16px;
}
.arco-table-size-middle .arco-table-th-item.arco-table-col-has-filter {
  padding-right: 28px;
}
.arco-table-size-middle .arco-table-col-has-sorter.arco-table-col-has-filter {
  padding-right: 0;
}
.arco-table-size-middle
  .arco-table-col-has-sorter.arco-table-col-has-filter
  .arco-table-cell-with-sorter {
  padding-right: 28px;
}
.arco-table-size-middle .arco-table-col-has-sorter {
  padding: 0;
}
.arco-table-size-middle .arco-table-col-has-sorter .arco-table-cell-with-sorter {
  padding: 7px 16px;
  cursor: pointer;
}
.arco-table-size-middle .arco-table-td {
  padding: 7px 16px;
  font-size: 14px;
}
.arco-table-size-middle .arco-table-th {
  font-size: 14px;
}
.arco-table-size-middle .arco-table-footer {
  padding: 7px 16px;
}
.arco-table-size-middle .arco-table-expand-fixed-row {
  margin: -7px -16px;
  padding: 7px 16px;
}
.arco-table-size-middle .arco-table-expand-content .arco-table-td .arco-table {
  margin: -7px -16px calc(-7px - 1px) -16px;
}
.arco-table-size-middle .arco-table-editable-row .arco-table-cell-wrap-value {
  padding: 7px 16px;
}
.arco-table-size-small .arco-table-th-item {
  padding: 5px 16px;
}
.arco-table-size-small .arco-table-th-item.arco-table-col-has-filter {
  padding-right: 28px;
}
.arco-table-size-small .arco-table-col-has-sorter.arco-table-col-has-filter {
  padding-right: 0;
}
.arco-table-size-small
  .arco-table-col-has-sorter.arco-table-col-has-filter
  .arco-table-cell-with-sorter {
  padding-right: 28px;
}
.arco-table-size-small .arco-table-col-has-sorter {
  padding: 0;
}
.arco-table-size-small .arco-table-col-has-sorter .arco-table-cell-with-sorter {
  padding: 5px 16px;
  cursor: pointer;
}
.arco-table-size-small .arco-table-td {
  padding: 5px 16px;
  font-size: 14px;
}
.arco-table-size-small .arco-table-th {
  font-size: 14px;
}
.arco-table-size-small .arco-table-footer {
  padding: 5px 16px;
}
.arco-table-size-small .arco-table-expand-fixed-row {
  margin: -5px -16px;
  padding: 5px 16px;
}
.arco-table-size-small .arco-table-expand-content .arco-table-td .arco-table {
  margin: -5px -16px calc(-5px - 1px) -16px;
}
.arco-table-size-small .arco-table-editable-row .arco-table-cell-wrap-value {
  padding: 5px 16px;
}
.arco-table-size-mini .arco-table-th-item {
  padding: 2px 16px;
}
.arco-table-size-mini .arco-table-th-item.arco-table-col-has-filter {
  padding-right: 28px;
}
.arco-table-size-mini .arco-table-col-has-sorter.arco-table-col-has-filter {
  padding-right: 0;
}
.arco-table-size-mini
  .arco-table-col-has-sorter.arco-table-col-has-filter
  .arco-table-cell-with-sorter {
  padding-right: 28px;
}
.arco-table-size-mini .arco-table-col-has-sorter {
  padding: 0;
}
.arco-table-size-mini .arco-table-col-has-sorter .arco-table-cell-with-sorter {
  padding: 2px 16px;
  cursor: pointer;
}
.arco-table-size-mini .arco-table-td {
  padding: 2px 16px;
  font-size: 12px;
}
.arco-table-size-mini .arco-table-th {
  font-size: 12px;
}
.arco-table-size-mini .arco-table-footer {
  padding: 2px 16px;
}
.arco-table-size-mini .arco-table-expand-fixed-row {
  margin: -2px -16px;
  padding: 2px 16px;
}
.arco-table-size-mini .arco-table-expand-content .arco-table-td .arco-table {
  margin: -2px -16px calc(-2px - 1px) -16px;
}
.arco-table-size-mini .arco-table-editable-row .arco-table-cell-wrap-value {
  padding: 2px 16px;
}
.arco-table {
  position: relative;
}
.arco-table .arco-spin {
  display: block;
}
.arco-table > .arco-spin > .arco-spin-children::after {
  z-index: 2;
}
.arco-table-footer {
  border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
}
.arco-table-scroll-position-right .arco-table-col-fixed-left-last::after,
.arco-table-scroll-position-middle .arco-table-col-fixed-left-last::after {
  box-shadow: inset 6px 0 8px -3px rgba(0, 0, 0, 0.15);
}
.arco-table-scroll-position-left .arco-table-col-fixed-right-first::after,
.arco-table-scroll-position-middle .arco-table-col-fixed-right-first::after {
  box-shadow: inset -6px 0 8px -3px rgba(0, 0, 0, 0.15);
}
.arco-table:not(.arco-table-has-fixed-col-left).arco-table-scroll-position-right
  .arco-table-content-scroll::before,
.arco-table:not(.arco-table-has-fixed-col-left).arco-table-scroll-position-middle
  .arco-table-content-scroll::before {
  box-shadow: inset 6px 0 8px -3px rgba(0, 0, 0, 0.15);
}
.arco-table:not(.arco-table-has-fixed-col-right).arco-table-scroll-position-left
  .arco-table-content-scroll::after,
.arco-table:not(.arco-table-has-fixed-col-right).arco-table-scroll-position-middle
  .arco-table-content-scroll::after {
  box-shadow: inset -6px 0 8px -3px rgba(0, 0, 0, 0.15);
}
.arco-table-layout-fixed .arco-table-content-inner {
  overflow-x: auto;
  overflow-y: hidden;
}
.arco-table-layout-fixed table {
  table-layout: fixed;
}
.arco-table thead > .arco-table-tr > .arco-table-th {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-table thead > .arco-table-tr:not(:last-child) > .arco-table-th[colspan] {
  border-bottom: 0;
}
.arco-table table {
  min-width: 100%;
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: separate;
}
.arco-table .arco-table-th {
  background-color: var(--color-neutral-2);
}
.arco-table .arco-table-td {
  background-color: var(--color-bg-2);
}
.arco-table .arco-table-th {
  box-sizing: border-box;
  text-align: left;
  color: rgb(var(--gray-10));
  line-height: 1.5715;
  font-weight: 500;
}
.arco-table .arco-table-th[colspan] {
  text-align: center;
}
.arco-table .arco-table-th:first-child {
  border-radius: var(--border-radius-medium) 0 0 0;
}
.arco-table .arco-table-th:last-child {
  border-radius: 0 var(--border-radius-medium) 0 0;
}
.arco-table .arco-table-td {
  box-sizing: border-box;
  text-align: left;
  color: rgb(var(--gray-10));
  border-bottom: 1px solid var(--color-neutral-3);
  line-height: 1.5715;
}
.arco-table-th.arco-table-col-sorted {
  background-color: var(--color-neutral-3);
}
.arco-table-td.arco-table-col-sorted {
  background-color: var(--color-fill-1);
}
.arco-table-col-fixed-left,
.arco-table-col-fixed-right {
  position: sticky;
  z-index: 1;
}
.arco-table-col-fixed-left-last::after,
.arco-table-col-fixed-right-first::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  width: 10px;
  pointer-events: none;
  transform: translateX(-100%);
  transition: box-shadow 0.1s cubic-bezier(0, 0, 1, 1);
  box-shadow: none;
}
.arco-table-col-fixed-left-last::after {
  left: unset;
  transform: translateX(100%);
  right: 0;
}
.arco-table-cell-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-table-editable-row .arco-table-cell-wrap-value {
  border: 1px solid var(--color-white);
  border-radius: var(--border-radius-medium);
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  cursor: pointer;
}
.arco-table-editable-row:hover .arco-table-cell-wrap-value {
  border: 1px solid var(--color-neutral-3);
}
.arco-table-cell {
  word-break: break-all;
}
.arco-table-cell-indent {
  height: 1px;
  float: left;
}
.arco-table-expand-icon-cell button {
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-neutral-3);
  color: var(--color-text-2);
  font-size: 12px;
  outline: none;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
  padding: 0;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.arco-table-expand-icon-cell button:hover {
  background-color: var(--color-neutral-4);
  color: var(--color-text-1);
  border-color: transparent;
}
.arco-table-cell-expand-icon {
  float: left;
}
.arco-table-cell-expand-icon button {
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-neutral-3);
  color: var(--color-text-2);
  font-size: 12px;
  outline: none;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
  padding: 0;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin-right: 4px;
}
.arco-table-cell-expand-icon button:hover {
  background-color: var(--color-neutral-4);
  color: var(--color-text-1);
  border-color: transparent;
}
.arco-table-cell-expand-icon-hidden {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.arco-table-expand-content .arco-table-td {
  background-color: var(--color-fill-1);
}
.arco-table-expand-fixed-row {
  position: sticky;
  left: 0;
  box-sizing: border-box;
}
.arco-table-expand-content .arco-table-td .arco-table .arco-table-container {
  border: none;
}
.arco-table-expand-content .arco-table-td .arco-table .arco-table-th {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-table-expand-content .arco-table-td .arco-table .arco-table-th,
.arco-table-expand-content .arco-table-td .arco-table .arco-table-td {
  background-color: transparent;
}
.arco-table-expand-content .arco-table-td .arco-table .arco-table-pagination {
  margin-bottom: 12px;
}
.arco-table-th.arco-table-operation .arco-table-th-item,
.arco-table-td.arco-table-operation {
  text-align: center;
  padding: 0;
}
.arco-table-radio,
.arco-table-checkbox {
  justify-content: center;
}
.arco-table-checkbox .arco-checkbox,
.arco-table-radio .arco-radio {
  padding-left: 0;
}
.arco-table-selection-col {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.arco-table-expand-icon-col {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.arco-table-radio .arco-table-th-item,
.arco-table-checkbox .arco-table-th-item,
div.arco-table-selection-col .arco-table-th-item,
div.arco-table-expand-icon-col .arco-table-th-item {
  padding: 0;
}
.arco-table-th-item {
  position: relative;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-table-cell-mouseenter {
  background-color: rgba(var(--gray-4), 0.5);
}
.arco-table-cell-next-ascend .arco-table-sorter-icon .arco-icon-caret-up {
  color: var(--color-neutral-6);
}
.arco-table-cell-next-descend .arco-table-sorter-icon .arco-icon-caret-down {
  color: var(--color-neutral-6);
}
.arco-table-sorter {
  display: inline-block;
  margin-left: 8px;
  vertical-align: -3px;
}
.arco-table-sorter.arco-table-sorter-direction-one {
  vertical-align: 0;
}
.arco-table-sorter-icon {
  position: relative;
  height: 8px;
  line-height: 8px;
  width: 12px;
  overflow: hidden;
}
.arco-table-sorter-icon .arco-icon-caret-up,
.arco-table-sorter-icon .arco-icon-caret-down {
  position: absolute;
  top: 50%;
  font-size: 12px;
  color: var(--color-neutral-5);
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-table-sorter-icon .arco-icon-caret-up {
  top: -2px;
  left: 0;
}
.arco-table-sorter-icon .arco-icon-caret-down {
  top: -3px;
  left: 0;
}
.arco-table-sorter-icon.arco-table-sorter-icon-active svg {
  color: rgb(var(--primary-6));
}
.arco-table-filters {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 24px;
  height: 100%;
  background-color: transparent;
  line-height: 1;
  vertical-align: 0;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-table-filters:hover,
.arco-table-filters-open {
  background-color: var(--color-neutral-4);
}
.arco-table-filters svg {
  color: var(--color-text-2);
  font-size: 16px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-table-filters-active svg {
  color: rgb(var(--primary-6));
}
.arco-table-container {
  position: relative;
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
}
.arco-table-header {
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
}
.arco-table-content-scroll {
  width: 100%;
  overflow: hidden;
}
.arco-table-content-scroll .arco-table-content-inner {
  width: auto;
}
.arco-table-content-scroll::before,
.arco-table-content-scroll::after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  height: 100%;
  width: 10px;
  transition: box-shadow 0.1s cubic-bezier(0, 0, 1, 1);
  box-shadow: none;
  pointer-events: none;
  z-index: 1;
}
.arco-table-content-scroll::before {
  border-top-left-radius: var(--border-radius-medium);
  left: 0;
}
.arco-table-content-scroll::after {
  border-top-right-radius: var(--border-radius-medium);
  right: 0;
}
.arco-table-header {
  scrollbar-color: transparent transparent;
  background-color: var(--color-neutral-2);
  overflow-x: scroll;
  overflow-y: hidden;
}
.arco-table-header::-webkit-scrollbar {
  background-color: transparent;
}
.arco-table-body {
  position: relative;
  overflow: auto;
  min-height: 40px;
  background-color: var(--color-bg-2);
}
.arco-table-no-data {
  padding: 20px;
  line-height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
}
.arco-table-empty-row .arco-table-td {
  border-bottom: 0;
}
.arco-table-border .arco-table-container {
  border-right: 1px solid var(--color-neutral-3);
  border-top: 1px solid var(--color-neutral-3);
}
.arco-table-border .arco-table-container .arco-table-th:first-child,
.arco-table-border .arco-table-container .arco-table-td:first-child {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-table-border .arco-table-container .arco-table-expand-fixed-row {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-table-border .arco-table-container::before {
  content: '';
  position: absolute;
  background-color: var(--color-neutral-3);
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.arco-table-border .arco-table-footer {
  border: 1px solid var(--color-neutral-3);
  border-top: 0;
}
.arco-table-border-cell .arco-table-th,
.arco-table-border-cell .arco-table-td {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-table-border-cell thead > .arco-table-tr:not(:last-child) > .arco-table-th[colspan] {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-table-border-header-cell .arco-table-th {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-table-border-header-cell thead > .arco-table-tr:not(:last-child) > .arco-table-th[colspan] {
  border-bottom: 1px solid var(--color-neutral-3);
}
.arco-table-border-body-cell .arco-table-td {
  border-left: 1px solid var(--color-neutral-3);
}
.arco-table-border-cell:not(.arco-table-border) .arco-table-th:first-child,
.arco-table-border-cell:not(.arco-table-border) .arco-table-td:first-child {
  border-left: 0;
}
.arco-table-stripe .arco-table-tr:nth-child(even) .arco-table-td {
  background-color: var(--color-fill-1);
}
.arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row):hover
  .arco-table-td:not(.arco-table-col-fixed-left):not(.arco-table-col-fixed-right) {
  background-color: var(--color-fill-1);
}
.arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row):hover
  .arco-table-td.arco-table-col-fixed-left::before,
.arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row):hover
  .arco-table-td.arco-table-col-fixed-right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-fill-1);
  z-index: -1;
}
.arco-table-hover
  .arco-table-expand-content:not(.arco-table-empty-row):hover
  .arco-table-td:not(.arco-table-col-fixed-left):not(.arco-table-col-fixed-right) {
  background-color: var(--color-fill-1);
}
.arco-table-expand-content
  .arco-table-td
  .arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row)
  .arco-table-td:not(.arco-table-col-fixed-left):not(.arco-table-col-fixed-right) {
  background-color: transparent;
}
.arco-table-expand-content
  .arco-table-td
  .arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row)
  .arco-table-td.arco-table-col-fixed-left::before,
.arco-table-expand-content
  .arco-table-td
  .arco-table-hover
  .arco-table-tr:not(.arco-table-empty-row)
  .arco-table-td.arco-table-col-fixed-right::before {
  background-color: transparent;
}
.arco-table-type-radio .arco-table-row-checked.arco-table-tr .arco-table-td {
  background-color: var(--color-fill-1);
}
.arco-table-virtualized table {
  table-layout: fixed;
}
.arco-table-virtualized div.arco-table-body div.arco-table-tr {
  display: flex;
}
.arco-table-virtualized div.arco-table-body div.arco-table-td {
  display: flex;
  align-items: center;
  flex: 1;
}
div.arco-table-tfoot {
  width: 100%;
  overflow-x: auto;
  background-color: var(--color-neutral-2);
  scrollbar-color: transparent transparent;
  position: relative;
  z-index: 1;
  box-shadow: 0 -1px 0 var(--color-neutral-3);
}
div.arco-table-tfoot::-webkit-scrollbar {
  background-color: transparent;
}
.arco-table-tfoot .arco-table-td {
  background-color: var(--color-neutral-2);
}
.arco-table-pagination {
  display: flex;
  justify-content: flex-end;
}
.arco-table-pagination-left {
  justify-content: flex-start;
}
.arco-table-pagination-center {
  justify-content: center;
}
.arco-table-pagination-top {
  margin-bottom: 12px;
  margin-top: 0;
}
.arco-table-pagination .arco-pagination {
  margin-top: 12px;
}
.arco-icon-hover.arco-tabs-icon-hover::before {
  width: 16px;
  height: 16px;
}
.arco-tabs .arco-tabs-icon-hover {
  font-size: 12px;
  color: var(--color-text-2);
  user-select: none;
}
.arco-tabs-dropdown-icon {
  font-size: 12px;
  margin-left: 6px;
  user-select: none;
}
.arco-tabs-close-icon {
  margin-left: 8px;
  user-select: none;
}
.arco-tabs-add-icon {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  padding: 0 8px;
}
.arco-tabs-add {
  position: relative;
}
.arco-tabs-left-icon {
  margin-left: 10px;
  margin-right: 6px;
}
.arco-tabs-right-icon {
  margin-right: 10px;
  margin-left: 6px;
}
.arco-tabs-up-icon {
  margin-bottom: 10px;
}
.arco-tabs-down-icon {
  margin-top: 10px;
}
.arco-tabs-nav-icon-disabled {
  cursor: not-allowed;
  color: var(--color-text-4);
}
.arco-tabs {
  position: relative;
  overflow: hidden;
}
.arco-tabs-header-nav {
  position: relative;
}
.arco-tabs-header-nav::before {
  content: '';
  clear: both;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-neutral-3);
  display: block;
}
.arco-tabs-header-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.arco-tabs-header {
  position: relative;
  display: inline-block;
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
  white-space: nowrap;
}
.arco-tabs-header-extra {
  width: auto;
  display: flex;
  align-items: center;
  line-height: 32px;
}
.arco-tabs-header-extra .arco-tabs-add-icon {
  padding-left: 0;
}
.arco-tabs-header-title {
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  color: var(--color-text-2);
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
  display: inline-flex;
  align-items: center;
  line-height: 1.5715;
  padding: 4px 0;
}
.arco-tabs-header-title-text {
  display: inline-block;
}
.arco-tabs-header-title:hover {
  color: var(--color-text-2);
  font-weight: 400;
}
.arco-tabs-header-title-disabled,
.arco-tabs-header-title-disabled:hover {
  color: var(--color-text-4);
  cursor: not-allowed;
}
.arco-tabs-header-title-active,
.arco-tabs-header-title-active:hover {
  color: rgb(var(--primary-6));
  font-weight: 500;
}
.arco-tabs-header-title-active.arco-tabs-header-title-disabled,
.arco-tabs-header-title-active:hover.arco-tabs-header-title-disabled {
  color: var(--color-primary-light-3);
}
.arco-tabs-header-ink {
  position: absolute;
  bottom: 0;
  right: initial;
  top: initial;
  height: 2px;
  background-color: rgb(var(--primary-6));
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-tabs-header-ink.arco-tabs-header-ink-no-animation {
  transition: none;
}
.arco-tabs-header-ink-disabled {
  background-color: var(--color-primary-light-3);
}
.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 40px;
}
.arco-tabs-header-nav-line .arco-tabs-header-title {
  line-height: 1.5715;
  margin: 0 16px;
  padding: 8px 0;
}
.arco-tabs-header-nav-line .arco-tabs-header-title-text {
  display: inline-block;
  position: relative;
  padding: 1px 0;
}
.arco-tabs-header-nav-line .arco-tabs-header-title-text::before {
  content: '';
  z-index: -1;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0, 0, 1, 1);
  border-radius: var(--border-radius-small);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -8px;
  right: -8px;
  background-color: transparent;
}
.arco-tabs-header-nav-line .arco-tabs-header-title:hover .arco-tabs-header-title-text::before {
  background-color: var(--color-fill-2);
}
.arco-tabs-header-nav-line .arco-tabs-header-title-active .arco-tabs-header-title-text::before,
.arco-tabs-header-nav-line
  .arco-tabs-header-title-active:hover
  .arco-tabs-header-title-text::before {
  background-color: transparent;
}
.arco-tabs-header-nav-line .arco-tabs-header-title-disabled .arco-tabs-header-title-text::before,
.arco-tabs-header-nav-line
  .arco-tabs-header-title-disabled:hover
  .arco-tabs-header-title-text::before {
  opacity: 0;
}
.arco-tabs-header-nav-line.arco-tabs-header-nav-horizontal
  > .arco-tabs-header-scroll
  .arco-tabs-header-title:first-of-type {
  margin-left: 16px;
}
.arco-tabs-header-nav-line.arco-tabs-header-nav-horizontal
  .arco-tabs-header-no-padding
  > .arco-tabs-header-title:first-of-type,
.arco-tabs-header-nav-text.arco-tabs-header-nav-horizontal
  .arco-tabs-header-no-padding
  > .arco-tabs-header-title:first-of-type {
  margin-left: 0;
}
.arco-tabs-header-nav-card .arco-tabs-header-title,
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  font-size: 14px;
  border: 1px solid var(--color-neutral-3);
  transition: padding 0.2s cubic-bezier(0, 0, 1, 1), color 0.2s cubic-bezier(0, 0, 1, 1);
  padding: 4px 16px;
}
.arco-tabs-header-nav-card .arco-tabs-header-title-editable,
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-editable {
  padding-right: 12px;
}
.arco-tabs-header-nav-card
  .arco-tabs-header-title-editable:not(.arco-tabs-header-title-active):hover
  .arco-icon-hover:hover::before,
.arco-tabs-header-nav-card-gutter
  .arco-tabs-header-title-editable:not(.arco-tabs-header-title-active):hover
  .arco-icon-hover:hover::before {
  background-color: var(--color-fill-4);
}
.arco-tabs-header-nav-card .arco-tabs-add-icon,
.arco-tabs-header-nav-card-gutter .arco-tabs-add-icon {
  height: 32px;
}
.arco-tabs-header-nav-card .arco-tabs-header-title {
  border-right: none;
  background-color: transparent;
}
.arco-tabs-header-nav-card .arco-tabs-header-title:last-child {
  border-right: 1px solid var(--color-neutral-3);
  border-top-right-radius: var(--border-radius-small);
}
.arco-tabs-header-nav-card .arco-tabs-header-title:first-child {
  border-top-left-radius: var(--border-radius-small);
}
.arco-tabs-header-nav-card .arco-tabs-header-title:hover {
  background-color: var(--color-fill-3);
}
.arco-tabs-header-nav-card .arco-tabs-header-title-disabled,
.arco-tabs-header-nav-card .arco-tabs-header-title-disabled:hover {
  background-color: transparent;
}
.arco-tabs-header-nav-card .arco-tabs-header-title-active,
.arco-tabs-header-nav-card .arco-tabs-header-title-active:hover {
  border-bottom-color: var(--color-bg-2);
  background-color: transparent;
}
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  margin-left: 4px;
  border-right: 1px solid var(--color-neutral-3);
  background-color: var(--color-fill-1);
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title:hover {
  background-color: var(--color-fill-3);
}
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-disabled,
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-disabled:hover {
  background-color: var(--color-fill-1);
}
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-active,
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-active:hover {
  border-bottom-color: var(--color-bg-2);
  background-color: transparent;
}
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title:first-child {
  margin-left: 0;
}
.arco-tabs-header-nav-text::before {
  display: none;
}
.arco-tabs-header-nav-text .arco-tabs-header-title {
  position: relative;
  line-height: 1.5715;
  margin: 0 9px;
  font-size: 14px;
  padding: 5px 0;
}
.arco-tabs-header-nav-text .arco-tabs-header-title:not(:first-of-type)::before {
  height: 12px;
  position: absolute;
  width: 2px;
  background-color: var(--color-fill-3);
  content: '';
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: -9px;
}
.arco-tabs-header-nav-text .arco-tabs-header-title-text {
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
}
.arco-tabs-header-nav-text .arco-tabs-header-title-text:hover {
  background-color: var(--color-fill-2);
}
.arco-tabs-header-nav-text .arco-tabs-header-title-active .arco-tabs-header-title-text,
.arco-tabs-header-nav-text .arco-tabs-header-title-active .arco-tabs-header-title-text:hover {
  background-color: transparent;
}
.arco-tabs-header-nav-text .arco-tabs-header-title-disabled .arco-tabs-header-title-text,
.arco-tabs-header-nav-text .arco-tabs-header-title-disabled .arco-tabs-header-title-text:hover {
  background-color: transparent;
}
.arco-tabs-header-nav-text
  .arco-tabs-header-title-active.arco-tabs-header-nav-text
  .arco-tabs-header-title-disabled
  .arco-tabs-header-title-text,
.arco-tabs-header-nav-text
  .arco-tabs-header-title-active.arco-tabs-header-nav-text
  .arco-tabs-header-title-disabled
  .arco-tabs-header-title-text:hover {
  background-color: var(--color-primary-light-3);
}
.arco-tabs-header-nav-rounded::before {
  display: none;
}
.arco-tabs-header-nav-rounded .arco-tabs-header-title {
  padding: 5px 0;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 6px;
  font-size: 14px;
  background-color: transparent;
  border-radius: 32px;
}
.arco-tabs-header-nav-rounded .arco-tabs-header-title:hover {
  background-color: var(--color-fill-2);
}
.arco-tabs-header-nav-rounded .arco-tabs-header-title-disabled:hover {
  background-color: transparent;
}
.arco-tabs-header-nav-rounded .arco-tabs-header-title-active,
.arco-tabs-header-nav-rounded .arco-tabs-header-title-active:hover {
  background-color: var(--color-fill-2);
}
.arco-tabs-header-nav-capsule::before {
  display: none;
}
.arco-tabs-header-nav-capsule .arco-tabs-header-wrapper {
  justify-content: flex-end;
}
.arco-tabs-header-nav-capsule .arco-tabs-header {
  line-height: 1;
  background-color: var(--color-fill-2);
  border-radius: var(--border-radius-small);
  padding: 3px 3px;
}
.arco-tabs-header-nav-capsule .arco-tabs-header-title {
  line-height: 26px;
  padding: 0 12px;
  background-color: transparent;
  position: relative;
  font-size: 14px;
  border-radius: var(--border-radius-small);
}
.arco-tabs-header-nav-capsule .arco-tabs-header-title:hover {
  background-color: var(--color-bg-2);
}
.arco-tabs-header-nav-capsule .arco-tabs-header-title-disabled:hover {
  background-color: unset;
}
.arco-tabs-header-nav-capsule .arco-tabs-header-title-active,
.arco-tabs-header-nav-capsule .arco-tabs-header-title-active:hover {
  background-color: var(--color-bg-2);
}
.arco-tabs-header-nav-capsule .arco-tabs-header-title-active::before,
.arco-tabs-header-nav-capsule .arco-tabs-header-title-active:hover::before,
.arco-tabs-header-nav-capsule .arco-tabs-header-title-active + .arco-tabs-header-title::before,
.arco-tabs-header-nav-capsule
  .arco-tabs-header-title-active:hover
  + .arco-tabs-header-title::before {
  opacity: 0;
}
.arco-tabs-header-nav-capsule.arco-tabs-header-nav-horizontal
  .arco-tabs-header-title:not(:first-of-type) {
  margin-left: 3px;
}
.arco-tabs-header-nav-capsule.arco-tabs-header-nav-horizontal
  .arco-tabs-header-title:not(:first-of-type)::before {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  display: block;
  height: 14px;
  width: 1px;
  background-color: var(--color-fill-3);
  content: '';
  transition: all 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-tabs-header-scroll {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}
.arco-tabs-content {
  width: 100%;
  overflow: hidden;
  padding-top: 16px;
  box-sizing: border-box;
}
.arco-tabs-content .arco-tabs-content-inner {
  display: flex;
  width: 100%;
}
.arco-tabs-content .arco-tabs-content-item {
  width: 100%;
  overflow: hidden;
  height: 0;
  flex-shrink: 0;
}
.arco-tabs-content .arco-tabs-content-item.arco-tabs-content-item-active {
  height: auto;
}
.arco-tabs-card > .arco-tabs-content,
.arco-tabs-card-gutter > .arco-tabs-content {
  border: 1px solid var(--color-neutral-3);
  border-top: none;
}
.arco-tabs-animation.arco-tabs-content-inner {
  transition: all 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-tabs-horizontal.arco-tabs-justify {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.arco-tabs-horizontal.arco-tabs-justify .arco-tabs-content,
.arco-tabs-horizontal.arco-tabs-justify .arco-tabs-content-inner,
.arco-tabs-horizontal.arco-tabs-justify .arco-tabs-pane {
  height: 100%;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 32px;
  font-size: 12px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-line .arco-tabs-header-title {
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 32px;
  font-size: 12px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-card .arco-tabs-header-title,
.arco-tabs-header-size-mini.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-card .arco-tabs-header-extra,
.arco-tabs-header-size-mini.arco-tabs-header-nav-card-gutter .arco-tabs-header-extra {
  line-height: 24px;
  font-size: 12px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-card .arco-tabs-add-icon,
.arco-tabs-header-size-mini.arco-tabs-header-nav-card-gutter .arco-tabs-add-icon {
  height: 24px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-capsule .arco-tabs-header-title {
  font-size: 12px;
  line-height: 18px;
}
.arco-tabs-header-size-mini.arco-tabs-header-nav-capsule .arco-tabs-header-extra {
  line-height: 24px;
  font-size: 12px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 36px;
  font-size: 14px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-line .arco-tabs-header-title {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 36px;
  font-size: 14px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-card .arco-tabs-header-title,
.arco-tabs-header-size-small.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-card .arco-tabs-header-extra,
.arco-tabs-header-size-small.arco-tabs-header-nav-card-gutter .arco-tabs-header-extra {
  line-height: 28px;
  font-size: 14px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-card .arco-tabs-add-icon,
.arco-tabs-header-size-small.arco-tabs-header-nav-card-gutter .arco-tabs-add-icon {
  height: 28px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-capsule .arco-tabs-header-title {
  font-size: 14px;
  line-height: 22px;
}
.arco-tabs-header-size-small.arco-tabs-header-nav-capsule .arco-tabs-header-extra {
  line-height: 28px;
  font-size: 14px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 44px;
  font-size: 14px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-line .arco-tabs-header-title {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-line .arco-tabs-header-extra {
  line-height: 44px;
  font-size: 14px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-card .arco-tabs-header-title,
.arco-tabs-header-size-large.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-card .arco-tabs-header-extra,
.arco-tabs-header-size-large.arco-tabs-header-nav-card-gutter .arco-tabs-header-extra {
  line-height: 36px;
  font-size: 14px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-card .arco-tabs-add-icon,
.arco-tabs-header-size-large.arco-tabs-header-nav-card-gutter .arco-tabs-add-icon {
  height: 36px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-capsule .arco-tabs-header-title {
  font-size: 14px;
  line-height: 30px;
}
.arco-tabs-header-size-large.arco-tabs-header-nav-capsule .arco-tabs-header-extra {
  line-height: 36px;
  font-size: 14px;
}
.arco-tabs-header-nav-vertical {
  float: left;
  height: 100%;
}
.arco-tabs-header-nav-vertical::before {
  position: absolute;
  left: initial;
  bottom: 0;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  clear: both;
}
.arco-tabs-header-nav-vertical .arco-tabs-add-icon {
  margin-left: 0;
  margin-top: 8px;
  padding: 0 16px;
  height: auto;
}
.arco-tabs-header-nav-right {
  float: right;
}
.arco-tabs-header-nav-vertical .arco-tabs-header {
  height: auto;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-scroll {
  flex-direction: column;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-overflow-scroll {
  padding: 6px 0;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-wrapper {
  height: 100%;
  flex-direction: column;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-ink {
  position: absolute;
  left: initial;
  right: 0;
  bottom: initial;
  width: 2px;
  transition: top 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    height 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-tabs-header-nav-vertical .arco-tabs-header-title {
  display: block;
  white-space: nowrap;
  margin: 0;
  margin-top: 12px;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-title:first-of-type {
  margin-top: 0;
}
.arco-tabs-header-nav-right::before {
  right: unset;
  left: 0;
}
.arco-tabs-header-nav-right .arco-tabs-header-ink {
  left: 0;
  right: unset;
}
.arco-tabs-header-nav-vertical .arco-tabs-header-scroll {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}
.arco-tabs-header-nav-line.arco-tabs-header-nav-vertical .arco-tabs-header-title {
  padding: 0 20px;
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card .arco-tabs-header-title {
  margin: 0;
  position: relative;
  border: 1px solid var(--color-neutral-3);
  border-bottom-color: transparent;
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card .arco-tabs-header-title:first-child {
  border-top-left-radius: var(--border-radius-small);
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card .arco-tabs-header-title-active,
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card .arco-tabs-header-title-active:hover {
  border-bottom-color: transparent;
  border-right-color: var(--color-bg-2);
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card .arco-tabs-header-title:last-child {
  border-bottom: 1px solid var(--color-neutral-3);
  border-bottom-left-radius: var(--border-radius-small);
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card-gutter .arco-tabs-header-title {
  margin-left: 0;
  border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
  position: relative;
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card-gutter
  .arco-tabs-header-title:not(:first-of-type) {
  margin-top: 4px;
}
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-active,
.arco-tabs-header-nav-vertical.arco-tabs-header-nav-card-gutter
  .arco-tabs-header-title-active:hover {
  border-right-color: var(--color-bg-2);
  border-bottom-color: var(--color-neutral-3);
}
.arco-tabs-content-vertical {
  width: auto;
  height: 100%;
  padding: 0;
}
.arco-tabs-right .arco-tabs-content-vertical {
  padding-right: 16px;
}
.arco-tabs-left .arco-tabs-content-vertical {
  padding-left: 16px;
}
.arco-tabs-card > .arco-tabs-content-vertical,
.arco-tabs-card-gutter > .arco-tabs-content-vertical {
  border: 1px solid var(--color-neutral-3);
  border-left: none;
}
body[arco-theme='dark'] .arco-tabs-header-nav-capsule .arco-tabs-header-title-active {
  background-color: var(--color-fill-3);
}
body[arco-theme='dark'] .arco-tabs-header-nav-capsule .arco-tabs-header-title:hover {
  background-color: var(--color-fill-3);
}
.arco-tag {
  display: inline-flex;
  height: 24px;
  line-height: 22px;
  padding: 0 8px;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  font-size: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  font-weight: 500;
  vertical-align: middle;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-tag .arco-icon-hover.arco-tag-icon-hover::before {
  width: 16px;
  height: 16px;
}
.arco-tag .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: var(--color-fill-3);
}
.arco-tag-checkable {
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-tag-checkable:hover {
  background-color: var(--color-fill-2);
}
.arco-tag-checked {
  border-color: transparent;
  background-color: var(--color-fill-2);
}
.arco-tag-checkable.arco-tag-checked:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
}
.arco-tag-bordered,
.arco-tag-checkable.arco-tag-checked.arco-tag-bordered:hover {
  border-color: var(--color-border-2);
}
.arco-tag-size-small {
  height: 20px;
  line-height: 18px;
  font-size: 12px;
}
.arco-tag-size-medium {
  height: 28px;
  line-height: 26px;
  font-size: 14px;
}
.arco-tag-size-large {
  height: 32px;
  line-height: 30px;
  font-size: 14px;
}
.arco-tag-hide {
  display: none;
}
.arco-tag-loading {
  opacity: 0.8;
  cursor: default;
}
.arco-tag-icon {
  margin-right: 4px;
  color: var(--color-text-2);
}
.arco-tag-checked.arco-tag-red {
  color: rgb(var(--red-6));
  background-color: rgb(var(--red-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-red .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--red-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-red:hover {
  background-color: rgb(var(--red-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-red.arco-tag-bordered,
.arco-tag-checked.arco-tag-red.arco-tag-bordered:hover {
  border-color: rgb(var(--red-6));
}
.arco-tag-checked.arco-tag-red .arco-tag-icon,
.arco-tag-checked.arco-tag-red .arco-tag-close-icon,
.arco-tag-checked.arco-tag-red .arco-tag-loading-icon {
  color: rgb(var(--red-6));
}
.arco-tag-checked.arco-tag-orangered {
  color: rgb(var(--orangered-6));
  background-color: rgb(var(--orangered-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-orangered .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--orangered-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-orangered:hover {
  background-color: rgb(var(--orangered-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-orangered.arco-tag-bordered,
.arco-tag-checked.arco-tag-orangered.arco-tag-bordered:hover {
  border-color: rgb(var(--orangered-6));
}
.arco-tag-checked.arco-tag-orangered .arco-tag-icon,
.arco-tag-checked.arco-tag-orangered .arco-tag-close-icon,
.arco-tag-checked.arco-tag-orangered .arco-tag-loading-icon {
  color: rgb(var(--orangered-6));
}
.arco-tag-checked.arco-tag-orange {
  color: rgb(var(--orange-6));
  background-color: rgb(var(--orange-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-orange .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--orange-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-orange:hover {
  background-color: rgb(var(--orange-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-orange.arco-tag-bordered,
.arco-tag-checked.arco-tag-orange.arco-tag-bordered:hover {
  border-color: gb(var(--orange-6));
}
.arco-tag-checked.arco-tag-orange .arco-tag-icon,
.arco-tag-checked.arco-tag-orange .arco-tag-close-icon,
.arco-tag-checked.arco-tag-orange .arco-tag-loading-icon {
  color: rgb(var(--orange-6));
}
.arco-tag-checked.arco-tag-gold {
  color: rgb(var(--gold-6));
  background-color: rgb(var(--gold-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-gold .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--gold-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-gold:hover {
  background-color: rgb(var(--gold-3));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-gold.arco-tag-bordered,
.arco-tag-checked.arco-tag-gold.arco-tag-bordered:hover {
  border-color: rgb(var(--gold-6));
}
.arco-tag-checked.arco-tag-gold .arco-tag-icon,
.arco-tag-checked.arco-tag-gold .arco-tag-close-icon,
.arco-tag-checked.arco-tag-gold .arco-tag-loading-icon {
  color: rgb(var(--gold-6));
}
.arco-tag-checked.arco-tag-lime {
  color: rgb(var(--lime-6));
  background-color: rgb(var(--lime-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-lime .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--lime-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-lime:hover {
  background-color: rgb(var(--lime-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-lime.arco-tag-bordered,
.arco-tag-checked.arco-tag-lime.arco-tag-bordered:hover {
  border-color: rgb(var(--lime-6));
}
.arco-tag-checked.arco-tag-lime .arco-tag-icon,
.arco-tag-checked.arco-tag-lime .arco-tag-close-icon,
.arco-tag-checked.arco-tag-lime .arco-tag-loading-icon {
  color: rgb(var(--lime-6));
}
.arco-tag-checked.arco-tag-green {
  color: rgb(var(--green-6));
  background-color: rgb(var(--green-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-green .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--green-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-green:hover {
  background-color: rgb(var(--green-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-green.arco-tag-bordered,
.arco-tag-checked.arco-tag-green.arco-tag-bordered:hover {
  border-color: rgb(var(--green-6));
}
.arco-tag-checked.arco-tag-green .arco-tag-icon,
.arco-tag-checked.arco-tag-green .arco-tag-close-icon,
.arco-tag-checked.arco-tag-green .arco-tag-loading-icon {
  color: rgb(var(--green-6));
}
.arco-tag-checked.arco-tag-cyan {
  color: rgb(var(--cyan-6));
  background-color: rgb(var(--cyan-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-cyan .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--cyan-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-cyan:hover {
  background-color: rgb(var(--cyan-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-cyan.arco-tag-bordered,
.arco-tag-checked.arco-tag-cyan.arco-tag-bordered:hover {
  border-color: rgb(var(--cyan-6));
}
.arco-tag-checked.arco-tag-cyan .arco-tag-icon,
.arco-tag-checked.arco-tag-cyan .arco-tag-close-icon,
.arco-tag-checked.arco-tag-cyan .arco-tag-loading-icon {
  color: rgb(var(--cyan-6));
}
.arco-tag-checked.arco-tag-blue {
  color: rgb(var(--blue-6));
  background-color: rgb(var(--blue-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-blue .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--blue-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-blue:hover {
  background-color: rgb(var(--blue-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-blue.arco-tag-bordered,
.arco-tag-checked.arco-tag-blue.arco-tag-bordered:hover {
  border-color: rgb(var(--blue-6));
}
.arco-tag-checked.arco-tag-blue .arco-tag-icon,
.arco-tag-checked.arco-tag-blue .arco-tag-close-icon,
.arco-tag-checked.arco-tag-blue .arco-tag-loading-icon {
  color: rgb(var(--blue-6));
}
.arco-tag-checked.arco-tag-arcoblue {
  color: rgb(var(--arcoblue-6));
  background-color: rgb(var(--arcoblue-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-arcoblue .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--arcoblue-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-arcoblue:hover {
  background-color: rgb(var(--arcoblue-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-arcoblue.arco-tag-bordered,
.arco-tag-checked.arco-tag-arcoblue.arco-tag-bordered:hover {
  border-color: rgb(var(--arcoblue-6));
}
.arco-tag-checked.arco-tag-arcoblue .arco-tag-icon,
.arco-tag-checked.arco-tag-arcoblue .arco-tag-close-icon,
.arco-tag-checked.arco-tag-arcoblue .arco-tag-loading-icon {
  color: rgb(var(--arcoblue-6));
}
.arco-tag-checked.arco-tag-purple {
  color: rgb(var(--purple-6));
  background-color: rgb(var(--purple-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-purple .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--purple-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-purple:hover {
  background-color: rgb(var(--purple-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-purple.arco-tag-bordered,
.arco-tag-checked.arco-tag-purple.arco-tag-bordered:hover {
  border-color: rgb(var(--purple-6));
}
.arco-tag-checked.arco-tag-purple .arco-tag-icon,
.arco-tag-checked.arco-tag-purple .arco-tag-close-icon,
.arco-tag-checked.arco-tag-purple .arco-tag-loading-icon {
  color: rgb(var(--purple-6));
}
.arco-tag-checked.arco-tag-pinkpurple {
  color: rgb(var(--pinkpurple-6));
  background-color: rgb(var(--pinkpurple-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-pinkpurple .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--pinkpurple-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-pinkpurple:hover {
  background-color: rgb(var(--pinkpurple-2));
  border-color: rgb(var(--pinkpurple-2));
}
.arco-tag-checked.arco-tag-pinkpurple.arco-tag-bordered,
.arco-tag-checked.arco-tag-pinkpurple.arco-tag-bordered:hover {
  border-color: rgb(var(--pinkpurple-6));
}
.arco-tag-checked.arco-tag-pinkpurple .arco-tag-icon,
.arco-tag-checked.arco-tag-pinkpurple .arco-tag-close-icon,
.arco-tag-checked.arco-tag-pinkpurple .arco-tag-loading-icon {
  color: transparent;
}
.arco-tag-checked.arco-tag-magenta {
  color: rgb(var(--magenta-6));
  background-color: rgb(var(--magenta-1));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-magenta .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--magenta-2));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-magenta:hover {
  background-color: rgb(var(--magenta-2));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-magenta.arco-tag-bordered,
.arco-tag-checked.arco-tag-magenta.arco-tag-bordered:hover {
  border-color: rgb(var(--magenta-6));
}
.arco-tag-checked.arco-tag-magenta .arco-tag-icon,
.arco-tag-checked.arco-tag-magenta .arco-tag-close-icon,
.arco-tag-checked.arco-tag-magenta .arco-tag-loading-icon {
  color: rgb(var(--magenta-6));
}
.arco-tag-checked.arco-tag-gray {
  color: rgb(var(--gray-6));
  background-color: rgb(var(--gray-2));
  border: 1px solid transparent;
}
.arco-tag-checked.arco-tag-gray .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgb(var(--gray-3));
}
.arco-tag-checkable.arco-tag-checked.arco-tag-gray:hover {
  background-color: rgb(var(--gray-3));
  border-color: transparent;
}
.arco-tag-checked.arco-tag-gray.arco-tag-bordered,
.arco-tag-checked.arco-tag-gray.arco-tag-bordered:hover {
  border-color: rgb(var(--gray-6));
}
.arco-tag-checked.arco-tag-gray .arco-tag-icon,
.arco-tag-checked.arco-tag-gray .arco-tag-close-icon,
.arco-tag-checked.arco-tag-gray .arco-tag-loading-icon {
  color: rgb(var(--gray-6));
}
.arco-tag-custom-color {
  color: var(--color-white);
}
.arco-tag-custom-color .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.arco-tag .arco-tag-close-btn {
  font-size: 12px;
  margin-left: 4px;
}
.arco-tag .arco-tag-close-btn > svg {
  position: relative;
}
.arco-tag-loading-icon {
  font-size: 12px;
  margin-left: 4px;
}
body[arco-theme='dark'] .arco-tag-checked {
  color: rgba(255, 255, 255, 0.9);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-red {
  background-color: rgba(var(--red-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-red
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--red-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-red:hover {
  background-color: rgba(var(--red-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-orangered {
  background-color: rgba(var(--orangered-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-orangered
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--orangered-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-orangered:hover {
  background-color: rgba(var(--orangered-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-orange {
  background-color: rgba(var(--orange-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-orange
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--orange-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-orange:hover {
  background-color: rgba(var(--orange-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-gold {
  background-color: rgba(var(--gold-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-gold
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--gold-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-gold:hover {
  background-color: rgba(var(--gold-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-lime {
  background-color: rgba(var(--lime-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-lime
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--lime-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-lime:hover {
  background-color: rgba(var(--lime-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-green {
  background-color: rgba(var(--green-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-green
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--green-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-green:hover {
  background-color: rgba(var(--green-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-cyan {
  background-color: rgba(var(--cyan-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-cyan
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--cyan-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-cyan:hover {
  background-color: rgba(var(--cyan-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-blue {
  background-color: rgba(var(--blue-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-blue
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--blue-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-blue:hover {
  background-color: rgba(var(--blue-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-arcoblue {
  background-color: rgba(var(--arcoblue-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-arcoblue
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--arcoblue-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-arcoblue:hover {
  background-color: rgba(var(--arcoblue-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-purple {
  background-color: rgba(var(--purple-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-purple
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--purple-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-purple:hover {
  background-color: rgba(var(--purple-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-pinkpurple {
  background-color: rgba(var(--pinkpurple-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-pinkpurple
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--pinkpurple-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-pinkpurple:hover {
  background-color: rgba(var(--pinkpurple-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-magenta {
  background-color: rgba(var(--magenta-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-magenta
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--magenta-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-magenta:hover {
  background-color: rgba(var(--magenta-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checked.arco-tag-gray {
  background-color: rgba(var(--gray-6), 0.2);
}
body[arco-theme='dark']
  .arco-tag-checked.arco-tag-gray
  .arco-icon-hover.arco-tag-icon-hover:hover::before {
  background-color: rgba(var(--gray-6), 0.35);
}
body[arco-theme='dark'] .arco-tag-checkable.arco-tag-checked.arco-tag-gray:hover {
  background-color: rgba(var(--gray-6), 0.35);
}
.arco-timeline-item {
  position: relative;
  color: var(--color-text-1);
  font-size: 14px;
  min-height: 78px;
  padding-left: 6px;
}
.arco-timeline-item-label {
  color: var(--color-text-3);
  font-size: 12px;
  line-height: 1.667;
}
.arco-timeline-item-content {
  line-height: 1.5715;
  font-size: 14px;
  color: var(--color-text-1);
  margin-bottom: 4px;
}
.arco-timeline-item-content-wrapper {
  margin-left: 16px;
  position: relative;
}
.arco-timeline-item.arco-timeline-item-last
  > .arco-timeline-item-dot-wrapper
  .arco-timeline-item-dot-line {
  display: none;
}
.arco-timeline-item-dot-wrapper {
  position: absolute;
  text-align: center;
  height: 100%;
  left: 0;
}
.arco-timeline-item-dot-wrapper .arco-timeline-item-dot-content {
  width: 6px;
  height: 22.001px;
  line-height: 22.001px;
  position: relative;
}
.arco-timeline-item-dot {
  width: 6px;
  height: 6px;
  border-radius: var(--border-radius-circle);
  box-sizing: border-box;
  color: rgb(var(--primary-6));
  margin-top: -50%;
  position: relative;
  top: 50%;
}
.arco-timeline-item-dot-solid {
  background-color: rgb(var(--primary-6));
}
.arco-timeline-item-dot-hollow {
  border: 2px solid rgb(var(--primary-6));
  background-color: var(--color-bg-2);
}
.arco-timeline-item-dot-custom {
  display: inline-flex;
  transform-origin: center;
  color: rgb(var(--primary-6));
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-bg-2);
  transform: translateX(-50%) translateY(-50%);
}
.arco-timeline-item-dot-custom svg {
  color: inherit;
}
.arco-timeline-item-dot-line {
  width: 1px;
  border-left-width: 1px;
  border-color: var(--color-neutral-3);
  left: 50%;
  transform: translateX(-50%);
  top: 18.0005px;
  bottom: -4.0005px;
  position: absolute;
  box-sizing: border-box;
}
.arco-timeline-alternate {
  overflow: hidden;
}
.arco-timeline-alternate .arco-timeline-item-vertical-left {
  padding-left: 0;
}
.arco-timeline-alternate .arco-timeline-item-vertical-left > .arco-timeline-item-dot-wrapper {
  left: 50%;
}
.arco-timeline-alternate .arco-timeline-item-vertical-left > .arco-timeline-item-content-wrapper {
  left: 50%;
  width: 50%;
  margin-left: 22px;
  padding-right: 22px;
}
.arco-timeline-alternate .arco-timeline-item-vertical-right {
  padding-right: 0;
}
.arco-timeline-alternate .arco-timeline-item-vertical-right > .arco-timeline-item-dot-wrapper {
  left: 50%;
}
.arco-timeline-alternate .arco-timeline-item-vertical-right > .arco-timeline-item-content-wrapper {
  left: 0;
  margin-left: -16px;
  margin-right: 0;
  text-align: right;
  width: 50%;
  padding-right: 16px;
}
.arco-timeline-right .arco-timeline-item-vertical-right {
  padding-right: 6px;
}
.arco-timeline-right .arco-timeline-item-vertical-right > .arco-timeline-item-dot-wrapper {
  right: 0;
  left: unset;
}
.arco-timeline-right .arco-timeline-item-vertical-right > .arco-timeline-item-content-wrapper {
  text-align: right;
  margin-left: 0;
  margin-right: 16px;
}
.arco-timeline-item-label-relative > .arco-timeline-item-label {
  position: absolute;
  top: 0;
  max-width: 100px;
  box-sizing: border-box;
}
.arco-timeline-item-vertical-left.arco-timeline-item-label-relative {
  margin-left: 100px;
}
.arco-timeline-item-vertical-left.arco-timeline-item-label-relative > .arco-timeline-item-label {
  text-align: right;
  padding-right: 16px;
  transform: translateX(-100%);
  left: 0;
}
.arco-timeline-item-vertical-right.arco-timeline-item-label-relative {
  margin-right: 100px;
}
.arco-timeline-item-vertical-right.arco-timeline-item-label-relative > .arco-timeline-item-label {
  text-align: left;
  padding-left: 16px;
  transform: translateX(100%);
  right: 0;
}
.arco-timeline-item-horizontal-top.arco-timeline-item-label-relative {
  margin-top: 50px;
}
.arco-timeline-item-horizontal-top.arco-timeline-item-label-relative > .arco-timeline-item-label {
  padding-bottom: 16px;
  transform: translateY(-100%);
}
.arco-timeline-item-horizontal-top.arco-timeline-item-label-relative > .arco-timeline-item-content {
  margin-bottom: 0;
}
.arco-timeline-item-horizontal-bottom.arco-timeline-item-label-relative {
  margin-bottom: 50px;
}
.arco-timeline-item-horizontal-bottom.arco-timeline-item-label-relative
  > .arco-timeline-item-content {
  margin-bottom: 0;
}
.arco-timeline-item-horizontal-bottom.arco-timeline-item-label-relative
  > .arco-timeline-item-label {
  top: unset;
  bottom: 0;
  text-align: left;
  padding-top: 16px;
  transform: translateY(100%);
}
.arco-timeline-alternate .arco-timeline-item-vertical-left.arco-timeline-item-label-relative {
  margin-left: 0;
}
.arco-timeline-alternate
  .arco-timeline-item-vertical-left.arco-timeline-item-label-relative
  > .arco-timeline-item-label {
  width: 50%;
  max-width: unset;
  transform: none;
  left: 0;
}
.arco-timeline-alternate .arco-timeline-item-vertical-right.arco-timeline-item-label-relative {
  margin-right: 0;
}
.arco-timeline-alternate .arco-timeline-item-horizontal-top.arco-timeline-item-label-relative {
  margin-top: 0;
}
.arco-timeline-alternate .arco-timeline-item-horizontal-bottom.arco-timeline-item-label-relative {
  margin-bottom: 0;
}
.arco-timeline-direction-horizontal {
  display: flex;
}
.arco-timeline-item-dot-line-is-horizontal {
  left: 12px;
  right: 4px;
  border-left: none;
  height: 1px;
  border-top-width: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: unset;
}
.arco-timeline-item-horizontal-bottom,
.arco-timeline-item-horizontal-top {
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  min-height: unset;
}
.arco-timeline-item-horizontal-bottom > .arco-timeline-item-dot-wrapper,
.arco-timeline-item-horizontal-top > .arco-timeline-item-dot-wrapper {
  height: auto;
  width: 100%;
  top: 0;
}
.arco-timeline-item-horizontal-bottom > .arco-timeline-item-dot-wrapper .arco-timeline-item-dot,
.arco-timeline-item-horizontal-top > .arco-timeline-item-dot-wrapper .arco-timeline-item-dot {
  margin-top: unset;
  top: unset;
}
.arco-timeline-item-horizontal-bottom
  > .arco-timeline-item-dot-wrapper
  .arco-timeline-item-dot-content,
.arco-timeline-item-horizontal-top
  > .arco-timeline-item-dot-wrapper
  .arco-timeline-item-dot-content {
  height: 6px;
  line-height: 6px;
}
.arco-timeline-item-horizontal-top {
  padding-top: 6px;
}
.arco-timeline-item-horizontal-top > .arco-timeline-item-dot-wrapper {
  bottom: unset;
  top: 0;
}
.arco-timeline-item-horizontal-top > .arco-timeline-item-content-wrapper {
  margin-top: 16px;
  margin-left: 0;
}
.arco-timeline-item-horizontal-bottom {
  padding-bottom: 6px;
}
.arco-timeline-item-horizontal-bottom > .arco-timeline-item-dot-wrapper {
  bottom: 0;
  top: unset;
}
.arco-timeline-item-horizontal-bottom > .arco-timeline-item-content-wrapper {
  margin-left: 0;
  margin-bottom: 16px;
}
.arco-timeline-alternate.arco-timeline-direction-horizontal {
  align-items: center;
  min-height: 200px;
  overflow: visible;
}
.arco-timeline-alternate.arco-timeline-direction-horizontal .arco-timeline-item-horizontal-bottom {
  transform: translateY(-50%);
  margin-top: 6px;
}
.arco-timeline-alternate.arco-timeline-direction-horizontal .arco-timeline-item-horizontal-top {
  margin-top: -6px;
  transform: translateY(50%);
}
.arco-timepicker {
  position: relative;
  display: flex;
  padding: 0;
  box-sizing: border-box;
}
.arco-timepicker-container {
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-neutral-3);
  background-color: var(--color-bg-popup);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.arco-timepicker-list {
  width: 64px;
  height: 224px;
  overflow: hidden;
  box-sizing: border-box;
  scrollbar-width: none;
}
.arco-timepicker-list::-webkit-scrollbar {
  width: 0;
}
.arco-timepicker-list:not(:last-child) {
  border-right: 1px solid var(--color-neutral-3);
}
.arco-timepicker-list:hover {
  overflow-y: auto;
}
.arco-timepicker-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.arco-timepicker-list ul::after {
  content: '';
  display: block;
  width: 100%;
  height: 192px;
}
.arco-timepicker-cell {
  padding: 8px / 2 0;
  text-align: center;
  color: var(--color-text-1);
  font-weight: 500;
  cursor: pointer;
}
.arco-timepicker-cell-inner {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.arco-timepicker-cell:not(.arco-timepicker-cell-selected):not(.arco-timepicker-cell-disabled):hover
  .arco-timepicker-cell-inner {
  background-color: var(--color-fill-2);
}
.arco-timepicker-cell-selected .arco-timepicker-cell-inner {
  background-color: var(--color-fill-2);
  font-weight: 500;
}
.arco-timepicker-cell-disabled {
  color: var(--color-text-4);
  cursor: not-allowed;
}
.arco-timepicker-footer-extra-wrapper {
  border-top: 1px solid var(--color-neutral-3);
  padding: 8px 8px;
  color: var(--color-text-1);
  font-size: 12px;
}
.arco-timepicker-footer-btn-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-neutral-3);
  padding: 8px 8px;
}
.arco-tooltip-content {
  padding: 8px 12px;
  background-color: var(--color-tooltip-bg);
  font-size: 14px;
  border-radius: var(--border-radius-small);
  color: #fff;
  line-height: 1.5715;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.arco-tooltip-content-inner {
  word-wrap: break-word;
  text-align: left;
}
.arco-tooltip-mini {
  font-size: 14px;
  padding: 4px 12px;
}
.arco-trigger-arrow.arco-tooltip-arrow {
  background-color: var(--color-tooltip-bg);
}
body[arco-theme='dark'] .arco-tooltip-content {
  border: 1px solid var(--color-neutral-3);
}
body[arco-theme='dark'] .arco-tooltip .arco-trigger-arrow.arco-tooltip-arrow {
  border: 1px solid var(--color-neutral-3);
  border-top: none;
  border-left: none;
  z-index: 1;
}
.arco-transfer {
  display: flex;
  align-items: center;
}
.arco-transfer-view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 200px;
  height: 224px;
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-small);
}
.arco-transfer-view-search {
  padding: 8px 12px 4px 12px;
}
.arco-transfer-view-list {
  flex: 1;
}
.arco-transfer-view-custom-list {
  flex: 1;
  overflow: auto;
}
.arco-transfer-view-header,
.arco-transfer-view-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.arco-transfer-view-header > *:first-child,
.arco-transfer-view-item .arco-transfer-view-item-content {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-transfer-view-header > *:first-child:not(:last-child),
.arco-transfer-view-item .arco-transfer-view-item-content:not(:last-child) {
  margin-right: 8px;
}
.arco-transfer-view-header {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--color-fill-1);
  color: var(--color-text-1);
}
.arco-transfer-view-header-title {
  display: flex;
  align-items: center;
}
.arco-transfer-view-header-title .arco-checkbox {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: inherit;
}
.arco-transfer-view-header-title .arco-checkbox-text {
  color: inherit;
}
.arco-transfer-view-header-unit {
  margin-right: 2px;
  font-weight: normal;
  font-size: 12px;
  color: var(--color-text-3);
}
.arco-transfer-view-item {
  position: relative;
  height: 36px;
  line-height: 36px;
  list-style: none;
  color: var(--color-text-1);
  background-color: transparent;
  cursor: default;
}
.arco-transfer-view-item-content {
  font-size: 14px;
}
.arco-transfer-view-item-disabled {
  color: var(--color-text-4);
  background-color: transparent;
  cursor: not-allowed;
}
.arco-transfer-view-item:not(.arco-transfer-view-item-disabled):hover {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}
.arco-transfer-view-item .arco-checkbox {
  width: 100%;
}
.arco-transfer-view-item .arco-checkbox-text {
  color: inherit;
}
.arco-transfer-view-item-draggable::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 1px;
}
.arco-transfer-view-item-gap-bottom::before {
  bottom: -2px;
  background-color: rgb(var(--primary-6));
}
.arco-transfer-view-item-gap-top::before {
  top: -2px;
  background-color: rgb(var(--primary-6));
}
.arco-transfer-view-item-dragging {
  background-color: var(--color-fill-1) !important;
  color: var(--color-text-4) !important;
}
.arco-transfer-view-item-dragged {
  animation: arco-transfer-drag-item-blink 0.4s;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}
.arco-transfer-view .arco-transfer-view-icon-clear,
.arco-transfer-view .arco-transfer-view-item-icon-remove {
  cursor: pointer;
  font-size: 12px;
  color: var(--color-text-2);
}
.arco-transfer-view .arco-transfer-view-icon-clear:hover::before,
.arco-transfer-view .arco-transfer-view-item-icon-remove:hover::before {
  background-color: var(--color-fill-3);
}
.arco-transfer-view .arco-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0;
}
.arco-transfer-view .arco-list-content {
  flex: 1;
  overflow-y: auto;
}
.arco-transfer-view .arco-list-footer {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 40px;
  padding: 0 8px;
}
.arco-transfer-view .arco-list .arco-pagination {
  position: absolute;
  top: 50%;
  right: 8px;
  margin: 0;
  transform: translateY(-50%);
}
.arco-transfer-view .arco-list .arco-pagination-jumper-input {
  width: 24px;
}
.arco-transfer-view .arco-list .arco-pagination-jumper-separator {
  padding: 0 8px;
}
.arco-transfer-view .arco-checkbox {
  padding-left: 6px;
}
.arco-transfer-view .arco-checkbox-wrapper {
  display: inline;
}
.arco-transfer-view .arco-checkbox .arco-icon-hover:hover::before {
  background-color: var(--color-fill-3);
}
.arco-transfer-operations {
  padding: 0 20px;
}
.arco-transfer-operations .arco-btn {
  display: block;
}
.arco-transfer-operations .arco-btn:last-child {
  margin-top: 12px;
}
.arco-transfer-operations-words .arco-btn {
  width: 100%;
  padding: 0 12px;
  text-align: left;
}
.arco-transfer-simple .arco-transfer-view-source {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.arco-transfer-simple .arco-transfer-view-target {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.arco-transfer-disabled .arco-transfer-view-header {
  color: var(--color-text-4);
}
@keyframes arco-transfer-drag-item-blink {
  0% {
    background-color: var(--color-primary-light-1);
  }
  100% {
    background-color: transparent;
  }
}
.arco-icon-hover.arco-tree-node-icon-hover::before {
  width: 16px;
  height: 16px;
}
.arco-tree-node-switcher {
  font-size: 12px;
  color: var(--color-text-2);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 10px;
  height: 32px;
  width: 12px;
  flex-shrink: 0;
}
.arco-tree-node-switcher-icon {
  position: relative;
  margin: 0 auto;
}
.arco-tree-node-switcher-icon svg {
  transform: rotate(-90deg);
  position: relative;
  transition: transform 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-tree-node-expanded .arco-tree-node-switcher-icon svg,
.arco-tree-node-is-leaf .arco-tree-node-switcher-icon svg {
  transform: rotate(0);
}
.arco-tree-node-drag-icon {
  color: rgb(var(--primary-6));
  opacity: 0;
  margin-left: 120px;
}
.arco-tree-node-custom-icon {
  font-size: inherit;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
}
.arco-tree-node .arco-icon-loading {
  color: rgb(var(--primary-6));
}
.arco-tree-node-minus-icon,
.arco-tree-node-plus-icon {
  cursor: pointer;
  background: var(--color-fill-2);
  border-radius: var(--border-radius-small);
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
}
.arco-tree-node-minus-icon::after,
.arco-tree-node-plus-icon::after {
  content: '';
  position: absolute;
  display: block;
  color: var(--color-text-2);
  width: 6px;
  height: 2px;
  background-color: var(--color-text-2);
  border-radius: 0.5px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -3px;
}
.arco-tree-node-plus-icon::before {
  content: '';
  border-radius: 0.5px;
  position: absolute;
  display: block;
  color: var(--color-text-2);
  height: 6px;
  width: 2px;
  background-color: var(--color-text-2);
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -3px;
}
.arco-tree {
  color: var(--color-text-1);
}
.arco-tree .arco-checkbox {
  margin-right: 10px;
  padding-left: 0;
  line-height: 32px;
}
.arco-tree-node {
  padding-left: 2px;
  color: var(--color-text-1);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  line-height: 1.5715;
}
.arco-tree-node-selected .arco-tree-node-title,
.arco-tree-node-selected .arco-tree-node-title:hover {
  color: rgb(var(--primary-6));
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-tree-node-disabled-selectable .arco-tree-node-title,
.arco-tree-node-disabled .arco-tree-node-title,
.arco-tree-node-disabled-selectable .arco-tree-node-title:hover,
.arco-tree-node-disabled .arco-tree-node-title:hover {
  background: none;
  color: var(--color-text-4);
  cursor: not-allowed;
}
.arco-tree-node-disabled.arco-tree-node-selected .arco-tree-node-title {
  color: var(--color-primary-light-3);
}
.arco-tree-node-title-block {
  flex: 1;
  box-sizing: content-box;
}
.arco-tree-node-title-block .arco-tree-node-drag-icon {
  position: absolute;
  right: 12px;
}
.arco-tree-node-indent {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.arco-tree-node-indent-block {
  display: inline-block;
  width: 12px;
  margin-right: 10px;
  height: 100%;
  position: relative;
}
.arco-tree-node-title {
  font-size: 14px;
  padding: 5px 0;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: -4px;
  border-radius: var(--border-radius-small);
  position: relative;
}
.arco-tree-node-title:hover {
  background-color: var(--color-fill-2);
  color: var(--color-text-1);
}
.arco-tree-node-title:hover .arco-tree-node-drag-icon {
  opacity: 1;
}
.arco-tree-node-title-draggable::before {
  content: '';
  display: block;
  height: 2px;
  border-radius: 1px;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}
.arco-tree-node-title-gap-bottom::before {
  top: unset;
  bottom: 0;
  background-color: rgb(var(--primary-6));
}
.arco-tree-node-title-gap-top::before {
  background-color: rgb(var(--primary-6));
}
.arco-tree-node-title-highlight {
  background-color: var(--color-primary-light-1);
  color: var(--color-text-1);
}
.arco-tree-node-title-dragging,
.arco-tree-node-title-dragging:hover {
  background-color: var(--color-fill-1);
  color: rgb(var(--primary-1));
}
.arco-tree-show-line {
  padding-left: 1px;
}
.arco-tree-show-line .arco-tree-node-switcher {
  width: 14px;
  text-align: center;
}
.arco-tree-show-line .arco-tree-node-switcher .arco-tree-node-icon-hover {
  width: 100%;
}
.arco-tree-show-line .arco-tree-node-indent-block {
  width: 14px;
}
.arco-tree-show-line .arco-tree-node-indent-block::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  border-left: 1px solid var(--color-neutral-3);
  content: '';
  box-sizing: border-box;
  top: -5px;
  bottom: -5px;
}
.arco-tree-show-line
  .arco-tree-node-is-leaf:not(.arco-tree-node-is-tail)
  .arco-tree-node-indent::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  border-left: 1px solid var(--color-neutral-3);
  width: 1px;
  right: -7px;
  transform: translateX(50%);
  top: 27px;
  bottom: -5px;
}
.arco-tree-show-line .arco-tree-node-indent-block-lineless::before {
  display: none;
}
.arco-tree-size-mini .arco-tree-node-switcher {
  height: 24px;
}
.arco-tree-size-mini .arco-checkbox {
  line-height: 24px;
}
.arco-tree-size-mini .arco-tree-node-title {
  font-size: 12px;
  line-height: 1.667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.arco-tree-size-mini .arco-tree-node-indent-block::before {
  top: 23px;
  bottom: -1px;
}
.arco-tree-size-mini
  .arco-tree-node-is-leaf:not(.arco-tree-node-is-tail)
  .arco-tree-node-indent::after {
  top: -1px;
  bottom: -1px;
}
.arco-tree-size-small .arco-tree-node-switcher {
  height: 28px;
}
.arco-tree-size-small .arco-checkbox {
  line-height: 28px;
}
.arco-tree-size-small .arco-tree-node-title {
  font-size: 14px;
  padding-top: 6px / 2;
  padding-bottom: 6px / 2;
}
.arco-tree-size-small .arco-tree-node-indent-block::before {
  top: 25px;
  bottom: -3px;
}
.arco-tree-size-small
  .arco-tree-node-is-leaf:not(.arco-tree-node-is-tail)
  .arco-tree-node-indent::after {
  top: -3px;
  bottom: -3px;
}
.arco-tree-size-large .arco-tree-node-switcher {
  height: 36px;
}
.arco-tree-size-large .arco-checkbox {
  line-height: 36px;
}
.arco-tree-size-large .arco-tree-node-title {
  font-size: 14px;
  padding-top: 14px / 2;
  padding-bottom: 14px / 2;
}
.arco-tree-size-large .arco-tree-node-indent-block::before {
  top: 29px;
  bottom: -7px;
}
.arco-tree-size-large
  .arco-tree-node-is-leaf:not(.arco-tree-node-is-tail)
  .arco-tree-node-indent::after {
  top: -7px;
  bottom: -7px;
}
.arco-tree-node-list {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-tree-select .arco-tree-select-view {
  color: var(--color-text-1);
  background-color: transparent;
  border: 1px solid var(--color-neutral-3);
}
.arco-tree-select:hover .arco-tree-select-view {
  background-color: transparent;
  border-color: rgb(var(--primary-6));
}
.arco-tree-select.arco-tree-select-focused .arco-tree-select-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
  box-shadow: 0 0 0 0 var(--color-primary-light-2);
}
.arco-tree-select .arco-tree-select-suffix-icon,
.arco-tree-select .arco-tree-select-loading-icon,
.arco-tree-select .arco-tree-select-search-icon,
.arco-tree-select .arco-tree-select-clear-icon,
.arco-tree-select .arco-tree-select-arrow-icon,
.arco-tree-select .arco-tree-select-expand-icon {
  color: var(--color-text-2);
}
.arco-tree-select-no-border .arco-tree-select-view {
  border: none !important;
  background: none !important;
}
.arco-tree-select-error .arco-tree-select-view {
  background-color: var(--color-danger-light-1);
  border: 1px solid transparent;
}
.arco-tree-select-error:hover .arco-tree-select-view {
  background-color: var(--color-danger-light-2);
  border-color: transparent;
}
.arco-tree-select-error.arco-tree-select-focused .arco-tree-select-view {
  color: var(--color-text-1);
  background-color: var(--color-bg-2);
  border-color: rgb(var(--danger-6));
  box-shadow: 0 0 0 0 var(--color-danger-light-2);
}
.arco-tree-select-no-border .arco-tree-select-view {
  border: none !important;
  background: none !important;
}
.arco-tree-select-disabled .arco-tree-select-view {
  color: var(--color-text-4);
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
}
.arco-tree-select-disabled:hover .arco-tree-select-view {
  background-color: var(--color-fill-2);
  border-color: transparent;
}
.arco-tree-select-disabled .arco-tree-select-suffix-icon,
.arco-tree-select-disabled .arco-tree-select-loading-icon,
.arco-tree-select-disabled .arco-tree-select-search-icon,
.arco-tree-select-disabled .arco-tree-select-clear-icon,
.arco-tree-select-disabled .arco-tree-select-arrow-icon,
.arco-tree-select-disabled .arco-tree-select-expand-icon {
  color: var(--color-text-4);
}
.arco-tree-select-no-border .arco-tree-select-view {
  border: none !important;
  background: none !important;
}
.arco-tree-select-size-mini.arco-tree-select-multiple .arco-tree-select-view {
  height: auto;
  font-size: 12px;
  padding: 0 3px;
  line-height: 0;
}
.arco-tree-select-size-mini.arco-tree-select-multiple .arco-input-tag-has-placeholder input,
.arco-tree-select-size-mini.arco-tree-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 4px;
}
.arco-tree-select-size-mini.arco-tree-select-multiple .arco-tree-select-suffix {
  padding-right: 4px;
}
.arco-tree-select-size-mini.arco-tree-select-multiple input {
  font-size: 12px;
}
.arco-tree-select-size-mini.arco-tree-select-single .arco-tree-select-view {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 7px;
}
.arco-tree-select-size-mini.arco-tree-select-single input {
  font-size: 12px;
}
.arco-tree-select-size-mini.arco-tree-select-multiple .arco-tree-select-view-with-prefix {
  padding-left: 7px;
}
.arco-tree-select-size-small.arco-tree-select-multiple .arco-tree-select-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-tree-select-size-small.arco-tree-select-multiple .arco-input-tag-has-placeholder input,
.arco-tree-select-size-small.arco-tree-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-tree-select-size-small.arco-tree-select-multiple .arco-tree-select-suffix {
  padding-right: 8px;
}
.arco-tree-select-size-small.arco-tree-select-multiple input {
  font-size: 14px;
}
.arco-tree-select-size-small.arco-tree-select-single .arco-tree-select-view {
  height: 28px;
  line-height: 26px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-tree-select-size-small.arco-tree-select-single input {
  font-size: 14px;
}
.arco-tree-select-size-small.arco-tree-select-multiple .arco-tree-select-view-with-prefix {
  padding-left: 11px;
}
.arco-tree-select-size-default.arco-tree-select-multiple .arco-tree-select-view {
  height: auto;
  font-size: 14px;
  padding: 0 3px;
  line-height: 0;
}
.arco-tree-select-size-default.arco-tree-select-multiple .arco-input-tag-has-placeholder input,
.arco-tree-select-size-default.arco-tree-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 8px;
}
.arco-tree-select-size-default.arco-tree-select-multiple .arco-tree-select-suffix {
  padding-right: 8px;
}
.arco-tree-select-size-default.arco-tree-select-multiple input {
  font-size: 14px;
}
.arco-tree-select-size-default.arco-tree-select-single .arco-tree-select-view {
  height: 32px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 11px;
}
.arco-tree-select-size-default.arco-tree-select-single input {
  font-size: 14px;
}
.arco-tree-select-size-default.arco-tree-select-multiple .arco-tree-select-view-with-prefix {
  padding-left: 11px;
}
.arco-tree-select-size-large.arco-tree-select-multiple .arco-tree-select-view {
  height: auto;
  font-size: 16px;
  padding: 0 3px;
  line-height: 0;
}
.arco-tree-select-size-large.arco-tree-select-multiple .arco-input-tag-has-placeholder input,
.arco-tree-select-size-large.arco-tree-select-multiple
  .arco-input-tag-has-placeholder
  .arco-input-tag-input-mirror {
  box-sizing: border-box;
  padding-left: 12px;
}
.arco-tree-select-size-large.arco-tree-select-multiple .arco-tree-select-suffix {
  padding-right: 12px;
}
.arco-tree-select-size-large.arco-tree-select-multiple input {
  font-size: 16px;
}
.arco-tree-select-size-large.arco-tree-select-single .arco-tree-select-view {
  height: 36px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 15px;
}
.arco-tree-select-size-large.arco-tree-select-single input {
  font-size: 16px;
}
.arco-tree-select-size-large.arco-tree-select-multiple .arco-tree-select-view-with-prefix {
  padding-left: 15px;
}
.arco-tree-select {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
.arco-tree-select-view {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius-small);
  outline: none;
  user-select: none;
  text-align: left;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1), padding 0s linear;
}
.arco-tree-select-view input {
  color: inherit;
  cursor: inherit;
}
.arco-tree-select-view input::placeholder {
  color: var(--color-text-3);
}
.arco-tree-select-multiple,
.arco-tree-select-show-search {
  cursor: text;
}
.arco-tree-select-disabled {
  cursor: not-allowed;
}
.arco-tree-select-disabled .arco-tree-select-view input::placeholder {
  color: var(--color-text-4);
}
.arco-tree-select-single .arco-tree-select-view-input {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-tree-select-single .arco-tree-select-view-value {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arco-tree-select-single .arco-tree-select-view-value::after {
  content: '.';
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}
.arco-tree-select-single .arco-tree-select-view .arco-tree-select-hidden {
  width: 0 !important;
  position: absolute;
}
.arco-tree-select-multiple {
  vertical-align: top;
}
.arco-tree-select-multiple .arco-tree-select-view {
  padding: 0 4px;
  line-height: 0;
}
.arco-tree-select-multiple .arco-tree-select-view-with-prefix {
  padding-left: 12px;
}
.arco-tree-select-multiple .arco-input-tag {
  flex: 1;
  padding: 0;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  overflow: hidden;
}
.arco-tree-select-multiple .arco-tag {
  max-width: 100%;
}
.arco-tree-select-multiple:not(.arco-tree-select-focused)
  .arco-input-tag
  input:not(:first-child)[value=''] {
  width: 0 !important;
  position: absolute;
}
.arco-tree-select-prefix {
  display: flex;
  align-items: center;
  margin-right: 12px;
  white-space: nowrap;
  color: var(--color-text-2);
}
.arco-tree-select-suffix {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.arco-tree-select-suffix-icon,
.arco-tree-select-search-icon,
.arco-tree-select-loading-icon,
.arco-tree-select-expand-icon,
.arco-tree-select-clear-icon {
  font-size: 12px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-tree-select-arrow-icon {
  font-size: 12px;
}
.arco-tree-select-open .arco-tree-select-arrow-icon svg {
  transform: rotateZ(180deg);
}
.arco-tree-select .arco-tree-select-clear-icon {
  display: none;
  cursor: pointer;
}
.arco-tree-select .arco-tree-select-clear-icon > svg {
  position: relative;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-tree-select:hover .arco-tree-select-clear-icon {
  display: block;
}
.arco-tree-select:hover .arco-tree-select-clear-icon ~ * {
  display: none;
}
.arco-tree-select-popup {
  box-sizing: border-box;
  border: 1px solid var(--color-fill-3);
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-popup);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 4px 0;
  padding-left: 10px;
  padding-right: 4px;
  max-height: 200px;
  overflow: auto;
}
.arco-tree-select-popup .arco-tree-node {
  padding-left: 0;
}
.arco-tree-select-highlight {
  font-weight: 500;
}
.arco-trigger {
  position: absolute;
  z-index: 999999;
  background-color: var(--white);
  backface-visibility: hidden;
}
.arco-trigger-arrow {
  background-color: var(--color-bg-5);
  content: '';
  height: 8px;
  width: 8px;
  position: absolute;
  display: block;
  box-sizing: border-box;
  transform: rotate(45deg);
  transform-origin: 50% 50% 0;
  z-index: -1;
}
.arco-trigger[trigger-placement='top'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='tl'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='tr'] .arco-trigger-arrow {
  bottom: -4px;
  margin-left: -4px;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 2px;
}
.arco-trigger[trigger-placement='bottom'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='bl'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='br'] .arco-trigger-arrow {
  top: -4px;
  margin-left: -4px;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 2px;
}
.arco-trigger[trigger-placement='left'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='lt'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='lb'] .arco-trigger-arrow {
  right: -4px;
  margin-top: -4px;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 2px;
}
.arco-trigger[trigger-placement='right'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='rt'] .arco-trigger-arrow,
.arco-trigger[trigger-placement='rb'] .arco-trigger-arrow {
  left: -4px;
  margin-top: -4px;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 2px;
}
.arco-typography {
  color: var(--color-text-1);
  line-height: 1.5715;
  word-break: break-all;
}
h1.arco-typography,
h2.arco-typography,
h3.arco-typography,
h4.arco-typography,
h5.arco-typography,
h6.arco-typography {
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
h1.arco-typography {
  font-size: 36px;
  line-height: 1.23;
}
h2.arco-typography {
  font-size: 32px;
  line-height: 1.25;
}
h3.arco-typography {
  font-size: 28px;
  line-height: 1.29;
}
h4.arco-typography {
  font-size: 24px;
  line-height: 1.33;
}
h5.arco-typography {
  font-size: 20px;
  line-height: 1.4;
}
h6.arco-typography {
  font-size: 16px;
  line-height: 1.5;
}
div.arco-typography,
p.arco-typography {
  margin-top: 0;
  margin-bottom: 1em;
}
.arco-typography-simple-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arco-typography-primary {
  color: rgb(var(--primary-6));
}
.arco-typography-secondary {
  color: var(--color-text-2);
}
.arco-typography-success {
  color: rgb(var(--success-6));
}
.arco-typography-warning {
  color: rgb(var(--warning-6));
}
.arco-typography-error {
  color: rgb(var(--danger-6));
}
.arco-typography-disabled {
  color: var(--color-text-4);
  cursor: not-allowed;
}
.arco-typography mark {
  background-color: rgb(var(--yellow-4));
}
.arco-typography u {
  text-decoration: underline;
}
.arco-typography del {
  text-decoration: line-through;
}
.arco-typography b {
  font-weight: 500;
}
.arco-typography code {
  font-size: 85%;
  color: var(--color-text-2);
  border: 1px solid var(--color-neutral-3);
  background-color: var(--color-neutral-2);
  padding: 2px 8px;
  margin: 0 2px;
  border-radius: 2px;
}
.arco-typography blockquote {
  margin: 0;
  margin-bottom: 1em;
  border-left: 2px solid var(--color-neutral-6);
  background-color: var(--color-bg-2);
  padding-left: 8px;
}
.arco-typography ol,
.arco-typography ul {
  padding: 0;
  margin: 0;
}
.arco-typography ul li,
.arco-typography ol li {
  margin-left: 20px;
}
.arco-typography ul {
  list-style: circle;
}
.arco-typography-spacing-close {
  line-height: 1.3;
}
.arco-typography-operation-copy,
.arco-typography-operation-copied {
  padding: 2px;
  margin-left: 2px;
}
.arco-typography-operation-copy {
  cursor: pointer;
  color: var(--color-text-2);
  background-color: transparent;
  border-radius: 2px;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-typography-operation-copy:hover {
  color: var(--color-text-2);
  background-color: var(--color-fill-2);
}
.arco-typography-operation-copied {
  color: rgb(var(--success-6));
}
.arco-typography-operation-edit {
  padding: 2px;
  margin-left: 2px;
  cursor: pointer;
  color: var(--color-text-2);
  background-color: transparent;
  border-radius: 2px;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-typography-operation-edit:hover {
  color: var(--color-text-2);
  background-color: var(--color-fill-2);
}
.arco-typography-operation-expand {
  color: rgb(var(--primary-6));
  margin: 0 4px;
  cursor: pointer;
}
.arco-typography-operation-expand:hover {
  color: rgb(var(--primary-5));
}
.arco-typography-edit-content {
  position: relative;
  left: -13px;
  margin-right: -13px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.arco-upload {
  display: inline-block;
  max-width: 100%;
}
.arco-upload-type-picture-card {
  vertical-align: top;
}
.arco-upload-drag {
  width: 100%;
}
.arco-upload-hide {
  display: none;
}
.arco-upload-disabled .arco-upload-trigger-picture,
.arco-upload-disabled .arco-upload-trigger-picture:hover {
  cursor: not-allowed;
  border-color: var(--color-neutral-4);
  background-color: var(--color-fill-1);
  color: var(--color-text-4);
}
.arco-upload-disabled .arco-upload-trigger-drag,
.arco-upload-disabled .arco-upload-trigger-drag:hover {
  cursor: not-allowed;
  border-color: var(--color-text-4);
  background-color: var(--color-fill-1);
}
.arco-upload-disabled .arco-upload-trigger-drag .arco-icon-plus,
.arco-upload-disabled .arco-upload-trigger-drag:hover .arco-icon-plus,
.arco-upload-disabled .arco-upload-trigger-drag .arco-upload-trigger-drag-text,
.arco-upload-disabled .arco-upload-trigger-drag:hover .arco-upload-trigger-drag-text {
  color: var(--color-text-4);
}
.arco-upload-disabled .arco-upload-trigger-tip {
  color: var(--color-text-4);
}
.arco-upload-trigger {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.arco-upload-trigger-tip {
  color: var(--color-text-3);
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.arco-upload-trigger-picture {
  min-width: 80px;
  height: 80px;
  color: var(--color-text-2);
  text-align: center;
  margin-bottom: 0;
  background: var(--color-fill-2);
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-neutral-3);
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-upload-trigger-picture:hover {
  border-color: var(--color-neutral-4);
  background-color: var(--color-fill-3);
  color: var(--color-text-2);
}
.arco-upload-trigger-picture-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.arco-upload-trigger-drag {
  width: 100%;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--color-neutral-3);
  background-color: var(--color-fill-1);
  text-align: center;
  transition: all 0.2s ease;
  color: var(--color-text-1);
  padding: 50px 0;
}
.arco-upload-trigger-drag .arco-icon-plus {
  font-size: 14px;
  margin-bottom: 24px;
  color: var(--color-text-2);
}
.arco-upload-trigger-drag:hover {
  border-color: var(--color-neutral-4);
  background-color: var(--color-fill-3);
}
.arco-upload-trigger-drag:hover .arco-upload-trigger-drag-text {
  color: var(--color-text-1);
}
.arco-upload-trigger-drag:hover .arco-icon-plus {
  color: var(--color-text-2);
}
.arco-upload-trigger-drag-active {
  border-color: rgb(var(--primary-6));
  color: var(--color-text-1);
  background-color: var(--color-primary-light-1);
}
.arco-upload-trigger-drag-active .arco-upload-trigger-drag-text {
  color: var(--color-text-1);
}
.arco-upload-trigger-drag-active .arco-icon-plus {
  color: rgb(var(--primary-6));
}
.arco-upload-trigger-drag .arco-upload-trigger-tip {
  margin-top: 0;
}
.arco-upload-trigger-drag-text {
  color: var(--color-text-1);
  line-height: 1.5;
  font-size: 14px;
}
.arco-upload-hide + .arco-upload-list .arco-upload-list-item:first-of-type {
  margin-top: 0;
}
.arco-upload-list {
  width: 100%;
}
.arco-upload-list-type-text .arco-upload-list-item:first-of-type,
.arco-upload-list-type-picture-list .arco-upload-list-item:first-of-type {
  margin-top: 24px;
}
.arco-upload-list-file-icon {
  line-height: 16px;
  font-size: 16px;
  color: rgb(var(--primary-6));
  margin-right: 12px;
}
.arco-upload-list-preview-icon {
  cursor: pointer;
}
.arco-upload-list-error-icon {
  cursor: pointer;
  color: rgb(var(--danger-6));
  font-size: 14px;
  margin-left: 4px;
}
.arco-upload-list-success-icon {
  cursor: pointer;
  color: rgb(var(--success-6));
  font-size: 14px;
  line-height: 14px;
}
.arco-upload-list-remove-icon {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}
.arco-upload-list-start-icon,
.arco-upload-list-cancel-icon {
  position: absolute;
  color: var(--color-white);
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  font-size: 12px;
}
.arco-upload-list-reupload-icon {
  cursor: pointer;
  color: rgb(var(--primary-6));
  font-size: 14px;
  transition: all 0.2s ease;
}
.arco-upload-list-reupload-icon:active,
.arco-upload-list-reupload-icon:hover {
  color: rgb(var(--primary-7));
}
.arco-upload-list-status {
  position: relative;
  cursor: pointer;
  line-height: 12px;
}
.arco-upload-list-status:hover .arco-progress-circle-mask {
  stroke: rgba(var(--gray-10), 0.2);
}
.arco-upload-list-status:hover .arco-progress-circle-path {
  stroke: rgb(var(--primary-7));
}
.arco-upload-list-item-done .arco-upload-list-item-text-file-icon {
  color: rgb(var(--success-6));
}
.arco-upload-list-item {
  box-sizing: border-box;
  padding-right: 24px;
  margin-top: 12px;
  position: relative;
}
.arco-upload-list-item-operation {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-text-2);
}
.arco-upload-list-item-operation .arco-upload-list-remove-icon {
  font-size: inherit;
}
.arco-upload-list-item-text {
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-fill-1);
  padding: 8px 0;
  padding-left: 12px;
  padding-right: 10px;
  flex-wrap: nowrap;
}
.arco-upload-list-item-text-content {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-upload-list-item-text-thumbnail {
  height: 40px;
  width: 40px;
  margin-right: 12px;
  flex-shrink: 0;
}
.arco-upload-list-item-text-thumbnail img {
  width: 100%;
  height: 100%;
}
.arco-upload-list-item-text-name {
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: center;
  color: var(--color-text-1);
  font-size: 14px;
  text-overflow: ellipsis;
  line-height: 1.4286;
  margin-right: 10px;
}
.arco-upload-list-item-text-name-link {
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  color: rgb(var(--link-6));
  text-overflow: ellipsis;
}
.arco-upload-list-item-text-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.arco-upload-list-item-error .arco-upload-list-status,
.arco-upload-list-item-done .arco-upload-list-status {
  display: none;
}
.arco-upload-list-type-text .arco-upload-list-item-error .arco-upload-list-item-text-name-link,
.arco-upload-list-type-text .arco-upload-list-item-error .arco-upload-list-item-text-name {
  color: rgb(var(--danger-6));
}
.arco-upload-list.arco-upload-list-type-picture-card {
  display: inline;
  vertical-align: top;
}
.arco-upload-list.arco-upload-list-type-picture-card .arco-upload-list-status {
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0;
}
.arco-upload-list-type-picture-card .arco-upload-list-item {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  padding-right: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-upload-list-type-picture-card
  .arco-upload-list-item-error
  .arco-upload-list-item-picture-mask {
  opacity: 1;
}
.arco-upload-list-item-picture {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  text-align: center;
  vertical-align: top;
}
.arco-upload-list-item-picture img {
  width: 100%;
  height: 100%;
}
.arco-upload-list-item-picture-mask {
  cursor: pointer;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
  font-size: 16px;
  line-height: 80px;
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-upload-list-item-picture-operation {
  display: none;
  font-size: 14px;
}
.arco-upload-list-item-picture-operation .arco-upload-list-reupload-icon {
  color: var(--color-white);
}
.arco-upload-list-item-picture-operation
  .arco-upload-list-reupload-icon
  + .arco-upload-list-remove-icon,
.arco-upload-list-item-picture-operation
  .arco-upload-list-preview-icon
  + .arco-upload-list-remove-icon {
  margin-left: 20px;
}
.arco-upload-list-item-picture-error-tip .arco-upload-list-error-icon {
  font-size: 26px;
  color: var(--color-white);
}
.arco-upload-list-item-picture-mask:hover,
.arco-upload-list-item-picture-mask:hover .arco-upload-list-item-picture-operation {
  opacity: 1;
  display: block;
}
.arco-upload-list-item-picture-mask:hover .arco-upload-list-item-picture-error-tip {
  display: none;
}
.arco-upload-list-type-picture-list .arco-upload-list-item-text {
  padding-top: 8px;
  padding-bottom: 8px;
}
.arco-upload-list-type-picture-list .arco-upload-list-item-error .arco-upload-list-item-text {
  background-color: var(--color-danger-light-1);
}
.arco-upload-list-type-picture-list
  .arco-upload-list-item-error
  .arco-upload-list-item-text-name-link,
.arco-upload-list-type-picture-list .arco-upload-list-item-error .arco-upload-list-item-text-name {
  color: rgb(var(--danger-6));
}
.arco-upload-slide-up-enter {
  opacity: 0;
}
.arco-upload-slide-up-enter-active {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
.arco-upload-slide-up-exit {
  opacity: 1;
}
.arco-upload-slide-up-exit-active {
  opacity: 0;
  overflow: hidden;
  margin: 0;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1),
    height 0.3s cubic-bezier(0.34, 0.69, 0.1, 1) 0.1s,
    margin 0.3s cubic-bezier(0.34, 0.69, 0.1, 1) 0.1s;
}
.arco-upload-list-item.arco-upload-slide-inline-enter {
  opacity: 0;
}
.arco-upload-list-item.arco-upload-slide-inline-enter-active {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 1, 1);
}
.arco-upload-list-item.arco-upload-slide-inline-exit {
  opacity: 1;
}
.arco-upload-list-item.arco-upload-slide-inline-exit-active {
  opacity: 0;
  overflow: hidden;
  margin: 0;
  transition: opacity 0.1s cubic-bezier(0, 0, 1, 1),
    width 0.3s cubic-bezier(0.34, 0.69, 0.1, 1) 0.1s,
    margin 0.3s cubic-bezier(0.34, 0.69, 0.1, 1) 0.1s;
}
