.integration-view
    &__box
        margin-top: 25px
        background: $white
        position: relative
        border-radius: $radius
        box-shadow: $boxShadow


.integration-objects, .integration-logs, .integration-tasks, .integration-request-logs
    max-height: calc(100vh - 185px)
    &__object,&__log, &__task
        padding: 5px 0
        border-bottom: 1px solid $gray
        &:not(:first-child)
            margin-top: 10px
        &:last-child
            border-bottom: 1px solid transparent
    &__type, &__status
        padding: 4px 7px
        border-radius: $radius
        background: $primary
        color: $white
        margin-left: 5px
        align-self: center
        font-size: $fontSizeM
        line-height: 15px
        &--queue, &--processing
            background: $orange
        &--red, &--error
            background: $red
        &--success
            background: $green
    &__name
        display: flex
        line-height: 40px
        font:
            weight: bold
            size: $fontSizeH3
    &__description
        margin-top: 5px
    &__date
        margin-left: 0
        margin-top: 5px
        color: $darkgray
        font:
            weight: normal
            size: $fontSizeM
    .actions-bar
        margin-top: 15px

.matrix-table
    display: flex
    flex-direction: column
    .column
        display: inline-flex
    .cell
        padding: 5px
        border-bottom: 1px solid $black
        border-right: 1px solid $black
        max-width: 200px
        min-width: 200px
        width: 200px
        &.empty
            width: 30px
            max-width: 30px
        &.selected
            background: $secondary
            color: $white
        &.column
            font-weight: bold
            text-align: center
            background: $primary
            color: $white
            display: block
            border-color: $white
        &--header
            background: $primary
            color: $white
            font-weight: bold
            flex: 0 0 40px
            text-align: center
            border-bottom: 1px solid $white
            padding: 5px
            &--large
                width: 100px
        &--action
            width: 100px
            height: 40px
            background: $primary
            color: $white
            text-align: center
            line-height: 40px
            border: none
            &--large
                width: 100%
            &:hover
                background: $secondary
                cursor: pointer


.matrix-view, .html-view
    max-width: 100%
    max-height: 600px
    overflow: scroll !important