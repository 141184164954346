+keyframes(cookie)
  0%
    left: 0%
    transform: translate(-50%, -50%) rotate(-90deg)
    opacity: 0
  60%
    left: 52%
    transform: translate(-50%, -50%) rotate(4deg)
  100%
    left: 50%
    transform: translate(-50%, -50%) translateX(0%)
    opacity: 1

+keyframes(sk-rotateplane)
  0%
    -webkit-transform: perspective(120px)
  50%
    -webkit-transform: perspective(120px) rotateY(180deg)
  100%
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)


  
+keyframes(menu-slide-in)
  from
    transform: translateX(100%)
    opacity: 0
  to
    transform: translateX(0%)
    opacity: 1
  
+keyframes(menu-slide-out)
  from
    transform: translateX(0%)
    opacity: 1
  to
    transform: translateX(100%)
    opacity: 0



+keyframes(pulse)
  0%
    box-shadow: 0 0 0 0 $primary
  70% 
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)
  100% 
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)


+keyframes(live-pulse)
  0%
    box-shadow: 0 0 0px transparent
  50%
    box-shadow: 0 0 20px $green
  100%
    box-shadow: 0 0 0px transparent

+keyframes(pulsate)
  0%
    transform: scale(0.1, 0.1)
    opacity: 0
  50%
    opacity: 1
  100%
    transform: scale(1.2, 1.2)
    opacity: 0



+keyframes(fade-in)
  0%
    opacity: 0
  100%
    opacity: 1

+keyframes(fade-out)
  0%
    opacity: 1
    display: block
  100%
    opacity: 0
    display: none

+keyframes(pop-in)
  0%
    display: block
    opacity: 0
    transform: scale(0.9)
    -webkit-transform: scale(0.9)
  80%
    transform: scale(0.94)
    -webkit-transform: scale(0.94)
  100%
    opacity: 1
    transform: scale(1)
    -webkit-transform: scale(1)
  
+keyframes(pop-out)
  0%
    display: block
    opacity: 1
    transform: scale(1)
    -webkit-transform: scale(1)
  80%
    transform: scale(0.96)
    -webkit-transform: scale(0.96)
  100%
    display: none
    opacity: 0
    transform: scale(0.9)
    -webkit-transform: scale(0.9)



+keyframes(bounce)
  0%
    opacity: 0
    transform: translateY(-2000px) rotate(-45deg)
  60%
    opacity: 1
    transform: translateY(30px) rotate(-45deg)
  80%
    transform: translateY(-10px) rotate(-45deg)
  100%
    transform: translateY(0) rotate(-45deg)

+keyframes(focus-bounce)
  0%
    transform: scale(0.95)
  60%
    transform: scale(1.03)
  100%
    transform: scale(1)
    
+keyframes(slide-in-bottom)
  from
    transform: translateY(-100%)
  to
    transfomr: translateY(0%)

+keyframes(slide-in-top)
  from
    transform: translateY(100%)
  to
    transfomr: translateY(0%)

+keyframes(slide-in-right)
  from
    transform: translateX(100%)
  to
    transform: translateX(0%)
  
+keyframes(slide-out-right)
  from
    transform: translateX(0%)
  to
    transform: translateX(100%)

+keyframes(slide-in-left)
  from
    transform: translateX(-100%)
  to
    transform: translateX(0%)

+keyframes(slide-out-left)
  from
    transform: translateX(0%)
  to
    transform: translateX(-100%)

+keyframes(lds-ripple)
  0%
    top: 36px
    left: 36px
    width: 0
    height: 0
    opacity: 1
  100%
    top: 0px
    left: 0px
    width: 72px
    height: 72px
    opacity: 0