.alert-badge
    padding: 10px 20px
    display: flex
    &.error
        background: $red
        color: $white
    &.warning
        background: $orange
        color: $white
    &.information
        background: $green
        color: $white
    .message
        display: inline-block
        flex: 1
        p
            display: inline-block
            margin: 0
            padding: 0 3px
    &__actions
        gap: 3px
        display: grid
        grid-auto-flow: column
        button
            border-radius: 0
            border: 1px solid transparent
            background: rgba(0,0,0,0.35)
            padding: 3px 10px
            line-height: 1
            height: 23px
            &:hover
                background: rgba(0,0,0,0.15)
                color: white
                border: 1px solid $white

.alert-badges
    display: flex
    margin: 10px 0
    .alert
        flex: 1