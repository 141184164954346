@import "../vars/_vars"

.transportOrder-list
	.transportOrder
		display: flex
		justify-content: space-between
		padding: 5px 0
		border-bottom: 1px solid $gray
		&:last-child
			border-bottom: 1px solid transparent
		&:hover
			cursor: pointer
			color: $primary
			border-color: $primary
		.content
			display: flex
			flex: 1.5
			.customer
				font-weight: bold
			.consignments
				margin-left: 10px
		.column
			align-self: center
		.id, .createdAt, .description
			font:
				size: $fontSizeM
			color: $darkgray
		.id
			flex: 1

.transportOrder-view
	.information
		.name
			font-size: 1.6em
	.collapsible-element
		margin-top: 15px
	.consignments
		padding: 0 0 25px 0
		.collapsible-element + div
			background: $white
			.actions-cards
				.action-card
					background: $lightgray
					&__spacer
						padding: 10px
					.action-card
						background: $white
			&:nth-child(2n)
				background: $lightgray
				.actions-cards
					.action-card
						background: $white
						.action-card
							background: $lightgray
		.collapsible-element.reversed + div
			background: $lightgray
			.actions-cards
				.action-card
					background: $white
			&:nth-child(2n)
				background: $white
				.actions-cards
					.action-card
						background: $lightgray
	.notes
		.note
			padding: 10px 0
			.meta
				font:
					style: italic
					size: $fontSizeM
			.title
				font:
					size: $fontSizeL
					weight: bold
				.date
					font:
						style: italic
						weight: normal
						size: $fontSizeM
			.text
				margin-top: 6px



.public-transportOrder
	padding: 30px 30px
	background: $white

@media all and (max-width: 660px)
	.transportOrder-list
		.transportOrder
			.createdAt
				display: none