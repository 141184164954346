.event-card
  width: 100%
  padding: 10px
  margin-top: 5px
  display: flex
  justify-content: space-between
  &--hidden
    display: none
  &__name
    font-weight: bold
    font-size: $fontSizeM
    margin: 0
    &.with-flair span
      padding: 2px 5px
      border-radius: $radius
      color: $white
      font-size: $fontSizeS

  &__dates
    color: $darkgray
    font-size: $fontSizeS
  &__children
    padding: 10px
    display: grid
    grid-template-columns: 2fr 2fr
    gap: 5px 10px
    .communicationRequest, &.events
      background: $lightgray
  &__expand
    padding: 5px 0
    width: 100%
    svg
      display: block
      margin: 0 auto
      width: 20px
      height: 20px
      fill: $black
      &:hover
        cursor: pointer
        fill: $primary
    &--rotated
      svg
        transform: rotate(180deg)