.documentation
    width: 100%
    height: calc(100vh - 40px)
    border: none
    background: white
    padding-top: 50px

.app-card
    width: 50px
    height: 50px
    border-radius: $radius
