//  -- container --


// -- Display --
.d-none
  display: none

.d-block
  display: block

.d-flex
  display: flex

.d-inline-flex
  display: inline-flex

.d-grid
  display: grid

.d-inline-grid
  display: inline-grid


// -- Alignments --

.align-items-center
  align-items: center

.justify-content-center
  justify-content: center

.justify-content-between
  justify-content: space-between

.justify-content-end
  justify-content: flex-end
