.actions-cards
  display: grid
  grid-template-columns: 100%
  .action-card
    width: 100%
    user-select: none
    &--child
      padding: 10px 20px
      .action-card__name
        flex: 1
    &--base
      .actions-cards
        padding: 0

    &__move
      flex: none
      background: transparent !important
      position: relative
      z-index: 1
      padding: 25px 10px

      .stick
        position: absolute
        left: 50%
        top: 0
        width: 4px
        height: 100%
        transform: translateX(-50%)
        margin: 0 auto
        background: $lightgray
        z-index: -1
        &--first
          top: 50%
          height: 50%
      .trip
        display: flex

        .time
          position: absolute
          left: calc(50% + 35px)
          line-height: 35px
          color: $primary

        .kilometers
          position: absolute
          right: calc(50% + 35px)
          line-height: 35px
          color: $primary

      .bubble
        height: 35px
        width: 35px
        border-radius: $roundRadius
        background: $lightgray
        margin: 0 auto
        display: block

        .icon
          svg
            width: 80%
            height: 80%
            margin: 10%
            fill: $darkgray

      &--actual
        .stick
          background: $primary

        .bubble
          background: $primary

          .icon
            svg
              fill: $white
    .actions-cards
      margin-top: 10px
    .action-card
      width: 100%
      margin: 0
    section.margin-top
      margin-top: 10px

.action-card
  padding: 10px
  .card-content
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    justify-content: flex-start
    margin-top: 10px
    font-size: $fontSizeM
    .card-item
      box-sizing: border-box
  &.grouped
    position: relative
    padding: 20px 20px 20px 40px
    .action-title
      &::after
        content: ''
        top: 6%
        left: 1%
        width: 22px
        height: 22px
        z-index: 999
        display: block
        position: absolute
        border-radius: $roundRadius
        background-color: $white
  &.addTrack
    &::after
      content: ''
      top: 12%
      left: 2%
      width: 3px
      height: 100%
      z-index: 999
      position: absolute
      display: inline-block
      background-color: $white

  // .draggable
    // transform: translateY(-8px)
  .tag
    display: flex
    align-items: center
    justify-content: center
    text-transform: capitalize
  &--actual
    +animation(live-pulse 2s infinite)
  &__spacer
    padding: 5px
    background: transparent !important
  &__name
    flex: 1
    font:
      weight: bold
    .tag
      font-size: $fontSizeM
  &__timewindow
    color: $black
    span
      color: $darkgray
      font-weight: normal
    &--realized
      color: $black
    &__time
      &--inside
        color: $green
      &--outside
        color: $red

  &__location
    .as-link
      transition: all 0.2s ease-in
      &:hover
        color: $darkgray
    .peek-map
      margin-left: 0px
      margin-right: 12px
      transition: all 0.2s ease-in
      &:hover
        svg
          fill: $darkgray
      svg
        fill: $primary

  &__remark
    margin-top: 5px
    font-size: $fontSizeM

  .communicationRequests,.events
    margin-top: 10px

  .action-result
    padding: 10px
    background: $white
    color: $black
  .files
    margin-top: 15px
    margin-bottom: 0px
    .file
      height: 75px
      min-width: 50px

// action card
.actions-container
  padding: 10px

  .action
    display: flex
    gap: 15px

    .border
      border: 3px solid $darkgray
      display: flex
      align-items: center
      justify-content: center

      .dot
        z-index: 1
        width: 30px
        height: 30px
        border: 5px solid $white
        border-radius: $radiusL
        background-color: $darkgray
        position: absolute

    .time
      display: flex
      align-items: center
      justify-content: center
      font-weight: bold
      color: $gray

    .details
      padding: 15px 20px
      position: relative
      border-radius: $radius
      background: $white
      margin: 10px 0
      min-height: 150px

      .angle
        height: 10px
        width: 10px
        position: absolute
        top: 46%
        right: -4px
        rotate: 45deg
        background-color: $white

      .date
        display: flex
        align-items: center
        gap: 5px
        width: 250px
        margin-bottom: 10px

        div:first-child
          font-size: $fontSizeH1
          color: $darkgray
          font-weight: bold

        div:last-child
          display: flex
          flex-direction: column

          span:first-child
            font-weight: bold
            color: $darkgray

          span:last-child
            font-weight: bold
            color: $gray
            font-size: $fontSizeM

      .address
        div:first-child
          font-weight: bold
          color: $primary

        .location
          font-weight: bold
          color: $darkgray
          font-size: $fontSizeM
          width: 250px
          display: flex

          &:hover
            cursor: pointer
            color: $primary

      .remarks
        div:first-child
          font-weight: bold
          color: $primary

        div:last-child
          font-weight: bold
          color: $darkgray
          font-size: $fontSizeM
          width: 250px
          display: flex

  .action
    &.inverse
      flex-flow: row-reverse

      .details
        .angle
          left: -4px

.communicationRequest
  width: 100%
  background: $white
  padding: 10px
  margin-top: 5px
  &--hidden
    display: none
  &__name
    font-weight: bold

    span
      padding: 2px 5px
      border-radius: $radius
      color: $white
      font-size: $fontSizeM

      &.processing
        background: $orange
      &.failure
        background: $red
      &.success
        background: $green

  &__description
    margin-top: 5px
  &__children
    padding: 10px
    display: grid
    grid-template-columns: 2fr 2fr
    gap: 5px 10px
    .communicationRequest, .events
      background: $lightgray
  &__expand
    padding: 5px 0
    width: 100%
    svg
      display: block
      margin: 0 auto
      width: 20px
      height: 20px
      fill: $black
      &:hover
        cursor: pointer
        fill: $primary
    &--rotated
      svg
        transform: rotate(180deg)
@media (max-width: 720px)
  .action-card
    &__name
      flex-wrap: wrap
