footer
    &.mini
        font-size: $fontSizeM
        padding: 3px 0
        line-height: 20px
    .container
        display: flex
    img
        display: inline-block
        height: 20px
        margin-right: 10px