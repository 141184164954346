.mail-list
    .mail
        display: flex
        justify-content: space-between
        padding: 5px 0
        border-bottom: 1px solid $gray
        &:last-child
            border-bottom: 1px solid transparent
        &:hover
            cursor: pointer
            border-color: $primary
            .content, .description
                color: $primary
                
        .column
            align-self: center
        .content
            display: flex
            .name
                font-weight: bold
            .description
                font-size: $fontSizeM
                & > div 
                    display: inline-block
                svg
                    height: 12px
                    margin-left: 10px
        .date,.description, .from
            color: $darkgray
        .date, .from
            font:
                size: $fontSizeM
        .from
            width: 75px

    
.mail-view
    padding-bottom: 50px
    h1
        margin-bottom: 6px
    .attachments
        display: flex
        .attachment
            width: 150px
            height: 150px
            display: block
            position: relative
            padding: 5px
            img
                height: 100%
                width: 100%
                object-fit: contain
    .actions
        position: sticky
        bottom: 0
        left: 0
        width: 100%
        height: 75px
        padding: 20px 20px
        background: $white
        box-shadow: 0px -15px 30px -15px rgba(0, 0, 0, 19%)

@media (max-width: 960px) 
    .mail-list
        .mail
            .description
                display: none