.consignment-detail
    height: calc(100vh - 170px)
    &__box
        height: 100%
        padding: 10px
        margin-top: 0
        background: $white
        position: relative
        border-radius: $radius
        box-shadow: $boxShadow
        border: 1px solid $gray
        overflow: scroll
        &--messages, &--map
            padding: 0
            overflow: hidden
    &__map
        width: 100%
        height: calc(100% - 40px)
        border-radius: $radius
        &__history
            .time
                position: absolute
                color: $white
                padding: 10px
                left: 10px
                top: 10px
                z-index: 500
                background: $primary
                border-radius: $radius
        &__openInGoogle &__openInGoogle div, &__openInGoogle svg
            width: 25px
            height: 25px
            fill: $black
            &:hover
                cursor: pointer
                fill: $primary
        &__openInGoogle
            position: absolute
            right: 10px
            top: 10px
            z-index: 500
    .chat
        height: 100%
        display: block
        .new-message
            border-top: 1px solid $gray
        .main
            height: 100%
            .messages
                max-height: calc(100% - 130px)
        .actions
            display: block
    .actions-cards
        justify-content: space-between

        .action-card
            background: $lightgray
            &__spacer
                padding: 10px
            .action-card
                background: $white
    .tab-links-container
        padding: 5px 0
    .goods
        margin-top: 25px
    
.consignment-form-element
    +transition($s)
    &.flat
        &:hover
            transform: none
    &:hover
        cursor: pointer
        transform: scale(1.05)
        .go svg
            fill: $primary
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px

.consignment-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray
    
.consignment
    .information
        .name
            font-size: $fontSizeH2
    .actions-cards
        margin-top: 15px
        justify-content: space-between
        .action-card
            background: $lightgray
    .files
        margin-bottom: 10px
    .goods
        margin-top: 25px
.consignment-results
    max-height: 400px

.consignment-card
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px
            