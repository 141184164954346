.vehicle-detail
    height: calc(100vh - 130px)
    &--standalone
        height: 100%
    &__box
        height: 100%
        padding: 10px
        margin-top: 0
        background: $white
        position: relative
        border-radius: $radius
        box-shadow: $boxShadow
        border: 1px solid $gray
        &--messages, &--map
            padding: 0
            overflow: hidden
        .vehicle-detail__box, .chauffeur-view__box
            height: auto
            h1
                margin: 0
    &__map
        width: 100%
        height: calc(100% - 40px)
        &__history
            display: flex
            .time
                color: $white
                padding: 10px
                left: 10px
                top: 10px
                z-index: 500
                background: $primary
                display: flex
                input
                    background: $primary
                    color: $white
                    height: auto
                    width: auto
            .slider
                flex: 1
        &__openInGoogle &__openInGoogle div, &__openInGoogle svg
            width: 25px
            height: 25px
            fill: $black
            &:hover
                cursor: pointer
                fill: $primary
        &__openInGoogle
            position: absolute
            right: 10px
            top: 10px
            z-index: 500
    .chat
        height: 100%
        display: block
        .new-message
            border-top: 1px solid $gray
        .main
            height: 100%
            .messages
                max-height: calc(100% - 130px)
    .actions-cards
        justify-content: space-between
        .action-card
            background: $lightgray
            flex: 0 0 48%
            .action-card
                background: $white
    .tab-links-container
        padding: 5px 0

.vehicle-trips, .vehicle-events, .vehicle-chauffeurs, .vehicle-transportEquipments
    &__trip,&__event, &__chauffeur, &__transportEquipment
        padding: 5px 0
        border-bottom: 1px solid $gray
        &:not(:first-child)
            margin-top: 10px
        &:last-child
            border-bottom: 1px solid transparent
        &.clickeable
            &:hover
                cursor: pointer
                color: $primary
                +transition($s)
    &__trip
        margin-top: 10px
        padding-top: 5px
        & + div
            padding-bottom: 30px
        &.active
            .name
                font-weight: 900
                color: $primary
        &:last-child
            border-bottom: 1px solid $gray
        svg:hover
            fill: $primary
            +transition($s)

    &__map
        width: 100%
        height: 300px
        z-index: 0
        margin-top: 20px
    &__name
        display: flex
        line-height: 40px
        font:
            weight: bold
            size: $fontSizeH3
        &--active
            color: $primary
    &__location
        font-size: $fontSizeL
    &__date
        margin-left: 8px
        color: $darkgray
        font:
            weight: normal
            size: $fontSizeM
    .actions-bar
        margin-top: 15px

.vehicle-trips
    height: calc(100% - 50px)
    &__trip:first-child
        padding-top: 0
    &__name
        font-size: $fontSizeH2

.vehicle-events, .vehicle-chauffeurs, .vehicle-transportEquipments, .vehicle-cameras
    max-height: 100%
    &__type
        padding: 4px 7px
        border-radius: $radius
        background: $primary
        color: $white
        margin-left: 5px
        align-self: center
        font-size: $fontSizeM
        line-height: 14px
    &__date
        margin-left: 0

.vehicle-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray

.vehicle-results
    max-height: 400px

.vehicle-card
    h3, h2
        margin: 0
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px

.vehicle-cameras
    iframe
        width: 100%
        min-height: 500px
        border: 0
        height: auto

.vehicle
    &__card
        color: $darkgray
        background-color: $lightgray
        display: flex
        align-items: center
        border-radius: $radiusS
        font-size: $fontSizeM
        padding: 5px 5px
        gap: 5px
        justify-content: space-between

    &__image
        height: 20px
        width: 20px
        border-radius: $radiusS
        background-color: $white

    &__detach
        background-color: $llightgray
        border-radius: $roundRadius
        height: 20px
        width: 20px
        display: flex
        align-items: center
        justify-content: center
        svg
            fill: $red
            height: 8px
            width: 8px
