
.rounded
  border-radius: $radius

.rounded-sm
  border-radius: $radiusS

.border-all
  border: 1px solid $gray

.border-bottom
  border-bottom: 1px solid $lightgray

.bg-default
  background-color: $lightgray

.bg-white
  background-color: $white

.bg-primary
  background-color: $primary

.shadow-default
  box-shadow: 2px 5px 9px 1px #e6e6e6

.text-capitalize
  text-transform: capitalize

.text-lowercase
  text-transform: lowercase

.text-uppercase
  text-transform: uppercase

.text-underline
  text-decoration: underline

.text-primary
  color: $primary

.text-danger
  color: $red

.text-success
  color: $green

.text-gray
  color: #a7a7a7

.text-darkgray
  color: $darkgray

.cursor-pointer
  cursor: pointer

.cursor-move
  cursor: move

.font-xl
  font-size: $fontSizeH2

.font-lg
  font-size: $fontSizeH3

.font-md
  font-size: $fontSizeL

.font-sm
  font-size: $fontSizeM

.font-xs
  font-size: $fontSizeS

.font-size-fields
  font-size: 14px

.font-medium
  font-weight: 500

.font-semibold
  font-weight: 600
  
.font-bold
  font-weight: 700

.font-default
  font-family: $secondaryHeaderFont

.font-color-white
  color: $white

.font-color-black
  color: $black
