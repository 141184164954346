.business
	.wrapper.top
		margin-top: 65px
.business-view
	&__box
		height: 100%
		padding: 10px
		margin-top: 0
		background: $white
		position: relative
		border-radius: $radius
		box-shadow: $boxShadow
		border: 1px solid $gray 
.side-business,.top-business
	.picture
		position: relative
		.action-icons
			position: absolute
			margin: 0
			bottom: 10px
			right: 0
			.action-icon
				&::after
					opacity: 1
				&:hover
					&::after
						background: $secondary
				svg
					fill: $white
		
	.headline
		font:
			size: $fontSizeH3
		margin-top: 10px
	.location
		margin-top: 5px
		color: $darkgray
		font:
			size: $fontSizeH3
	.banner
		object-fit: cover
		&.editable
			&:hover
				opacity: 0.7
				cursor: pointer

.top-business
	padding-bottom: 25px
	.banner
		width: 100%
		height: 282px
		background: $secondary
		+transition($s)
	.info
		padding-left: 30px
	.action-icons
		margin-top: 30px
	.picture
		width: 150px
		height: 150px  
		display: block
		margin: -88px 0 0
		img
			border-radius: $roundRadius
			background: $white
			border: 3px solid $white
			height: 100%
			width: 100%
			display: block
	.name
		padding: 10px 0 0
		line-height: 38px
		font:
			family: 'Titillium Web'
			size: $fontSizeH1
			weight: normal