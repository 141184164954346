.fade-in
  opacity: 0.7

.rotate-270
    transform: rotate(270deg)

.top-right
  top: 5px
  right: 5px
  position: absolute

.display-between
  display: flex
  align-items: center
  flex-direction: row
  justify-content: space-between

.d-flex-space-between
  display: flex
  align-items: center
  flex-direction: row
  justify-content: space-between

.main-container
  overflow: scroll

  .lanes
    gap: 20px
    display: flex
    flex-direction: row
    align-items: stretch
    .lane
      width: 16vw
      // height: 100%
      height: 100vh
      min-width: 300px
      padding: 20px 15px
      overflow-y: scroll
      scrollbar-width: none
      -ms-overflow-style: none
      &::-webkit-scrollbar
        display: none
      .tag
        margin: 5px
        color: $white
        padding: 5px 10px
        border-radius: $radiusS
    .task
      width: 100%
      margin: 15px 0
      // min-width: 520px
      min-height: 250px
      padding: 20px 10px
      align-items: start
      position: relative
      padding-left: 20px
      border-radius: $radiusS
      .before
        content: ''
        top: 0
        left: 0%
        width: 13px
        height: 100%
        position: absolute
        background-color: $primary
        border-top-left-radius: $radiusS
        border-bottom-left-radius: $radiusS
      &.completed
        background: color-mix(in srgb, $primary 40%, white)
      &.in-progress
        background: color-mix(in srgb, $yellow 40%, white)
        &::before
          background-color: $yellow
      &.todo
        background: color-mix(in srgb, $orange 40%, white)
        &::before
          background-color: $orange
      &.overdue
        background: color-mix(in srgb, $lightred 60%, white)
        &::before
          background-color: $red

.todo-details
  &__box
    height: 100%
    padding: 20px
    margin-top: 0
    background: $white
    position: relative
    border-radius: $radius
    box-shadow: $boxShadow
    border: 1px solid $gray 
  hr
    border: none
    height: 1px
    background-color: $llightgray
    margin: 20px 0

  .attachment
    height: 56px
    padding: 10px
    overflow-x: scroll
    transition: all 0.2s ease-in
    border: 1px solid $llightgray
    &:hover
      border-color: $primary

  .add-comment-wrapper
    padding: 16px
    border-radius: $radiusS
    border: 1px solid $llightgray
    .add-comment
      height: 50px
      padding: 5px
      border-radius: $radiusS
      border: 1px solid $llightgray
      transition: all 0.2s ease-in
      &:hover
        border-color: $primary
  
  .entity-wrapper
    min-height: 64px
    height: fit-content
    border-radius: $radiusS
    transition: all 0.2s ease-in
    border: 1px solid $llightgray
    &:hover
      border-color: $primary
    .entity
      height: 100%
      padding: 15px 

  .comment
    padding: 16px
    border-radius: $radiusS
    border: 1px solid $llightgray

.todo-wrapper
  padding: 10px
  img
    border: 0.5px solid $llightgray
    box-shadow: 2px 5px 9px 1px #e6e6e6

  .main-container
    scrollbar-width: none
    -ms-overflow-style: none

  .board-switcher__btn-left, .board-switcher__btn-right
    padding-top: 2px

  .overview-stats
    overflow-x: scroll
    justify-content: space-between

  .my-todo-table
    width: 100%
    height: 100%
    max-width: 100vw
    margin-top: 20px      
    table
      background: $white
      white-space: nowrap
      border-collapse: collapse
      border: 1px solid $lightgray
      width: -webkit-fill-available
      .month-header
        display: flex
        column-gap: 30px
        user-select: none
        align-items: center
        justify-content: center
        svg
          cursor: pointer
          stroke: $darkgray
          transform: translateY(3px)
          transition: all 0.2s ease-in
          &:hover
            stroke: $primary
            
      .feature
        color: $black
        text-align: left
        font-weight: 600
        line-height: 150%
        font-weight: bold
        font-size: $fontSizeL
        text-transform: capitalize
      tr
        border-bottom: 1px solid $lightgray
      tr:last-child
        border-bottom: 0px
      th
        color: $black 
        font-style: normal
        font-weight: 600
        line-height: 150%
        font-size: $fontSizeM
        letter-spacing: 0.05em
        background: $llightgray
        filter: brightness(1.05)
        text-transform: capitalize
        border-bottom: 1px solid $lightgray
      td
        font-style: normal
        font-weight: 400
        font-size: $fontSizeL
        line-height: 150%
        color: $black
        text-align: center
        padding: 5px 24px
      th
        padding: 20px 24px

  .todo-header
    .actions-row
      row-gap: 16px
      .button
        color: $primary
        background: $white
        border: 1px solid $primary
        &:hover
          color: $white
          background: $primary
        &.active
          color: $white
          background: $primary
      .left-section
        h1
          color: $black
          line-height: 32px
          letter-spacing: -0.41px
        [class*="indicatorContainer"]
          color: $primary
          transform: translateY(-1px)
      .right-section
        gap: 16px
        [class*="indicatorContainer"]
          color: $white
          transform: translateY(-1px)
    .subtitle
      margin-top: 25px
      letter-spacing: 0.5px
  
  .boards
    display: grid
    grid-gap: 20px
    padding: 20px 0px
    overflow-x: hidden
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
    .board-card
      // margin: 0 auto
      width: 100%
      background: $llightgray 
      .board-card__stats span
        font-size: 14px
    
  // .svg-md svg
  //   width: 12px
  //   height: 13px

  .board-card, .task
    width: 400px
    min-height: 280px
    padding: 15px 15px
    &__title
      font-weight: 600
    &__actions
      z-index: 1
    &__date, &__owner
      margin: 10px 0
      font-size: 14px
    &__stats
      column-gap: 15px
      align-items: flex-start
      svg
        width: 14px
        height: 14px
    &__statuses
      row-gap: 12px
      column-gap: 7px
    &__status
      // width: 110px
      gap: 10px
      display: flex
      font-size: 14px
      width: fit-content
      // justify-content: space-between
      // .text
      //   overflow: hidden
      //   position: relative
      //   white-space: nowrap
      //   display: inline-block
      //   text-overflow: ellipsis
      .tag
        display: flex
        padding: 0 12px
        font-size: 10px
        align-items: center

  .todo-card
    width: 100%
    // cursor: pointer
    min-height: 150px
    border-radius: 4px
    margin-bottom: 16px
    flex-direction: column
    border: 1px solid $llightgray
    &__footer
      height: 40px
      padding: 5px 20px
      border-top: 1px solid $llightgray
      .stats
        column-gap: 15px
        align-items: flex-start
        svg
          width: 14px
          height: 14px
    &__content
      padding: 0 20px 5px
      p
        margin: 8px 0
      .redirect 
        z-index: 1
        padding: 5px
        cursor: pointer
        border-radius: 3px
        border: 1px solid $llightgray
        div
          display: flex
        svg
          width: 16px
          height: 16px
          rotate: 270deg
          stroke: $darkgray
          transition: all 0.2s ease-in
        &:hover
          svg
            stroke: $primary
    &__header
      padding: 10px 20px 0
      
  .calendar
    margin: 12px 0
    &__content-monthly, &__content-weekly
      margin: 12px 0
      justify-content: space-between
    
    &__content-monthly
      .day-card
        height: 180px
        color: $black
        padding: 15px 5px
        background: $white
        flex-direction: column
        justify-content: flex-start
        transition: all 0.2s ease-in
        border: 1px solid $llightgray
        &.expand
          z-index: 99
          top: -5px
          height: fit-content
          left: -5px
          width: 100%
          min-width: 200px
          max-height: 400px
          // min-height: 280px
          overflow-y: scroll
          background: $white
          position: absolute
          border-radius: $radius
          transition: all 0.2s ease-in
          box-shadow: 2px 5px 9px 1px $lightgray
        &::-webkit-scrollbar-track
          margin: 10px 0
          background-color: $lightgray
        &::-webkit-scrollbar
          width: 2px
          background-color: tranparent
        &::-webkit-scrollbar-thumb
          background-color: $primary
        &.disabled
          opacity: 0.4
          pointer-events: none
        &.active
          color: $primary
          border-width: 2px
          border-color: $primary
          .todo
            color: $black
        .todo
          height: 32px
          color: $black
          overflow: hidden
          line-height: 32px
          position: relative
          padding-left: 10px
          white-space: nowrap
          margin-bottom: 10px
          display: inline-block
          border-radius: $radiusS
          text-overflow: ellipsis
          .before
            content: ''
            left: 0%
            width: 3px
            height: 100%
            position: absolute
            display: inline-block
            background-color: $primary

          &.dueToday
            background: color-mix(in srgb, $gray 60%, white) !important
            .before
              background-color: $darkgray !important
          &.overdue
            background: color-mix(in srgb, $red 20%, white) !important
            .before
              background-color: $red !important
    
    &__content-weekly
      padding: 40px
      border-radius: 4px
      border: 1px solid $llightgray
      // overflow-y: scroll

    &__header-weekly, &__header-monthly
      margin: 0
      padding: 0
      display: flex
      padding: 16px 0
      align-items: center
      justify-content: space-between
      .forward-btn svg
        rotate: 270deg
      .back-btn svg
        rotate: 90deg
      .forward-btn, .back-btn
        margin: 0 10px
        cursor: pointer
        svg
          width: 24px
          height: 24px
          stroke: $darkgray
          transition: all 0.2s ease-in
        &:hover
          svg
            stroke: $primary

    &__header-weekly
      border-radius: 3px
      border: 1px solid $llightgray
      
    .header-weekly__information-box
      width: 220px
      height: 60px
      row-gap: 5px
      display: flex
      cursor: pointer
      background: $white
      align-items: center
      flex-direction: column
      justify-content: center
      border: 1px solid $llightgray
      small
        font-size: 12px
        color: $darkgray
      big
        font-size: 16px
        font-weight: 500
      &.active
        color: $primary
        border-color: $primary

.lane-form
  .lane-card
    height: 60px
    padding: 0 20px
    user-select: none
    .remove
      width: 24px
      height: 24px
      svg
        width: 17px
        height: 15px
        fill: $white
        stroke: $white
        transform: translateY(3px)
      background: $red
      border-radius: $roundRadius
  .colors
    .color
      width: 40px
      height: 40px
      span
        width: 100%
        height: 100%
        clip-path: polygon(0 0, 100% 0%, 0% 100%)
    .selected
      box-shadow: 2px 5px 9px 1px $darkgray
    .disabled
      opacity: 0.2
      pointer-events: none

.board-form, .todo-form
  .editor-wrapper
    margin-top: 25px
    border-radius: 3px
    border: 1px solid $lightgray
    .message-input
      padding: 0 16px
      font-size: 16px
      color: $darkgray
      font-weight: 400
      min-height: 125px
      line-height: 24px
    .actions-bar
      border: none
      padding: 0 10px
      margin-bottom: 0
      border-bottom: 1px solid $lightgray
      .rdw-option-wrapper
        border: none
        &:hover, &:active
          cursor: pointer
          box-shadow: none
          background: $primary
      .rdw-option-active
        cursor: pointer
        box-shadow: none
        background: $primary

.comment-form
  .editor
    border-radius: 3px
    border: 1px solid $lightgray
    & > div:first-child 
      border: none
      display: flex
      padding: 10px 10px
      margin-bottom: 0
      border-bottom: 1px solid $lightgray
    button
      border: none
      padding: 0px
      border-radius: 0
      margin-right: 15px
      height: fit-content
      background: transparent
      &:focus, &:hover, &:active, &.akzb7t5
        background: $primary
        
    .DraftEditor-root
      padding: 0 16px
      font-size: 16px
      color: $darkgray
      font-weight: 400
      min-height: 125px
      line-height: 24px
  
  
@media (max-width: 640px) 
  .todo-wrapper
    .calendar
      &__content-weekly
        padding: 0