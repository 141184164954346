.planBoard,.planner,.insights,.watchTower,.dashboard-main,.optimizer,.route-planner,.combination-planner,.map-planner,.chauffeur-planner, .tenders-table
    &--standalone
        background: $white
        padding: 0 10px 0 20px
    .marker-popup-wrapper
        min-width: 400px
        ::-webkit-scrollbar
            width: 0px
        a.leaflet-popup-close-button
            display: none
        .leaflet-popup-content-wrapper
            margin: 0px
            width: 100%
            padding: 0px
            max-height: 320px
            border-radius: 0px
            overflow-x: scroll !important
            background: $white !important
            .leaflet-popup-content
                margin: 0px
                width: 100% !important
                overflow-x: scroll !important
                .marker-popup
                    h4, h5
                        margin: 0px
                        font-weight: 600
                        width: fit-content
                    h4
                        font-size: $fontSizeL
                    h5
                        font-size: $fontSizeM
                    .title
                        margin-top: 30px
                    .popup-table
                        .table, table
                            width: 100%
                            color: $black
                            font-size: $fontSizeM
                            line-height: normal
                            border-collapse: collapse
                            tr
                                position: relative !important
                            td
                                padding: 2px 5px
                                border-bottom: 4px solid $white
                            .past
                                background: color-mix(in srgb, $primary 40%, white)
                                .address
                                    color: $white
                                .logo svg
                                    fill: $primary
                            .present
                                background: $primary
                                color: $white
                            .future
                                background: $gray
                                color: $black
                                .logo 
                                    svg
                                        fill: $gray
                                    &::after
                                        background-color: $gray
                            .action-cell
                                width: 70px
                                font-weight: 600
                            .logo
                                width: 30px
                                position: relative
                                svg
                                    width: 20px
                                    height: 20px
                                    fill: $primary
                                &::after
                                    content: ''
                                    left: 50%
                                    width: 1px
                                    height: 12px
                                    z-index: 999
                                    position: absolute
                                    display: inline-block
                                    background-color: $primary
                            .departure-cell
                                width: 80px
                                font-size: 11px
                            .address
                                color: $gray
                                font-size: $fontSizeS
                    hr
                        width: 100%  
                        height: 1px
                        border: none
                        margin: 5px 0 10px 0
                    .mb-50
                        margin-bottom: 30px
                    .subtitle
                        color: $gray !important
                        font-size: $fontSizeS !important
                    .content
                        width: 100%
                        padding: 10px 20px 0px 28px
                        .close-icon
                            width: 10px
                            height: 10px
                            svg
                                width: 100%
                                height: 100%
                                transform: translateY(-1px)
                                transition: all 0.2s ease-in
                            &:hover
                                svg 
                                    fill: $primary
                        .data-row
                            td
                                padding-bottom: 5px
                                padding-right: 15px
                            span
                                color: $black
                                font-size: $fontSizeM
                            .order-number
                                color: $gray
                                transition: all 0.2s ease-in
                                &:hover
                                    color: $primary
                        .as-link
                            float: right
                            font-size: $fontSizeS

    .actions
        display: flex
        margin-left: 8px
        z-index: 100
        height: auto
        &:first-child
            margin-left: 0
        .action
            margin: 0 4px
            position: relative
            display: flex
            &.no-margin-left
                margin-left: 0
            .action-input
                width: 200px
                &--small
                    width: 180px
            .action-icon
                width: 40px
                height: 40px
                align-self: center
            .action-menu
                position: absolute
                top: 60px
                right: 15px
                width: 260px
                height: auto
                background: $white
                padding: 8px 3px
                display: none
                box-shadow: 0px 0px 43px -3px rgba(0, 0, 0, 0.30)
                animation: $s pop-out
                z-index: 100
                &.show
                    animation: 0.1s pop-in
                    display: block
                    &::after
                        border: solid transparent
                        content: " "
                        height: 0
                        width: 0
                        position: absolute
                        pointer-events: none
                        border-bottom-color: $white
                        border-width: 15px
                        right: 0px
                        top: -30px
                .menu-container
                    max-height: 300px
                .action-item
                    padding: 4px 6px
                    font-size: $fontSizeM
                    flex-wrap: nowrap
                    user-select: none
                    &:hover
                        cursor: pointer
                    .name
                        &:first-letter
                            text-transform: uppercase
                input[type = "checkbox"], input[type = "radio"]
                    width: auto
                    margin: 0
                    height: 15px
                    width: 15px
                    padding: 7px
                    margin-right: 6px
                    &:checked::after
                        font-size: $fontSizeS
                        line-height: 14px
                label.normal
                    +transition($s)
                    &:hover
                        cursor: pointer
                        color: $primary
    .small-selector
        z-index: 100
    .calendar
        [class$="-ValueContainer"]
            padding: 2px 10px 2px 0px
        h2
            margin: 0
            line-height: 40px 
    
.dashboard
    .map-overview
        height: 345px
        width: 100%
        position: relative
        z-index: 0
    &__welcome
        filter: drop-shadow(0px 0px 15px rgba(82, 49, 49, 0.09))
        &.top
            margin-top: -35px

        &__header
            display: block
            width: calc(100% / 3 * 2)
            padding: 5px 10px
            background: $white
            border-top-right-radius: $radius
            border-top-left-radius: $radius
            box-shadow: $boxShadow
            border-bottom: none
            .icon, h1
                display: inline-block
            .icon, .icon > div
                height: 35px
                width: 35px
            .icon
                margin-right: 15px
                svg
                    fill: $primary
            h1, h2
                margin: 0
                
    &__information
        display: flex
        background: $white
        border-radius: $radius
        border-top-left-radius: 0px
        &__block
            min-width: 33%
            flex: 1
            background: $white
            text-align: center
            padding: 10px
            &:not(:last-child)
                border-right: 1px solid $gray
            &__icon, &__icon > div, &__icon svg
                width: 35px
                height: 35px
                display: inline-block
                fill: $primary
                stroke: $primary
            &__icon
                display: inline-block
                margin: 0 15px 15px 0
                fill: $primary
                stroke: $primary
            &__name
                font-size: $fontSizeH2
                margin: 0
            &__value
                font-size: $fontSizeH1
                font-weight: bold
                color: $primary
            &--bottom-left-corner
                border-bottom-left-radius: $radius
            &--right-corner
                border-top-right-radius: $radius
                border-bottom-right-radius: $radius
    .table .top-functions .search-block input
        background: $lightgray

    .dash-box
        padding: 20px
        margin-top: 35px
        background: $white
        border-radius: $radius
        box-shadow: $boxShadow
        z-index: 10
        &.with-tabs
            margin-top: -1px
            border-top-left-radius: 0
            border-top-right-radius: 0
        &.half
            flex: 0 0 48%
            padding: 20px
        &.full
            flex: 0 0 100%
        &.top
            margin-top: -35px
        .table
            margin-top: 0
            .search
                background: $lightgray
    .tabs
        .tab, .tab:hover
            border: 1px solid transparent
            border-bottom: 1px solid $gray
            &.active
                border: 1px solid $gray
                border-bottom: 1px solid $white
                z-index: 2
    &-main
        margin-top: 35px
        margin-bottom: 0px
        .actions
            z-index: 101




.dashboard-main
    flex-wrap: nowrap

.side-dropdown
    padding-left: 25px
    border-left: 1px solid $gray

.tabs-content-container
    margin: 10px 0
    padding: 15px
    .table
        margin-top: 0

.tab-active
    background-color: $primary
    color: $white

.border-none
    border: none !important

.operational-dashboard
    overflow: hidden
    .table-subtitle
        margin-left: 5%
    .table-header
        width: 55%
        .title
            font-size: $fontSizeL
            line-height: 21px
            margin: 10px 0px 30px
        .from
            font-size: $fontSizeL
            line-height: 18.75px
        .date
            font-size: $fontSizeM
            line-height: 16px
    .spacer
        left: 0
        width: 100%
        height: 20px
        position: absolute
        background: $lightgray
    .charts
        margin-top: 15px
        display: grid
        grid-template-columns: 1fr 1fr 
        gap: 20px
        background: $lightgray
        .spacer
            display: none
        .chart-header
            h4
                font-size: $fontSizeL
                line-height: 24px
                letter-spacing: 0em
            p
                margin-top: 5px
                line-height: 16px
                letter-spacing: 0em
            .export
                color: $black
                font-size: $fontSizeM
                font-weight: 600
                line-height: 19px
                border-radius: $radiusS
                letter-spacing: 0em
                background: transparent
                border-color: $llightgray
                transition: all 0.2s ease-in
                svg
                    width: 14px
                    height: 14px
                    transform: translateY(4px)
                    transition: all 0.2s ease-in
                &:hover
                    color: $white
                    border-color: $primary
                    background: $primary
                    svg
                        fill: $white

        .bar-chart
            padding: 0 20px 0 10px
            .export
                margin-top: 20px
            .chart-label-y
                margin-bottom: 40px
            .chart-wrapper
                .recharts-wrapper
                    transform: translateX(-10px)
            .filters
                gap: 25px
                margin: 20px 0 30px
                span
                    color: $gray
                    font-size: $fontSizeM
                    line-height: 19px
                    transition: all 0.2s ease-in
                    &:hover
                        color: $primary
                    &.active
                        color: $primary
        .pie-chart
            padding: 0 5px 0 25px
            .piechart-wrapper
                height: 100%
            .recharts-wrapper
                width: 100% !important
            .no-data
                height: 400px
                width: -webkit-fill-available
        .bar-chart, .pie-chart
            background: $white
            .recharts-wrapper
                .tooltip-wrapper
                    box-shadow: 0 0 4px $lightgray
                    .tooltip
                        min-width: 140px
                        padding: 10px 12px 6px 
                        h4, span
                            margin: 0px 0                           
                            font-size: $fontSizeM
                            line-height: 16px
                            letter-spacing: 0em
                        .stat-icon
                            width: 8px
                            height: 8px
                            border-radius: $roundRadius
                            background: #efdc30
                        .stat-date
                            float: right
                            font-size: $fontSizeXS
                            color: $darkgray
                            transform: translateY(-10px)
                    hr, .separator
                        margin: 0
                        border-top: 1px solid $llightgray
                .recharts-tooltip-cursor
                    display: none !important
            .recharts-legend-wrapper
                width: 100% !important
                .recharts-custom-legend
                    gap: 20px
                    padding: 0
                    li
                        transition: all 0.2s ease-in
                        width: fit-content !important
                        span
                            width: 22px
                            height: 11px
                            margin-right: 5px
                        &.legend-bar
                            color: $black
                        &.clicked
                            span
                                background: transparent !important
    .table
        width: 100%
        height: 100%
        padding: 0px
        overflow-x: scroll
        white-space: nowrap
        border-top: 1px solid $gray
        table
            width: 100%
            border-collapse: collapse
        th
            font-size: $fontSizeL
            color: $darkgray
            font-weight: 400
            padding: 20px 30px
            line-height: 18.75px
            border-right: 1px solid $gray
        th, td
            text-align: center
        td
            color: $primary
            font-size: $fontSizeL
            font-weight: 900
            position: relative
            line-height: 21.78px
            padding: 0 15px 10px
            border-right: 1px solid $gray
            .previousStat
                color: $black
                margin: 10px 0
                font-size: $fontSizeM
                line-height: 14px
            .difference
                font-size: $fontSizeS
                line-height: 11.72px
            .decrement
                color: $red
                &::after
                    content: " "
                    width: 0
                    height: 0
                    left: 35px
                    bottom: 15px
                    position: absolute
                    border-top: 15px solid $red
                    border-left: 10px solid transparent
                    border-right: 10px solid transparent
            .increment
                color: $primary
                &::after
                    content: " "
                    width: 0
                    height: 0
                    left: 35px
                    bottom: 15px
                    position: absolute
                    border-bottom: 15px solid $primary
                    border-left: 10px solid transparent
                    border-right: 10px solid transparent
        th:last-of-type, td:last-of-type
            border-right: none

@media (max-width: 960px)
    .planBoard,.insights, .watchTower, .dashboard-main
        .actions
            margin-left: 0
            flex-wrap: wrap
            .action
                .action-input
                    &--small
                        width: 120px
    .dashboard
        .dash-box
            &.top
                &:not(:first-child)
                    margin-top: 35px
            &.half
                flex: 0 0 100%
    .operational-dashboard
        .table-header
            width: 100%
        .vertical-spacer
            display: none
        .table-subtitle
            margin-left: 0
        .charts
            flex-direction: column
            .pie-chart
                margin-top: 50px
            .pie-chart, .bar-chart
                width: 100%
                padding: 0

@media all and (max-width: 768px)
  .watchTower
    .watchtower-search.action
      flex-direction: column
    .action
      width: fit-content
      
@media (max-width: 460px) 
    .operational-dashboard
        .recharts-wrapper
            overflow-x: auto
            padding-right: 40px


