.btn
  font-weight: bold
  border-radius: $radiusL
  display: inline-block
  padding: 0 40px
  height: 40px
  line-height: 40px
  transition: .2s
  font-family: Montserrat, sans-serif
  font-size: $fontSizeM
  cursor: pointer

  &:hover
    background-color: $primary
    color: $white

.btn-active
  background-color: $primary
  color: $white

.btn-primary
  background-color: $primary

  &:hover
    color: $white

.btn-left
  border-radius: $radiusL 0 0 $radiusL

.btn-right
  border-radius: 0 $radiusL $radiusL 0

.btn-grab
  cursor: grab

.dropdown svg
  color: $darkgray
  width: 20px
  height: 20px
  margin-top: 5px

button,.button
  color: $white
  background: $primary
  border: 1px solid $primary
  padding: 5px 25px
  height: 35px
  line-height: 25px
  border-radius: $radiusL
  font:
    size: $fontSizeM
    family: $secondaryHeaderFont
    weight: 100
  display: inline-block
  position: relative
  +transition($s)
  &.center
    display: block
    margin: 0 auto
  &.full
    padding: 0
    width: 100%
  &.back, &.dark
    margin-right: 15px
    background: $secondary
    border-color: $secondary
    &:hover
      color: $secondary
  &.light
    margin-right: 15px
    background: $lightgray
    border-color: $lightgray
    color: $darkgray
    &:hover
      border-color: $primary
      color: $primary
  &.active
    border-color: $primary
    background-color: $primary
    color: $white
  &.left
    border-radius: $radiusL 0 0 $radiusL
    margin: 0
  &.right
    border-radius: 0 $radiusL $radiusL 0
    margin: 0
  &:disabled
    opacity: 0.6
    pointer-events: none
  &:active,&:focus
    outline: none
  &.loading
    padding-left: 40px
  &:hover
    cursor: pointer
    color: $primary
    background: none
    +transition($s)
  i.fa
    margin-right: 10px
    &.behind
      margin-left: 10px
      margin-right: 0
  &.with-options
    padding: 5px 35px 5px 25px
    transition: none
    -webkit-transition: none
    &::after
      content: "\25BC"
      position: absolute
      right: 10px
      top: 0
      height: 100%
      line-height: 35px
      color: $white
      font-size: $fontSizeXS
    &:hover
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0
      ul
        display: block
      &::after
        color: $primary
        content: "\25B2"
  ul
    display: none
    padding: 0
    margin: 0
    list-style: none
    position: absolute
    width: calc(100% + 2px)
    left: -2px
    bottom: -35px
    border-bottom-right-radius: $radius
    border-bottom-left-radius: $radius
    li
      width: 100%
      color: $lightgray
      background: $primary
      border: 1px solid $primary
      padding: 5px 5px
      height: 35px
      line-height: 25px
      font:
        size: $fontSizeM
        family: $secondaryHeaderFont
        weight: 100
      &:last-child
        border-bottom-right-radius: $radius
        border-bottom-left-radius: $radius
      &:hover
        cursor: pointer
        background: $secondary
        border-color: $secondary
        +transition($s)