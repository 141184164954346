.trip-view
    padding-bottom: 25px
    &__map
        width: 100%
        height: 250px
        margin-bottom: 20px
    &.tabbed
        padding: 5px 10px
    .actions-cards
        justify-content: space-between
        .action-card
            background: $lightgray
            flex: 0 0 50%
            .communicationRequests, .events
                background: $white
            .action-card
                background: $white
                .communicationRequests, .events
                    background: $lightgray
.trip-info
    margin-top: 10px
    display: grid
    grid-template-columns: 1fr 1fr
    margin-top: 10px
    > div
        display: grid
        grid-template-columns: 1fr 2fr
        padding: 2px 0
    &__block
        padding-right: 10px
    &__label
        font-weight: bold
    .trip-status
        padding: 3px 10px
        border-radius: $radius
        color: $white
        &--requested
            background: $gray
            color: $black
        &--completed, &--in_transit, &--confirmed, &--accepted
            background: $green
        &--cancelled
            background: $red
.trip-map
    margin-top: 20px

.trip-breakdown
    &__header-row, &__row
        display: flex
        border-bottom: 1px solid $gray
    &__row
        &:last-child
            border-bottom: 1px solid transparent
    &__column
        flex: 1
        padding: 6px
        &:not(:last-child)
            border-right: 1px solid $gray
        &.small
            flex: none
            width: 100px
        &.xsmall
            flex: none
            width: 40px
        &.one
            flex: 1
        &.two
            flex: 2
    &__header-row &
        &__column
            font-weight: bold
    &__capacity
        b
            padding-right: 3px
            &.red
                color: $red
            &.green
                color: $green

.trips-breakdown
    max-width: 100%
    padding-left: 25px
    &__elements
        display: flex
    &__breakdown
        padding-left: 50px
    &__item
        padding: 4px 0
        border-bottom: 1px solid $gray
        &:hover
            background: $lightgray
            border-color: $primary
        svg
            width: 25px
            height: 25px
            border-radius: $roundRadius
            fill: $white
            background: $primary
            padding: 5px
            margin-right: 10px

.trip-expenses
    width: 100%
    margin-top: 10px
    .trip-expense
        display: flex
        padding: 3px 0
        &:not(:last-child)
            border-bottom: 1px solid $gray
        &__name, &__description, &__amount, &__total
            flex: 1
            padding-right: 10px
        &__name
            font-weight: bold
    &__overview
        margin-top: 15px

.public-trip
    padding: 30px 30px
    background: $white

.trip-card
    border-radius: $radius
    border: 2px solid $white
    box-shadow: $shadow
    background-color: $primary
    font-family: $secondaryHeaderFont
    z-index: 10
    margin: 10px 0 20px
    padding: 10px 0
    position: relative
    .svg-tag
        display: block
    .inline-tags
        display: flex
        position: absolute
        top: -10px
        right: 10px
        .tag
            font-size: $fontSizeS
            padding: 2px 10px
    .trip-absolute-info
        position: absolute
        width: 100%
        height: 80%
        top: 0
        left: 0
        display: flex
        justify-content: space-between
        color: $white
        font-size: $fontSizeM
        user-select: none
        pointer-events: none
        .leftSide, .rightSide
            top: 50%
            height: auto
            position: absolute
            transform: translateY(-50%)
        .leftSide
            left: 4px
        .rightSide
            right: 4px
    &.small
        .trip-content, .trip-absolute-info
            display: none
    &.responsive, &:hover
        min-width: 400px !important
        overflow: visible
        z-index: 12
        padding: 15px 8px
        .inline-tags
            display: flex
        .svg-tag
            display: block
        .trip-absolute-info
            display: none
        .trip-content
            display: grid
            justify-content: normal
            align-items: normal
            grid-template-columns: 90px auto
            .attachment-sidebar
                display: block
                height: auto
                min-height: 90px
                .attachment-list-item span
                    display: block
            .attachment-content
                display: block
        .trip-destination div:not(:first-child)
            display: inline-block

    .trip-content
        display: flex
        justify-content: center
        align-items: center

        .attachment-sidebar
            background-color: $white
            border-right: 4px solid $primary
            padding: 5px
            display: flex
            .attachment-list-item
                margin-bottom: 4px
                span
                    display: none

        .attachment-content
            display: none
            background-color: $white
            padding: 5px
            max-height: 120px
            min-width: 100px
            .attachment-content-item, .attachment-list-item
                justify-content: start
                margin-bottom: 4px
                display: block
            .from, .to
                flex: 1
                width: 100%
                align-items: self-start
                b
                    width: 40px
            .trip-breakdown
                width: 1200px
                zoom: 100%
                font-size: $fontSizeS
                &__column
                    padding: 3px
        .attachment-sidebar .attachment-list-item,
        .attachment-content .attachment-content-item
            padding: 0 4px
            border-radius: $radiusS
            font-size: $fontSizeS
            display: flex
            align-items: center
            gap: 5px
            &:hover, &.active
                color: $primary
                cursor: pointer
                background-color: $lightgray
            &.row
                flex-direction: row
                &.from, &.to
                    align-items: self-start
            .icon
                svg
                    fill: $darkgray
                    border-radius: $radiusS
                    width: 15px
                    height: 15px
                    margin-top: 5px
            .close-icon
                svg
                    background-color: $red
                    padding: 3px
                    fill: $white
                    border-radius: $radiusL
                    width: 15px
                    height: 15px
                    margin-top: 5px

    .trip-route-timelines
        padding: 5px 0
        display: inline-flex
        font-weight: bold
        font-size: $fontSizeS
        overflow: hidden
        max-width: 100%
        .route-timeline
            min-height: 5px
            background: $white
            border-radius: $radiusS
            transition: opacity 0.3s ease, visibility 0.3s ease
            &:hover
                min-width: 40px !important
                label
                    display: block

            label
                padding: 0 5px
                display: none
        .stop
            background: $red
            label
                color: $white
    .trip-destination
        color: $white
        background-color: $darkgray
        padding: 3px 5px
        border-radius: $radiusS
        font-size: $fontSizeS
        position: absolute
        display: flex
        align-items: center
        gap: 5px
        bottom: -11px
        left: 50%
        transform: translate(-50%)
        white-space: nowrap
    &.cancelled
        border: 1px solid $lightred
        background-color: $lightgray
    &.spacer
        height: 90px
        box-shadow: none
        background-color: transparent
        z-index: -1
        margin: 0
        &.small
            height: 30px
