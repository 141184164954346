.support-center
    .top-functions
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin: 40px 0 20px
        .search-block
            flex: 1
            max-width: 400px
        button,input
            display: block
        input
            width: 100%
.support-tickets
    .ticket
        padding: 10px 20px
        display: flex
        justify-content: space-between
        color: $black
        background: $white
        border-bottom: 1px solid $gray
        +transition($s)
        &:hover
            background: $white
            cursor: pointer
            border-color: $primary
            .subject
                color: $primary
            
        b
            font-size: .8em
        .subject
            font:
                size: 1.4em
        .date
            color: $darkgray
            font:
                size: .9em
                style: itatlic
        .priority
            margin-left: 12px
            font:
                size: .9em
.support-ticket
    min-height: calc(100vh - 85px)
    h2
        margin: 20px 0 10px
    .content-block
        padding: 0 15px
        &.middle
            height: 100%
    .full-height
        height: 100%
    .main
        background: $white
        padding-bottom: 35px
        position: relative
    .new-support-ticket
        width: 100%
        position: absolute
        bottom: 0
        left: 0
    .title
        border-bottom: 1px solid $gray
        padding: 25px 0
        h1, h2, h3, h6
            margin: 0
        h6
            margin-top: 10px
    .subtitle
        padding-top: 6px
        font-size: $fontSizeM
    .message
        height: 100px
    .request-info
        flex-direction: column
        display: flex
        h2
            margin-top: 0
        .one
            display: block
    .attachments
        display: flex
        .attachment
            width: 150px
            height: 150px
            display: block
            position: relative
            img
                height: 100%
                width: 100%
                object-fit: contain
    .info-block
        margin-bottom: 10px
        line-height: 1.2
        .name
            font-weight: bold
    .support-ticket-requests, .support-ticket-errors
        max-height: calc((100vh - 215px) / 2)
        min-height: 380px
    .support-ticket-request, .support-ticket-error
        border-bottom: 1px solid $gray
        padding: 5px 0 10px
        .request
            &.GET
                color: green
            &.POST
                color: orange
            &.PUT
                color: blue
            &.DELETE
                color: red
        .url
            margin-left: 10px
            word-break: break-all
            font-size: $fontSizeM
        .responseTime
            font-size: $fontSizeM
        .agent, .date, .ip, .error
            margin-top: 4px
            word-break: break-all
            color: $darkgray
            font:
                size: $fontSizeS
        .date,.ip
            font:
                size: $fontSizeM

    .support-ticket-actions
        max-height: 230px
    .support-ticket-action
        padding: 10px 0
        margin-top: 15px
        &:first-child
            margin-top: 0
        &:not(:last-child)
            border-bottom: 1px solid $gray
        .name
            font-weight: bold
            span
                font-size: $fontSizeS
                padding: 3px 5px
                background: $lightgray
                border-radius: $radius
                font-weight: normal
                color: $primary
        .date
            color: $darkgray
            font:
                size: $fontSizeM
        .action-message
            margin-top: 10px
