@import "../vars/_vars"

.chat
	display: grid
	grid-template-columns: 1fr 2fr
	gap: 15px
	h1
		font-size: $fontSizeH2
	h1,h2,h3
		margin: 0
	p
		margin: 0
	.aside
		background-color: $white
		padding: 10px
		border-radius: $radius 10px 0 0 
		max-height: calc(100vh - 95px)
		.aside-list
			height: 86%

		input
			margin: 10px 0

		.chat-list
			display: flex
			flex-direction: column
			height: 100%

			.list-item
				display: flex
				align-items: center
				gap: 10px
				padding: 5px
				cursor: pointer
				border-bottom: 1px solid $gray
				&:hover, &.active
					background: $lightgray
					+transition($s)
				.avatar 
					div
						height: 45px
						width: 45px
					svg
						height: 45px
						width: 45px
						padding: 8px
						background-color: $white
						border-radius: $roundRadius
				.details
					.name
						font-weight: bold
					.preview
						color: $darkgray
					.preview-date
						color: $darkgray
						font:
							style: italic
							size: $fontSizeM
							weight: normal

	.chat-header
		display: flex
		align-items: center
		justify-content: space-between
		gap: 5px
		padding: 5px
		font-weight: bolder
		font-family: $basicFont
		.thumbnail-title
			display: flex
			gap: 10px
			align-items: center
			font-weight: bolder
			img
				width: 45px
				height: 45px
				border-radius: $roundRadius
			svg
				background-color: $white
				padding: 5px
				width: 45px
				height: 45px
				border-radius: $roundRadius

		.icons
			display: flex
			align-items: center
			justify-content: flex-end
			gap: 5px
			.svg-fix svg
				margin-top: 0 !important
				fill: $primary


	.chat-left
		background: $white
		padding: 15px
		height: 100%
		overflow: clip

		.chats-list
			height: 100%
			.input-group
				margin: 10px 0 !important
		.chats
			height: calc(100% - 50px)
			overflow-y: scroll
			list-style: none
			padding: 0
			margin: 0
			max-height: calc(100vh - 180px)
			.item
				display: flex
				padding: 10px 0
				border-bottom: 1px solid $gray
				line-height: 1
				+transition($s)
				&:hover, &.active
					background: $gray
					cursor: pointer
				img
					height: 55px
					width: 55px
					border-radius: $roundRadius
					margin: 0 10px
					align-self: center
				.left-box
					margin: 3px 0
				.name
					font:
						size: $fontSizeL
					position: relative
					display: inline-block
					span
						font-size: $fontSizeS
						position: absolute
						right: -30px
						top: 1px
						background: $primary
						color: $white
						border-radius: $radius
						min-width: 16px
						width: auto
						height: 16px
						display: inline-block
						text-align: center
						line-height: 16px
						padding: 0 4px
				.preview
					font-size: $fontSizeM
					margin: 6px 0
					color: $darkgray
				.preview-date
					color: $darkgray
					font:
						style: italic
						size: $fontSizeS
						weight: normal

	.main
		height: 100%
		position: relative
		background-color: $white
		height: calc(100vh - 95px)
		border-radius: $radius $radius 0 0 
		padding: 10px
		.create-chat
			margin: 10px 0
			.input-group
				margin: 10px  0

		.messages
			max-height: calc(95vh - 250px)
			padding: 0 20px 20px
			position: relative
			display: flex
			flex-direction: column-reverse
			a
				color: $black
				&:hover
					color: $primary
			.day-seperator
				margin-top: 20px
				text-align: center
				font-size: $fontSizeM
				.date
					padding: 5px 15px
					text-align: center
					display: inline-block
					position: relative
					&::before
						content: ""
						position: absolute
						width: 100%
						height: 100%
						top: 0
						left: 0
						background: $primary
						opacity: 0.1
						border-radius: $radius
			.msg
				margin-top: 14px
				flex-wrap: nowrap
				position: relative
				&-container
					width: 100%
				img
					height: 45px
					width: 45px
					border-radius: $roundRadius
					margin: 5px 10px 0 0
				.name
					font-size: $fontSizeL
					&--highlighted
						color: $primary
					span
						font-size: $fontSizeS
						color: $darkgray
						margin-left: 6px
				.message-row
					display: flex
					justify-content: space-between
					&--has-reply
						background: $lightgray
						padding: 10px 10px 5px
						.message
							padding: 5px 10px
							background: $white
							display: block
							&--error
								color: $red
							&--referenced
								padding: 5px
								background: $lightgray
								position: relative
								margin-bottom: 10px
								&::before
									content: ""
									position: absolute
									left: 0
									bottom: -10px
									width: 0
									height: 0
									border-right: 10px solid transparent
									border-left: 10px solid transparent
									border-bottom: 10px solid $white

				.translation-button
					float: right
					&.enable
						pointer-events: all
					&.disable
						pointer-events: none
					span
						transition: all 0.2s ease-in
						&:hover
							color: $primary
							text-decoration: underline

				.message
					color: $darkgray
					display: inline-block
					a
						color: $primary
						&:hover
							text-decoration: underline
					&.offline
						opacity: 0.6
			.files
				margin: 3px 0
				.file
					img
						height: 100%
						width: auto
						max-width: 100%
						min-width: 30px
						border-radius: 0
						margin: 0
			.options-menu
				margin-right: 10px
			.checks
				font-style: italic
				color: $gray
				display: flex
				margin-left: 10px
				.check
					width: 10px
					&--all
						color: $green
						fill: $green
					svg
						height: 100%
						width: 100%
						display: block

	.new-message
		min-height: 130px
		width: 100%
		background: $white
		position: absolute
		bottom: 0
		left: 0
		border-radius: 0 0 $radius $radius
		overflow: clip
		.uploaded-files .file
			margin: 0
			.actions
				width: 30px
				background: none
		form
			padding: 0
			width: 100%
			height: auto
		.input-group
			margin: 0
			display: block
		.drag-and-drop
			p
				margin: 0
				max-height: 90px
		.message-input
			width: 100%
			padding: 0 100px 12px 20px
			overflow-y: scroll
			display: block
			max-height: 100px
			&:focus
				outline: none
			&:empty:before
				content: attr(placeholder)
				pointer-events: none
				display: block
				color: $gray
		button.send
			position: absolute
			right: 25px
			bottom: 30px
			height: 40px
			width: 40px
			padding: 0
			margin: 0
			border-radius: $roundRadius
			&:disabled
				opacity: 0.5
			i
				font-size: $fontSizeL
				text-align: center
				width: 100%
				height: 100%
				line-height: 40px
				position: absolute
				color: $black
				left: 53%
				top: 48%
				transform: translate(-50%, -50%)

		.actions-bar
			height: 40px
			width: 100%
			min-width: 400px
			padding: 0 10px
			background: $llightgray
			border: none
			gap: 10px

		.actions-bar, .actions
			height: 40px
			display: flex
			justify-content: flex-start
			&.darkMode
				img
					filter: invert(1)
			button
				height: auto
			.action, .rdw-option-wrapper
				display: inline-block
				border-radius: $roundRadius
				position: relative
				height: 40px
				width: 40px
				background: none
				border: none
				box-shadow: none
				margin: 0
				+transition($s)
				&:hover, &.rdw-option-active
					cursor: pointer
					background: $primary
					box-shadow: none
					.icon svg
						fill: $white
					img
						filter: invert(1)
				img
					width: 16px
					max-height: 16px
					margin: 7px 7px
					object-fit: contain
					+transition($s)
				svg
					margin: 10px 10px
					height: 20px
					width: 20px
					display: block
					fill: $black
					+transition($s)
				.icon
					&.active
						svg
							fill: $primary


.chat-info
	&__image
		svg , img
			display: block
			width: 150px
			height: 150px
			margin: 0 auto
			border-radius: $roundRadius
	&__title
		text-align: center
		margin-top: 15px
		font:
			size: $fontSizeH1
	&__createdAt
		text-align: center
		color: $darkgray
		font-size: $fontSizeH3
	&__users
		margin-top: 25px
	&__user
		display: flex
		padding: 10px 0
		border-bottom: 1px solid $gray
		&:last-child
			border-bottom: 1px solid transparent
		&__avatar
			img,.avatar
				width: 35px
				height: 35px
				display: block
				border-radius: $roundRadius
				margin-right: 15px
				svg, div
					height: 100%
					width: 100%
		&__name
			font-size: $fontSizeH3
			align-self: center

.chat-popups
	position: fixed
	z-index: 1000
	bottom: 0
	right: 0

	.chat-header
		padding: 0 5px
		.thumbnail-title
			gap: 5px
			font-size: $fontSizeM
			img
				width: 40px
				height: 40px
			svg
				margin-top: 5px
				padding: 0
				width: 40px
				height: 30px
	.sidebar
		width: 19vw
		max-width: 400px
		height: auto
		max-height: 80vh
		position: absolute
		right: 0
		bottom: 0
		border-radius: $radius $radius 0 0
		.aside
			height: auto
			box-shadow: $popupShadow
			.aside-list
				height: calc(80vh - 100px)
				.chat-list
					height: calc(100% - 50px)
			.chat-header h2
				font-size: $fontSizeL
		.main
			height: auto
			box-shadow: $popupShadow
			.chat-header h2
				font-size: $fontSizeL
			.create-chat
				height: 70vh


	.user-chats
		width: 80vw
		height: auto
		max-height: 80vh
		position: fixed
		left: 0
		bottom: 0
		border-radius: $radius
		padding: 0 10px
		display: flex
		gap: 5px
		flex-direction: row-reverse
		align-items: flex-end
		overflow: scroll
		pointer-events: none

		.main
			pointer-events: fill
			height: auto
			padding: 5px
			box-shadow: $popupShadow
			width: 19vw
			overflow: clip

			.chat-box
				height: 60vh
				.messages
					max-height: calc(60vh - 120px)

	.user-chats::-webkit-scrollbar
		display: none

@media only screen and (max-width: 1440px)
	.chat-popups
		.sidebar
			width: 24vw
		.user-chats
			width: 75vw
			.main
				width: 24vw
				.messages
					max-height: calc(85vh - 310px)

@media only screen and (max-width: 1024px)
	.chat-popups
		.sidebar
			width: 29vw
		.user-chats
			width: 70vw
			.main
				width: 29vw

@media only screen and (max-width: 768px)
	.main-chat
		.chat
			grid-template-columns: 1fr
			margin-bottom: 50px
			.main
				height: calc(100vh - 225px)

	.chat
		.aside
			max-height: calc(100vh - 135px)
	.chat-popups
		.sidebar
			width: 40vw
		.user-chats
			width: 59vw
			.main
				width: 40vw

@media only screen and (max-width: 425px)
	.chat-popups
		.sidebar
			bottom: 42px
			width: 90vw
			.aside
				.aside-list
					height: calc(80vh - 50px)
					.chat-list
						height: calc(100% - 50px)

		.user-chats
			padding: 0
			left: 45px
			bottom: 42px
			width: 90vw
			z-index: 1000
			display: block
			height: 80vh
			overflow: clip
			.main
				width: 100%
				.messages
					max-height: calc(73vh - 130px)
				.chat-box
					height: 73vh

	.chat .new-message .actions-bar .action,
	.chat .new-message .actions-bar .rdw-option-wrapper,
	.chat .new-message .actions .action,
	.chat .new-message .actions .rdw-option-wrapper
		width: 35px


@media only screen and (max-width: 320px)
	.chat-popups
		.user-chats
			left: 52px
			bottom: 42px
