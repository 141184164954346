@import "../vars/_vars"

body
  margin: 0
  padding: 0
  color: $black
  background: $lightgray
  background-image: var(--background-image)
  background-repeat: repeat
  background-size: 1600px
  font-family: $basicFont
  font-size: $fontSizeM
  line-height: 1.3
  min-height: 100vh
  scrollbar-width: unset !important

.map-layer-dark
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%)

.leaflet-container
  background: $white !important

h1
  line-height: 1
  font:
    family: $secondaryHeaderFont
    size: $fontSizeH1
    weight: bold
h2
  font:
    family: $basicFont, sans-serif
    size: 1.3em
  &.top
    color: $secondary
    position: relative
    margin: 0
    margin-bottom: 25px
    font:
      family: $secondaryHeaderFont
      weight: bold
      size: 1.35em
    &::after
      content: ""
      position: absolute
      width: 50px
      height: 3px
      background: $primary
      left: 0
      bottom: -5px
h3
  font:
    family: $basicFont
    size: 1.3em
    weight: lighter

h1,h2,h3,h4
  &.with-flair
    display: flex
    span
      margin-left: 10px
      color: $white
      background: $primary
      font-size: $fontSizeL
      font-weight: normal
      padding: 5px 10px
      align-self: center
      width: auto
      border-radius: $fontSizeH1
      line-height: 100%
      
code
  padding: 20px
  border: 1px solid $darkgray
  background: $lightgray
  border-radius: $radius
  display: block
  white-space: pre-wrap

.fullpage
  min-height: -webkit-fill-available
  min-height: 100vh
  position: relative

.no-data, .nothing, .css-gg45go-NoOptionsMessage
  text-align: center !important
  color: $darkgray
  font:
    size: $fontSizeM
    style: italic

.not-found-action-box
  padding: 50px 30px
  background: $white
  text-align: center
  &.small
    padding: 10px 20px
    .icon
      height: 75px
      svg
        height: 75px
  .icon
    margin: 0 auto
    display: block
    height: 100px
    svg
      height: 100px
      width: auto
      fill: $primary
  .no-data
    font-size: $fontSizeL
  .action
    +transition($s)
    color: $primary
    &:hover
      color: $secondary
      cursor: pointer

a,.as-link
  text-decoration: none
  color: $primary
  display: inline
  &:hover
    color: $secondary
    cursor: pointer
  &:focus, &:active
    outline: none

img
  &.rounded
    border-radius: $roundRadius

.spinner
  width: 40px
  height: 40px
  background:
    color: $primary
    size: cover
    //image: url(../images/Vynwork_gray.svg)
  margin: 50px auto
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out
  animation: sk-rotateplane 1.2s infinite ease-in-out

.lds-ripple
  display: block
  position: relative
  width: 80px
  height: 80px
  margin: 50px auto
  div
    position: absolute
    border: 4px solid $primary
    opacity: 1
    border-radius: $roundRadius
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite
    &:nth-child(2)
      animation-delay: -0.5s

.aside-view
  display: flex
  // position: relative
  min-height: 100vh
  .aside-view-content
    text-align: center
    align-self: center
    .step-form
      .form
        display: none
        &.active
          display: inline-block
    form
      display: inline-block
      width: 100%
      text-align: left
    .input-container
      margin: 0 auto
  .aside-view-image
    flex: 4
    width: 50%
    position: absolute
    right: 0
    top: 0
    height: 100%
    z-index: -1
    &.linear-gradient
      +linear-background()
    img
      height: 100%
      width: 100%
      object-fit: cover

.overlay
  height: 100vh
  width: 100%
  position: fixed
  left: 0
  top: 0
  z-index: 1000
  opacity: 0
  user-select: none
  pointer-events: none
  +transition($s)
  &.active
    opacity: 1
    pointer-events: all
    user-select: auto
  .background
    background: $black
    width: 100%
    height: 100%
    position: relative
    left: 0
    top: 0
    opacity: 0.85
  .children
    z-index: 10
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
  .overlay-component
    background: $white
    .drag-and-drop
      p
        margin: 10px
.overhead
  width: 100%
  height: 100%
  background: $lightgray
  opacity: 0.99
  position: absolute
  z-index: 1000
  position: absolute
  left: 0
  top: 0
  display: none

.pager
  display: flex
  margin-top: 10px
  .left,.right
    height: 35px
    width: 15px
    align-self: center
    display: flex
    pointer-events: none
    &:hover
      cursor: pointer
    &.active
      pointer-events: all
      svg
        fill: $black
    div
      align-self: center
      width: 100%
      svg
        display: block
        fill: $gray
  .right
    transform: rotateZ(180deg)
  span
    align-self: center
  .page-number
    padding: 5px 8px
    border-radius: $radiusS
    align-self: center
    &.active
      pointer-events: none
      color: $primary
    &:hover
      cursor: pointer

.page
  &.standalone
    background: $white
    padding: 5px
  &.pagefade-enter
    opacity: 0
    z-index: 1000000
  &.pagefade-exit
    opacity: 0
  &.pagefade-enter.pagefade-enter-active
    opacity: 1
    transition: all 250ms ease-in
  &.nothing
    footer
      +linear-background-down()
      margin-top: 250px
      &::after
        width: 100%
        height: 150px
        content: ""
        position: absolute
        top: -149px
        left: 0
        z-index: 99
        background-position-y: -1px
        background: -webkit-linear-gradient(177deg, transparent 49%, $primary 50%) no-repeat top/100% 150px, linear-gradient(-5deg, transparent 49%, transparent 50%) no-repeat bottom/100% 150px, transparent
        background: -moz-linear-gradient(177deg, transparent 49%, $primary 50%) no-repeat top/100% 150px, linear-gradient(-5deg, transparent 49%, transparent 50%) no-repeat bottom/100% 150px, transparent
        background: linear-gradient(177deg, transparent 49%, $primary 50%) no-repeat top/100% 150px, linear-gradient(-5deg, transparent 49%, transparent 50%) no-repeat bottom/100% 150px, transparent
      .top
        .list
          color: $white
          a
            color: $white
            &:hover
              color: $secondary
          h1
            color: $white
            &::after
              background: $white
        .social
          i
            background: $white
            border-color: $white
            color: $primary
            &:hover
              color: $white
              background: transparent
      .red
        display: none
      .white
        display: block
      i.green
        color: $white
      .bottom
        color: $white
        .version
          color: $white
  &.contact
    footer
      margin-top: 0px

.scrollable, .scroll, .pg-viewer-wrapper
  overflow: scroll
  scrollbar-color: $primary
  &.thick
    &::-webkit-scrollbar
      width: 6px
      height: 6px
  &::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1)
    background-color: $white
  &::-webkit-scrollbar
    width: 2px
    height: 2px
    border-radius: $radiusS
    background-color: $lightgray
  &::-webkit-scrollbar-thumb
    background-color: $primary
  &__x
    overflow-x: scroll
    overflow-y: visible
  &__y
    overflow-x: visible
    overflow-y: scroll
  &__both
    overflow-x: scroll

.peek-map
  display: inline-block
  margin-left: 8px
  position: relative
  &:hover &
    &__map-container
      opacity: 1
      pointer-events: all
  &.to-top &
    &__map-container
      top: -150px
      border-radius: $radius
      border-bottom-right-radius: 0
      &::after
        bottom: -20px
        border-bottom-color: transparent
        border-top-color: $white
  &.to-right &
    &__map-container
      top: -115px
      left: 25px
      right: auto
      border-radius: $radius
      border-bottom-left-radius: 0
      &::after
        bottom: 0px
        left: -20px
        right: auto
        border-bottom-color: transparent
        border-right-color: $white
  &.to-left &
    &__map-container
      top: -115px
      right: 25px
      border-radius: $radius
      border-bottom-right-radius: 0
      &::after
        bottom: 0px
        right: -20px
        left: auto
        border-bottom-color: transparent
        border-left-color: $white
  &__icon
    width: 20px
    height: 20px
    &:hover
      cursor: pointer
      svg
        fill: $primary
    div
      display: flex
    svg
      width: 100%
      height: 100%
      fill: $black
      +transition($s)
  &__map-container
    position: absolute
    top: 25px
    right: 3px
    width: 200px
    height: 140px
    opacity: 0
    z-index: 10
    pointer-events: none
    background: $white
    box-shadow: 0px 10px 33px -1px rgba(0, 0, 0, 0.2)
    border-radius: $radius
    border-top-right-radius: 0
    &::after
      bottom: 100%
      border: solid transparent
      content: " "
      height: 0
      width: 0
      position: absolute
      pointer-events: none
      border-bottom-color: $white
      border-width: 10px
      right: 0px
  &__wrapper
    position: relative
  &__map
    width: 200px
    height: 140px
    position: relative
    overflow: hidden
    border-radius: $radius
    border-top-right-radius: 0
    z-index: 0
    border: 6px solid $white

.progress-bar
  width: 100%
  height: 8px
  border-radius: $radiusS
  .progress
    background-color: $primary
    height: 100%
    margin: 0
    +transition($s)
    &.green
      background-color: $green
    &.red
      background-color: $red
    &.orange
      background-color: $orange

.close-button, .draggable
  &:hover
    cursor: pointer
  div
    height: 100%
    display: flex
  svg
    fill: $black
    stroke: $black
    height: 100%
    width: auto
    display: block
    +transition($s)
    &:hover
      fill: $primary
      stroke: $primary

.verified-check
  div
    height: 100%
    display: flex
    margin-right: 5px
    svg
      fill: $primary
      height: 100%
      width: 18px
      display: block
      +transition($s)

.draggable
  &:hover
    cursor: move
    cursor: grab
    cursor: -moz-grab
    cursor: -webkit-grab
  svg
    height: auto
    align-self: center

.action-icons
  height: 40px
  width: auto
  .options-wrapper
    height: 40px
  .action
    margin: 0 4px
    .action-icon
      margin: 0
  .action-icon
    width: 40px
    height: 40px
    border-radius: $roundRadius
    display: block
    position: relative
    z-index: 10
    flex: 1
    &.green
      &::after
        background: $green
      svg
        fill: $green
    &::after
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      border-radius: $roundRadius
      background: $primary
      opacity: 0.1
      z-index: -1
      +transition($s)
    &.margin
      margin: 0 4px
    &:hover
      cursor: pointer
      svg
        fill: $white
        stroke: $white
      &::after
        opacity: 0.9
    &.active
      &::before
        content: attr(data-content)
        position: absolute
        min-width: 10px
        width: auto
        height: 15px
        background: $secondary
        color: white
        bottom: 65%
        right: -5px
        line-height: 15px
        font:
          size: $fontSizeS
          weight: 600
        z-index: 100
        text-align: center
        pointer-events: none
        border-radius: $radius
        padding: 1px 3px
        +transition($s)
    div.small
      position: absolute
      top: 13px
      right: -7px
      background: $primary
      width: 15px
      height: 15px
      border-radius: $radius
      svg
        width: 15px
        height: 15px
        padding: 3px
        margin: 0
        fill: $white
        stroke: $white
    div
      display: flex
    svg
      height: 30px
      width: 30px
      margin: 5px
      padding: 5px
      fill: $primary
      stroke: $primary
      +transition($s)

.field-renderer
  display: grid
  grid-template-columns: 1fr
  margin-top: 10px
  &.two-pane
    grid-template-columns: 1fr 1fr
    > div 
      grid-template-columns: 1fr 1fr
  > div
    display: grid
    grid-template-columns: 1fr 2fr
    padding: 2px 0

.filePreviewer-container
  height: 100%
  pointer-events: none
  iframe
    pointer-events: all
    height: 100%
    width: 80%
    margin: 0 auto
    display: block
    border: none

.files
  display: flex
  .file
    max-width: 230px
    min-width: 100px
    height: 120px
    position: relative
    user-select: none
    border-radius: $radius
    overflow: hidden
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: $black
      opacity: 0
      pointer-events: none
      +transition($s)
    &:hover
      cursor: pointer
      &::after
        pointer-events: all
        opacity: 0.3
    &:not(:last-child)
      margin-right: 3px
    img
      height: 100%
      width: auto
      max-width: 100%
      min-width: 100%
      object-fit: cover
      display: block

.alert-stack
  position: fixed
  left: 20px
  bottom: 40px
  z-index: 10000
  .alert
    background: $white
    padding: 20px
    box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.2)
    margin-bottom: 10px
    max-width: 300px
    +transition($s)
    &.animateIn
      +animation($s slide-in-left)
    &.animateOut
      transform: translateX(-100%)
      box-shadow: none
      +animation($s slide-out-left)
    &.hidden
      display: none
    .description
      font-size: $fontSizeM
    &.success
      border-left: 2px solid $green
    &.error
      border-left: 2px solid $red
    &.warning
      border-left: 2px solid $orange

.collapsible
  display: flex
  padding: 8px 0
  justify-content: space-between
  border-bottom: 1px solid $gray
  &:hover
    cursor: pointer
  &.open
    .icon
      svg
        transform: rotateZ(90deg)
  .right-elements
    div
      margin-right: 5px
  .icon
    svg
      transform: rotateZ(-90deg)
      height: 20px
      fill: $gray
      +transition($s)

.options-menu
  position: relative
  width: 20px
  height: 20px
  &--open
    .options-menu
      &__options
        display: block
  &__dots
    width: 20px
    height: 20px
    line-height: 20px
    svg
      fill: $black
      stroke: $black
      width: 20px
      height: 20px
      +transition($s)
    &:hover
      cursor: pointer
      svg
        fill: $primary
        stroke: $primary
  &__options
    display: none
    right: 0
    top: 40px
    position: absolute
    background: $white
    z-index: 10
    white-space: pre
    box-shadow: 0px 0px 35px rgba(0,0,0,0.2)
    &::after
      border: solid transparent
      content: " "
      height: 0
      width: 0
      position: absolute
      pointer-events: none
      border-bottom-color: $white
      border-width: 15px
      right: 0px
      top: -30px
    &__item
      padding: 8px 32px
      +transition($s)
      &:hover
        cursor: pointer
        background: $primary
        color: $white

.file-preview
  position: relative
  width: 100%
  height: 100%
  &:hover
    cursor: pointer
  &.with-information
    &::before
      content: ""
      height: 60%
      width: 100%
      left: 0
      bottom: 0
      position: absolute
      background: -webkit-linear-gradient(to top, rgba(0,0,0,0.9), rgba(255, 255, 255, 0))
      background: -moz-linear-gradient(to top, rgba(0,0,0,0.9), rgba(255, 255, 255, 0))
      background: linear-gradient(to top, rgba(0,0,0,0.9), rgba(255, 255, 255, 0))
  &__info
    position: absolute
    bottom: 0
    left: 0
    padding: 5px 10px
    color: $white
    width: 100%
  &__name
    font-weight: bold
  &__size
    font-size: $fontSizeM

.dropdown
  position: relative
  z-index: 10
  font-size: $fontSizeM !important
  font-weight: bold
  &.active
    box-shadow: 0 0 20px -1px rgba(0,0,0,0.2)
    .items
      opacity: 1
      pointer-events: all
      min-width: 200px
      fill: $black
    .item
      &.active
        background: $gray
        svg
          fill: $white
      svg
        transform: rotateZ(0deg)
  .items
    position: absolute
    box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.2)
    z-index: -1
    width: 100%
    max-height: 300px
    opacity: 0
    pointer-events: none
    .item
      padding: 12px 18px
      height: auto
  .item
    display: flex
    background: $white
    box-sizing: border-box
    padding: 0 18px
    line-height: initial
    height: 100%

    +transition($s)
    &:hover
      cursor: pointer
      background: $lightgray
    img, div
      align-self: center
    img
      height: 25px
      width: 25px
      display: block
      margin-right: 10px
      object-fit: contain
    svg
      fill: $black
      display: block
      transform: rotateZ(-180deg)
      +transition($s)
    .carrot
      margin-left: 5px

.notify-banner
  font-size: $fontSizeS
  padding: 4px
  &.warning
    background: $red
    color: $white

.bubble
  min-width: 15px
  width: auto
  height: 15px
  background: $secondary
  color: white
  bottom: 65%
  right: -5px
  line-height: 15px
  font:
    size: $fontSizeS
    weight: 600
  z-index: 100
  text-align: center
  border-radius: $radius
  padding: 1px 3px
  display: inline-block
  &__processing
    background: $orange
  &__failure
    background: $red
  &__success
    background: $green

.buttons
  button, input[type="button"], input[type="submit"]
    margin: 0 5px

.layer-selector
  left: 10px
  bottom: 5px
  display: flex
  z-index: 1000
  position: absolute
  &:hover
    .layer-options
      display: block
  .layer-options, .current-layer, .layer-option
    height: 50px
    width: 50px
    background: $white
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
  .current-layer
    border: 2px solid $primary
    border-radius: $radius
  .layer-options
    width: auto
    display: none
    margin-left: 10px
    border-radius: $radius
    padding: 0 10px
    .layer-option
      padding: 5px

.default-marker
  fill: $primary
  filter: drop-shadow(0px 1px 1px rgba(0,0,0, 0.3))

.number-marker
  #path
    fill: $secondary
  #number
    fill: $white
    z-index: 10
.location-pin-marker
  fill: $primary

.tab-links-container
  &.white
    .tab-links
      background-color: $lightgray
      &:hover
        color: $primary
      &.tab-active
        background-color: $primary
        color: $white

  &.small
    .tab-links
      height: 30px
      padding: 0 15px
      line-height: 30px

.tab-links
  background-color: $white
  height: 40px
  display: inline-block
  line-height: 40px
  border-radius: $radiusL
  transition: .2s
  padding: 0 30px
  font-family: Montserrat, sans-serif
  font-size: $fontSizeM
  font-weight: bold
  white-space: nowrap
  &.smaller
    height: 30px
    line-height: 30px
    border-radius: $radiusL
    padding: 0 30px
  &.dark
    background-color: $lightgray
  &:hover
    background-color: $primary
    color: $white
    cursor: pointer
  &.tab-active
    background-color: $primary
    color: $white

.tabs-content-container
  margin: 10px 0
  padding: 10px
  .table
    margin-top: 0

.tab-active
  background-color: $primary
  color: $white

.cursor-drag
  cursor: move !important

.contact-details
  padding: 10px 0
  .contact-detail
    .name
      font-weight: bold
    .value
      margin-left: 10px
    
.detail-view
  .action-bar
    position: sticky
    bottom: 0
    left: 0
    padding: 30px 5px
    background: $white
    width: 100%
    height: auto
    display: flex
    justify-content: space-between
    box-shadow: $boxShadow

.base-stat
  height: 95px
  width: 350px
  min-width: 250px
  border-radius: 4px
  border: 1px solid $llightgray
  .content-wrapper
    height: 100%
    padding: 15px 20px
    &.adjustable
      padding-right: 15px
    .stats
      margin-left: 15px
      .value
        font-style: normal
        font-weight: 600
        font-size: 18px
        line-height: 16px
        color: $black
        margin: 0 0 10px
      .title
        margin: 10px 0 0
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: $darkgray
    .icon-wrapper
      width: 70px
      height: 60px
      min-width: 70px
      border-radius: 2px
      background: $llightgray
      svg
        width: 30px
        height: 30px
        fill: $primary
      &::before
        content: none

.text-line-warning
  display: inline-block
  height: 1em
  width: 1em
  margin: 0 .5em

  div, svg
    height: 1em
    width: 1em
  &.red
    svg 
      fill: $red

@media only screen and (max-width: 1190px)
  .beta
    &::before
      display: none

@media only screen and (max-width: 660px)
  .base-stat
    width: 100% !important
  .cookie
    .box
      width: 90%
    .text
      padding: 25px 5%
  button
    padding: 0 15px
  .hidden-small
    display: none !important

  .dropdown
    min-width: 50px

  .dropdown .item
    padding: 0 7px

  .dropdown.active .items
    min-width: 96px

.test-border
  border: 1px solid red !important

.max-content
  width: max-content !important

.ledger-table
  td, th
    border: 1px solid $gray
    padding: 10px
  tr:first-child th
    border-top: 0
  tr:last-child td
    border-bottom: 0
  tr
    td:first-child, th:first-child
      border-left: 0
    td:last-child, th:last-child
      border-right: 0

@media all and (max-width: 660px)
  .base-stat
    width: 100% !important
