@import "../vars/_vars"

input, textarea, .input
  border: none
  background: $lightgray
  height: 40px
  width: 100%
  padding: 0px 10px
  font:
    size: $fontSizeM
    family: $basicFont
  color: $black
  position: relative
  &:hover
    cursor: pointer
  &:disabled, &:read-only
    opacity: 0.6
  &:disabled
    pointer-events: none
  &.hidden
    visibility: hidden
    position: absolute
    width: 1px
    height: 1px
    left: 0
    top: 0
  &.search
    width: 100%
    display: block
    margin: 0 auto
    padding: 0 10px
    border: none
    box-sizing: border-box
    background: $white
  &.border
    border: 1px solid
  &[type="color"]
    border: none
  &[type="range"]
    -webkit-appearance: none
    width: 100%
    margin: 0
    border: none
    background: transparent
    &::-webkit-slider-thumb
      -webkit-appearance: none
      background-color: $primary
    &:focus
      outline: none
    &::-ms-track
      width: 100%
      cursor: pointer
      background: $primary
      border-color: transparent
      color: transparent
    &::-webkit-slider-runnable-track
      width: 100%
      height: 2px
      cursor: pointer
      background: $primary
      border-radius: $radiusS
    &:focus::-webkit-slider-runnable-track
      background: $primary
    &::-moz-range-track
      width: 100%
      height: 2px
      cursor: pointer
      background: $primary
      border-radius: $radiusS
    &::-ms-track
      width: 100%
      height: 2px
      cursor: pointer
      background: transparent
      border-color: transparent
      border-width: 16px 0
      color: transparent
    &::-ms-fill-lower
      background: $primary
      border-radius: $radiusS
    &:focus::-ms-fill-lower
      background: $primary
    &::-ms-fill-upper
      background: $primary
      border-radius: $radiusS
    &:focus::-ms-fill-upper
      background: $primary
    &::-webkit-slider-thumb
      -webkit-appearance: none
      height: 20px
      width: 20px
      border-radius: $roundRadius
      background: $secondary
      cursor: pointer
      margin-top: -9px
      box-shadow: $shadow
    &::-moz-range-thumb, &::-ms-thumb
      box-shadow: $shadow
      height: 20px
      width: 20px
      border-radius: $roundRadius
      background: $secondary
      cursor: pointer
  &[type="submit"]
    border: none
    color: $white
    background: $primary
    border: 1px solid $primary
    width: auto
    opacity: 1
    padding: 0 25px
    font-size: $fontSizeL
    border-radius: $radiusL
    height: 35px
    font-family: $secondaryHeaderFont
    font-weight: 100
    display: inline-block
    +transition($s)
    &:disabled
      opacity: 0.6
    &.round
      border-radius: $roundRadius
    &:hover
      cursor: pointer
      color: $primary
      background: none
      +transition($s)
  &[type="checkbox"], &[type="radio"]
    -webkit-appearance: none
    background: none
    border: 1px solid $primary !important
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05)
    padding: 9px
    border-radius: $radiusS
    display: inline-block
    position: relative
    margin-top: 6px
    height: 20px
    width: 20px
    line-height: 20px
    float: left
    opacity: 1
    &:hover
      cursor: pointer
    &:active
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05)
    &:checked
      background: $primary
      &:active
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05)
        background-color: none
        border: 1px solid $primary
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05)
        color: $primary
      &:after
        content: '\2714'
        font-size: $fontSizeS
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        text-align: center
        color: $white
        pointer-events: none
  &[type="radio"]
    border-radius: $radius

  &:focus, &:active
    outline: none
    &::after
      left: 0
      width: 100%
  &:focus + label:not(.normal), &.has-value + label:not(.normal)
    top: -15px
    font-size: $fontSizeS
    color: $primary
    +transition($s)
    & + .requirements
      display: flex
      +transition($s)

.messageAutomation-template-thumbnail
  width: fit-content
  img
    transition: all 0.2s ease-in
    border: 1px solid $llightgray
    &:hover
      cursor: pointer
      border-color: $primary

form,.form, .list-sector
  width: 100%
  padding: 35px 0
  &.register-form
    padding: 40px 0 !important
    .d-flex
      width: 100%
  .top-spacing
    margin-top: 20px
  .payment-element
    padding: 15px
    background: $white
  .billing-submit
    button
      color: $white
    .error-message
      color: $red
      padding: 0 10px
  .billing-info
    margin-top: 15px
    label
      top: 15px
      color: $darkgray
    input
      height: 50px
      color: $darkgray
      padding: 25px 10px 5px
      border-bottom: 1px solid lightgray
      &:focus + label:not(.normal), &.has-value + label:not(.normal)
        top: 5px
        color: $primary
  &.reverse
    [class$="-control"], [class$="-control"]:hover
      background: $white
    input, textarea, .input
      &:not([type = "submit"])
        background: $white
  &.hidden
    display: none
  &.high
    padding: 100px 0
  &.nothing
    padding: 0
    margin: 0
    font-size: $fontSizeH3
  &.small-selector
    width: auto
    padding: 0
    justify-content: center
    align-self: center
    [class$="-control"], [class$="-control"]:hover
      background: transparent
      min-height: auto
      min-width: 130px
      font-size: $fontSizeH3
      font-family: $basicFont
      font-weight: bold
      .css-1uccc91-singleValue
        color: $black
  .msg,.srv-validation-message
    float: left
    width: 100%
    text-align: left
    margin-top: 8px
    font-size: $fontSizeM
    color: $red
    &.error
      color: $red
    &.bottom
      margin: 0 0 18px
      text-align: center
      font-size: $fontSizeM
  .profile-image
    width: 100px
    height: 100px
    border-radius: $roundRadius
    float: left
    margin-right: 20px
    object-fit: cover
    filter: drop-shadow(0px 0px 6px rgba(0,0,0, 0.1))
  [class$="-control"], [class$="-control"]:hover
    border: none
    background: $lightgray
    color: $black
    border-radius: 0
    box-shadow: none
    min-height: 40px
    margin: 0 !important
    padding: 0 !important
    [class$="-indicatorContainer"]
      padding: 6px
    [class$="-singleValue"]
      color: $black
    input
      height: 32px
  [class$="-menu"]
    background: $white
    border-radius: 0
    box-shadow: $formShadow
  .css-1hwfws3
    padding-left: 10px
    padding-top: 0px
    padding-bottom: 0px
  [class$="-indicatorContainer"]
    margin: 0
    padding: 0
  [class$="-ValueContainer"]
    padding: 2px 10px 2px 10px
  .css-d7l1ni-option
    background: $lightgray
    color: $black
  .css-tr4s17-option
    background-color: $primary
  [class$="-option"]
    &:active, &:focus
      color: $white
      background-color: $secondary
  [class$="-placeholder"]
    margin: 0
    width: fit-content
  [class$="-Input"]
    margin: 0
  
  .react-datetime-picker
    width: 100%
    color: $primary
    &__wrapper
      width: 100%
      border: none
      border-bottom: 1px solid $primary
    &__button
      &:enabled
        &:hover
          stroke: $primary
      &__icon
        stroke: $gray
  .react-calendar
    &__navigation
      button
        color: $black
        padding: 0
        border-radius: 0
    &__tile
      color: $black
      min-height: auto
      border-radius: 0
      font-size: $fontSizeM
      &--active
        &:enabled
          &:hover, &:focus
            background: $primary
            color: $white
    &__button
      svg
        stroke: hsl(0,0%,80%)
        fill: hsl(0,0%,80%)
  &.small

    .input-group
      margin-top: 20px
      &.no-margin-top
        margin-top: 0px
      input, .input, textarea
        height: 30px
      textarea
        min-height: 30px
      [class$="-control"], [class$="-control"]:hover
        min-height: 30px
        input
          height: auto
textarea
  min-height: 250px
  height: auto
  display: block
  padding: 10px
  box-sizing: border-box
  max-width: 100%
  min-width: 100%
  &.small
    min-height: 120px
  &.tiny
    min-height: 60px

.input-container
  max-width: 650px
  width: 100%
  margin: 0 auto
  position: relative
  &.fixed-width
    width: 650px
  &.middle
    max-width: 350px
    width: 350px
  &.wide
    width: 100%
    max-width: none

.input-group
  position: relative
  width: 100%
  margin: 0 auto
  margin-top: 23px
  display: inline-block
  &::after
    content: ""
    position: absolute
    bottom: 0
    left: 50%
    width: 0%
    height: 1px
    background: $secondary
    z-index: 1
    +transition($s)
  &:focus,&.active
    &::after
      width: 100%
      left: 0
  &.highlight-box
    padding: 20px
    background: $white
    border-radius: $radius
  &.no
    margin-top: 0
  &.more
    margin-top: 40px
  &.eighty
    width: 80%
    flex: 0 0 80%
  &.seventy
    width: 70%
    flex: 0 0 70%
  &.ninety
    width: 90%
    flex: 0 0 90%
  &.sixty
    width: 60%
    flex: 0 0 60%
  &.half
    width: 50%
    flex: 0 0 50%
  &.fourty
    width: 40%
    flex: 0 0 40%
  &.thirty
    width: 30%
    flex: 0 0 30%
  &.twothird
    width: 66%
    flex: 0 0 60%
  &.third
    width: 33%
    flex: 0 0 33%
  &.fourth
    width: 25%
    flex: 0 0 25%
  &.fifth
    width: 20%
    flex: 0 0 20%
  &.tenth
    width: 10%
    flex: 0 0 10%
  &.eighty,&.half,&.third,&.fourth,&.fifth,&.tenth,&.ninety,&.sixty,&.fourty,&.thirty,&.twothird
    float: left
  &.right
    text-align: right
  &.center
    text-align: center
  &.left
    text-align: left
  p
    text-align: left
    font-size: $fontSizeM
    &.first-time
      padding-left: 130px
    &.no-padding
      padding-left: 0
  .text
    text-align: left
    font-size: $fontSizeH3
  h1
    text-align: left
    font:
      size: $fontSizeH3 !important
  h1,h2
    margin: 0
    color: $secondary
  h2
    &.top
      margin: 10px 0 25px
  label
    position: absolute
    left: 10px
    top: 8px
    font:
      family: $basicFont
      weight: 400
    color: $primary
    pointer-events: none
    user-select: none
    +transition($s)
    &.picture
      pointer-events: all
      position: static
      float: none
      border: none
      color: $white
      background: $primary
      border: 1px solid $primary
      margin-top: 25px
      font-size: $fontSizeL !important
      padding: 0 25px
      height: 35px
      box-sizing: border-box
      color: $white
      line-height: 31px
      font-family: $secondaryHeaderFont
      display: inline-block
      +transition($s)
      &:hover
        cursor: pointer
        color: $primary
        background: none
    &.normal
      pointer-events: all
      font-style: normal
      padding-left: 35px
      float: left
      text-align: left
      color: $primary
      font-weight: normal
      line-height: 1
      &:hover
        cursor: pointer
      &.gray
        color: $darkgray
    &.remember
      pointer-events: all
      font-style: normal
      margin-top: -10px
      width: 100%
      display: inline-block
      right: 0
      float: right
      text-align: right
      color: $black
      font-family: $secondaryHeaderFont
      &:hover
        cursor: pointer

    a
      font:
        weight: 600
  .action-icons
    height: 40px
    right: 0
    .action-icon
      height: 40px
      width: 40px
      svg
        margin: 5px
        padding: 5px
      div
        display: inline-block
        height: 40px
        width: 40px
  .select-option, .select-value
    display: flex
    img
      height: 25px
      width: 25px
      border-radius: $roundRadius
      margin-right: 10px
  &.matrix-table
    .cell
      padding: 0
  .react-datepicker-wrapper
    width: 100%

.list-sector
  background: $white
  padding: 10px 15px
  margin-top: 18px
  user-select: none
  &.more
    margin-top: 40px

  .list-actions
    display: flex
    height: 30px
    gap: 10px
    justify-content: space-between
    h2
      margin: 0
    .close-button, .go
      height: 14px
      width: 14px
      margin: 8px 0
      svg
        display: block
    .go
      transform: rotateZ(180deg)
      height: 22px
      width: 22px
      margin: 4px 0
  [class$="-control"], [class$="-control"]:hover
    background: $lightgray
  input, textarea, .input
    &:not([type = "submit"])
      background: $lightgray

.actions-input
  .list-sector
    width: auto
    width: 48%
    user-select: none
    &.full-width
      width: 100%
    
.step-form
  display: block
  max-width: 650px
  width: 100%
  margin: 0 auto
  &.transport-order-form
    svg
      width: 15px
      height: 15px
  &.location-form .opening-times
    svg
      width: 20px
      height: 20px
      fill: $primary
      transition: all 0.2s ease-in
      &:hover
        fill: $secondary
  .location-type-selector
    z-index: 9999
  #map-wrapper
    margin-top: 40px
    position: relative
  .leaflet-container
    height: 400px
    width: 100%
    .disable
      pointer-events: none

  &.wide
    max-width: 98%

.input-toolbutton
  width: 30px
  height: 30px
  right: 5px
  top: 5px
  left: auto
  position: absolute
  &:hover
    cursor: pointer
    svg
      fill: $primary

.field-path-selector
  height: 100%
  .field-depth
    background: rgba(200,200,200, 0.1)
    padding: 2px
    .field
      padding: 1px 2px
      &--selectable
        &:hover
          cursor: pointer
          color: $primary

.drag-and-drop
  user-select: none
  margin: 0
  .text-overlay
    display: none
  &.dragging
    .text-overlay
      width: 100%
      height: 100%
      z-index: 999
      display: flex
      position: absolute
      align-items: center
      justify-content: center
      background: linear-gradient(to bottom, rgba(0,0,0,0), $white 80%)
    .table
      opacity: 0.4
    p:not(.no-data)
      border-style: solid
      background: $green
      border-color: $green
      color: $white
  &.rejected
    p, .table
      background: $red
      border-color: $red
  &:hover
    cursor: pointer
  div:focus
    outline: none
    p
      border-color: $secondary
  p:not(.no-data)
    margin: 10px 0 0
    padding: 40px 35px
    text-align: center
    border: 1px dashed $primary
    color: $black
    font:
      size: .9em
    +transition($s)
    &:hover
      color: $primary

.image-cropper
  &.avatar
    width: 100%
    height: 300px
    position: relative
  &.banner
    width: 100%
    height: 300px
    position: relative
    .close-button
      width: 20px
      height: 20px
      position: absolute
      top: 10px
      right: 10px
      z-index: 1000
      svg
        fill: $white
        &:hover
          fill: $primary

.upload-files
  .file .actions
    display: flex
    justify-content: center
    margin-top: 5px
  .attachment-icon svg
    transform: translateY(4px)
    &:hover
      stroke: $primary
  &.small
    display: flex
    justify-content: flex-start
    .drag-and-drop
      width: 60px
      height: 60px
      padding: 0
      line-height: 60px
      p
        padding: 0
        margin: 0
    .uploaded-files
      margin: 0
      width: auto
      display: flex
      .actions
        width: auto

.minimum
  gap: 10px
  overflow-x: scroll
  flex-direction: row-reverse
  .uploaded-files
    gap: 20px
    .actions
      transition: all 0.2s ease-in
      svg
        width: 10px
        height: 10px
        transition: transform .2s ease-in-out
      &:hover
        svg
          fill: $primary
          transform: rotate(180deg)
      .remove-action
        margin-left: 5px

.uploaded-files
  .file
    position: relative
    background: $llightgray
    display: flex
    min-height: 62px
    max-height: 62px
    max-width: 62px
    min-width: 62px
    text-align: center
    border-radius: 5px
    padding: 0px
    border: 1px dashed $primary
    img
      width: 60px
      height: 100%
      display: flex
      max-width: 60px
      color: $darkgray
      object-fit: contain
      font-size: $fontSizeXS
    .file-preview
      width: auto
      height: 100%
      overflow: hidden
      max-width: 100px
      border-radius: 3px
      background-size: cover
      background-position: center
    .info
      flex: 1
      display: flex
      flex-direction: column
      .name
        width: 100%
        height: 100%
        display: flex
        font-size: $fontSizeS
        color: $darkgray
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        flex-direction: column
        justify-content: center
        span
          font-size: $fontSizeXS
          color: $darkgray
      .state
        display: flex
        font-size: $fontSizeS
        flex-direction: column
        div
          align-self: center
        span
          padding-right: 10px

    .remove-action
      top: -5px
      width: 16px
      right: -5px
      height: 16px
      position: absolute
      align-self: center
      text-align: center
      background: $black
      border-radius: $roundRadius
      transition: all 0.2s ease-in
      svg
        width: 8px
        height: 8px
        fill: $white
        transform: translateY(-3px)
        transition: transform .2s ease-in-out
      &:hover
        cursor: pointer
        background: $primary
        svg
          fill: $white
          transform: translateY(-3px) rotate(180deg)

.signature
  border: 1px solid $primary
  display: block

.drag-and-drop
  label
    position: static
    pointer-events: all
    width: 100%

.DateRangePicker
  z-index: 0

.DayPicker-Month
  margin: 0 !important
.DayPicker, .DayPicker-wrapper
  &:focus
    outline: none
.DayPickerInput
  display: block !important
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover
    background-color: $primary

.rg-dropdown-cell 
  input
    height: auto 
    
.switch
  position: relative
  display: inline-block
  width: 60px
  height: 34px
  &.disabled
    opacity: 0.4
    pointer-events: none
  input
    opacity: 0
    width: 0
    height: 0
  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ccc
    +transition($s)
    &:before
      position: absolute
      content: ""
      height: 26px
      width: 26px
      left: 4px
      bottom: 4px
      background-color: white
      +transition($s)
    &.round
      border-radius: $radiusL
      &:before
        border-radius: $roundRadius
  input
    &:checked + .slider
      background-color: $primary
    &:focus + .slider
      box-shadow: 0 0 1px $primary
    &:checked + .slider:before
      -webkit-transform: translateX(26px)
      -ms-transform: translateX(26px)
      transform: translateX(26px)

.or
  width: 100%
  height: 50px
  display: block
  text-align: center
  margin: auto
  line-height: 50px
  color: $secondary
  position: relative
  &::before, &::after
    width: 30%
    height: 1px
    background: $secondary
    content: ""
    position: absolute
    margin-top: 25px
  &::before
    left: 15%
  &::after
    right: 15%

.range-slider
  -webkit-appearance: none
  width: 100%
  height: 15px
  border-radius: $radiusS
  background: $lightgray
  outline: none
  opacity: 0.7
  -webkit-transition: .2s
  transition: opacity .2s
  &::-webkit-slider-thumb
    -webkit-appearance: none
    appearance: none
    width: 25px
    height: 25px
    border-radius: $roundRadius
    background: $primary
    cursor: pointer
  &::-moz-range-thumb
    width: 25px
    height: 25px
    border-radius: $roundRadius
    background: $primary
    cursor: pointer

.bank-options
  display: flex
  flex-wrap: wrap
  justify-content: space-around
  height: 210px
  width: 520px
  margin: 35px auto 0
  .bank-option
    width: 90px
    height: 90px
    overflow: hidden
    display: flex
    background: white
    position: relative
    border-top: 2px solid transparent
    +transition($s)
    &:hover
      cursor: pointer
    &.selected,&:focus, &:hover
      outline: none
      border-top: 2px solid $primary
    &.selected,&:focus
      border-color: $secondary
      &::after
        content: ""
        position: absolute
        width: 0
        height: 0
        left: 50%
        top: 0
        transform: translateX(-50%)
        border-left: 10px solid transparent
        border-right: 10px solid transparent
        border-top: 10px solid $secondary
        z-index: 100
        +transition($s)
    img
      justify-content: center
      align-self: center
      width: 90%
      margin-left: 5%
      height: auto

.rating
  border: none
  display: flex
  flex-direction: row-reverse
  justify-content: flex-end
  &.small
    label:before
      font-size: $fontSizeH3
  input
    display: none
  label:before
    margin: 5px
    user-select: all
    font:
      size: $fontSizeH2
      weight: 900
    display: inline-block
    content: "\2605"
  .half:before
    content: "\f089"
    position: absolute
  label
    pointer-events: all
    color: $gray !important
    position: static
  input:checked > label
    color: #FFD700 !important
  &:not(:checked) >label:hover
    color: #FFD700 !important
    cursor: pointer
    label
      color: #FFD700 !important
  >
    input:checked
    + label:hover, > label:hover
      color: #FFED85 !important
    label:hover > input:checked  label, input:checked > label:hover >  label
      color: #FFED85 !important

.stepper
  width: 100%
  color: $secondary
  margin: 0 0 35px
  font:
    size: .9em
  display: flex
  .step
    flex: 1
    text-align: left
    border-bottom: 2px solid $gray
    user-select: none
    margin-right: 30px
    +transition($s)
    &:last-child
      margin-right: 0px
    &.disabled
      opacity: 0.6
    &:not(:disabled):hover
      cursor: pointer
      transfrom: scale(1.05)
    .number
      background: $primary
      color: $white
      border-radius: $roundRadius
      display: inline-block
      height: 30px
      width: 30px
      text-align: center
      line-height: 30px
    .text
      margin: 10px 0
    &.active
      border-bottom: 2px solid $primary

.sidebyside-input
  display: flex
  .left-side, .right-side
    position: relative
    width: 50%

.contentEditable,.variableContentEditor
  white-space: pre-wrap
  white-space: -moz-pre-wrap
  white-space: -pre-wrap
  white-space: -o-pre-wrap
  word-wrap: break-word

.variableContentEditor
  border: none
  background: $lightgray
  height: 40px
  width: 100%
  padding: 0px 10px
  font:
    size: 1em
    family: $basicFont
  color: $primary
  min-height: 250px
  height: auto
  display: block
  padding: 10px
  box-sizing: border-box
  max-width: 100%

.variableContentEditorSuggestionList
  list-style: none
  margin: 15px 5px
  padding: 5px
  box-shadow: $shadow
  background-color: $white
  border-radius: $radiusS
  position: fixed
  top: 0
  left: 0
  z-index: 1000
  max-height: 200px
  overflow: scroll
  -ms-overflow-style: none /* IE and Edge */
  scrollbar-width: none /* Firefox */
  li
    margin-bottom: 1px
    background: $primary
    padding: 0 10px
    cursor: pointer
    min-width: 60px
    font-size: $fontSizeM
    color: white
    text-align: center
    border-radius: $radiusS
    &:hover
      background: $darkgray
.variableContentEditorSuggestionList::-webkit-scrollbar
  display: none

.step-form
  &.messageAutomation
    svg
      width: 15px
      height: 15px
      cursor: pointer
      transition: all 0.2s ease-in
      &:hover
        fill: $primary
  &.saml
    .refresh
      color: $black
      font-size: $fontSizeM
      font-weight: 600
      padding: 5px 12px
      line-height: 19px
      border-radius: $radiusS
      letter-spacing: 0em
      background: transparent
      border-color: $llightgray
      transition: all 0.2s ease-in
      &:hover
        color: $white
        background: $primary
        border-color: $primary
    .subtitle
      margin: 0
      color: $gray
      font-size: $fontSizeS
      font-style: italic
    .separator
      display: flex
      margin-top: 25px
      align-items: center
      text-align: center
      color: $llightgray
    .separator::before,
    .separator::after
      content: ''
      flex: 1
      border-bottom: 1px solid $llightgray
    .separator:not(:empty)::before
      margin-right: .25em
    .separator:not(:empty)::after
      margin-left: .25em
.saml-view
  p, h3, h4
    margin: 0

.date-picker-modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  align-items: center
  justify-content: center
  z-index: 1000

.date-picker-modal-content
  background: white
  padding: 20px
  border-radius: 8px
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2)
  max-width: 500px
  width: 100%

@media only screen and (max-width: 550px)
  form
    margin-top: 0
    padding: 15px 0
    display: inline-block
    .input-container
      width: 100%
    .banner-image
      width: 300px
      height: 168px
    .input-group
      &.small-full
        width: 100%
      &.small-eighty
        width: 80%
      &.small-sixty
        width: 60%
    input[type="submit"]
      padding: 0 15px
  .bank-options
    height: 500px
    width: 310px

@media only screen and (max-width: 650px)
  .input-container
    width: 100%
