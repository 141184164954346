.start
  display: flex
  min-height: 600px
  height: 100vh

  .visual
    flex: 0 0 50%
    overflow: hidden
    position: relative
    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: $primary
      opacity: 0.3
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: linear-gradient(-45deg, $primary, rgba(255, 255, 255, 0))

    img
      width: 100%
      height: 100%
      object-fit: cover

    .content
      position: absolute
      top: 50%
      left: 50%
      width: 80%
      transform: translate(-50%, -50%)
      z-index: 10
      h1, h2, h3, h4, h5, h6, a
        display: block
      h1
        color: $white
        z-index: 100
        margin: 0
        font:
          size: 6vw
          weight: bold

      a
        color: $white
        text-decoration: underline
        margin-top: 25px
        font:
          size: 2vw

        &:hover
          text-decoration: none

  .action
    height: 100vh
    flex: 0 0 50%
    overflow: scroll
    position: relative

    .absolute-center
      max-height: 100vh

    &.center
      text-align: center

    &.info
      h1
        margin-bottom: 18px

    .content
      display: none
      text-align: center

    form
      width: 50%
      min-width: 350px
      margin: 0 auto
      padding: 0
      input[type = "checkbox"]
        &:checked
          background: $primary

    a.center
      margin: 40px auto 0
      display: block
      text-align: center

  img
    &.logo
      display: block
      width: 85px


@media only screen and (max-width: 960px)
  .start
    img.logo
      margin: 0 auto
      width: 250px
      max-height: 100px
    .visual
      flex: 0 0 100%
      .content
        display: none

    .action
      position: absolute
      width: 90%
      height: 100%
      left: 5%
      background: $lightgray
      .absolute-center
        width: 80%
        max-height: 100vh
      .content
        display: block
        text-align: left

        h1
          font-size: $fontSizeH1
          margin: 20px 0
          text-align: center

      form
        width: 100%
        min-width: auto

@media only screen and (max-width: 960px)
  .start
    img.logo
      margin: 0 auto
      width: 250px
      max-height: 100px
    .visual
      flex: 0 0 100%
      .content
        display: none
    .action
      position: absolute
      width: 90%
      height: 100%
      left: 5%
      background: $lightgray
      .absolute-center
        width: 80%
      .content
        display: block
        text-align: left
        h1
          font-size: $fontSizeH1
          margin: 20px 0
          text-align: center

      form
        width: 100%
        min-width: auto