.goods-form-element
    +transition($s)
    &:hover
        cursor: pointer
        transform: scale(1.05)
        .go svg
            fill: $primary
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px

.goods-view
    .adr-view
        padding-left: 20px
    .goods
        margin-top: 25px

.transportEquipment-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray

.transportEquipment-results
    max-height: 400px

.transportEquipment-card
    h3, h2
        margin: 0
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px