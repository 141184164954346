
.settings
	.xxl-box
		&.container
			width: 100%
			padding-left: 3%
			section
				width: 100%
		.content-wrapper
			padding: 15px 30px

	.billing-overview
		hr
			margin: 50px 0px
		.overview-table-wrapper
			margin-top: 25px
			.overview-table
				.filter-row
					margin-top: 20px
				input
					border: 1px solid
			.table
				width: 100%
				height: 100%
				max-width: 75vw
				overflow-x: auto
				min-height: 460px
				margin-top: 20px
				&::-webkit-scrollbar
					width: 6px
					height: 6px
				&::-webkit-scrollbar-track
					-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1)
					background-color: $white
				&::-webkit-scrollbar
					width: 2px
					height: 2px
					border-radius: $radiusS
					background-color: $lightgray
				&::-webkit-scrollbar-thumb
					background-color: $primary
				table
					background: $white
					white-space: nowrap
					border-collapse: collapse
					width: -webkit-fill-available
					.month-header
						display: flex
						align-items: center
						justify-content: space-between
						svg
							cursor: pointer
							stroke: $darkgray
							transform: translateY(3px)
							transition: all 0.2s ease-in
							&:hover
								stroke: $primary
								
					.feature
						font-weight: bold
						text-transform: capitalize
						font-weight: 600
						font-size: $fontSizeL
						line-height: 150%
						color: $black
						text-align: left
					tr
						border-bottom: 1px solid $lightgray
					tr:last-child
						border-bottom: 0px
					th
						color: $darkgray
						background: $llightgray
						filter: brightness(1.05)
						text-transform: capitalize
						font-style: normal
						font-weight: 600
						font-size: $fontSizeM
						border-bottom: 1px solid $lightgray
						line-height: 150%
						letter-spacing: 0.05em
					td
						font-style: normal
						font-weight: 400
						font-size: $fontSizeL
						line-height: 150%
						color: $black
					th, td
						text-align: center
						padding: 12px 24px
		.filter-panel
			left: 0
			top: 40px
			z-index: 10
			margin-top: 5px
			position: absolute
			background: $white
			border: 1px solid $gray
			transition: all 0.4s ease-in
			.footer
				padding: 15px 20px
				border-top: 1px solid $llightgray
				.filter-button
					width: 100%
					color: $white
					font-size: $fontSizeL
					font-weight: 600
					line-height: 24px
					border-radius: $radiusS
					&.empty
						color: $primary
						background: $white
						&:hover
							color: $white
							background: $primary
					&:hover
						color: $primary
			.panel-header
				padding: 15px
				border-bottom: 1px solid $llightgray
				h3
					margin: 0
					color: $black
					font-size: $fontSizeL
					font-weight: 600
					line-height: 24px
				.close-button-wrapper
					width: 100%
					.close-button
						width: 15px
						height: 15px
			&.reveal
				width: 360px
				display: block
				height: fit-contents
			&.hidden
				display: none
			input[type="checkbox"]
				width: 10px
				height: 10px
				padding: 6px
				margin: 0 5px
				border-radius: $radiusS
				border-width: 2px !important
				border-color: $black !important
				&:checked
					background: $white
					border-color: $primary !important
					&::after
						content: ''
						width: 10px
						height: 10px
						color: $primary
						mask-size: cover
						transform: translate(1px, 1px)
						background: $primary
						-webkit-mask-size: cover
						mask: url('../../../public/icons/checkmark.svg') no-repeat 50% 50%
						-webkit-mask: url('../../../public/icons/checkmark.svg') no-repeat 50% 50%
			.filter-group
				padding: 15px 20px
				h4
					margin: 0
					color: $black
					font-size: $fontSizeM
					font-weight: 600
					line-height: 20px
				.options
					padding-top: 5px
					.mr-2
						margin-top: 20px
						margin-right: 20px
					label
						color: $black
						font-size: $fontSizeM
						font-weight: 400
						margin-left: 5px
						line-height: 140%
						text-align: center
		.overview-chart
			.chart-wrapper
				overflow-x: scroll
				.recharts-wrapper
					width: 1200px
				.recharts-tooltip-cursor
					display: none !important
				.recharts-label
					color: $black
					writing-mode: tb
					font-weight: bold
					transform: translateX(-20px)
				.recharts-legend-wrapper
					overflow-x: scroll
					width: 100% !important
					.recharts-legend-item-text
						text-transform: capitalize
			.overview-stats
				width: 100%
				display: flex
				margin: 20px 0
				justify-content: space-between
				.stats
					width: 100%
					text-align: left
					padding: 15px 10px
					border-right: 1px solid $darkgray
					.title
						font-size: $fontSizeM
					.stat
						font-size: $fontSizeL
						font-weight: bold
				.feature-count
					border-right: 0px
				.tooltip
					padding: 10px 5px
					min-width: 120px

					h5, h6
						margin: 0 !important
						width: fit-content

		.progress-overview
			.title
				font-style: normal
				font-weight: 700
				font-size: $fontSizeH1
				line-height: 38px
				letter-spacing: 0.05em
				color: $black
			.subtitle
				font-style: normal
				font-weight: 400
				font-size: $fontSizeM
				line-height: 14px
				letter-spacing: 0.05em
				color: $darkgray
				margin-bottom: 25px
			.text
				font-style: italic
				font-weight: 300
				font-size: $fontSizeS
				line-height: 12px
				letter-spacing: 0.05em
				color: $black
			.paid-bill
				color: $black
				font-style: normal
				font-weight: 400
				font-size: $fontSizeH2
				line-height: 29px
				margin: 5px 0 0 
				letter-spacing: 0.05em
			.expected-bill
				width: 100%
				color: $black
				position: relative
				text-align: right
				font-style: normal
				font-weight: 400
				font-size: $fontSizeL
				line-height: 21px
				letter-spacing: 0.05em
				p
					margin: 0
					padding: 20px 0 0
				&::after
					content: " "
					top: 1px
					right: 0
					width: 0
					height: 0
					bottom: 100%
					position: absolute
					pointer-events: none
					border-bottom: 12px solid $primary
					border-left: 6px solid transparent
					border-right: 6px solid transparent
			.progress-bar-wrapper
				width: 100%
				height: 25px
				margin: 0 0 5px
				border-radius: $radiusS
				background-color: $lightgray
			.progress-bar
				height: 100%
				border-radius: $radiusS
				background-color: $primary

		.stat-wrapper
			row-gap: 20px
			margin: 25px 0
			flex-wrap: wrap
			flex-direction: row		
		.bar-chart
			width: 100%
			min-height: 450px
			border-radius: $radiusS
			.applied-filter
				color: $primary
				font-size: $fontSizeM
				font-weight: 400
				line-height: 19px
				padding: 5px 12px
				border-radius: $radiusS
				background: $white
				border-color: $llightgray
				text-transform: capitalize
				transition: all 0.2s ease-in
				&:hover
					color: $white
					background: $primary
			.export
				svg
					width: 14px
					height: 14px
					fill: $white
					transform: translateY(2px)
					transition: all 0.2s ease-in
				&:hover
					svg
						fill: $primary
			.filter
				color: $black
				font-size: $fontSizeM
				font-weight: 600
				padding: 5px 12px
				line-height: 19px
				border-radius: $radiusS
				letter-spacing: 0em
				background: transparent
				border-color: $llightgray
				transition: all 0.2s ease-in
				.filter-count
					background: $primary
					color: $white
					width: 22px
					height: 22px
					font-weight: 700
					font-size: $fontSizeS
					line-height: 19px
					border-radius: $radiusS
					transition: all 0.2s ease-in
				svg
					width: 14px
					height: 14px
					transform: translateY(2px)
					transition: all 0.2s ease-in
				&:hover
					color: $white
					border-color: $primary
					background: $primary
					.filter-count
						color: $primary
						background: $white
					svg
						fill: $white
			.chart-header
				.title
					font-style: normal
					font-weight: 600
					font-size: $fontSizeL
					line-height: 24px
					color: $darkgray
					margin: 0 0 5px
				.subtitle
					margin: 0 0 0
					font-style: normal
					font-weight: 400
					font-size: $fontSizeM
					line-height: 16px
					color: $gray
			.chart-label
				font-style: normal
				font-weight: 400
				font-size: $fontSizeM
				line-height: 16px
				text-align: center
				color: $gray
				flex-direction: column
			.chart-wrapper
				.recharts-legend-wrapper
					overflow: scroll
					width: 100% !important
					.recharts-custom-legend
						column-gap: 20px
						padding: 0
						flex-wrap: wrap
						align-items: center
						justify-content: center
						display: flex !important
						li
							cursor: pointer
							align-items: center
							display: flex !important
							transition: all 0.2s ease-in
							width: fit-content !important
							font-style: normal	
							font-weight: 600
							font-size: $fontSizeM
							line-height: 20px
							color: $darkgray
							span
								width: 22px
								height: 11px
								margin-right: 5px
							&.legend-bar
								color: $black
								font-weight: 700
							&.clicked
								span
									background: transparent !important
				.recharts-wrapper
					.tooltip-wrapper
						box-shadow: 0 0 4px $lightgray
						.tooltip
							min-width: 140px
							padding: 10px 12px 6px 
							.mb-15
								margin-bottom: 15px
							h4, span
								margin: 0px 0                           
								font-size: $fontSizeM
								line-height: 16px
								letter-spacing: 0em
							.stat-icon
								width: 8px
								height: 8px
								border-radius: $roundRadius
								background: #efdc30
							.stat-date
								float: right
								font-size: $fontSizeXS
								color: $gray
								transform: translateY(-10px)
						hr, .separator
							margin: 0
							border-top: 1px solid $llightgray
					.recharts-tooltip-cursor
						display: none !important
				.chart-label-y
					margin: 40px 0px
					font-style: normal
					font-weight: 400
					font-size: $fontSizeM
					line-height: 16px
					color: $gray
	
	.transportial-pricing
		padding: 40px 0
		.checkboxes
			label
				margin-right: 20px
				input[type="checkbox"]
					margin-right: 10px
					border-radius: 0px
					border: 2px solid $black !important
				input[type="checkbox"]:checked
					background: transparent !important
					&::after
						color: $black !important
		.pricing-cards
			padding: 40px 0
			column-gap: 60px
		.pricing-card
			width: 320px
			height: 300px
			display: flex
			overflow: hidden
			position: relative
			margin-right: 0px
			margin-bottom: 80px
			transition: all 0.4s ease-in
			&.expand
				height: fit-content
				.carrot
					transform: rotateX(180deg)
			.card-header
				color: $white
				padding: 10px 12px
				hr
					margin: 0
					width: 210px
					margin-bottom: 2px
					border: 1px solid $white
				h2
					font-size: $fontSizeH3
				.price
					font-size: $fontSizeH1
					font-weight: 800
				.unit
					transform: translateY(-5px)
			.card-content
				padding: 20px
				border: 1px solid $gray
				border-top: 0px
				height: -webkit-fill-available
				.table
					width: 90%
					font-size: $fontSizeL
					td
						padding: 5px 0
					.currency
						font-weight: 700
						padding-left: 10px
					.free
						color: $red
						font-size: $fontSizeS
						text-align: center
				.subtitle
					margin-top: 0
					font-size: $fontSizeL
					font-weight: 700
				.content
					font-size: $fontSizeL
					font-weight: 400
					margin-bottom: 0
					margin-top: 0
					white-space: pre-line
			.expansion-overlay
				left: 0
				width: 100%
				bottom: 0px
				border-left: 1px solid $gray
				border-right: 1px solid $gray
				background: linear-gradient(to bottom, rgba(0,0,0,0), $white 50%)
				.carrot
					transition: all 0.2s ease-in
		
	.platform
		.pricing-form-element
			background: $llightgray !important
		.planning
			.input-group
				&::after
					content: none
				input
					width: 30%
					max-width: 120px
					min-width: 120px
		.jsoneditor-wrapper
			position: relative
			.ace_gutter
				z-index: 0
			.cancel
				top: 48px
				right: 10px
				color: $white
				padding: 0 10px
				cursor: pointer
				position: absolute
				transition: all 0.2s ease-in
				&:hover
					color: $black
			.jsoneditor-react-container
				height: 400px
				.jsoneditor
					border: 1px solid $llightgray
					.jsoneditor-menu
						background: $primary
						border-bottom-color: $llightgray
						.jsoneditor-poweredBy, .jsoneditor-transform, .jsoneditor-sort
							display: none
					.ace_active-line
						background: $llightgray

	.container
		padding-top: 0px
		h1
			margin: 0
		.__box
			width: 100%
			display: flex
			flex-wrap: wrap
			min-height: calc(100vh - 150px)
		.tabs
			flex: 1.2
			position: relative
			&.left li
				height: auto !important
				padding-left: 18px
		section
			flex: 4.5
			min-height: 520px
			box-sizing: border-box
			display: none
			&.active
				display: block
			h1
				color: $black
		form
			text-align: left
			h1
				margin: 0
				text-align: left

	.highlight-box
		.scrollable-table
			max-height: 80vh
	.sessions
		.session
			padding: 10px
			background: $lightgray
			&:not(:first-child)
				margin-top: 10px
			h2, h3
				font-size: $fontSizeH3
				margin: 0
			h3
				&:not(:first-child)
					margin-left: 8px
	.section
		background-color: $white
		padding: 20px
		border-radius: $radius
	.inline-form
		margin-bottom: 10px
		font-family: Montserrat, sans-serif
		input
			margin-left: 1px
			height: 35px
			padding: 0 15px
			font-size: $fontSizeM
			font-family: Montserrat, sans-serif
			&.first
				border-radius:  $radiusL 0 0 $radiusL
		.switch
			min-width: 50px
		button
			border-radius: 0 $radiusL $radiusL 0
			height: 35px
			padding: 5px 10px
			min-width: 100px
		.update-button 
			border-radius: 0
			border-right: 1px solid white

.two-factor-setting
	display: flex
	align-items: center
	flex-direction: column
	margin-top: 5px
	text-align: center

	.qrcode
		display: block
		text-align: center
		img
			width: 150px
			height: 150px
	.otp-input-group
		display: flex
		gap: 10px
		.otp-input
			text-align: center
			letter-spacing: 5px

	.otp-input::-webkit-outer-spin-button,
	.otp-input::-webkit-inner-spin-button
		-webkit-appearance: none
		-moz-appearance: textfield
		margin: 0

@media all and (max-width: 1024px)
	.settings
		.pricing-cards
			column-gap: 20px !important
@media all and (max-width: 660px)
	.settings
		.set-duration
			flex-wrap: wrap
			h4
				margin-bottom: 0px
		.container
			&.xxl-box
				.content-wrapper
					padding: 15px
			.overview-table-wrapper
				.table
					max-width: 90vw !important
			.__box
				flex-direction: column
			.pricing-card
				height: 320px
				margin-bottom: 40px
			.settings-box
				display: none
				min-height: auto
			.select-box
				width: 100%
				.new
					display: none
			section
				height: auto
				min-height: auto
				padding: 0

