
.invoice, .quote
  .information
    margin-bottom: 35px
  &-details-container
    text-align: left
    font-family: Montserrat, sans-serif
    font-size: $fontSizeM
    p
      margin: 0
    header
      display: grid
      grid-template-columns: 2fr 1fr
      align-items: center
      margin: 20px 0

    .logo
      display: grid
      img, svg
        width: 150px
        height: 150px
    header
      display: grid
      grid-template-columns: 2fr 1fr
      align-items: center
      .header-dates
        font-size: $fontSizeM
      .date-item
        display: grid
        grid-template-columns: repeat(2, 1fr)

    table
      width: 100%
      tr
        td
          padding: 0 2px 0 0
        &.invoice-line
          td
            padding: 10px 5px
            border-top: 1px solid $gray
        &.summary-line
          td
            padding: 10px 5px
            border-top: 1px solid $gray
        &.summary
          td
            border-top: 1px solid $gray
            padding: 25px 5px 10px 5px
      td
        padding: 2px 0
      
  &-form-labels
    font-family: Montserrat, sans-serif
    font-weight: bold
    font-size: $fontSizeM
  &-details-contact
    margin: 5px
  &-details-lines
    margin: 5px
  &-details-administration
    display: grid
    grid-template-columns: 2fr 1fr
  &-3fr
    grid-template-columns: 1fr 1fr 1fr
  &-4fr
    grid-template-columns: 1fr 1fr 1fr 1fr
  &-2fr-1fr
    grid-template-columns: 2fr 1fr
  &-1f-1fr
    grid-template-columns: 1fr 1fr
  &-1f-2fr
    grid-template-columns: 1fr 2fr

  &-description-field
    border-radius: $radiusS 5px 0 0
    font-family: Montserrat, sans-serif
    font-size: $fontSizeM

  &-description-bottom
    border-radius: 0 0 5px 5px
    padding: 5px 10px
    background-color: $lightgray
    margin-top: 1px
    font-weight: bold
    font-family: Montserrat, sans-serif
    font-size: $fontSizeM
  &-period
    color: $primary !important
    cursor: pointer
  &-footer-actions
    padding-top: 30px
    border-top: 1px solid $lightgray
    text-align: right
  &-date-input-field
    padding: 0 10px
    background-color: $lightgray
    border-radius: $radiusS
    color: $primary
    font-weight: 600
    height: 40px
    line-height: 40px
    font-family: Montserrat, sans-serif
    font-size: $fontSizeM
    cursor: pointer

  .terms-and-conditions
    margin-top: 50px
  .actions
    margin-top: 50px
    display: flex