.location-view
    &__map
        width: 100%
        height: 300px
        z-index: 0
    
.location-info
    margin: 20px 0


.location-info-row
    display: flex
    justify-content: space-between
    padding: 10px 0
    border-bottom: 1px solid $lightgray
    &__name
        align-self: center
        font-weight: bold
    .lds-ripple
        margin: 0

.location-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray

.location-results
    max-height: 400px

.location-card
    h3, h2
        margin: 0
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px