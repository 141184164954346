
.widget-public-page
    

.widget-view
    &__preview
        margin-top: 25px
        iframe
            width: 100%
            height: 350px
.widget
    &__map
        width: 100%
        height: 100%