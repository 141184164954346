.chauffeurs-search
  margin: 0 auto
  &__search-container
    margin-top: 20px

.chauffeur-view
    &__box
        height: 100%
        padding: 10px
        margin-top: 0
        background: $white
        position: relative
        border-radius: $radius
        box-shadow: $boxShadow
        border: 1px solid $gray 

.chauffeur-times
    display: flex
    flex-direction: column
    .driving, .resting
        margin-top: 20px
    .time-row
        padding: 5px
        border-bottom: 1px solid $gray
        &--header
            font-weight: bold
        &__name
            padding-right: 5px
        .progress-grid
            border-right: 1px solid $gray
            border-left: 1px solid $gray

.chauffeur-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray

.chauffeur-results
    max-height: 400px

.chauffeur-card
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px

.chauffeur-planner
    display: grid
    grid-template-columns: auto 5fr
    gap: 10px
    font-family: Montserrat, sans-serif
    &.not-plannable
        grid-template-columns: 1fr
    .attachments
        overflow: scroll
        height: auto
        max-height: 100vh
        min-width: 300px
        