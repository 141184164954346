.fleet-vehicle
    font-size: $fontSizeH3
    display: flex
    &__number
        font-weight: bold
    &__name
        span
            +transition($s)
            &:hover
                color: $primary
                cursor: pointer