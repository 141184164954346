@import "_fonts"
$theme: var(--theme)

// Fonts
$basicFont: "Roboto", Helvetica, Arial, sans-serif
$primaryHeaderFont: "Titillium Web", sans-serif
$secondaryHeaderFont: "Montserrat", sans-serif

// Font sizes
$fontSizeH1: 32px
$fontSizeH2: 25px
$fontSizeH3: 20px

$fontSizeL: 17px
$fontSizeM: 14px
$fontSizeS: 11px
$fontSizeXS: 8px

// Theme colors
$primary: var(--primary-color) 
$secondary: var(--secondary-color)

$white: var(--white)
$black: var(--black)

// Grays
$gray: var(--gray)
$darkgray: var(--darkgray)
$lightgray: var(--lightgray)
$llightgray: var(--llightgray)

// Shadows
$popOverBackground: rgba(50,50,50,0.75)

$shadow: 0px 4px 11px -5px rgba(0, 0, 0, 0.29)
$shadowSmall:  2px 5px 9px 1px $black
$boxShadow: 0px 0px 30px -15px rgba(0, 0, 0, 0.19)
$formShadow: 0px 20px 65px -5px rgba(0, 0, 0, 0.19)
$popupShadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.10)


// General colors
$red : #d32525
$lightred : #fcb8b8
$blue: blue
$orange: orange
$green: green
$purple: purple
$orange: orange
$yellow: yellow


// Margins
$containerTopMargin: var(--container-margin-top)

// Constants
$s: 0.3s

//radius
$radiusS: 5px
$radius: 10px
$radiusL: 50px
$radiusXL: 150px
$roundRadius: 50%


@import "_mixins"
@import "_animations"
