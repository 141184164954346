.pricing-form-element
    +transition($s)
    &:hover
        cursor: pointer
        transform: scale(1.05)
        .go svg
            fill: $primary
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px

.pricing-selector
    input[type="checkbox"]
        margin-right: 15px
    label
        position: relative
        flex: 1
        top: 0
        left: 0
        pointer-events: all
        color: inherit
        padding: 10px 0
        border-bottom: 1px solid $gray
    
.pricing
    .information
        .name
            font-size: $fontSizeH2
    .actions-cards
        margin-top: 15px
        .action-card
            margin: 10px 0

.pricing-results
    max-height: 400px

.pricing-card
    .value-list
        display: flex
        .value-list-item
            margin-right: 8px
            