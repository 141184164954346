@import "../vars/_vars"
 
.shepherd-target
  pointer-events: none
  z-index: 10000000 !important
  position: relative !important

.shepherd-modal-overlay-container.shepherd-modal-is-visible
  opacity: 0.2 !important

.footer-text
  .shepherd-content
    margin-bottom: 30px !important

  &::after
    content: "If you wish to continue later, you can always access tutorials later from top menu"
    right: 0
    bottom: 4%
    color: $white
    margin: 0 15px
    font-size: $fontSizeS
    width: fit-content
    position: absolute
    pointer-events: none
    text-decoration: underline
    text-underline-offset: 1px

.shepherd-element
  margin: 10px
  white-space: pre-line
  color: $white !important
  z-index: 10000001 !important
  background: $primary !important
  &.payment-popup
    max-width: 760px
    min-height: 500px
    background: pink
    max-height: 500px
    margin: 0 !important
    color: $black !important
    .shepherd-content
      padding: 0 !important
      .shepherd-text
        padding: 0
    .payment-welcome
      width: 100%
      display: flex
      min-height: 500px
      .image-wrapper
        width: 45%
        display: flex
        padding: 68px 46px
        background: $primary
        flex-direction: column
        transform: translateY(-25px)
        .intro
          color: $white
          font-size: $fontSizeH2
          line-height: normal
        .title
          color: $black
          font-size: $fontSizeH1
          font-weight: 600
          line-height: normal
      .content
        width: 55%
        color: $black
        display: flex
        align-items: center
        transform: translateY(-25px)
        padding: 0px 54px 0px !important
        font-family: $secondaryHeaderFont
        p
          font-size: $fontSizeL
          line-height: 1.6
          transform: translateY(-15px)
        .price
          font-size: $fontSizeH1
          color: $primary
          line-height: normal
    background: $white !important
    .shepherd-footer
      width: 55%
      right: 0
      bottom: 85px
      position: absolute
      justify-content: space-between
      padding: 40px 58px 0px !important
      button
        color: $white !important
        border-radius: $radiusL !important
        background: $primary !important
  &.welcome-popup
    max-width: 760px
    margin: 0 !important
    color: $black !important
    .shepherd-content
      padding: 0 !important
    .payment-welcome
      width: 100%
      display: flex
      max-height: 500px
      .image-wrapper
        width: 45%
        display: flex
        img
          object-fit: cover
      .content
        width: 55%
        display: flex
        align-items: center
        font-family: $secondaryHeaderFont
        >div
          padding:  0 58px 40px
        .intro
          color: $primary
          font-size: $fontSizeH2
        .title
          font-size: $fontSizeH1
          font-weight: 600
          line-height: normal
        p
          font-size: $fontSizeL
    background: $white !important
    .shepherd-footer
      width: 55%
      right: 0
      bottom: 35px
      position: absolute
      justify-content: space-between
      padding: 40px 58px 0px !important
      button
        color: $white !important
        border-radius: $radiusL !important
        background: $primary !important
  &.hide-button
    button:last-of-type
      display: none
  &.move-top
    transform: translateY(-30px)
  &.move-left
    transform: translateX(-30px)
  &.move-top-left
    transform: translate(-30px, -30px)
  &.move-left-major
    transform: translateX(-110%)
  &.move-top-left-major
    transform: translate(-110%, -30px)
  .shepherd-content
    padding: 10px 0px
  .shepherd-header
    padding: 10px 15px 0px !important
    background: $primary !important
    .shepherd-title
      font-size: $fontSizeL
      color: $white !important
  .shepherd-text
    font-size: $fontSizeM
    line-height: 1.8
    padding: 10px 15px
    letter-spacing: 0.5px
    color: $white !important
    &#welcome-description
      padding: 0
      display: flex
      color: $black !important
  .shepherd-footer
    align-items: center
    padding: 0px 15px !important
    justify-content: space-between
    .shepherd-button-wrapper
      display: flex
    .shepherd-pagination
      width: 42%
      color: $white
      font-size: $fontSizeM
      text-decoration: underline
      text-underline-offset: 1px
    .shepherd-button
      margin: 0
      color: $white !important
      padding: 5px 20px !important
      transition: all 0.2s ease-out
      background: transparent !important
      &:hover
        background: rgba(0, 0, 0, 0.2) !important 
@media all and (max-width: 900px)
  .shepherd-element 
    &.move-left
      transform: none !important
    &.move-left-major
      transform: translateX(-5%)
    &.move-top-left-major
      transform: translate(-15%, -30px)

@media all and (max-width: 768px)
  .shepherd-element
    &.payment-popup
      max-width: 300px !important
      max-height: 400px !important
      min-height: 400px !important
      .shepherd-footer
        width: 100% !important
        bottom: 25px !important
        padding: 0px 20px !important
      .payment-welcome
        flex-direction: column
        .image-wrapper
          height: 30% !important
          width: 100% !important
          padding: 40px 20px !important
          .intro
            font-size: $fontSizeL !important
          .title
            font-size: $fontSizeH2 !important
        .content
          width: 100% !important
          padding: 0 20px !important
          p
            font-size: $fontSizeL !important
            transform: translateY(0px)
          .price
            font-size: $fontSizeH2 !important
    &.welcome-popup
      max-width: 300px !important
      .shepherd-footer
        width: 100% !important
        bottom: 25px !important
        padding: 0px 20px !important
      .payment-welcome
        flex-direction: column
        .image-wrapper
          height: 30% !important
          width: 100% !important
        .content
          width: 100% !important
          .intro
            font-size: $fontSizeL !important
          .title
            font-size: $fontSizeH3 !important
          p
            font-size: $fontSizeM !important
          >div
            padding: 0 20px !important