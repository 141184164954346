.tenders
  .actions
    display: flex
    margin-left: 8px
    z-index: 100
    height: auto

    &:first-child
      margin-left: 0

    .action
      margin: 0 4px
      position: relative
      display: flex

      &.no-margin-left
        margin-left: 0

      .action-input
        width: 200px

        &--small
          width: 180px

      .action-icon
        width: 40px
        height: 40px
        align-self: center

      .action-menu
        position: absolute
        top: 60px
        right: 15px
        width: 260px
        height: auto
        background: $white
        padding: 8px 3px
        display: none
        box-shadow: 0px 0px 43px -3px rgba(0, 0, 0, 0.30)
        animation: $s pop-out
        &.show
          animation: 0.1s pop-in
          display: block

          &::after
            border: solid transparent
            content: " "
            height: 0
            width: 0
            position: absolute
            pointer-events: none
            border-bottom-color: $white
            border-width: 15px
            right: 0px
            top: -30px

        .menu-container
          max-height: 300px

        .action-item
          padding: 4px 6px
          font-size: $fontSizeM
          flex-wrap: nowrap
          user-select: none

          &:hover
            cursor: pointer

          .name
            &:first-letter
              text-transform: uppercase

        input[type="checkbox"], input[type="radio"]
          width: auto
          margin: 0
          height: 15px
          width: 15px
          padding: 7px
          margin-right: 6px

          &:checked::after
            font-size: $fontSizeS
            line-height: 14px

        label.normal
          +transition($s)

          &:hover
            cursor: pointer
            color: $primary

  .tender-actions

  .tender-item
    display: grid
    grid-template-columns: 1fr 2fr 1fr
    // display: flex
    // justify-content: space-between
    font-family: Montserrat, sans-serif
    padding: 10px
    cursor: pointer
    border-radius: $radiusS
    margin-top: 10px
    align-items: center
    transition: all 0.2s ease-in
    gap: 10px
    border-color: $white

    &:hover
      border: 1px solid $primary
      // background-color: $llightgray

    .tender-business-details
      display: flex
      gap: 10px
      align-items: center

      .icon svg
        background-color: $llightgray
        padding: 7px
        margin-top: 5px
        border-radius: $radiusS
        width: 55px
        height: 55px
        stroke: $gray

      .name
        color: $darkgray
        font-weight: bold

      .package
        font-weight: bold
        color: $gray
        font-size: $fontSizeM

    .tender-location
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: 10px
      align-items: center

      .font-sm
        font-size: 14px
      .font-xs
        font-size: 12px

      .location-trip-time
        font-weight: bold
        color: $darkgray

        div:nth-of-type(2)
          color: $gray

      .align-right
        text-align: right

      .location-trip-stops
        justify-self: center
        display: flex

        .trip-stops-point
          background: $lightgray
          border-radius: $radiusL
          font-size: $fontSizeM
          color: $darkgray
          font-weight: bold
          height: 50px
          width: 50px
          display: flex
          margin: 0 10px
          align-items: center
          justify-content: center
          z-index: 10 !important

        .trip-stops-divider
          border: 1px solid $gray
          rotate: 270deg

    .tender-actions
      text-align: center
      justify-self: flex-end

      div:nth-of-type(1)
        font-size: $fontSizeL
        font-weight: bold
        color: $darkgray

        small
          font-size: $fontSizeM

// ------------ tender details ----------------- //
.tender-details
  .section
    margin-top: 20px
    background-color: $llightgray
    border-radius: $radius
    padding: 20px

    .section-title h1
      margin: 10px 0
      text-transform: capitalize
      font-size: $fontSizeH2
      color: $primary

@media all and (max-width: 1024px)
  .tenders
    .tender-location
      display: none

@media all and (max-width: 600px)
  .tenders
    .tender-item
      grid-template-columns: 1fr

    .tender-actions
      justify-self: start
