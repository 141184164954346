@import "vars/_vars"

.tutorial-highlight 
  animation: pulse 2s infinite

.info-icon 
  position: relative
  height: fit-content
  display: inline-block
  &:hover
    .icon
      background-color: $primary
    .tooltip 
      visibility: visible
      opacity: 1
  .icon 
    width: 18px
    padding: 5px
    height: 18px
    color: $white
    font-size: 12px
    border-radius: 50%
    background-color: $darkgray
    transition: all 0.2s ease-in
  .tooltip 
    left: 50%
    opacity: 0
    z-index: 99
    bottom: 125% 
    padding: 5px
    color: $white
    text-align: left
    border-radius: 4px
    position: absolute
    visibility: hidden
    white-space: nowrap
    transition: opacity 0.3s
    background-color: $darkgray

.cookie, .select-pop-over
  width: 100%
  height: 100%
  min-height: -webkit-fill-available
  position: fixed
  z-index: 1100
  top: 0
  left: 0

  .box
    position: absolute
    width: 600px
    height: auto
    max-height: 100%
    display: inline-block
    background: $white
    box-shadow: 0 0 140px rgba(0, 0, 0, 0.2)
    padding: 35px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    animation: cookie 900ms
    border-radius: $radius

    &.large
      width: 90%

  .title
    width: 100%
    text-align: center
    color: $primary
    size: $fontSizeH2
    font-weight: 100

  .text
    width: 100%
    height: auto
    text-align: center
    padding: 5px 30px 15px
    font-size: $fontSizeL
    box-sizing: border-box
    color: $black

  .choice
    width: 100%
    height: 50px
    text-align: center
    line-height: 50px
    margin-top: 10px

    button
      width: auto
      margin: 0 5px

      &:active, &:focus
        outline: none

      &:hover
        cursor: pointer
        background: none
        color: $primary

    a, .as-link
      padding: 0 25px

.notification-list
  .notification
    color: $black
    border-bottom: 1px solid transparent
    padding: 15px
    display: flex
    align-self: flex-start
    +transition($s)

    &:hover
      cursor: pointer
      background: $lightgray

    &:not(:last-child)
      border-bottom: 1px solid $gray

    img
      height: 60px
      width: 60px
      border-radius: $roundRadius
      object-fit: cover
      margin-right: 10px

    .content
      flex: 1

    .title
      font:
        size: $fontSizeH2
      display: flex
      justify-content: space-between

    .message
      color: $darkgray

    .date
      line-height: 28px
      font:
        size: $fontSizeM
        style: italic

.circle-sm
  min-width: 25px
  min-height: 25px
  display: flex
  align-items: center
  justify-content: center

.svg-sm svg
  fill: $black
  stroke: $black
  width: 9px
  height: 9px

.circle-md
  min-width: 30px
  min-height: 30px
  display: flex
  align-items: center
  justify-content: center

.svg-md svg
  fill: $primary
  stroke: $primary
  width: 10px
  height: 10px

.circle-lg
  min-width: 45px
  min-height: 45px
  display: flex
  align-items: center
  justify-content: center

.svg-lg svg
  stroke: $primary
  fill: $primary
  width: 20px
  height: 20px
  margin-top: 5px

.circle-icon-primary
  text-align: center
  border-radius: $roundRadius
  position: relative
  background: none
  z-index: 10

  &::after
    content: ""
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    border-radius: $roundRadius
    background: $primary
    opacity: 0.1
    z-index: -1
    +transition($s)

  svg, .svg-md svg, .svg-lg svg, .svg-sm svg
    stroke: $primary
    fill: $primary

  &:hover
    cursor: pointer

    &::after
      opacity: 1

    svg, .svg-md svg, .svg-lg svg, .svg-sm svg
      stroke: $white
      fill: $white


/* badge
.badge-sm
  min-width: 50px
  padding: 0 10px
  height: 20px
  border-radius: $radiusS
  display: flex
  align-items: center
  justify-content: center


  .visual
    flex: 0 0 50%
    overflow: hidden
    position: relative

    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: $primary
      opacity: 0.3

    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: linear-gradient(-45deg, $primary, rgba(255, 255, 255, 0))

    img
      width: 100%
      height: 100%
      object-fit: cover

    .content
      position: absolute
      top: 50%
      left: 50%
      width: 80%
      transform: translate(-50%, -50%)
      z-index: 10

      h1, h2, h3, h4, h5, h6, a
        display: block

      h1
        color: $white
        z-index: 100
        margin: 0
        font:
          size: 6vw
          weight: bold

      a
        color: $white
        text-decoration: underline
        margin-top: 25px
        font:
          size: $fontSizeH2

        &:hover
          text-decoration: none

  .action
    flex: 0 0 50%
    position: relative

    &.center
      text-align: center

    &.info
      h1
        margin-bottom: 18px

    .content
      display: none
      text-align: center

    form
      width: 50%
      min-width: 350px
      margin: 0 auto
      padding: 0

    a.center
      margin: 40px auto 0
      display: block
      text-align: center

  img
    &.logo
      display: block
      width: 85px


/* button



.info-bar
  border-left: 4px solid $primary
  background-color: $lightgray
  padding: 20px
  width: 100%
  text-align: left
  font-family: Montserrat, sans-serif
  border-radius: 0 $radiusS $radiusS 0
  font-size: $fontSizeM


.DateRangePicker__CalendarSelection
  border: 1px solid $primary !important
  background-color: $primary !important

.DateRangePicker__CalendarHighlight--single
  border: 1px solid $primary !important

.step-tracker
  display: flex
  padding: 5px 0
  gap: 5px
  .step
    width: 100%
    font-size: $fontSizeM
    font-weight: 600
    color: $gray
    span
      display: block
      background-color: $white
      height: 3px
      width: 100%
      margin-top: 4px
    &.active
      color: $primary
      span
        background: $primary





.inline-tags
  display: flex
  align-items: center

.inline-field-tags
  display: flex
  align-items: flex-start
  padding-top: 4px
  padding-bottom: 4px

.dropdown-item
  display: flex
  align-items: center
  width: 100%
  cursor: pointer
  margin: 0

.flex-menu-field
  display: flex
  align-items: center
  width: 225px
  height: 28px
  cursor: pointer
  margin: 0
  gap: 8px

.svg-tag
  width: 35px
  height: 17px
  padding: 0 10px
  text-align: center
  background-color: $darkgray
  border-radius: $radiusL
  svg
    width: 13px
    height: 17px
    stroke-width: 3px
    stroke: $darkgray

.tag
  padding: 0px 10px 0 10px
  border-radius: $radiusL
  margin-right: 1px
  color: $darkgray
  text-align: center
  display: inline-block

  &-default, &-concept
    color: $darkgray
    background-color: $lightgray
  &-primary
    background: $primary
    color: $white
  &-off-route
    background: $purple
    color: $white
  &-too-late, &-closed, &-too_late
    background: $red
    color: $white
  &-too-early, &.too_early
    background: $blue
  &-needs-action, &-partially_planned, &-planned, &-projected, &-reminded
    background: $orange
    color: $white
  &-confirmed
    background: $yellow
  &-accepted, &-requested
    background: $lightgray
    color: $darkgray
  &-cancelled
    background: $red
    color: $white
  &-realized, &-completed, &-actual, &-in-transit, &-in_transit, &-ok, &-open
    background: $green
    color: $white
  &.no-padding
    padding: 0

  svg
    width: 13px
    height: 17px
    stroke-width: 3px
    stroke: $lightgray

.collapse
  position: absolute
  right: -10px
  top: 40%
  width: 17px
  height: 17px
  padding: 0
  svg
    rotate: 90deg


.variable-suggestions-dropdown
  animation-name: fade-in
  opacity: 0
  animation-duration: 0.2s
  animation-delay: 300ms
  animation-fill-mode: forwards

.rta--loading
  .variable-suggestions-dropdown
    top: 0px 
    overflow: unset
    max-width: unset
    max-height: unset 
    min-width: 200px
    background: $white
  .rta__loader
    .lds-ripple
      top: 25% !important
      margin: auto !important
  .rta__loader.rta__loader--empty-suggestion-data
    box-shadow: none	

.variable-suggestions-dropdown
  z-index: 10
  overflow: scroll 
  max-width: 400px
  max-height: 200px
  transform: translateY(10%)

.variable-suggestions-list
  min-height: 50px
  min-width: 100px
	
.variable-suggestions-list
  color: $primary
  width: 100% !important
  overflow: scroll !important
  padding: 0px 0px !important

.variable-suggestions-item
  width: 100%
  padding: 0px 0
  background: $white
  font-size: $fontSizeL
  border-bottom: 0px solid !important
  .rta__entity
    width: max-content
  .rta__entity--selected
    color: $black !important
    background: $lightgray !important
	
.variable-suggestions
  textarea
    line-height: normal
    min-height: 250px !important
    background: $lightgray !important

.attachment-icon svg
  stroke: $darkgray
  transition: all 0.2s ease-in
    
